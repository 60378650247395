import { ActivityAttachment } from '../../models/attachments/activity-attachment.model';
import {
  ActivityAttachmentAction,
  ADD_ACTIVITY_ATTACHMENTS,
  LOAD_ACTIVITY_ATTACHMENTS,
  REMOVE_ACTIVITY_ATTACHMENT,
  SET_ERRORS
} from '../actions/attachment.action';

export interface State {
    attachments: ActivityAttachment[];
    errors: string[];
}

export const initialState = (): State => ({
    attachments: [],
    errors: []
});

let lastId = 0;

export function reducer(
  state = initialState(),
  action: ActivityAttachmentAction
): State {
  switch (action.type) {
    case LOAD_ACTIVITY_ATTACHMENTS: {
      lastId = 0;
      return {
        ...state,
          attachments: action.attachments.map(a => ({
            ...a,
            uid: `${++lastId}`
          }))
      };
    }

    case ADD_ACTIVITY_ATTACHMENTS: {
      return {
        ...state,
          attachments: [
            ...state.attachments,
            ...action.attachments.map(a => ({
              ...a,
              uid: `${++lastId}`
            }))
          ]
      };
    }

    case REMOVE_ACTIVITY_ATTACHMENT: {
      return {
        ...state,
        attachments: [
          ...state.attachments.filter(a => action.uId !== a.uid)
        ],
        errors: []
      };
    }

    case SET_ERRORS: {
      return {
        ...state,
        errors: action.errors
      };
    }

    default: {
      return state;
    }
  }
}
