var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FailAction } from '../../../store/actions/fail-action';
export var LOAD_SECURITIES = '[SECURITIES] Load Securities';
export var LOAD_SECURITIES_SUCCESS = '[SECURITIES] Load Securities Success';
export var LOAD_SECURITIES_FAIL = '[SECURITIES] Load Securities Fail';
export var SUBMIT_SECURITIES = '[EVENT ACTIVITY DIALOG] Submit Securities';
export var SET_SELECTED_SECURITIES = '[SECURITIES] Set Selected Securities';
export var INIT_SELECTED_SECURITIES = '[SECURITIES] Init Selected Securities';
var LoadSecurities = /** @class */ (function () {
    function LoadSecurities(payload) {
        this.payload = payload;
        this.type = LOAD_SECURITIES;
    }
    return LoadSecurities;
}());
export { LoadSecurities };
var LoadSecuritiesSuccess = /** @class */ (function () {
    function LoadSecuritiesSuccess(payload) {
        this.payload = payload;
        this.type = LOAD_SECURITIES_SUCCESS;
    }
    return LoadSecuritiesSuccess;
}());
export { LoadSecuritiesSuccess };
var LoadSecuritiesFail = /** @class */ (function (_super) {
    __extends(LoadSecuritiesFail, _super);
    function LoadSecuritiesFail(error) {
        var _this = _super.call(this) || this;
        _this.error = error;
        _this.type = LOAD_SECURITIES_FAIL;
        return _this;
    }
    return LoadSecuritiesFail;
}(FailAction));
export { LoadSecuritiesFail };
var SubmitSecurities = /** @class */ (function () {
    function SubmitSecurities(payload) {
        this.payload = payload;
        this.type = SUBMIT_SECURITIES;
    }
    return SubmitSecurities;
}());
export { SubmitSecurities };
var SetSelectedSecurities = /** @class */ (function () {
    function SetSelectedSecurities(payload) {
        this.payload = payload;
        this.type = SET_SELECTED_SECURITIES;
    }
    return SetSelectedSecurities;
}());
export { SetSelectedSecurities };
var InitSelectedSecurities = /** @class */ (function () {
    function InitSelectedSecurities(payload) {
        this.payload = payload;
        this.type = INIT_SELECTED_SECURITIES;
    }
    return InitSelectedSecurities;
}());
export { InitSelectedSecurities };
