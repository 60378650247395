import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { filter, mergeMap, withLatestFrom, map, catchError } from 'rxjs/operators';
import { FailAction, LOG_AUTH_ERROR_TYPE, LogAuthErrorAction } from '../actions/fail-action';
import { LoggingService, LogLevel } from '../../services/logging.service';
import { BillfoldAuthClient } from '../../authorization/services/auth-client.service';
import { HttpErrorResponse } from '@angular/common/http';
import { HttpStatusCodes } from '../../event-activity/models';
import { EMPTY } from 'rxjs';

@Injectable()
export class LogEffects {
  constructor(
    private actions$: Actions,
    private authClient: BillfoldAuthClient,
    private loggingService: LoggingService
  ) {}

  @Effect({ dispatch: false })
  LogFailActions$ = this.actions$.pipe(
    filter(action => action instanceof FailAction),
    filter((action: FailAction) =>
      !(action.error instanceof HttpErrorResponse &&
      action.error.status === HttpStatusCodes.Unauthorized)),
    mergeMap((failAction: FailAction) => {
      return this.loggingService.log({
        message: this.getErrorString(failAction.error),
        level: LogLevel.Error
      }).pipe(catchError(() => EMPTY));
    })
  );

  @Effect({ dispatch: false })
  LogAuthError$ = this.actions$.pipe(
    ofType(LOG_AUTH_ERROR_TYPE),
    withLatestFrom(this.authClient.getAuthorizationToken()),
    map(([action, token]: [LogAuthErrorAction<any>, string]) => ({
      error: action.error,
      availableToken: action.availableToken || token
    })),
    filter((message) => !!message.availableToken),
    mergeMap((message) => {
      const error = message.error;
      const errorString = this.getErrorString(error);

      return this.loggingService.log({
          message: errorString,
          level: LogLevel.Error
        }, `IA ${message.availableToken}`
      ).pipe(catchError(() => EMPTY));
    })
  );

  private getErrorString(error: any) {
    if (error instanceof Error) {
      return JSON.stringify({
        message: error.message,
        stack: error.stack
      });
    } else {
      return JSON.stringify(error);
    }
  }
}
