import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import {
  CupcakePopoverContentInterface,
  CupcakePopoverTemplateInterface
} from '@ipreo/cupcake-components';
import { SuggesterItem } from '../../models/participant/internal-participant-suggester-item';
import { LinkService } from '../../services';
import { InternalParticipant } from '../../models';

@Component({
  selector: 'ea-internal-participant-group-popover',
  templateUrl: './internal-participant-group-popover.component.html',
  styleUrls: ['./internal-participant-group-popover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InternalParticipantGroupPopoverComponent
  implements CupcakePopoverContentInterface, OnInit, OnDestroy {
  context: Observable<any>;
  response: Subject<any>;
  popoverInstance: CupcakePopoverTemplateInterface;

  subscription: Subscription;

  constructor(public linkService: LinkService) {}

  ngOnInit() {
    this.subscription = this.context.subscribe(() => {
      this.popoverInstance.resize();
    });
  }

  removeItem(item: SuggesterItem<InternalParticipant>, e: Event) {
    e.stopPropagation();
    this.response.next(item);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
