/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./event-activity-defined-fields.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "../../components/adf-input/adf-input.component.ngfactory";
import * as i4 from "../../components/adf-input/adf-input.component";
import * as i5 from "../../components/activity-defined-fields-selector/activity-defined-fields-selector.component.ngfactory";
import * as i6 from "../../components/activity-defined-fields-selector/activity-defined-fields-selector.component";
import * as i7 from "@angular/common";
import * as i8 from "./event-activity-defined-fields.component";
import * as i9 from "@ngrx/store";
var styles_ActivityDefinedFieldsContainerComponent = [i0.styles];
var RenderType_ActivityDefinedFieldsContainerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ActivityDefinedFieldsContainerComponent, data: {} });
export { RenderType_ActivityDefinedFieldsContainerComponent as RenderType_ActivityDefinedFieldsContainerComponent };
function View_ActivityDefinedFieldsContainerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "c-text-warning c-m-top-xs"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("bd/event-activity/form.adfs_saving_error_message")); _ck(_v, 1, 0, currVal_0); }); }
function View_ActivityDefinedFieldsContainerComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "ea-adf-input", [], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = (_co.onChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_AdfInputComponent_0, i3.RenderType_AdfInputComponent)), i1.ɵdid(2, 114688, null, 0, i4.AdfInputComponent, [], { field: [0, "field"] }, { valueChange: "valueChange" })], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ActivityDefinedFieldsContainerComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "div", [["class", "c-row ea-adfs"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "c-col-2 c-p-right-0"], ["data-automation-id", "adf-name"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "label", [["class", "ea-adf-label"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 8, "div", [["class", "c-col-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 4, "ea-defined-fields-selector", [], null, [[null, "loadFields"], [null, "select"], [null, "hide"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("loadFields" === en)) {
        var pd_0 = (_co.onLoadActivityDefinedFields() !== false);
        ad = (pd_0 && ad);
    } if (("select" === en)) {
        var pd_1 = (_co.onSelect($event) !== false);
        ad = (pd_1 && ad);
    } if (("hide" === en)) {
        var pd_2 = (_co.onHide($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i5.View_ActivityDefinedFieldsSelectorComponent_0, i5.RenderType_ActivityDefinedFieldsSelectorComponent)), i1.ɵdid(7, 49152, null, 0, i6.ActivityDefinedFieldsSelectorComponent, [], { fields: [0, "fields"], loaded: [1, "loaded"], loading: [2, "loading"] }, { loadFields: "loadFields", select: "select", hide: "hide" }), i1.ɵpid(131072, i7.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpid(131072, i7.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpid(131072, i7.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_ActivityDefinedFieldsContainerComponent_1)), i1.ɵdid(12, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i7.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(14, 0, null, null, 3, "div", [["class", "c-col-7 c-p-left-0"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_ActivityDefinedFieldsContainerComponent_2)), i1.ɵdid(16, 278528, null, 0, i7.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null), i1.ɵpid(131072, i7.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform(_co.fields$)); var currVal_2 = i1.ɵunv(_v, 7, 1, i1.ɵnov(_v, 9).transform(_co.fieldsLoaded$)); var currVal_3 = i1.ɵunv(_v, 7, 2, i1.ɵnov(_v, 10).transform(_co.fieldsLoading$)); _ck(_v, 7, 0, currVal_1, currVal_2, currVal_3); var currVal_4 = i1.ɵunv(_v, 12, 0, i1.ɵnov(_v, 13).transform(_co.alert$)); _ck(_v, 12, 0, currVal_4); var currVal_5 = i1.ɵunv(_v, 16, 0, i1.ɵnov(_v, 17).transform(_co.selectedFields$)); var currVal_6 = _co.trackByFn; _ck(_v, 16, 0, currVal_5, currVal_6); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("bd/event-activity/form.adfs")); _ck(_v, 3, 0, currVal_0); }); }
export function View_ActivityDefinedFieldsContainerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ea-defined-fields-container", [], null, null, null, View_ActivityDefinedFieldsContainerComponent_0, RenderType_ActivityDefinedFieldsContainerComponent)), i1.ɵdid(1, 49152, null, 0, i8.ActivityDefinedFieldsContainerComponent, [i9.Store], null, null)], null, null); }
var ActivityDefinedFieldsContainerComponentNgFactory = i1.ɵccf("ea-defined-fields-container", i8.ActivityDefinedFieldsContainerComponent, View_ActivityDefinedFieldsContainerComponent_Host_0, {}, {}, []);
export { ActivityDefinedFieldsContainerComponentNgFactory as ActivityDefinedFieldsContainerComponentNgFactory };
