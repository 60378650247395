export enum ChangeAction {
  Update = 0,
  Delete = 1
}

export enum ChangeEntity {
  Activity = 'Activity',
  Location = 'Location',
  InternalParticipant = 'InternalParticipant',
  ExternalParticipant = 'ExternalParticipant',
  Adf = 'Adf',
  Attachment = 'Attachment',
  Security = 'Security',
  AddressComment = 'AddressComment'
}

export interface ChangeItem {
  entity: ChangeEntity;
  action: ChangeAction;
  id?: string | number;
  field?: ChangeField;
}

export enum ChangeField {
  Status = 'status'
}
