<div class="ea-rixml-modal">
  <div class="ea-rixml-selectors">
    <ea-rixml-fields-selector [title]="'bd/event-activity/form.type'"
                              [selected]="selectedSubType"
                              [options]="subTypes$ | async"
                              (changed)="onSubTypeChange($event)">
    </ea-rixml-fields-selector>
    <ea-rixml-fields-selector [title]="'bd/event-activity/form.mode'"
                              [selected]="selectedMode"
                              [options]="modes$ | async"
                              (changed)="onModeChange($event)">
    </ea-rixml-fields-selector>
    <ea-rixml-fields-selector [title]="'bd/event-activity/form.method'"
                              [options]="[selectedMethod]"
                              [selected]="selectedMethod">
    </ea-rixml-fields-selector>
    <ea-rixml-fields-selector [title]="'bd/event-activity/form.initiator'"
                              [placeholder]="'bd/event-activity/form.activity_initiator_placeholder'"
                              [selected]="selectedInitiator"
                              [options]="initiators$ | async"
                              (changed)="onInitiatorChange($event)">
    </ea-rixml-fields-selector>
  </div>

  <div class="c-footer c-modal-footer ea-rixml-footer">
    <div class="c-footer-right">
      <button (click)="onCancel()"
              class="c-btn c-btn-default c-m-left-md">
        {{'bd/event-activity/form.cancel' | translate}}
      </button>
      <button (click)="onSave()"
              class="c-btn c-btn-primary c-m-left-md">
        {{'bd/event-activity/form.save' | translate}}
      </button>
    </div>
  </div>
</div>