import { Action } from '@ngrx/store';
import { CloseDialogAction } from '../../models';

export const CLOSE_DIALOG = '[DIALOG] Close';
export const CLOSE_DIALOG_AFTER_DELETE = '[DIALOG] Close dialog after delete';

export class CloseDialog<T = void> implements Action {
    readonly type = CLOSE_DIALOG;

    constructor(public action: CloseDialogAction = CloseDialogAction.Cancel, public payload: T = null) { }
}
