/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./text-adf.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "@ngx-translate/core";
import * as i4 from "./text-adf.component";
var styles_TextAdfComponent = [i0.styles];
var RenderType_TextAdfComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TextAdfComponent, data: {} });
export { RenderType_TextAdfComponent as RenderType_TextAdfComponent };
export function View_TextAdfComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "c-p-bottom-sm"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 10, "div", [["class", "c-row c-row-collapsed c-row-align-center c-full-width"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "c-col-6 c-p-right-md c-text-ellipsis c-text-right"], ["data-automation-id", "adf-text-numeric-name"]], [[8, "title", 0]], null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 7, "span", [["class", "c-col-6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 6, "input", [["class", "c-input"], ["data-automation-id", "adf-text-numeric-input"], ["type", "text"]], [[8, "placeholder", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "blur"], [null, "input"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 6).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 6)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 6)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("blur" === en)) {
        var pd_4 = (_co.onBlur() !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 16384, null, 0, i2.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i2.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.DefaultValueAccessor]), i1.ɵdid(8, 540672, null, 0, i2.FormControlDirective, [[8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR], [2, i2.ɵangular_packages_forms_forms_q]], { form: [0, "form"] }, null), i1.ɵprd(2048, null, i2.NgControl, null, [i2.FormControlDirective]), i1.ɵdid(10, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_10 = _co.input; _ck(_v, 8, 0, currVal_10); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.field == null) ? null : _co.field.cdfName); _ck(_v, 2, 0, currVal_0); var currVal_1 = ((_co.field == null) ? null : _co.field.cdfName); _ck(_v, 3, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 11).transform("bd/event-activity/form.adf_placeholder")); var currVal_3 = i1.ɵnov(_v, 10).ngClassUntouched; var currVal_4 = i1.ɵnov(_v, 10).ngClassTouched; var currVal_5 = i1.ɵnov(_v, 10).ngClassPristine; var currVal_6 = i1.ɵnov(_v, 10).ngClassDirty; var currVal_7 = i1.ɵnov(_v, 10).ngClassValid; var currVal_8 = i1.ɵnov(_v, 10).ngClassInvalid; var currVal_9 = i1.ɵnov(_v, 10).ngClassPending; _ck(_v, 5, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); }); }
export function View_TextAdfComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ea-text-adf", [], null, null, null, View_TextAdfComponent_0, RenderType_TextAdfComponent)), i1.ɵdid(1, 114688, null, 0, i4.TextAdfComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TextAdfComponentNgFactory = i1.ɵccf("ea-text-adf", i4.TextAdfComponent, View_TextAdfComponent_Host_0, { field: "field" }, { valueChange: "valueChange" }, []);
export { TextAdfComponentNgFactory as TextAdfComponentNgFactory };
