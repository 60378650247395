import { ActivityTemplate, ActivityTemplateCode } from './activity-template.model';
import { ActivitySubType } from './activity-type.enum';
import { ActivityMode } from './activity-mode.enum';
import { ActivityMethod } from './activity-method.enum';
import { ActivityInitiator } from './activity-initiator.enum';

export const otherType: ActivityTemplate = {
  id: ActivityTemplateCode.Other,
  localizationKey: 'activity_type_other',
  defaultSubType: ActivitySubType.None,
  modes: [],
  method: null,
  initiators: [],
  subTypes: []
};

export const activityTypeOptions: ActivityTemplate[] = [
  {
    id: ActivityTemplateCode.OneOnOne,
    localizationKey: 'activity_type_one_on_one',
    subTypes: [ActivitySubType.OneOnOne],
    modes: [ActivityMode.Inperson, ActivityMode.Video],
    method: ActivityMethod.Interaction,
    initiators: [
      null,
      ActivityInitiator.Provider,
      ActivityInitiator.Consumer,
      ActivityInitiator.Corporate
    ],
    defaultSubType: ActivitySubType.OneOnOne,
    defaultMode: ActivityMode.Inperson
  },
  {
    id: ActivityTemplateCode.GroupMeeting,
    localizationKey: 'activity_type_group_meeting',
    subTypes: [ActivitySubType.Group, ActivitySubType.TwoOnOne, ActivitySubType.ThreeOnOne],
    modes: [ActivityMode.Inperson, ActivityMode.Video],
    method: ActivityMethod.Interaction,
    initiators: [
      null,
      ActivityInitiator.Provider,
      ActivityInitiator.Consumer,
      ActivityInitiator.Corporate
    ],
    defaultSubType: ActivitySubType.Group,
    defaultMode: ActivityMode.Inperson
  },
  {
    id: ActivityTemplateCode.ConferenceCall,
    localizationKey: 'activity_type_conference_call',
    subTypes: [ActivitySubType.Group, ActivitySubType.TwoOnOne, ActivitySubType.ThreeOnOne],
    modes: [ActivityMode.Call],
    method: ActivityMethod.Interaction,
    initiators: [
      null,
      ActivityInitiator.Consumer,
      ActivityInitiator.Provider,
      ActivityInitiator.Corporate
    ],
    defaultSubType: ActivitySubType.Group,
    defaultMode: ActivityMode.Call
  },
  {
    id: ActivityTemplateCode.IncomingCall,
    localizationKey: 'activity_type_incoming_call',
    subTypes: [ActivitySubType.OneOnOne],
    modes: [ActivityMode.Call, ActivityMode.VoiceMail],
    method: ActivityMethod.Interaction,
    initiators: [ActivityInitiator.Provider, ActivityInitiator.Consumer],
    defaultSubType: ActivitySubType.OneOnOne,
    defaultMode: ActivityMode.Call,
    defaultInitiator: ActivityInitiator.Consumer
  },
  {
    id: ActivityTemplateCode.OutgoingCall,
    localizationKey: 'activity_type_outgoing_call',
    subTypes: [ActivitySubType.OneOnOne],
    modes: [ActivityMode.Call, ActivityMode.VoiceMail],
    method: ActivityMethod.Interaction,
    initiators: [ActivityInitiator.Corporate],
    defaultSubType: ActivitySubType.OneOnOne,
    defaultMode: ActivityMode.Call,
    defaultInitiator: ActivityInitiator.Corporate
  },
  {
    id: ActivityTemplateCode.IncomingEmail,
    localizationKey: 'activity_type_incoming_email',
    subTypes: [ActivitySubType.OneOnOne],
    modes: [ActivityMode.Email],
    method: ActivityMethod.Electronic,
    initiators: [ActivityInitiator.Provider, ActivityInitiator.Consumer],
    defaultSubType: ActivitySubType.OneOnOne,
    defaultMode: ActivityMode.Email,
    defaultInitiator: ActivityInitiator.Consumer
  },
  {
    id: ActivityTemplateCode.OutgoingEmail,
    localizationKey: 'activity_type_outgoing_email',
    subTypes: [
      ActivitySubType.OneOnOne,
      ActivitySubType.Group,
      ActivitySubType.TwoOnOne,
      ActivitySubType.ThreeOnOne
    ],
    modes: [ActivityMode.Email],
    method: ActivityMethod.Electronic,
    initiators: [ActivityInitiator.Corporate],
    defaultSubType: ActivitySubType.OneOnOne,
    defaultMode: ActivityMode.Email,
    defaultInitiator: ActivityInitiator.Corporate
  },
  otherType
];
