import { ChangeDetectorRef, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { CupcakeSuggesterActions, CupcakeSuggesterComponent, CupcakeSuggesterContainerPosition } from '@ipreo/cupcake-suggester';
import { Observable, of, ReplaySubject, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { WarningPopover } from './warning-popover';
import { LocationGroupEnum } from '../../models';
var BaseActivityLocationComponent = /** @class */ (function () {
    function BaseActivityLocationComponent(placeFacade, cdr, sourceService) {
        this.placeFacade = placeFacade;
        this.cdr = cdr;
        this.sourceService = sourceService;
        this.eventLocationMatched$ = of(true);
        this.timeZoneFailed$ = of(false);
        this.locationSelected = new EventEmitter();
        this.suggesterInput = new FormControl();
        this.suggesterPositions = CupcakeSuggesterContainerPosition;
        this.suggesterControlStream = new Subject();
        this.popoverOptions = new WarningPopover();
        this.hasGoogleResults$ = of(true);
        this.locationGroup = LocationGroupEnum;
        this.destroySubject$ = new Subject();
        this.LOCATION_SEARCH_WAIT_TIME = 400;
        this.placeSearchText = new ReplaySubject(1);
        this.touched = false;
    }
    BaseActivityLocationComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.locations$ = this.sourceService.getLocationsSource();
        this.placeSearchText.pipe(debounceTime(this.LOCATION_SEARCH_WAIT_TIME), distinctUntilChanged(), filter(function (x) { return !!x; }), tap(function () {
            _this.inputChanged = true;
            _this.popoverOptions.showTzPopover = false;
        }), takeUntil(this.destroySubject$)).subscribe(function (value) {
            _this.fetchResult(value);
        });
        this.suggesterInput.valueChanges
            .pipe(withLatestFrom(this.value$), takeUntil(this.destroySubject$))
            .subscribe(function (_a) {
            var change = _a[0], value = _a[1];
            if (!change) {
                _this.onAddressClear(value);
            }
            if (change !== value) {
                _this.sourceService.setLoading(true);
            }
            _this.placeSearchText.next(change);
        });
        this.eventLocationMatched$.pipe(takeUntil(this.destroySubject$)).subscribe(function (matched) {
            _this.popoverOptions.warningMessage = 'bd/event-activity/form.location_does_not_match_event_location';
            _this.popoverOptions.showMatchPopover = !matched && _this.touched;
            _this.cdr.detectChanges();
        });
        this.value$.pipe(takeUntil(this.destroySubject$)).subscribe(function (value) {
            _this.suggesterInput.setValue(value);
            _this.selectedAddress = value;
            _this.selectedValue = value ? [value] : [];
            _this.cdr.detectChanges();
        });
        this.timeZoneFailed$.pipe(takeUntil(this.destroySubject$)).subscribe(function (failed) {
            if (failed) {
                _this.suggesterControlStream.next(CupcakeSuggesterActions.ClearAll);
                _this.popoverOptions.warningMessage = 'bd/event-activity/form.time_zone_update_failed';
                _this.popoverOptions.showTzPopover = true;
            }
            _this.tzFailed = failed;
        });
    };
    BaseActivityLocationComponent.prototype.ngOnDestroy = function () {
        this.destroySubject$.next();
    };
    BaseActivityLocationComponent.prototype.onAddressClear = function (value) {
        this.clearAddress(value);
    };
    BaseActivityLocationComponent.prototype.onLocationSelected = function (selectedItems) {
        this.touched = true;
        if (selectedItems.length === 0) {
            return;
        }
        if (selectedItems[0].groupKey === LocationGroupEnum.GoogleLocations) {
            this.selectGoogleResult(selectedItems[0].item);
        }
    };
    BaseActivityLocationComponent.prototype.mapToSelected = function () {
        var _this = this;
        this.touched = true;
        setTimeout(function () {
            if (_this.selectedPlace === _this.suggesterInput.value) {
                _this.suggesterInput.setValue(_this.selectedAddress);
                _this.cdr.detectChanges();
            }
            else if (_this.inputChanged && _this.selectedAddress !== _this.suggesterInput.value) {
                _this.suggesterControlStream.next(CupcakeSuggesterActions.ClearAll);
                _this.locationSelected.emit(null);
            }
            _this.inputChanged = false;
        }, 200);
    };
    BaseActivityLocationComponent.prototype.onFocus = function () {
        this.popoverOptions.showTzPopover = this.tzFailed;
    };
    BaseActivityLocationComponent.prototype.selectGoogleResult = function (item) {
        var _this = this;
        var placeId = item.placeId, description = item.description;
        this.selectedPlace = description;
        this.placeFacade.getDetails({ placeId: placeId }).pipe(takeUntil(this.destroySubject$)).subscribe(function (location) {
            _this.selectedAddress = _this.placeFacade.getDescription(location.venueName, location.address);
            _this.locationSelected.emit(location);
            _this.cdr.detectChanges();
        });
        this.sourceService.setAutocompleteSessionToken();
    };
    BaseActivityLocationComponent.prototype.clearAddress = function (value) {
        if (!!this.selectedAddress) {
            if (!!value) {
                this.locationSelected.emit(null);
            }
            this.selectedAddress = null;
        }
    };
    return BaseActivityLocationComponent;
}());
export { BaseActivityLocationComponent };
