import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RouterStateUrl } from '../../models';
import { RouterReducerState } from '@ngrx/router-store';

export const getRouterState = createFeatureSelector<RouterReducerState<RouterStateUrl>>(
  'routerReducer'
);

export const getActivityId = createSelector(
  getRouterState,
  router => router && router.state.params.id
);

export const getEventId = createSelector(
  getRouterState,
  router => router && router.state.queryParams['eventId']
);

export const getStartDate = createSelector(
  getRouterState,
  router => router && router.state.queryParams['startDate']
);

export const getActionDataKey = createSelector(
  getRouterState,
  router => router && router.state.queryParams['actionDataKey']
);

export const getBillfoldHost = createSelector(
  getRouterState,
  router => router && router.state.queryParams['billfoldHost']
);
