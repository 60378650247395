var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Actions, Effect, ofType } from '@ngrx/effects';
import { of, pipe } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { catchError, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import * as savedLocationsActions from '../actions/saved-locations.action';
import * as eventActivityAction from '../actions/event-activity.action';
import * as dialogViewActions from '../actions/dialog-view.action';
import * as userActions from '../../../store/actions/user.action';
import { SavedLocationService, LocationMapperService, SavedLocationNameService } from '../../services';
import { SavedLocationSaveResult } from '../../models';
import { getEditValue, getEventActivityLocation, getSelectedLocation, isLocationChanged, isDefaultChecked } from '../selectors';
import { getDefaultLocation } from '../../../store/selectors';
var SavedLocationsEffect = /** @class */ (function () {
    function SavedLocationsEffect(actions$, savedLocationsService, locationMapperService, nameService, store) {
        var _this = this;
        this.actions$ = actions$;
        this.savedLocationsService = savedLocationsService;
        this.locationMapperService = locationMapperService;
        this.nameService = nameService;
        this.store = store;
        this.LoadSavedLocations$ = this.actions$.pipe(ofType(savedLocationsActions.LOAD_SAVED_LOCATIONS), map(function (action) { return action.searchText; }), switchMap(function (searchText) { return _this.savedLocationsService.search(searchText)
            .pipe(map(function (response) { return new savedLocationsActions.LoadSavedLocationsSuccess(response); }), catchError(function (error) { return of(new savedLocationsActions.LoadSavedLocationsFail(error)); })); }));
        this.LoadSelectedLocation$ = this.actions$.pipe(ofType(eventActivityAction.LOAD_EVENT_ACTIVITY), map(function (action) { return action.payload; }), this.loadSelectedLocation());
        this.LoadSelectedLocationAfterSave$ = this.actions$.pipe(ofType(eventActivityAction.SAVE_EVENT_ACTIVITY_SUCCESS), map(function (action) { return action.payload.activity; }), this.loadSelectedLocation());
        this.CreateSavedLocation$ = this.actions$.pipe(ofType(savedLocationsActions.CREATE_SAVED_LOCATION), withLatestFrom(this.store.pipe(select(getEditValue))), switchMap(function (_a) {
            var _ = _a[0], editValue = _a[1];
            return _this.savedLocationsService.create(editValue)
                .pipe(map(function (result) { return new savedLocationsActions.CreateSavedLocationSuccess(result); }), catchError(function (error) { return of(new savedLocationsActions.CreateSavedLocationFail(error)); }));
        }));
        this.CreateSavedLocationSuccess$ = this.actions$.pipe(ofType(savedLocationsActions.CREATE_SAVED_LOCATION_SUCCESS), map(function (action) { return action.payload; }), switchMap(function (location) { return [
            new savedLocationsActions.SetLocation(location),
            new dialogViewActions.SubmitLocation(_this.locationMapperService.fromLocationModel(location))
        ]; }));
        this.DeleteSavedLocation$ = this.actions$.pipe(ofType(savedLocationsActions.DELETE_SAVED_LOCATION), withLatestFrom(this.store.pipe(select(getSelectedLocation))), switchMap(function (_a) {
            var selected = _a[1];
            return _this.savedLocationsService.delete(selected.locationId)
                .pipe(map(function () { return new savedLocationsActions.DeleteSavedLocationSuccess(selected.locationId); }), catchError(function (error) { return of(new savedLocationsActions.DeleteSavedLocationFail(error)); }));
        }));
        this.DeleteSavedLocationSuccess$ = this.actions$.pipe(ofType(savedLocationsActions.DELETE_SAVED_LOCATION_SUCCESS), withLatestFrom(this.store.pipe(select(getEventActivityLocation))), map(function (_a) {
            var location = _a[1];
            var activityLocation = __assign({}, location);
            delete activityLocation.locationId;
            return new dialogViewActions.SubmitLocation(activityLocation);
        }));
        this.EditStart$ = this.actions$.pipe(ofType(savedLocationsActions.EDIT_START), withLatestFrom(this.store.pipe(select(getEventActivityLocation)), this.store.pipe(select(getSelectedLocation)), this.store.pipe(select(getDefaultLocation))), switchMap(function (_a) {
            var _ = _a[0], location = _a[1], selectedLocation = _a[2], defaultLocationId = _a[3];
            var isDefault = !!selectedLocation && !!defaultLocationId &&
                (selectedLocation.locationId === defaultLocationId);
            return [
                new savedLocationsActions.UpdateDefault(isDefault),
                new savedLocationsActions.UpdateAddress(selectedLocation || _this.locationMapperService.fromActivityLocation(location, '')),
                new savedLocationsActions.UpdateName(selectedLocation && selectedLocation.locationName || '')
            ];
        }));
        this.EditAllSavedLocation$ = this.actions$.pipe(ofType(savedLocationsActions.SUBMIT_SAVED_LOCATION), map(function (action) { return action.payload.updateAll; }), withLatestFrom(this.store.pipe(select(getEditValue)), this.store.pipe(select(isLocationChanged))), filter(function (_a) {
            var updateAll = _a[0], isChanged = _a[2];
            return updateAll && isChanged;
        }), switchMap(function (_a) {
            var editValue = _a[1], isChanged = _a[2];
            return _this.savedLocationsService.update(editValue)
                .pipe(switchMap(function (result) { return [
                new savedLocationsActions.EditSavedLocationSuccess(result),
                new dialogViewActions.SubmitLocation(_this.locationMapperService.fromLocationModel(result))
            ]; }), catchError(function (error) { return of(new savedLocationsActions.EditSavedLocationFail(error)); }));
        }));
        this.SubmitSavedLocation$ = this.actions$.pipe(ofType(savedLocationsActions.SUBMIT_SAVED_LOCATION), map(function (action) { return action.payload; }), withLatestFrom(this.store.pipe(select(getEditValue)), this.store.pipe(select(isLocationChanged))), filter(function (_a) {
            var payload = _a[0], isChanged = _a[2];
            return isChanged && !payload.updateAll;
        }), switchMap(function (_a) {
            var location = _a[1];
            var locationName = location.locationName;
            var truncatedName = _this.nameService.getInitialName(locationName);
            return _this.savedLocationsService
                .search(truncatedName)
                .pipe(switchMap(function (locations) {
                var actions = [];
                var newName = _this.nameService.getLocationName(truncatedName, locationName, locations);
                if (newName === locationName) {
                    actions.push(new savedLocationsActions.CreateSavedLocation());
                }
                else {
                    actions.push(new savedLocationsActions.UpdateName(newName), new savedLocationsActions.SetResult(SavedLocationSaveResult.Warning));
                }
                return actions;
            }), catchError(function (error) { return of(new savedLocationsActions.EditSavedLocationFail(error)); }));
        }));
        this.OnSuccess$ = this.actions$.pipe(ofType(savedLocationsActions.CREATE_SAVED_LOCATION_SUCCESS, savedLocationsActions.EDIT_SAVED_LOCATION_SUCCESS, savedLocationsActions.DELETE_SAVED_LOCATION_SUCCESS), withLatestFrom(this.store.pipe(select(isDefaultChecked)), this.store.pipe(select(getSelectedLocation)), this.store.pipe(select(getDefaultLocation))), map(function (_a) {
            var isDefault = _a[1], selected = _a[2], oldDefaultLocationId = _a[3];
            if (isDefault && !selected) {
                return new userActions.SetDefaultLocation(null);
            }
            if (isDefault && !!selected && selected.locationId !== oldDefaultLocationId) {
                return new userActions.SetDefaultLocation(selected.locationId);
            }
            if (!isDefault && !!selected && selected.locationId === oldDefaultLocationId) {
                return new userActions.SetDefaultLocation(null);
            }
            return new savedLocationsActions.EditFinish();
        }));
        this.SetError$ = this.actions$.pipe(ofType(userActions.SET_DEFAULT_LOCATION_FAIL, savedLocationsActions.CREATE_SAVED_LOCATION_FAIL, savedLocationsActions.EDIT_SAVED_LOCATION_FAIL, savedLocationsActions.DELETE_SAVED_LOCATION_FAIL), map(function () { return new savedLocationsActions.SetResult(SavedLocationSaveResult.Error); }));
        this.UpdateOnlyDefault$ = this.actions$.pipe(ofType(savedLocationsActions.SUBMIT_SAVED_LOCATION), withLatestFrom(this.store.pipe(select(getSelectedLocation)), this.store.pipe(select(isLocationChanged))), filter(function (_a) {
            var isChanged = _a[2];
            return !isChanged;
        }), map(function (_a) {
            var value = _a[1];
            return new savedLocationsActions.EditSavedLocationSuccess(value);
        }));
        this.EditFinish$ = this.actions$.pipe(ofType(userActions.SET_DEFAULT_LOCATION_SUCCESS), map(function () { return new savedLocationsActions.EditFinish(); }));
    }
    SavedLocationsEffect.prototype.loadSelectedLocation = function () {
        return pipe(map(function (activity) { return activity.savedLocation; }), map(function (location) { return new savedLocationsActions.SetLocation(location); }));
    };
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], SavedLocationsEffect.prototype, "LoadSavedLocations$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], SavedLocationsEffect.prototype, "LoadSelectedLocation$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], SavedLocationsEffect.prototype, "LoadSelectedLocationAfterSave$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], SavedLocationsEffect.prototype, "CreateSavedLocation$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], SavedLocationsEffect.prototype, "CreateSavedLocationSuccess$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], SavedLocationsEffect.prototype, "DeleteSavedLocation$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], SavedLocationsEffect.prototype, "DeleteSavedLocationSuccess$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], SavedLocationsEffect.prototype, "EditStart$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], SavedLocationsEffect.prototype, "EditAllSavedLocation$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], SavedLocationsEffect.prototype, "SubmitSavedLocation$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], SavedLocationsEffect.prototype, "OnSuccess$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], SavedLocationsEffect.prototype, "SetError$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], SavedLocationsEffect.prototype, "UpdateOnlyDefault$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], SavedLocationsEffect.prototype, "EditFinish$", void 0);
    return SavedLocationsEffect;
}());
export { SavedLocationsEffect };
