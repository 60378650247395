var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var FailAction = /** @class */ (function () {
    function FailAction() {
    }
    return FailAction;
}());
export { FailAction };
export var LOG_AUTH_ERROR_TYPE = '[AUTH] Log Error';
export var LOG_STYLES_LOAD_ERROR_TYPE = '[Styles] Log Load Error';
var LogStyleLoadErrorAction = /** @class */ (function (_super) {
    __extends(LogStyleLoadErrorAction, _super);
    function LogStyleLoadErrorAction(link, message) {
        var _this = _super.call(this) || this;
        _this.type = LOG_STYLES_LOAD_ERROR_TYPE;
        _this.error = { link: link, message: message };
        return _this;
    }
    return LogStyleLoadErrorAction;
}(FailAction));
export { LogStyleLoadErrorAction };
var LogAuthErrorAction = /** @class */ (function () {
    function LogAuthErrorAction(error, availableToken) {
        this.error = error;
        this.availableToken = availableToken;
        this.type = LOG_AUTH_ERROR_TYPE;
    }
    return LogAuthErrorAction;
}());
export { LogAuthErrorAction };
