import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { of, pipe } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { catchError, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';

import * as savedLocationsActions from '../actions/saved-locations.action';
import * as eventActivityAction from '../actions/event-activity.action';
import * as dialogViewActions from '../actions/dialog-view.action';
import * as userActions from '../../../store/actions/user.action';

import { LoadSavedLocations } from '../actions/saved-locations.action';
import { SavedLocationService, LocationMapperService, SavedLocationNameService } from '../../services';
import { State } from '../reducers/saved-locations.reducer';
import { EventActivity, LocationModel, SavedLocationSaveResult } from '../../models';
import {
  getEditValue,
  getEventActivityLocation,
  getSelectedLocation,
  isLocationChanged,
  isDefaultChecked
} from '../selectors';
import { getDefaultLocation } from '../../../store/selectors';

@Injectable()
export class SavedLocationsEffect {
  constructor(
    private actions$: Actions,
    private savedLocationsService: SavedLocationService,
    private locationMapperService: LocationMapperService,
    private nameService: SavedLocationNameService,
    private store: Store<State>
  ) {}

  @Effect()
  LoadSavedLocations$ = this.actions$.pipe(
    ofType<savedLocationsActions.LoadSavedLocations>(savedLocationsActions.LOAD_SAVED_LOCATIONS),
    map((action: LoadSavedLocations) => action.searchText),
    switchMap((searchText: string) => this.savedLocationsService.search(searchText)
      .pipe(
        map(response => new savedLocationsActions.LoadSavedLocationsSuccess(response)),
        catchError(error => of(new savedLocationsActions.LoadSavedLocationsFail(error)))
      )
    )
  );

  @Effect()
  LoadSelectedLocation$ = this.actions$.pipe(
    ofType(eventActivityAction.LOAD_EVENT_ACTIVITY),
    map((action: eventActivityAction.LoadEventActivity) => action.payload),
    this.loadSelectedLocation()
  );

  @Effect()
  LoadSelectedLocationAfterSave$ = this.actions$.pipe(
    ofType(eventActivityAction.SAVE_EVENT_ACTIVITY_SUCCESS),
    map((action: eventActivityAction.SaveEventActivitySuccess) => action.payload.activity),
    this.loadSelectedLocation()
  );

  @Effect()
  CreateSavedLocation$ = this.actions$.pipe(
    ofType(savedLocationsActions.CREATE_SAVED_LOCATION),
    withLatestFrom(this.store.pipe(select(getEditValue))),
    switchMap(([_, editValue]) =>
      this.savedLocationsService.create(editValue)
        .pipe(
          map((result) => new savedLocationsActions.CreateSavedLocationSuccess(result)),
          catchError(error => of(new savedLocationsActions.CreateSavedLocationFail(error)))
        )
    )
  );

  @Effect()
  CreateSavedLocationSuccess$ = this.actions$.pipe(
    ofType(savedLocationsActions.CREATE_SAVED_LOCATION_SUCCESS),
    map((action: savedLocationsActions.CreateSavedLocationSuccess) => action.payload),
    switchMap((location: LocationModel) => [
      new savedLocationsActions.SetLocation(location),
      new dialogViewActions.SubmitLocation(this.locationMapperService.fromLocationModel(location))
    ])
  );

  @Effect()
  DeleteSavedLocation$ = this.actions$.pipe(
    ofType(savedLocationsActions.DELETE_SAVED_LOCATION),
    withLatestFrom(this.store.pipe(select(getSelectedLocation))),
    switchMap(([, selected]) => this.savedLocationsService.delete(selected.locationId)
        .pipe(
          map(() => new savedLocationsActions.DeleteSavedLocationSuccess(selected.locationId)),
          catchError((error) => of(new savedLocationsActions.DeleteSavedLocationFail(error)))
        ))
  );

  @Effect()
  DeleteSavedLocationSuccess$ = this.actions$.pipe(
    ofType(savedLocationsActions.DELETE_SAVED_LOCATION_SUCCESS),
    withLatestFrom(this.store.pipe(select(getEventActivityLocation))),
    map(([, location]) => {
      const activityLocation = {...location};
      delete activityLocation.locationId;
      return new dialogViewActions.SubmitLocation(activityLocation);
    })
  );

  @Effect()
  EditStart$ = this.actions$.pipe(
    ofType(savedLocationsActions.EDIT_START),
    withLatestFrom(
      this.store.pipe(select(getEventActivityLocation)),
      this.store.pipe(select(getSelectedLocation)),
      this.store.pipe(select(getDefaultLocation))
      ),
    switchMap(([_, location, selectedLocation, defaultLocationId]) => {
      const isDefault = !!selectedLocation && !!defaultLocationId &&
                        (selectedLocation.locationId === defaultLocationId);
      return [
        new savedLocationsActions.UpdateDefault(isDefault),
        new savedLocationsActions.UpdateAddress(
          selectedLocation || this.locationMapperService.fromActivityLocation(location, '')),
        new savedLocationsActions.UpdateName(selectedLocation && selectedLocation.locationName || '')
      ];
    })
  );

  @Effect()
  EditAllSavedLocation$ = this.actions$.pipe(
    ofType<savedLocationsActions.SubmitSavedLocation>(savedLocationsActions.SUBMIT_SAVED_LOCATION),
    map((action: savedLocationsActions.SubmitSavedLocation) => action.payload.updateAll),
    withLatestFrom(this.store.pipe(select(getEditValue)), this.store.pipe(select(isLocationChanged))),
    filter(([updateAll, , isChanged]: [boolean, LocationModel, boolean]) => updateAll && isChanged),
    switchMap(([, editValue, isChanged]: [boolean, LocationModel, boolean]) =>
      this.savedLocationsService.update(editValue)
        .pipe(
          switchMap((result) => [
            new savedLocationsActions.EditSavedLocationSuccess(result),
            new dialogViewActions.SubmitLocation(this.locationMapperService.fromLocationModel(result))
          ]),
          catchError(error => of(new savedLocationsActions.EditSavedLocationFail(error)))
        )
    )
  );

  @Effect()
  SubmitSavedLocation$ = this.actions$.pipe(
    ofType<savedLocationsActions.SubmitSavedLocation>(savedLocationsActions.SUBMIT_SAVED_LOCATION),
    map((action: savedLocationsActions.SubmitSavedLocation) => action.payload),
    withLatestFrom(
      this.store.pipe(select(getEditValue)),
      this.store.pipe(select(isLocationChanged))
    ),
    filter(([payload, , isChanged]) => isChanged && !payload.updateAll),
    switchMap(([, location]) => {
      const locationName = location.locationName;
      const truncatedName = this.nameService.getInitialName(locationName);
      return this.savedLocationsService
        .search(truncatedName)
        .pipe(
          switchMap(locations => {
            const actions = [];
            const newName = this.nameService.getLocationName(truncatedName, locationName, locations);

            if (newName === locationName) {
              actions.push(new savedLocationsActions.CreateSavedLocation());
            } else {
              actions.push(
                new savedLocationsActions.UpdateName(newName),
                new savedLocationsActions.SetResult(SavedLocationSaveResult.Warning)
              );
            }
            return actions;
          }),
          catchError(error => of(new savedLocationsActions.EditSavedLocationFail(error)))
        );
      }
    )
  );

  @Effect()
  OnSuccess$ = this.actions$.pipe(
    ofType(
      savedLocationsActions.CREATE_SAVED_LOCATION_SUCCESS,
      savedLocationsActions.EDIT_SAVED_LOCATION_SUCCESS,
      savedLocationsActions.DELETE_SAVED_LOCATION_SUCCESS
    ),
    withLatestFrom(
      this.store.pipe(select(isDefaultChecked)),
      this.store.pipe(select(getSelectedLocation)),
      this.store.pipe(select(getDefaultLocation))
    ),
    map(([, isDefault, selected, oldDefaultLocationId]) => {
      if (isDefault && !selected) {
        return new userActions.SetDefaultLocation(null);
      }
      if (isDefault && !!selected && selected.locationId !== oldDefaultLocationId) {
        return new userActions.SetDefaultLocation(selected.locationId);
      }
      if (!isDefault && !!selected && selected.locationId === oldDefaultLocationId) {
        return new userActions.SetDefaultLocation(null);
      }
      return new savedLocationsActions.EditFinish();
    })
  );

  @Effect()
  SetError$ = this.actions$.pipe(
    ofType(
      userActions.SET_DEFAULT_LOCATION_FAIL,
      savedLocationsActions.CREATE_SAVED_LOCATION_FAIL,
      savedLocationsActions.EDIT_SAVED_LOCATION_FAIL,
      savedLocationsActions.DELETE_SAVED_LOCATION_FAIL
    ),
    map(() => new savedLocationsActions.SetResult(SavedLocationSaveResult.Error))
  );

  @Effect()
  UpdateOnlyDefault$ = this.actions$.pipe(
    ofType<savedLocationsActions.SubmitSavedLocation>(savedLocationsActions.SUBMIT_SAVED_LOCATION),
    withLatestFrom(
      this.store.pipe(select(getSelectedLocation)),
      this.store.pipe(select(isLocationChanged))
    ),
    filter(([, , isChanged]) => !isChanged),
    map(([, value]) => new savedLocationsActions.EditSavedLocationSuccess(value))
  );

  @Effect()
  EditFinish$ = this.actions$.pipe(
    ofType<userActions.SetDefaultLocationSuccess>(userActions.SET_DEFAULT_LOCATION_SUCCESS),
    map(() => new savedLocationsActions.EditFinish())
  );

  private loadSelectedLocation() {
    return pipe(
      map((activity: EventActivity) => activity.savedLocation),
      map(location => new savedLocationsActions.SetLocation(location as LocationModel))
    );
  }
}
