/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./google-apis-script.component";
import * as i2 from "../../event-activity/services/google-host-selector.service";
var styles_GoogleApisScriptComponent = [];
var RenderType_GoogleApisScriptComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_GoogleApisScriptComponent, data: {} });
export { RenderType_GoogleApisScriptComponent as RenderType_GoogleApisScriptComponent };
export function View_GoogleApisScriptComponent_0(_l) { return i0.ɵvid(0, [], null, null); }
export function View_GoogleApisScriptComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ea-google-apis-script", [], null, null, null, View_GoogleApisScriptComponent_0, RenderType_GoogleApisScriptComponent)), i0.ɵdid(1, 114688, null, 0, i1.GoogleApisScriptComponent, [i0.Renderer2, i0.ElementRef, i2.GoogleHostSelectorService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GoogleApisScriptComponentNgFactory = i0.ɵccf("ea-google-apis-script", i1.GoogleApisScriptComponent, View_GoogleApisScriptComponent_Host_0, {}, {}, []);
export { GoogleApisScriptComponentNgFactory as GoogleApisScriptComponentNgFactory };
