/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./error-handling.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "./error-handling.component";
var styles_ErrorHandlingComponent = [i0.styles];
var RenderType_ErrorHandlingComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ErrorHandlingComponent, data: {} });
export { RenderType_ErrorHandlingComponent as RenderType_ErrorHandlingComponent };
export function View_ErrorHandlingComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "c-container c-text-center c-body-text ea-no-data"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "ea-no-activity-data-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "h2", [["class", "c-header-xl c-m-bottom-0"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "ea-no-data-text c-m-bottom-sm"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(8, 0, null, null, 2, "button", [["class", "c-btn c-btn-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(9, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("bd/event-activity/form.no_data_header")); _ck(_v, 3, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform("bd/event-activity/form.no_data_text")); _ck(_v, 6, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 9, 0, i1.ɵnov(_v, 10).transform("bd/event-activity/form.close")); _ck(_v, 9, 0, currVal_2); }); }
export function View_ErrorHandlingComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ea-error-handling", [], null, null, null, View_ErrorHandlingComponent_0, RenderType_ErrorHandlingComponent)), i1.ɵdid(1, 49152, null, 0, i3.ErrorHandlingComponent, [], null, null)], null, null); }
var ErrorHandlingComponentNgFactory = i1.ɵccf("ea-error-handling", i3.ErrorHandlingComponent, View_ErrorHandlingComponent_Host_0, {}, { close: "close" }, []);
export { ErrorHandlingComponentNgFactory as ErrorHandlingComponentNgFactory };
