var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { FormControl } from '@angular/forms';
import { EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { BehaviorSubject, asyncScheduler, combineLatest, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, observeOn, takeUntil } from 'rxjs/operators';
import { inputDebounceTime } from '../../../models';
var SingleSelectAdfComponent = /** @class */ (function () {
    function SingleSelectAdfComponent() {
        this.valueChange = new EventEmitter(true);
        this.suggesterInput = new FormControl('');
        this.filteredItems$ = new BehaviorSubject([]);
        this.optionsLimit = 100;
        this.selectedItemsSubject$ = new BehaviorSubject([]);
        this.destroySubject$ = new Subject();
        this.selectedItems$ = this.selectedItemsSubject$.pipe(observeOn(asyncScheduler));
    }
    SingleSelectAdfComponent.prototype.ngOnInit = function () {
        var _this = this;
        combineLatest([this.suggesterInput.valueChanges.pipe(debounceTime(inputDebounceTime), distinctUntilChanged()),
            this.selectedItemsSubject$]).pipe(takeUntil(this.destroySubject$)).subscribe(function (_a) {
            var searchTerm = _a[0], selectedItems = _a[1];
            _this.filteredItems$.next(_this.filterOptions(searchTerm, selectedItems));
        });
        if (this.field) {
            if (this.field.values && this.field.values.length > 0) {
                this.selectedItemsSubject$.next([{
                        id: this.field.values[0].elementId,
                        name: this.field.values[0].textValue
                    }]);
            }
            else if (this.field.elements) {
                this.onAdfSingleSelectChange([this.field.elements[0]]);
            }
        }
    };
    SingleSelectAdfComponent.prototype.ngOnDestroy = function () {
        this.destroySubject$.next();
        this.destroySubject$.complete();
    };
    SingleSelectAdfComponent.prototype.onAdfSingleSelectChange = function (newValue) {
        if (newValue.length) {
            var selectedValues = [
                {
                    elementId: newValue[0].id,
                    textValue: newValue[0].name
                }
            ];
            this.valueChange.emit(__assign({}, this.field, { values: selectedValues }));
        }
        else {
            this.valueChange.emit(__assign({}, this.field, { values: [] }));
        }
        this.selectedItemsSubject$.next(newValue.slice());
    };
    SingleSelectAdfComponent.prototype.filterOptions = function (searchTerm, selectedItems) {
        var term = searchTerm.toLocaleLowerCase();
        if (selectedItems.length) {
            var selected = selectedItems[0].name.toLocaleLowerCase();
            if (selected === term) {
                return this.field.elements;
            }
        }
        return this.field.elements.filter(function (e) { return e.name.toLocaleLowerCase().includes(term); });
    };
    return SingleSelectAdfComponent;
}());
export { SingleSelectAdfComponent };
