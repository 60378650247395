var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Actions, Effect, ofType } from '@ngrx/effects';
import { withLatestFrom, switchMap, map, tap, filter } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { pipe } from 'rxjs';
import * as kendoFileSaver from 'file-saver';
import { ChangeEntity, ChangeAction } from '../../models';
import * as activityAttachmentAction from '../actions/attachment.action';
import * as dialogAction from '../actions/dialog-view.action';
import { getActivityAttachments } from '../selectors';
import { getActivityId } from 'src/app/store';
import { ActivityAttachmentService } from '../../services';
import * as eventActivityAction from '../actions/event-activity.action';
import { LoadActivityAttachments } from '../actions/attachment.action';
import { ActivityAttachmentMapper } from '../../services/activity-attachment-mapper.service';
var AttachmentEffects = /** @class */ (function () {
    function AttachmentEffects(actions$, store, attachmentService, attachmentMapper) {
        var _this = this;
        this.actions$ = actions$;
        this.store = store;
        this.attachmentService = attachmentService;
        this.attachmentMapper = attachmentMapper;
        this.LoadAttachments$ = this.actions$.pipe(ofType(eventActivityAction.LOAD_EVENT_ACTIVITY_MODEL_SUCCESS), filter(function (action) { return !!action.payload.attachments && action.payload.attachments.length > 0; }), map(function (action) { return action.payload.attachments; }), this.mapAttachments());
        this.SetAttachmentsAfterSave$ = this.actions$.pipe(ofType(eventActivityAction.SAVE_EVENT_ACTIVITY_SUCCESS), map(function (action) { return action.payload.activity.attachments; }), this.mapAttachments());
        this.TrackAttachmentDelete$ = this.actions$.pipe(ofType(activityAttachmentAction.REMOVE_ACTIVITY_ATTACHMENT_SUBMIT), withLatestFrom(this.store.pipe(select(getActivityAttachments))), switchMap(function (_a) {
            var action = _a[0], attachments = _a[1];
            var removedDocId = attachments.find(function (att) { return action.uId === att.uid; }).id;
            return [
                (!!removedDocId) &&
                    new dialogAction.UpdateChangeLog([{
                            entity: ChangeEntity.Attachment,
                            action: ChangeAction.Delete,
                            id: removedDocId
                        }]),
                new activityAttachmentAction.RemoveActivityAttachment(action.uId)
            ].filter(Boolean);
        }));
        this.downloadSavedActivityAttachment$ = this.actions$.pipe(ofType(activityAttachmentAction.DOWNLOAD_ACTIVITY_ATTACHMENT), map(function (action) { return action.attachment; }), filter(function (attachment) { return !attachment.rawFile; }), withLatestFrom(this.store.pipe(select(getActivityId))), switchMap(function (_a) {
            var attachment = _a[0], activityId = _a[1];
            return _this.attachmentService.getAttachment({ activityId: activityId, documentId: attachment.id }).pipe(map(function (blob) { return ({
                blob: blob,
                name: attachment.name
            }); }));
        }), tap(function (payload) {
            kendoFileSaver.saveAs(payload.blob, payload.name);
        }));
        this.downloadUnsavedActivityAttachment$ = this.actions$.pipe(ofType(activityAttachmentAction.DOWNLOAD_ACTIVITY_ATTACHMENT), map(function (action) { return action.attachment; }), filter(function (attachment) { return !!attachment.rawFile; }), tap(function (attachment) {
            return kendoFileSaver.saveAs(attachment.rawFile, attachment.name);
        }));
        this.ClearErrors$ = this.actions$.pipe(ofType(eventActivityAction.SAVE_EVENT_ACTIVITY), map(function () { return new activityAttachmentAction.SetErrors([]); }));
    }
    AttachmentEffects.prototype.mapAttachments = function () {
        var _this = this;
        return pipe(map(function (attachments) { return (new LoadActivityAttachments(attachments.map(function (attachment) { return _this.attachmentMapper.fromDto(attachment); }))); }));
    };
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], AttachmentEffects.prototype, "LoadAttachments$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], AttachmentEffects.prototype, "SetAttachmentsAfterSave$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], AttachmentEffects.prototype, "TrackAttachmentDelete$", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], AttachmentEffects.prototype, "downloadSavedActivityAttachment$", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], AttachmentEffects.prototype, "downloadUnsavedActivityAttachment$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], AttachmentEffects.prototype, "ClearErrors$", void 0);
    return AttachmentEffects;
}());
export { AttachmentEffects };
