/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./external-participant-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@ngx-translate/core";
import * as i4 from "@ipreo/cupcake-components/dist/cjs/popover/cupcake-popover.directive";
import * as i5 from "@ipreo/cupcake-components/dist/cjs/popover/cupcake-popover.service";
import * as i6 from "../../../pager/paginate-pipe/paginate.pipe";
import * as i7 from "../../../pager/services/paging.service";
import * as i8 from "../../pipes/status/participant-status-icon.pipe";
import * as i9 from "../../pipes/status/participant-status-localization-key.pipe";
import * as i10 from "../../../pager/pager-component/pager.component.ngfactory";
import * as i11 from "../../../pager/pager-component/pager.component";
import * as i12 from "./external-participant-list.component";
import * as i13 from "@ipreo/cupcake-components/dist/cjs/modal/cupcake-modal.service";
import * as i14 from "../../services/link.service";
var styles_ExternalParticipantListComponent = [i0.styles];
var RenderType_ExternalParticipantListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ExternalParticipantListComponent, data: {} });
export { RenderType_ExternalParticipantListComponent as RenderType_ExternalParticipantListComponent };
function View_ExternalParticipantListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_ExternalParticipantListComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "i", [["aria-hidden", "true"]], [[8, "title", 0]], null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵppd(3, 1), i1.ɵppd(4, 1), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_1 = i1.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent.parent.parent, 1), _v.parent.parent.context.$implicit.status)); _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 0, 0, i1.ɵnov(_v, 5).transform(i1.ɵunv(_v, 0, 0, _ck(_v, 4, 0, i1.ɵnov(_v.parent.parent.parent, 2), _v.parent.parent.context.$implicit.status)))); _ck(_v, 0, 0, currVal_0); }); }
function View_ExternalParticipantListComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "a", [["class", "c-text-muted c-text-sm"], ["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵted(2, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 0, 0, i1.ɵnov(_v, 1).transform(_co.linkService.buildProfileLink(_v.parent.parent.parent.context.$implicit.company))); _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.parent.parent.parent.context.$implicit.company.name; _ck(_v, 2, 0, currVal_1); }); }
function View_ExternalParticipantListComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "c-text-muted c-text-sm"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.parent.parent.context.$implicit.company.name; _ck(_v, 1, 0, currVal_0); }); }
function View_ExternalParticipantListComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "ea-participant-company"], ["data-qa-id", "attendee-company-name"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ExternalParticipantListComponent_6)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["noLink", 2]], null, 0, null, View_ExternalParticipantListComponent_7))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.linkService.isProfileAvailable(_v.parent.parent.context.$implicit.company.id); var currVal_1 = i1.ɵnov(_v, 3); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_ExternalParticipantListComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_ExternalParticipantListComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "ea-participant-name"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ExternalParticipantListComponent_4)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "a", [["class", "c-text"], ["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵted(5, null, [" ", " ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ExternalParticipantListComponent_5)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ExternalParticipantListComponent_8)), i1.ɵdid(9, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.hasStatus(_v.parent.context.$implicit.status); _ck(_v, 2, 0, currVal_0); var currVal_4 = _v.parent.context.$implicit.company; _ck(_v, 7, 0, currVal_4); var currVal_5 = i1.ɵnov(_v.parent, 5); _ck(_v, 9, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform(_co.linkService.buildProfileLink(_v.parent.context.$implicit.contact))); _ck(_v, 3, 0, currVal_1); var currVal_2 = _v.parent.context.$implicit.firstName; var currVal_3 = _v.parent.context.$implicit.lastName; _ck(_v, 5, 0, currVal_2, currVal_3); }); }
function View_ExternalParticipantListComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "a", [["class", "c-text"], ["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵted(2, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 0, 0, i1.ɵnov(_v, 1).transform(_co.linkService.buildProfileLink(_v.parent.parent.context.$implicit.company))); _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.parent.parent.context.$implicit.company.name; _ck(_v, 2, 0, currVal_1); }); }
function View_ExternalParticipantListComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "c-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.parent.context.$implicit.company.name; _ck(_v, 1, 0, currVal_0); }); }
function View_ExternalParticipantListComponent_12(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_ExternalParticipantListComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "ea-participant-name"], ["data-qa-id", "attendee-contact-name"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ExternalParticipantListComponent_10)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["noLink", 2]], null, 0, null, View_ExternalParticipantListComponent_11)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ExternalParticipantListComponent_12)), i1.ɵdid(5, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.linkService.isProfileAvailable(_v.parent.context.$implicit.company.id); var currVal_1 = i1.ɵnov(_v, 3); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵnov(_v.parent, 5); _ck(_v, 5, 0, currVal_2); }, null); }
function View_ExternalParticipantListComponent_13(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "span", [["class", "c-dropdown"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 16777216, null, null, 2, "button", [["class", "c-p-horizontal-sm c-p-vertical-xs ea-menu-btn c-text c-text-primary-hover"]], null, [[null, "popoverMessage"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("popoverMessage" === en)) {
        var pd_0 = (_co.onPopoverResponse($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 1458176, null, 0, i4.CupcakePopoverDirective, [i1.ViewContainerRef, i5.CupcakePopoverService], { options: [0, "options"] }, { popoverMessage: "popoverMessage" }), (_l()(), i1.ɵeld(3, 0, null, null, 0, "i", [["class", "fa fa-ellipsis-v ea-menu-icon"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getPopoverOptions(_v.parent.context.$implicit); _ck(_v, 2, 0, currVal_0); }, null); }
function View_ExternalParticipantListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "ea-participant-link c-bd-bottom c-p-xs c-d-flex"], ["data-qa-id", "attendee-mobile-row"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ExternalParticipantListComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfThen: [1, "ngIfThen"], ngIfElse: [2, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["contactItem", 2]], null, 0, null, View_ExternalParticipantListComponent_3)), (_l()(), i1.ɵand(0, [["companyItem", 2]], null, 0, null, View_ExternalParticipantListComponent_9)), (_l()(), i1.ɵand(0, [["menu", 2]], null, 0, null, View_ExternalParticipantListComponent_13))], function (_ck, _v) { var currVal_0 = !_v.context.$implicit.contact; var currVal_1 = i1.ɵnov(_v, 4); var currVal_2 = i1.ɵnov(_v, 3); _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_ExternalParticipantListComponent_0(_l) { return i1.ɵvid(2, [i1.ɵpid(0, i6.PaginatePipe, [i7.PagingService]), i1.ɵpid(0, i8.ParticipantStatusIconPipe, []), i1.ɵpid(0, i9.ParticipantStatusLocalizationKeyPipe, []), (_l()(), i1.ɵeld(3, 0, null, null, 8, "div", [["class", "c-d-flex c-flex-column c-full-height"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "ea-participants-list-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "ea-participants-list"], ["data-qa-id", "participants-list"]], [[2, "c-full-page-loader", null]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_ExternalParticipantListComponent_1)), i1.ɵdid(7, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null), i1.ɵppd(8, 3), (_l()(), i1.ɵeld(9, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "ea-pager", [], null, [[null, "pageSelect"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("pageSelect" === en)) {
        var pd_0 = ((_co.currentPage = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i10.View_PagerComponent_0, i10.RenderType_PagerComponent)), i1.ɵdid(11, 49152, null, 0, i11.PagerComponent, [], { totalPages: [0, "totalPages"], visiblePagesCount: [1, "visiblePagesCount"], selectedPage: [2, "selectedPage"] }, { pageSelect: "pageSelect" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵunv(_v, 7, 0, _ck(_v, 8, 0, i1.ɵnov(_v, 0), _co.participants, _co.currentPage, _co.pageSize)); var currVal_2 = _co.trackByFn; _ck(_v, 7, 0, currVal_1, currVal_2); var currVal_3 = _co.totalPages; var currVal_4 = 3; var currVal_5 = _co.currentPage; _ck(_v, 11, 0, currVal_3, currVal_4, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loading; _ck(_v, 5, 0, currVal_0); }); }
export function View_ExternalParticipantListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ea-external-participant-list", [], null, null, null, View_ExternalParticipantListComponent_0, RenderType_ExternalParticipantListComponent)), i1.ɵdid(1, 573440, null, 0, i12.ExternalParticipantListComponent, [i13.CupcakeModalService, i14.LinkService, i7.PagingService], null, null)], null, null); }
var ExternalParticipantListComponentNgFactory = i1.ɵccf("ea-external-participant-list", i12.ExternalParticipantListComponent, View_ExternalParticipantListComponent_Host_0, { participants: "participants", loading: "loading" }, { remove: "remove", update: "update", add: "add" }, []);
export { ExternalParticipantListComponentNgFactory as ExternalParticipantListComponentNgFactory };
