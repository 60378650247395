var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as eventActivityActions from '../actions/event-activity-data.action';
import { ListType } from '../../models';
export var initialState = function () { return ({
    event: null,
    internalParticipants: { data: [], loading: false, loaded: false },
    institutionLists: { data: [], totalCount: 0, loaded: false },
    contactLists: { data: [], totalCount: 0, loaded: false }
}); };
export function reducer(state, action) {
    if (state === void 0) { state = initialState(); }
    switch (action.type) {
        case eventActivityActions.LOAD_INTERNAL_PARTICIPANTS: {
            return __assign({}, state, { internalParticipants: __assign({}, state.internalParticipants, { loading: true }) });
        }
        case eventActivityActions.LOAD_INTERNAL_PARTICIPANTS_SUCCESS: {
            return __assign({}, state, { internalParticipants: { data: action.payload, loaded: true, loading: false } });
        }
        case eventActivityActions.LOAD_INTERNAL_PARTICIPANTS_FAIL: {
            return __assign({}, state, { internalParticipants: __assign({}, state.internalParticipants, { loading: false }) });
        }
        case eventActivityActions.LOAD_LISTS: {
            return __assign({}, state, { institutionLists: __assign({}, state.institutionLists, { loaded: false }), contactLists: __assign({}, state.contactLists, { loaded: false }) });
        }
        case eventActivityActions.LOAD_LISTS_SUCCESS: {
            var payload = action.payload;
            return __assign({}, state, { institutionLists: {
                    data: payload.institutionLists.data,
                    totalCount: payload.institutionLists.totalCount,
                    loaded: true
                }, contactLists: {
                    data: payload.contactLists.data,
                    totalCount: payload.contactLists.totalCount,
                    loaded: true
                } });
        }
        case eventActivityActions.LOAD_EVENT_DATA: {
            return __assign({}, state, { event: action.payload });
        }
    }
    return state;
}
export var getInternalParticipants = function (state) { return state.internalParticipants.data; };
export var getInternalParticipantsLoaded = function (state) { return state.internalParticipants.loaded; };
export var getEventSchedules = function (state) { return state && state.event && state.event.schedules || []; };
export var getEventName = function (state) { return state && state.event && state.event.name; };
export var getIsVirtualEvent = function (state) { return state && state.event && state.event.isVirtual; };
export var getTotalCount = function (state) {
    var _a;
    return _a = {},
        _a[ListType.Account] = {
            total: state.institutionLists.totalCount,
            loaded: state.institutionLists.data.length
        },
        _a[ListType.Contact] = {
            total: state.contactLists.totalCount,
            loaded: state.contactLists.data.length
        },
        _a;
};
export var getLists = function (state) { return state.contactLists.data.concat(state.institutionLists.data); };
export var getListsLoaded = function (state) {
    return state.institutionLists.loaded && state.contactLists.loaded;
};
