import { Injectable } from '@angular/core';
import {
  ContactExternalParticipant,
  ExternalParticipant
} from '../models';
import {
  ContactMissingData, ContactMissingField, MissingField
} from '@ipreo/event-participant-missing-data-edit';

@Injectable()
export class ActivityParticipantValidationService {
  constructor() {}

  getMissingFields(participant: ExternalParticipant): ContactMissingField[] {
    return participant.company.name ? [] : [ContactMissingField.Institution];
  }

  toMissingFieldsContactModel(
    participant: ContactExternalParticipant,
    fields: MissingField[]
  ): ContactMissingData {
    return <ContactMissingData>{
      crmId: participant.contact.id.crmId,
      prId: participant.contact.id.prId,
      fullName: `${participant.firstName} ${participant.lastName}`,
      companyName: participant.company.name,
      missingFields: fields
    };
  }
}
