import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { map, filter, switchMap, tap, catchError } from 'rxjs/operators';
import { State, getUserProfile, getUserProfileProgressState } from '../store';
import { Store, select } from '@ngrx/store';
import { CanActivate } from '@angular/router';
import { Observable, Subject, of, forkJoin } from 'rxjs';
import { Flavor, UserProfile, ProgressState } from '../models';
import { LogStyleLoadErrorAction } from '../store/actions/fail-action';
import { variables } from '../build.variables';

export const defaultCupcakeFlavorLink = 'default-cupcake-flavor';
export const defaultKendoCupcakeFlavorLink = 'default-cupcake-kendo-flavor';

const cdnDomain = 'https://cdn.bdc.ipreo.com';
const ihsStylesPath = `${cdnDomain}/@ipreo/cupcake/${variables.cupcakeVersion}/ihs-bd.min.css`;
const ihsKendoStylesPath =
  `${cdnDomain}/@ipreo/cupcake-kendo-styles/${variables.kendoStylesVersion}/kendo-ihs-bd.min.css`;

  const jpmcStyles = `${cdnDomain}/@ipreo/cupcake-branding/${variables.cupcakeBranding}/jpmc.min.css`;
  const bnyStyles = `${cdnDomain}/@ipreo/cupcake-branding/${variables.cupcakeBranding}/bny.min.css`;

@Injectable()
export class FlavorInjectorService implements CanActivate {
  constructor(@Inject(DOCUMENT) private document: Document, private store: Store<State>) { }

  canActivate(): Observable<boolean> {
    return this.injectStyles().pipe(map(_ => true), catchError(_ => of(false)));
  }

  public injectStyles() {
    return this.getUserWhenLoaded().pipe(
      switchMap(u => {
        if (!u) {
          return of(null);
        }
        if (u.isJpmcClient) {
          return forkJoin([
            this.insertIhsStyleLink(u.flavor),
            this.insertLink(jpmcStyles)]);
        } else if (u.isBonyClient) {
          return forkJoin(
            [this.insertIhsStyleLink(u.flavor),
            this.insertLink(bnyStyles)]);
        }
        return this.insertIhsStyleLink(u.flavor);
      }));
  }

  private getUserWhenLoaded(): Observable<UserProfile> {
    return this.store.pipe(
      select(getUserProfileProgressState),
      filter((progress) => progress !== ProgressState.Loading && progress !== ProgressState.NotLoaded),
      switchMap((progress) => {
        if (progress === ProgressState.Loaded) {
          return this.store.pipe(select(getUserProfile), filter(u => !!u));
        }
        return of(null);
      })
    );
  }

  private insertIhsStyleLink(flavor: Flavor) {
    if (flavor === Flavor.Ihs) {
      return forkJoin(
        [this.insertLink(ihsStylesPath),
        this.insertLink(ihsKendoStylesPath)]
      ). pipe(
        tap(_ => {
          this.removeDefaultFlavor();
        })
      );
    }
    return of(null);
  }

  private insertLink(href: string) {
    const loadSubject = new Subject<void>();
    const link = this.document.createElement('link');
    link.setAttribute('rel', 'stylesheet');
    link.setAttribute('href', href);
    link.onload = () => {
      loadSubject.next();
      loadSubject.complete();
    };
    link.onerror = () => {
      this.store.dispatch(new LogStyleLoadErrorAction(href, 'failed to load styles'));
      loadSubject.next();
      loadSubject.complete();
    };
    this.document.head.appendChild(link);
    return loadSubject.asObservable();
  }

  private removeDefaultFlavor() {
    this.document.head.removeChild(this.document.getElementById(defaultCupcakeFlavorLink));
    this.document.head.removeChild(this.document.getElementById(defaultKendoCupcakeFlavorLink));
  }
}
