import { Injectable } from '@angular/core';
import { LocationModel } from '../models';
import { escapeRegExp } from 'lodash';

@Injectable()
export class SavedLocationNameService {

  getLocationName(searchString: string, initName: string, locations: LocationModel[]) {
    const regexp = new RegExp(escapeRegExp(searchString.toLocaleLowerCase()) + '\\s\\((\\d+)\\)$', 'i');
    const locationNames = locations.map(d => d.locationName.toLocaleLowerCase());

    const hasExactMatch = locationNames.some(d => d === initName.toLocaleLowerCase());

    if (!hasExactMatch) {
      return initName;
    }

    const counters = locationNames.map(d => {
      const match = regexp.exec(d);
      return !match ? null : match[2] ? 0 : match[1];
    }).filter(Boolean);

    const currentCounter = counters.length > 0 ? Math.max.apply(null, counters) : 0;
    return `${searchString} (${currentCounter + 1})`;
  }

  getInitialName(locationName: string) {
    const counterRegexp = /\s\(\d+\)$/;
    return counterRegexp.test(locationName) ? locationName.replace(counterRegexp, '') : locationName;
  }
}
