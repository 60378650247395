var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { map } from 'rxjs/operators';
var SecuritiesService = /** @class */ (function () {
    function SecuritiesService(http) {
        this.http = http;
        this.baseUrl = environment.domain + "/corporate/activities/api";
    }
    SecuritiesService.prototype.getSecurities = function (searchText) {
        return this.http
            .post(this.baseUrl + "/securities", { searchText: searchText })
            .pipe(map(function (items) { return items.map(function (i) { return (__assign({}, i, { name: i.name === 'N/A' ? '' : i.name })); }); }));
    };
    return SecuritiesService;
}());
export { SecuritiesService };
