import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ChangeDetectionStrategy
} from '@angular/core';
import {
  ActivityDefinedField,
  ActivityDefinedFieldElement,
  ActivityDefinedFieldValue
} from '../../../models';
import { AdfComponentInterface } from '../adf-component.interface';
import { BehaviorSubject } from 'rxjs';
import { delay } from 'rxjs/operators';

@Component({
  selector: 'ea-multiselect-adf',
  templateUrl: './multiselect-adf.component.html',
  styleUrls: ['./multiselect-adf.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MultiSelectAdfComponent implements AdfComponentInterface, OnInit {
  @Input()
  field: ActivityDefinedField;

  @Output()
  valueChange = new EventEmitter<ActivityDefinedField>();

  elements: ActivityDefinedFieldElement[];
  selectedElementsSubject$ = new BehaviorSubject([]);
  selectedElements$ = this.selectedElementsSubject$.asObservable().pipe(delay(0));
  optionsLimit = 100;

  ngOnInit() {
    if (this.field && this.field.elements) {
      this.elements = this.field.elements;
    }
    if (this.field && this.field.values && this.field.values.length > 0) {
      this.selectedElementsSubject$.next(this.field.values.map((value) =>
                <ActivityDefinedFieldElement>{ id: value.elementId, name: value.textValue }));
    }
  }

  onAdfMultiSelectChange(newValue: ActivityDefinedFieldElement[] = []) {
    const selectedValues = newValue.map((value) =>
                <ActivityDefinedFieldValue>{ elementId: value.id, textValue: value.name });
    this.valueChange.emit(<ActivityDefinedField>{
      ...this.field,
      values: selectedValues
    });
  }
}
