/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "@ngx-translate/core";
import * as i3 from "../../pipes/status/participant-status-icon.pipe";
import * as i4 from "../../pipes/status/participant-status-localization-key.pipe";
import * as i5 from "./external-participant-popover-content.component";
var styles_ExternalParticipantPopoverContentComponent = [];
var RenderType_ExternalParticipantPopoverContentComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ExternalParticipantPopoverContentComponent, data: {} });
export { RenderType_ExternalParticipantPopoverContentComponent as RenderType_ExternalParticipantPopoverContentComponent };
function View_ExternalParticipantPopoverContentComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 12, "div", [["data-qa-id", "status"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 11, "a", [["class", "c-dropdown-item c-cursor-pointer"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSetStatus(_v.parent.parent.context.ngIf, _v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(3, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(4, { "c-dropdown-item-selected": 0 }), (_l()(), i0.ɵeld(5, 0, null, null, 3, "i", [["class", "c-p-right-xs"]], null, null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(7, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵppd(8, 1), (_l()(), i0.ɵeld(9, 0, null, null, 3, "span", [["class", "c-p-right-xs"]], null, null, null, null, null)), (_l()(), i0.ɵted(10, null, ["", ""])), i0.ɵppd(11, 1), i0.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { var currVal_0 = "c-dropdown-item c-cursor-pointer"; var currVal_1 = _ck(_v, 4, 0, (_v.parent.parent.context.ngIf.status === _v.context.$implicit)); _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = "c-p-right-xs"; var currVal_3 = i0.ɵunv(_v, 7, 1, _ck(_v, 8, 0, i0.ɵnov(_v.parent.parent.parent, 0), _v.context.$implicit)); _ck(_v, 7, 0, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_4 = i0.ɵunv(_v, 10, 0, i0.ɵnov(_v, 12).transform(i0.ɵunv(_v, 10, 0, _ck(_v, 11, 0, i0.ɵnov(_v.parent.parent.parent, 1), _v.context.$implicit)))); _ck(_v, 10, 0, currVal_4); }); }
function View_ExternalParticipantPopoverContentComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "div", [["class", "c-dropdown-heading c-text-xs c-text-muted c-text-uppercase c-text-bold c-p-xs"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, [" ", " "])), i0.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(5, 0, null, null, 0, "div", [["class", "c-dropdown-divider"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ExternalParticipantPopoverContentComponent_3)), i0.ɵdid(7, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵeld(8, 0, null, null, 0, "div", [["class", "c-dropdown-divider"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.statuses; _ck(_v, 7, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 3, 0, i0.ɵnov(_v, 4).transform("bd/event-activity/form.participation")); _ck(_v, 3, 0, currVal_0); }); }
function View_ExternalParticipantPopoverContentComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "button", [["class", "c-dropdown-item ea-add-institution-contacts-link c-p-xs"]], [[8, "disabled", 0], [8, "title", 0], [2, "c-text-muted", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onAddContacts(_v.parent.context.ngIf, _v.parent.context.ngIf.hasContacts) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵted(3, null, [" ", " "])), i0.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i0.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = !_v.parent.context.ngIf.hasContacts; var currVal_1 = (!_v.parent.context.ngIf.hasContacts ? i0.ɵunv(_v, 1, 1, i0.ɵnov(_v, 2).transform("bd/event-activity/form.no_contacts_to_display")) : ""); var currVal_2 = !_v.parent.context.ngIf.hasContacts; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = i0.ɵunv(_v, 3, 0, i0.ɵnov(_v, 4).transform("bd/event-activity/form.add_institution_contacts")); _ck(_v, 3, 0, currVal_3); }); }
function View_ExternalParticipantPopoverContentComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "div", [["class", "c-dropdown-divider"]], null, null, null, null, null))], null, null); }
function View_ExternalParticipantPopoverContentComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "div", [["class", "c-text attendee-popover-wrapper"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ExternalParticipantPopoverContentComponent_2)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ExternalParticipantPopoverContentComponent_4)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ExternalParticipantPopoverContentComponent_5)), i0.ɵdid(6, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(7, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 2, "a", [["class", "c-dropdown-item c-cursor-pointer c-text-danger c-p-xs"], ["data-qa-id", "removeButton"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onRemove(_v.context.ngIf) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(9, null, [" ", " "])), i0.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { var currVal_0 = _v.context.ngIf.contact; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_v.context.ngIf.contact; _ck(_v, 4, 0, currVal_1); var currVal_2 = !_v.context.ngIf.contact; _ck(_v, 6, 0, currVal_2); }, function (_ck, _v) { var currVal_3 = i0.ɵunv(_v, 9, 0, i0.ɵnov(_v, 10).transform("bd/event-activity/form.remove_external_participant")); _ck(_v, 9, 0, currVal_3); }); }
export function View_ExternalParticipantPopoverContentComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i3.ParticipantStatusIconPipe, []), i0.ɵpid(0, i4.ParticipantStatusLocalizationKeyPipe, []), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_ExternalParticipantPopoverContentComponent_1)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i1.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 3, 0, i0.ɵnov(_v, 4).transform(_co.context)); _ck(_v, 3, 0, currVal_0); }, null); }
export function View_ExternalParticipantPopoverContentComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ea-external-participant-popover-content", [], null, null, null, View_ExternalParticipantPopoverContentComponent_0, RenderType_ExternalParticipantPopoverContentComponent)), i0.ɵdid(1, 114688, null, 0, i5.ExternalParticipantPopoverContentComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ExternalParticipantPopoverContentComponentNgFactory = i0.ɵccf("ea-external-participant-popover-content", i5.ExternalParticipantPopoverContentComponent, View_ExternalParticipantPopoverContentComponent_Host_0, {}, {}, []);
export { ExternalParticipantPopoverContentComponentNgFactory as ExternalParticipantPopoverContentComponentNgFactory };
