<div class="c-row ea-adfs">
  <div class="c-col-2 c-p-right-0" data-automation-id="adf-name">
      <label class="ea-adf-label">{{'bd/event-activity/form.adfs' | translate}}</label>
  </div>
  <div class="c-col-3">
    <ea-defined-fields-selector
      [fields]="fields$ | async"
      [loaded]="fieldsLoaded$ | async"
      [loading]="fieldsLoading$ | async"
      (loadFields)="onLoadActivityDefinedFields()"
      (select)="onSelect($event)"
      (hide)="onHide($event)">
    </ea-defined-fields-selector>
    <div class='c-text-warning c-m-top-xs' *ngIf="alert$ | async">
      {{ 'bd/event-activity/form.adfs_saving_error_message' | translate }}
    </div>
  </div>
    <div class="c-col-7 c-p-left-0">
        <ng-container *ngFor="let field of (selectedFields$ | async); trackBy: trackByFn">
            <ea-adf-input [field]="field"
                          (valueChange)="onChange($event)"></ea-adf-input>
        </ng-container>
    </div>
</div>
