import { Injectable } from '@angular/core';

@Injectable({providedIn: 'root'})
export class PagingService {
  public getTotalPages(pageSize: number, itemsTotal: number): number {
    return Math.ceil(itemsTotal / pageSize);
  }

  public getPageItems<T>(allItems: T[], page: number, pageSize: number): T[] {
    const start = (page - 1) * pageSize;
    const end = Math.min(page * pageSize, allItems.length);
    return allItems.slice(start, end);
  }
}
