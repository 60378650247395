var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var UserService = /** @class */ (function () {
    function UserService(http) {
        this.http = http;
        this.baseUrl = environment.baseUrl;
    }
    UserService.prototype.load = function () {
        var _this = this;
        return this.http.get(this.baseUrl + "/api/user").pipe(map(function (profile) {
            return __assign({}, profile, { userPreferences: _this.mapUserPreferences(profile.userPreferences) });
        }));
    };
    UserService.prototype.mapUserPreferences = function (source) {
        return __assign({}, source, { shortTimePattern: source.shortTimePattern ? source.shortTimePattern.replace('tt', 'a') : null });
    };
    UserService.prototype.setDefaultLocation = function (locationId) {
        return this.http.post(this.baseUrl + "/api/location/set-default", { locationId: locationId });
    };
    UserService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserService_Factory() { return new UserService(i0.ɵɵinject(i1.HttpClient)); }, token: UserService, providedIn: "root" });
    return UserService;
}());
export { UserService };
