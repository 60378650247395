import { OnInit, OnDestroy, EventEmitter, ElementRef, } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, takeUntil, observeOn } from 'rxjs/operators';
import { Subject, asyncScheduler } from 'rxjs';
import { CupcakePopoverService } from '@ipreo/cupcake-components';
import { inputDebounceTime } from '../../../models/constants';
var InputComponent = /** @class */ (function () {
    function InputComponent(popoverService, elementRef) {
        this.popoverService = popoverService;
        this.elementRef = elementRef;
        this.valueChange = new EventEmitter();
        this.inputForm = new FormControl();
        this.focused = false;
        this.destroyed$ = new Subject();
        this.openPopoverQueue$ = new Subject();
    }
    Object.defineProperty(InputComponent.prototype, "warning", {
        set: function (value) {
            this.warningMsg = value;
            if (this.isValid) {
                this.hasWarning ? this.openWarningPopover() : this.closeWarningPopover();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InputComponent.prototype, "error", {
        set: function (value) {
            this.errorMsg = value;
            this.isValid ? this.closeErrorPopover() : this.openErrorPopover();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InputComponent.prototype, "value", {
        set: function (val) {
            if (this.inputForm.value !== val) {
                this.inputForm.setValue(val, { emitEvent: false });
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InputComponent.prototype, "isValid", {
        get: function () { return !this.errorMsg; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InputComponent.prototype, "hasWarning", {
        get: function () { return !!this.warningMsg; },
        enumerable: true,
        configurable: true
    });
    InputComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.inputForm.valueChanges.pipe(debounceTime(inputDebounceTime), distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe(function (value) { return _this.valueChange.emit(value); });
        this.openPopoverQueue$.pipe(observeOn(asyncScheduler), // to avoid ExpressionChangedAfterItHasBeenCheckedError
        takeUntil(this.destroyed$)).subscribe(function (options) {
            _this.popover = _this.popoverService.openPopover(_this.elementRef.nativeElement, options).response;
        });
    };
    InputComponent.prototype.onFocus = function () {
        this.focused = true;
        if (this.isValid && this.hasWarning) {
            this.openWarningPopover();
        }
    };
    InputComponent.prototype.onBlur = function () {
        this.focused = false;
        if (this.isValid && this.hasWarning) {
            this.closeWarningPopover();
        }
    };
    InputComponent.prototype.ngOnDestroy = function () {
        this.destroyed$.next();
        this.destroyed$.complete();
        this.closePopover();
    };
    InputComponent.prototype.openWarningPopover = function () {
        if (this.focused) {
            this.openPopover('c-popover-warning', this.warningMsg);
        }
    };
    InputComponent.prototype.openErrorPopover = function () {
        this.closePopover();
        this.openPopover('c-popover-danger', this.errorMsg);
    };
    InputComponent.prototype.closeErrorPopover = function () {
        this.closePopover();
        if (this.hasWarning) {
            this.openWarningPopover();
        }
    };
    InputComponent.prototype.closeWarningPopover = function () {
        this.closePopover();
    };
    InputComponent.prototype.openPopover = function (rootClass, msg) {
        var options = {
            rootCssClass: rootClass,
            position: 'bottom',
            trigger: 'manual',
            text: msg
        };
        this.openPopoverQueue$.next(options);
    };
    InputComponent.prototype.closePopover = function () {
        if (this.popover && !this.popover.closed) {
            this.popover.complete();
        }
    };
    return InputComponent;
}());
export { InputComponent };
