import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { UserProfile, UserPreferences } from '../models';
import { environment } from '@env/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) {}

  load(): Observable<UserProfile> {
    return this.http.get<UserProfile>(`${this.baseUrl}/api/user`).pipe(map((profile: UserProfile) => {
      return {
        ...profile,
        userPreferences: this.mapUserPreferences(profile.userPreferences)
      };
    }));
  }

  mapUserPreferences(source: UserPreferences): UserPreferences {
    return {
      ...source,
      shortTimePattern: source.shortTimePattern ? source.shortTimePattern.replace('tt', 'a') : null
    };
  }

  setDefaultLocation(locationId: number): Observable<Object> {
    return this.http.post<Object>(`${this.baseUrl}/api/location/set-default`, { locationId });
  }
}
