var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Contact, Institution } from '@ipreo/bd-external-participants';
import { sortBy, uniq } from 'lodash';
import * as eventActivityActions from '../actions/event-activity.action';
import { defaultDuration, Duration, maxLengthLimit, minLengthLimit, Error, isEqualParticipants, ChangeEntity, ActivityTemplateCode, ActivityMode, activityTypeOptions, otherType } from '../../models';
import { HttpStatusCodes } from '../../models/http-status-codes';
import { LocationType } from '../../models/saved-locations/location-type';
export var initialState = function () {
    var _a;
    return ({
        entity: null,
        loading: false,
        loaded: false,
        error: {
            type: Error.None,
            failedEntities: (_a = {},
                _a[ChangeEntity.Adf] = false,
                _a[ChangeEntity.ExternalParticipant] = false,
                _a[ChangeEntity.InternalParticipant] = false,
                _a[ChangeEntity.Location] = false,
                _a[ChangeEntity.Activity] = false,
                _a[ChangeEntity.Attachment] = false,
                _a[ChangeEntity.Security] = false,
                _a[ChangeEntity.AddressComment] = false,
                _a)
        }
    });
};
export function reducer(state, action) {
    var _a;
    if (state === void 0) { state = initialState(); }
    switch (action.type) {
        case eventActivityActions.INIT_NEW:
        case eventActivityActions.LOAD_EVENT_ACTIVITY_MODEL: {
            return __assign({}, state, { loading: true });
        }
        case eventActivityActions.LOAD_EVENT_ACTIVITY_MODEL_SUCCESS: {
            return __assign({}, state, { loading: false, loaded: true, error: __assign({}, state.error, { type: Error.None }) });
        }
        case eventActivityActions.LOAD_EVENT_ACTIVITY: {
            return __assign({}, state, { entity: __assign({}, action.payload, { attachments: [] }) });
        }
        case eventActivityActions.LOAD_EVENT_ACTIVITY_MODEL_FAIL: {
            return __assign({}, state, { loading: false, error: __assign({}, state.error, { type: mapErrorCode(action.payload) }) });
        }
        case eventActivityActions.SAVE_EVENT_ACTIVITY: {
            return __assign({}, state, { loading: true });
        }
        case eventActivityActions.SAVE_EVENT_ACTIVITY_SUCCESS: {
            return action.payload.savingErrors.length > 0 ? __assign({}, state, { loading: false, error: __assign({}, state.error, { type: Error.PartialSaveError, failedEntities: mapFailedEntities(action.payload.savingErrors) }), entity: __assign({}, action.payload.activity, { attachments: [] }) }) : state;
        }
        case eventActivityActions.SAVE_EVENT_ACTIVITY_FAIL: {
            return __assign({}, state, { loading: false, error: __assign({}, state.error, { type: mapErrorCode(action.payload) }) });
        }
        case eventActivityActions.UPDATE_SUBJECT: {
            return __assign({}, state, { entity: __assign({}, state.entity, { subject: action.payload }) });
        }
        case eventActivityActions.UPDATE_START_DATE: {
            return __assign({}, state, { entity: __assign({}, state.entity, { date: action.payload }) });
        }
        case eventActivityActions.UPDATE_END_DATE: {
            return __assign({}, state, { entity: __assign({}, state.entity, { endDate: action.payload }) });
        }
        case eventActivityActions.UPDATE_DURATION: {
            return mapDuration(state, action);
        }
        case eventActivityActions.UPDATE_INTERNAL_PARTICIPANTS: {
            return __assign({}, state, { entity: __assign({}, state.entity, { internalParticipants: action.payload }) });
        }
        case eventActivityActions.UPDATE_LOCATION: {
            return __assign({}, state, { entity: __assign({}, state.entity, { location: action.payload && __assign({}, action.payload, { activityId: state.entity.activityId, activityLocationId: state.entity.location && state.entity.location.activityLocationId }) }) });
        }
        case eventActivityActions.UPDATE_EVENT_LOCATION: {
            return __assign({}, state, { entity: __assign({}, state.entity, { scheduleId: action.payload.scheduleId, timeZone: action.payload.timeZone || state.entity.timeZone }) });
        }
        case eventActivityActions.UPDATE_ACTIVITY_TEMPLATE: {
            return __assign({}, state, { entity: __assign({}, state.entity, { activityMode: action.payload.defaultMode, activityType: action.payload.defaultSubType, activityMethod: action.payload.method, initiator: action.payload.initiators ? action.payload.defaultInitiator : null }) });
        }
        case eventActivityActions.UPDATE_ACTIVITY_INITIATOR: {
            return __assign({}, state, { entity: __assign({}, state.entity, { initiator: action.payload }) });
        }
        case eventActivityActions.UPDATE_ACTIVITY_MODE: {
            return __assign({}, state, { entity: __assign({}, state.entity, { activityMode: action.payload }) });
        }
        case eventActivityActions.UPDATE_ACTIVITY_SUB_TYPE: {
            return __assign({}, state, { entity: __assign({}, state.entity, { activityType: action.payload }) });
        }
        case eventActivityActions.ADD_EXTERNAL_PARTICIPANTS: {
            var newParticipants = action.payload.filter(function (d) { return !state.entity.externalParticipants.find(function (a) { return isEqualParticipants(d, a); }); });
            return __assign({}, state, { entity: __assign({}, state.entity, { externalParticipants: state.entity.externalParticipants.concat(newParticipants) }) });
        }
        case eventActivityActions.UPDATE_EXTERNAL_PARTICIPANTS: {
            return __assign({}, state, { entity: __assign({}, state.entity, { externalParticipants: action.payload.slice() }) });
        }
        case eventActivityActions.UPDATE_EXTERNAL_PARTICIPANT_STATUS: {
            var participant = state.entity.externalParticipants.find(function (d) { return d === action.payload.participant; });
            var otherParticipants = state.entity.externalParticipants.filter(function (d) { return d !== action.payload.participant; });
            return __assign({}, state, { entity: __assign({}, state.entity, { externalParticipants: otherParticipants.concat([
                        __assign({}, participant, { status: action.payload.newStatus })
                    ]) }) });
        }
        case eventActivityActions.DELETE_EXTERNAL_PARTICIPANT: {
            return __assign({}, state, { entity: __assign({}, state.entity, { externalParticipants: state.entity.externalParticipants.filter(function (d) { return !isEqualParticipants(action.payload, d); }) }) });
        }
        case eventActivityActions.CHANGE_EXTERNAL_PARTICIPANTS_COMPANY_CONTACTS: {
            var otherParticipants = state.entity.externalParticipants.filter(function (d) { return !d.contact || (d.company && d.company.id.prId !== action.payload.id.prId); });
            return __assign({}, state, { entity: __assign({}, state.entity, { externalParticipants: otherParticipants.concat(action.payload.participants) }) });
        }
        case eventActivityActions.UPDATE_NOTES: {
            return __assign({}, state, { entity: __assign({}, state.entity, { comment: action.payload }) });
        }
        case eventActivityActions.DELETE_ACTIVITY_FAIL: {
            return __assign({}, state, { error: __assign({}, state.error, { type: mapErrorCode(action.payload) }) });
        }
        case eventActivityActions.RESET_PARTIAL_ERRORS: {
            var entities = __assign({}, state.error.failedEntities, (_a = {}, _a[action.payload] = false, _a));
            return __assign({}, state, { error: __assign({}, state.error, { type: Object.values(entities).every(function (x) { return !x; }) ? Error.None : Error.PartialSaveError, failedEntities: entities }) });
        }
        case eventActivityActions.UPDATE_TIMEZONE: {
            return __assign({}, state, { entity: __assign({}, state.entity, { timeZone: action.payload }) });
        }
        case eventActivityActions.UPDATE_ADDRESS_COMMENT: {
            return __assign({}, state, { entity: __assign({}, state.entity, { addressComment: __assign({}, state.entity.addressComment, { value: action.payload }) }) });
        }
        case eventActivityActions.UPDATE_ONLINE_MEETING_LINK: {
            var newMode = state.entity && getNewActivityMode(state.entity, action.payload);
            return __assign({}, state, { entity: __assign({}, state.entity, { onlineMeetingLink: action.payload, activityMode: newMode }) });
        }
        case eventActivityActions.UPDATE_ONLINE_MEETING_PASSWORD: {
            return __assign({}, state, { entity: __assign({}, state.entity, { onlineMeetingPassword: action.payload }) });
        }
    }
    return state;
}
function mapErrorCode(errorCode) {
    switch (errorCode) {
        case HttpStatusCodes.BadRequest:
            return Error.BadRequest;
        case HttpStatusCodes.NotFound:
            return Error.NotFound;
        case HttpStatusCodes.InternalError:
            return Error.InternalError;
        default:
            return Error.Unknown;
    }
}
function mapFailedEntities(savingErrors) {
    var _a;
    // partial save error when Activity wasn`t updated is not handled
    var entities = uniq(savingErrors.map(function (e) { return e.entity; }).filter(function (entity) { return entity !== ChangeEntity.Activity; }));
    var result = (_a = {},
        _a[ChangeEntity.Adf] = false,
        _a[ChangeEntity.ExternalParticipant] = false,
        _a[ChangeEntity.InternalParticipant] = false,
        _a[ChangeEntity.Location] = false,
        _a[ChangeEntity.Activity] = false,
        _a[ChangeEntity.Attachment] = false,
        _a[ChangeEntity.Security] = false,
        _a[ChangeEntity.AddressComment] = false,
        _a);
    entities.forEach(function (entity) { return result[entity] = true; });
    return result;
}
function mapDuration(state, action) {
    var date = state.entity.date;
    if (action.payload !== Duration.AllDay &&
        state.entity.duration === Duration.AllDay &&
        state.previousStartDate) {
        date = state.previousStartDate;
    }
    if (action.payload === Duration.EndDate) {
        var endDate = new Date(+date);
        return __assign({}, state, { entity: __assign({}, state.entity, { date: date, duration: action.payload, endDate: new Date(endDate.setMinutes(endDate.getMinutes() + defaultDuration)) }) });
    }
    if (action.payload === Duration.AllDay) {
        date = new Date(state.entity.date.getFullYear(), state.entity.date.getMonth(), state.entity.date.getDate(), 0, 0);
        return __assign({}, state, { entity: __assign({}, state.entity, { duration: action.payload, date: date, endDate: null }), previousStartDate: new Date(+state.entity.date) });
    }
    return __assign({}, state, { entity: __assign({}, state.entity, { date: date, endDate: null, duration: action.payload }) });
}
function getNewActivityMode(activity, newLink) {
    var currentLink = activity.onlineMeetingLink;
    var activityTemplate = getActivityTemplate(activity.activityMode, activity.activityMethod, activity.activityType, activity.initiator);
    if ([ActivityTemplateCode.OneOnOne, ActivityTemplateCode.GroupMeeting].includes(activityTemplate.id)) {
        if (!!newLink && !currentLink) {
            return ActivityMode.Video;
        }
        else if (!newLink && !!currentLink) {
            return ActivityMode.Inperson;
        }
    }
    return activity.activityMode;
}
export function getActivityTemplate(mode, method, subType, initiator) {
    if (initiator === void 0) { initiator = null; }
    return activityTypeOptions.find(function (d) {
        return d.modes && d.modes.includes(mode) &&
            d.subTypes && d.subTypes.includes(subType) &&
            d.method === method &&
            ((!d.initiators && !initiator) || d.initiators.includes(initiator));
    }) || otherType;
}
export var getEventActivityEntity = function (state) { return state.entity; };
export var getEventActivityLoading = function (state) { return state.loading; };
export var getEventActivityLoaded = function (state) { return state.loaded; };
export var getIsNewActivity = function (state) { return !state.entity || !state.entity.activityId; };
export var getEventActivityDuration = function (state) { return state.entity.duration; };
export var getEventActivitySubjectIsValid = function (state) {
    var subject = state.entity && state.entity.subject;
    return !subject || (subject.length >= minLengthLimit && subject.length <= maxLengthLimit);
};
export var getEventActivityStartDateIsValid = function (state) {
    return !!state.entity && !!state.entity.date;
};
export var getEventActivityEndDateIsValid = function (state) {
    return !!state.entity && (!isEndDateSelected(state.entity.duration) || !!state.entity.endDate);
};
export var getEventActivityDateRangeIsValid = function (state) {
    return (getEventActivityStartDateIsValid(state) &&
        getEventActivityEndDateIsValid(state) &&
        (!isEndDateSelected(state.entity.duration) || +state.entity.endDate > +state.entity.date));
};
function isEndDateSelected(duration) {
    return duration === Duration.EndDate;
}
export var getEventActivityVirtualMeetingLinkIsValid = function (state) {
    var link = state.entity && state.entity.onlineMeetingLink;
    return !link || link.length <= 1000;
};
export var getEventActivityVirtualMeetingPasswordIsValid = function (state) {
    var password = state.entity && state.entity.onlineMeetingPassword;
    return !password || password.length <= 200;
};
export var getExternalParticipants = function (entity) {
    return (entity &&
        sortBy(entity.externalParticipants || [], function (d) { return [
            d.company && d.company.name && d.company.name.toLowerCase(),
            d.lastName && d.lastName.toLowerCase()
        ]; }));
};
export var getNewExternalParticipantsCount = function (entity) {
    return entity && entity.externalParticipants ? entity.externalParticipants.filter(function (ep) { return !ep.id; }).length : 0;
};
export var getSuggesterExternalParticipants = function (entity) {
    return (entity &&
        entity.externalParticipants.map(function (d) {
            return d.contact
                ? Contact.fromDto({
                    prId: d.contact.id.prId,
                    crmId: d.contact.id.crmId,
                    isCrm: !!d.contact.id.crmId,
                    isPrimary: null,
                    name: d.contact.name,
                    accountName: d.company.name,
                    prCompanyId: d.company.id.prId,
                    crmAccountId: d.company.id.crmId,
                    firstName: d.firstName,
                    lastName: d.lastName,
                    phone: d.workPhone,
                    mobilePhone: d.mobilePhone,
                    email: d.email,
                    jobRole: null
                }, d.companyCountry, d.companyCity)
                : Institution.fromDto({
                    prId: d.company.id.prId,
                    crmId: d.company.id.crmId,
                    isCrm: !!d.company.id.crmId,
                    name: d.company.name,
                    equityAssets: null,
                    country: d.companyCountry,
                    city: d.companyCity
                }, d.hasContacts);
        }));
};
export var getIsEventActivity = function (entity) {
    return entity && !!entity.eventId;
};
export var isSavedLocationSelected = function (entity) {
    return entity && !!entity.location && !!entity.location.locationId;
};
export var getLocationType = function (entity) {
    if (!entity || !entity.location) {
        return LocationType.None;
    }
    if (entity.location.locationId) {
        return entity.location.googlePlaceId ? LocationType.ValidSaved : LocationType.InvalidSaved;
    }
    return entity.location.googlePlaceId ? LocationType.ValidOneOff : LocationType.InvalidOneOff;
};
export var getAddressComment = function (entity) {
    return !!entity && entity.addressComment;
};
export var getOnlineMeetingLink = function (entity) {
    return !!entity && entity.onlineMeetingLink || '';
};
export var getOnlineMeetingPassword = function (entity) {
    return !!entity && entity.onlineMeetingPassword || '';
};
