<div class="c-row ea-securities">
  <div class="c-col-2 c-p-right-0" data-automation-id="symbols-name">
    <label>
      {{ 'bd/event-activity/form.symbols' | translate }}
    </label>
  </div>
  <div class="c-col-10">
    <div data-automation-id="symbols">
      <cupcake-suggester  #suggester
                          [formControl]="formInput"
                          [suggesterPlaceholder]="'bd/event-activity/form.symbols_placeholder' | translate"
                          [suggesterMinLength]="1"
                          [suggesterSelectedItems]="selectedSecurities$ | async"
                          [suggesterIdField]="'key'"
                          [suggesterValueField]="'name'"
                          [suggesterMultiselectMode]="true"
                          [suggesterClearInputOnBlur]="true"
                          [suggesterSelectAllEnabled]="false"
                          [suggesterClearAllEnabled]="false"
                          [suggesterSelectFilteredEnabled]="false"
                          [suggesterItemTemplate]="itemTemplate"
                          [suggesterPillTemplate]="pillTemplate"
                          [suggesterDataSource]="suggestedSecurities$"
                          [containerPosition]="ContainerPosition.Auto"
                          (suggesterOnSelectEvent)="onSelect($event)"
      >
      </cupcake-suggester>

      <ng-template #itemTemplate let-model="item">
        <div class="c-row c-row-collapsed">
          <div class="c-col-2" [innerHTML]="model.item.model.name | highlight : formInput.value"></div>
          <div class="c-col-6 c-text-muted" [innerHTML]="model.item.model.issuerName  | highlight : formInput.value"></div>
          <div class="c-col-4 c-text-muted c-text-right"> {{ model.item.model.primaryExchangeName }} </div>
        </div>
      </ng-template>
      <ng-template #pillTemplate let-model="item">
        {{ model.model.name || model.model.issuerName }}
      </ng-template>
    </div>
    <div class='c-text-warning c-m-top-xs' *ngIf="alert$ | async">
      {{ 'bd/event-activity/form.symbols_saving_error_message' | translate }}
    </div>
  </div>
</div>
