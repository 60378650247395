import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import {
  ContactListItemsSearchResponse,
  InstitutionListItemsSearchResponse,
  listsCount,
  ListSearchResponse,
  ListType,
  ContactModel,
  isEqualCompositeId,
  CompositeId
} from '../models';
import { environment } from '@env/environment';
import { AccountQueryService } from './account-query.service';

@Injectable()
export class ListsService {
  private baseUrl = `${environment.domain}/app/bd/lists/api`;
  constructor(private http: HttpClient, private accountQueryService: AccountQueryService) {}

  getInstitutionLists(searchText?: string): Observable<ListSearchResponse> {
    let params = new HttpParams({
      fromObject: {
        type: ListType.Account,
        size: listsCount.toString(),
        institutionsOnly: 'true'
      }
    });
    if (searchText) {
      params = params.append('searchText', searchText);
    }
    return this.http.get<ListSearchResponse>(`${this.baseUrl}/lists`, { params });
  }

  getContactsLists(searchText?: string): Observable<ListSearchResponse> {
    let params = new HttpParams({
      fromObject: {
        type: ListType.Contact,
        size: listsCount.toString()
      }
    });
    if (searchText) {
      params = params.append('searchText', searchText);
    }
    return this.http.get<ListSearchResponse>(`${this.baseUrl}/lists`, { params });
  }

  getContactsFromList(id: number): Observable<ContactListItemsSearchResponse> {
    return this.http.get<ContactListItemsSearchResponse>(`${this.baseUrl}/lists/${id}/contacts`).pipe(
      mergeMap(r => this.mapContactsAccountInfo(r.data)),
      map(data => <ContactListItemsSearchResponse> { data })
    );
  }

  getInstitutionsFromList(id: number): Observable<InstitutionListItemsSearchResponse> {
    return this.http.get<InstitutionListItemsSearchResponse>(
      `${this.baseUrl}/lists/${id}/accounts?institutionsOnly=true`
    );
  }

  private mapContactsAccountInfo(contacts: ContactModel[]) {
    const unmappedCompanyContacts = contacts.filter(c => !c.ipreoCompanyId && c.companyId);
    if (!unmappedCompanyContacts || unmappedCompanyContacts.length === 0) {
      return of(contacts);
    }
    return this.accountQueryService.getAccountsFromContactsList(unmappedCompanyContacts)
      .pipe(map((accounts) => contacts.map(c => {
          const account = accounts.find(
            a => isEqualCompositeId(a.id, <CompositeId> { prId: c.ipreoCompanyId, crmId: c.companyId }));

          return <ContactModel>{
            ...c,
            companyCity: account && account.city,
            companyCountry: account && account.country
          };
        })));
  }
}
