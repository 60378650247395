import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { Injector, LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { StoreModule, Store, select } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { InputsModule } from '@progress/kendo-angular-inputs';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { UploadModule } from '@progress/kendo-angular-upload';

import { CupcakeSuggesterModule } from '@ipreo/cupcake-suggester';
import { DateTimeSelectorModule } from '@ipreo/bd-date-time-selector';
import {
  CupcakeCommonModule,
  CupcakeDropdownsModule,
  CupcakeModalModule,
  CupcakePopoverModule,
  CupcakeNotifyModule, CupcakeIntegrationModule
} from '@ipreo/cupcake-components';
import {
  BdExternalParticipantsModule,
  EnvironmentProviderService
} from '@ipreo/bd-external-participants';
import { NotesEditorModule } from '@ipreo/notes-editor';

import { reducers, effects } from './store';
import { ExternalParticipantsProvider } from './services/external-participants-provider';

import * as fromContainers from './containers';
import * as fromComponents from './components';
import * as fromServices from './services';
import * as fromPipes from './pipes';
import * as fromDirectives from './directives';

import '@progress/kendo-angular-intl/locales/de/calendar';
import '@progress/kendo-angular-intl/locales/en-CA/calendar';
import '@progress/kendo-angular-intl/locales/en-GB/calendar';
import '@progress/kendo-angular-intl/locales/en/calendar';
import '@progress/kendo-angular-intl/locales/fr/calendar';
import '@progress/kendo-angular-intl/locales/ja/calendar';
import '@progress/kendo-angular-intl/locales/ru/calendar';
import '@progress/kendo-angular-intl/locales/zh/calendar';
import '@progress/kendo-angular-intl/locales/da/calendar';

import '@progress/kendo-angular-intl/locales/de/numbers';
import '@progress/kendo-angular-intl/locales/en-CA/numbers';
import '@progress/kendo-angular-intl/locales/en-GB/numbers';
import '@progress/kendo-angular-intl/locales/en/numbers';
import '@progress/kendo-angular-intl/locales/fr/numbers';
import '@progress/kendo-angular-intl/locales/ja/numbers';
import '@progress/kendo-angular-intl/locales/ru/numbers';
import '@progress/kendo-angular-intl/locales/zh/numbers';
import '@progress/kendo-angular-intl/locales/da/numbers';
import 'ckeditor';
import { Observable } from 'rxjs';
import {
  Localization,
  ParticipantMissingDataEditModule,
  ParticipantProfileHostProvider
} from '@ipreo/event-participant-missing-data-edit';
import {
  CompanyContactsModalModule,
  Localization as CompanyContactsLocalization
} from '@ipreo/company-contacts-modal';
import { ParticipantProfileHostService } from './services/participant-profile-host-provider';
import { PagerModule } from '../pager/pager.module';
import { getDefaultToNewProfiles } from '../store/selectors';
import { environment } from '@env/environment';
import { httpInterceptorProviders } from './http-interceptors';

export function profilesHostProviderFactory(injector: Injector): ParticipantProfileHostProvider {
  return {
    getProfilesHost: () => injector.get(ParticipantProfileHostService).getProfilesHost()
  };
}

export function defaultToNewProfilesFactory(injector: Injector): Observable<boolean> {
  const store = injector.get(Store);
  return store.pipe(select(getDefaultToNewProfiles));
}

export function localizationFactory(injector: Injector): Localization {
  const translateService: TranslateService = injector.get(TranslateService);

  return {
    contacts: translateService.instant('bd/event-activity/form.contacts'),
    editContact: translateService.instant('bd/event-activity/form.edit_contact'),
    reviewFailedInfo: translateService.instant('bd/event-activity/form.review_failed_info')
  };
}

export function companyContactsLocalizationFactory(injector: Injector): CompanyContactsLocalization {
  const translateService = injector.get(TranslateService);
  return {
    add: translateService.instant('bd/event-activity/form.add'),
    cancel: translateService.instant('bd/event-activity/form.cancel'),
    showMore: translateService.instant('bd/event-activity/form.show_more'),
    participantSearchPlaceholder: translateService.instant('bd/event-activity/form.participant_search_placeholder'),
    noContactsFound: translateService.instant('bd/event-activity/form.participant_search_not_found'),
    loadMoreError: translateService.instant('bd/event-activity/form.load_more_error_message'),
    contactsNotAvailable: translateService.instant('bd/event-activity/form.contacts_not_available'),
    notAvailableDescription: translateService.instant('bd/event-activity/form.not_available_description_message'),
    retry: translateService.instant('bd/event-activity/form.retry')
  };
}

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    CupcakeSuggesterModule,
    CupcakePopoverModule,
    CupcakeDropdownsModule,
    CupcakeModalModule,
    CupcakeCommonModule,
    CupcakeNotifyModule,
    RouterModule,
    StoreModule.forFeature('event-activity-dialog', reducers),
    EffectsModule.forFeature(effects),
    TranslateModule,
    DateTimeSelectorModule,
    BdExternalParticipantsModule,
    InputsModule,
    DateInputsModule,
    NotesEditorModule,
    UploadModule,
    ParticipantMissingDataEditModule.forRoot({
      profilesHostProviderFactory,
      localizationFactory,
      defaultToNewProfilesFactory
    }),
    PagerModule,
    CompanyContactsModalModule.forRoot({
      environment: environment.companyContactsEnvironment,
      localizationFactory: companyContactsLocalizationFactory
    }),
    CupcakeIntegrationModule
  ],
  declarations: [
    ...fromContainers.containers,
    ...fromComponents.components,
    ...fromPipes.pipes,
    ...fromDirectives.directives
  ],
  entryComponents: [...fromComponents.entryComponents],
  providers: [
    ...httpInterceptorProviders,
    ...fromServices.services,
    {
      provide: LOCALE_ID,
      useValue: 'en-US'
    },
    { provide: EnvironmentProviderService, useClass: ExternalParticipantsProvider }
  ],
  exports: [...fromContainers.containers, ...fromComponents.components]
})
export class EventActivityModule {}
