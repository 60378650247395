var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { EventEmitter, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { delay } from 'rxjs/operators';
var MultiSelectAdfComponent = /** @class */ (function () {
    function MultiSelectAdfComponent() {
        this.valueChange = new EventEmitter();
        this.selectedElementsSubject$ = new BehaviorSubject([]);
        this.selectedElements$ = this.selectedElementsSubject$.asObservable().pipe(delay(0));
        this.optionsLimit = 100;
    }
    MultiSelectAdfComponent.prototype.ngOnInit = function () {
        if (this.field && this.field.elements) {
            this.elements = this.field.elements;
        }
        if (this.field && this.field.values && this.field.values.length > 0) {
            this.selectedElementsSubject$.next(this.field.values.map(function (value) {
                return ({ id: value.elementId, name: value.textValue });
            }));
        }
    };
    MultiSelectAdfComponent.prototype.onAdfMultiSelectChange = function (newValue) {
        if (newValue === void 0) { newValue = []; }
        var selectedValues = newValue.map(function (value) {
            return ({ elementId: value.id, textValue: value.name });
        });
        this.valueChange.emit(__assign({}, this.field, { values: selectedValues }));
    };
    return MultiSelectAdfComponent;
}());
export { MultiSelectAdfComponent };
