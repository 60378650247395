import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError } from 'rxjs/operators';
import { of, pipe } from 'rxjs';

import { ActivityDefinedFieldService } from '../../services';
import * as eventActivityAction from '../actions/event-activity.action';
import * as adfAction from '../actions/activity-defined-field.action';
import { EventActivity } from '../../models';

@Injectable()
export class ActivityDataFieldEffects {
  constructor(
    private actions$: Actions,
    private activityDefinedFieldService: ActivityDefinedFieldService
  ) {}

  @Effect()
  Init$ = this.actions$.pipe(
    ofType(eventActivityAction.INIT),
    map(() => new adfAction.CheckHasAny())
  );

  @Effect()
  GetHasAny$ = this.actions$.pipe(
    ofType(adfAction.CHECK_HAS_ANY),
    switchMap(() =>
      this.activityDefinedFieldService.hasAny().pipe(
        map(hasAny => new adfAction.CheckHasAnySuccess({ hasAny })),
        catchError(error => of(new adfAction.CheckHasAnyFail(error)))
      )
    )
  );

  @Effect()
  LoadAdf$ = this.actions$.pipe(
    ofType(adfAction.LOAD_ACTIVITY_DEFINED_FIELDS),
    switchMap(() =>
      this.activityDefinedFieldService.list().pipe(
        map(fields => new adfAction.LoadActivityDefinedFieldsSuccess({ fields })),
        catchError(error => of(new adfAction.LoadActivityDefinedFieldsFail(error)))
      )
    )
  );

  @Effect()
  LoadSelectedAdfs$ = this.actions$.pipe(
    ofType(eventActivityAction.LOAD_EVENT_ACTIVITY),
    map((action: eventActivityAction.LoadEventActivity) => action.payload),
    this.loadSelectedAdfs()
  );

  @Effect()
  LoadSelectedAdfsAfterSave$ = this.actions$.pipe(
    ofType(eventActivityAction.SAVE_EVENT_ACTIVITY_SUCCESS),
    map((action: eventActivityAction.SaveEventActivitySuccess) => action.payload.activity),
    this.loadSelectedAdfs()
  );

  private loadSelectedAdfs() {
    return pipe(
      map((activity: EventActivity) => activity.activityDefinedFields),
      map(fields => new adfAction.SetSelectedActivityDefinedFields({ fields }))
    );
  }
}
