  <span class="k-file-extension-wrapper">
    <span class="k-file-extension">{{ file.extension.substring(1) }}</span> <span class="k-file-state"></span>
  </span>
  <span class="k-file-name-size-wrapper">
    <span class="k-file-name c-cursor-pointer" 
          [title]="file.name" 
          (click)="downloadFile()" 
          data-automation-id="file-name">{{ file.name }}</span>
    <span class="k-file-size">{{ getTotalFilesSizeMessage([file]) }}</span>
  </span>
  <button class="k-upload-action" (click)="onRemove()">
    <span class="k-icon k-delete k-i-x" title="{{'bd/event-activity/form.remove' | translate}}"></span>
  </button>
