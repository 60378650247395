import { NgZone } from '@angular/core';
import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
var PlaceService = /** @class */ (function () {
    function PlaceService(zone, document) {
        this.zone = zone;
        this.autocompleteService = new google.maps.places.AutocompleteService();
        // in order to make google place service work DOM element should be attached to it, even if it remains invisible
        this.placesService = new google.maps.places.PlacesService(document.createElement('div'));
    }
    PlaceService.prototype.getPlacePredictions = function (autocompletionRequest) {
        var _this = this;
        var request = {
            input: autocompletionRequest.input,
            types: autocompletionRequest.types || [],
            sessionToken: autocompletionRequest.sessionToken || null
        };
        var subj = new Subject();
        this.autocompleteService.getPlacePredictions(request, function (result, status) {
            _this.zone.run(function () {
                switch (status) {
                    case google.maps.places.PlacesServiceStatus.OK:
                        subj.next(result.map(function (r) {
                            return {
                                placeId: r.place_id,
                                name: r.structured_formatting.main_text,
                                description: r.description,
                                reference: r.reference
                            };
                        }));
                        subj.complete();
                        break;
                    case google.maps.places.PlacesServiceStatus.ZERO_RESULTS:
                        subj.next([]);
                        subj.complete();
                        break;
                    case google.maps.places.PlacesServiceStatus.INVALID_REQUEST:
                    case google.maps.places.PlacesServiceStatus.OVER_QUERY_LIMIT:
                    case google.maps.places.PlacesServiceStatus.NOT_FOUND:
                    case google.maps.places.PlacesServiceStatus.REQUEST_DENIED:
                    case google.maps.places.PlacesServiceStatus.UNKNOWN_ERROR:
                        subj.error(status.toString());
                        break;
                }
            });
        });
        return subj.asObservable();
    };
    PlaceService.prototype.createAutocompleteSessionToken = function () {
        return new google.maps.places.AutocompleteSessionToken();
    };
    PlaceService.prototype.getDetails = function (request) {
        var _this = this;
        var subj = new Subject();
        this.placesService.getDetails(request, function (result, status) {
            _this.zone.run(function () {
                switch (status) {
                    case google.maps.places.PlacesServiceStatus.OK:
                        subj.next(result);
                        subj.complete();
                        break;
                    case google.maps.places.PlacesServiceStatus.ZERO_RESULTS:
                    case google.maps.places.PlacesServiceStatus.INVALID_REQUEST:
                    case google.maps.places.PlacesServiceStatus.OVER_QUERY_LIMIT:
                    case google.maps.places.PlacesServiceStatus.NOT_FOUND:
                    case google.maps.places.PlacesServiceStatus.REQUEST_DENIED:
                    case google.maps.places.PlacesServiceStatus.UNKNOWN_ERROR:
                        subj.error(status.toString());
                        break;
                }
            });
        });
        return subj.asObservable();
    };
    PlaceService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PlaceService_Factory() { return new PlaceService(i0.ɵɵinject(i0.NgZone), i0.ɵɵinject(i1.DOCUMENT)); }, token: PlaceService, providedIn: "root" });
    return PlaceService;
}());
export { PlaceService };
