var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { catchError, filter, map, scan, startWith, switchMap, withLatestFrom } from 'rxjs/operators';
import { differenceBy } from 'lodash';
import { EntityType } from '@ipreo/bd-external-participants';
import * as dialogActions from '../actions/dialog-view.action';
import * as eventActivityActions from '../actions/event-activity.action';
import * as adfActions from '../actions/activity-defined-field.action';
import * as attachmentActions from '../actions/attachment.action';
import * as securitiesActions from '../actions/securities.action';
import * as fromEventActivity from '../selectors/event-activity.selector';
import * as fromErrors from '../selectors/error.selector';
import * as fromDialog from '../selectors/dialog-view.selector';
import * as fromAttachment from '../selectors/attachment.selector';
import * as fromAdf from '../selectors/activity-defined-field.selector';
import * as fromSecurities from '../selectors/securities.selector';
import * as fromRoot from '../../../store';
import { CloseDialogAction } from '../../../models';
import { ChangeAction, ChangeEntity, ChangeField, ContactExternalParticipant, Error, InstitutionExternalParticipant } from '../../models';
import * as fromSelectors from '../selectors';
import { of, throwError } from 'rxjs';
import { MapsService } from '../../services/maps.service';
var DialogViewEffects = /** @class */ (function () {
    function DialogViewEffects(actions$, store, mapsService) {
        var _this = this;
        this.actions$ = actions$;
        this.store = store;
        this.mapsService = mapsService;
        this.lastSaveResult$ = this.actions$.pipe(ofType(eventActivityActions.SAVE_EVENT_ACTIVITY_SUCCESS), map(function (action) { return action.payload; }), scan(function (accResult, currentPayload) { return (__assign({}, currentPayload, { isParticipantsChanged: (!!accResult && accResult.isParticipantsChanged) || currentPayload.isParticipantsChanged })); }, null));
        this.CloseAfterSave$ = this.lastSaveResult$.pipe(filter(function (payload) { return payload.savingErrors.length === 0; }), map(function (payload) {
            return new fromRoot.CloseDialog(CloseDialogAction.Cancel, {
                activity: payload.hubLoadActivity,
                participantsUpdated: payload.isParticipantsChanged
            });
        }));
        this.CloseAfterCancel$ = this.actions$.pipe(ofType(dialogActions.CANCEL_FORM), 
        // tslint:disable-next-line:deprecation
        withLatestFrom(this.lastSaveResult$.pipe(startWith(null))), map(function (_a) {
            var _ = _a[0], lastSaveResult = _a[1];
            return lastSaveResult ?
                new fromRoot.CloseDialog(CloseDialogAction.Cancel, {
                    activity: lastSaveResult.hubLoadActivity,
                    participantsUpdated: lastSaveResult.isParticipantsChanged
                }) :
                new fromRoot.CloseDialog();
        }));
        this.AppendAdfChangeLog$ = this.actions$.pipe(ofType(dialogActions.SUBMIT_FORM), withLatestFrom(this.store.pipe(select(fromEventActivity.getEventActivityIsValid)), this.store.pipe(select(fromAdf.getRemovedFields))), filter(function (_a) {
            var isValid = _a[1];
            return isValid;
        }), map(function (_a) {
            var fields = _a[2];
            return new dialogActions.UpdateChangeLog(fields.map(function (field) {
                return ({ entity: ChangeEntity.Adf, action: ChangeAction.Delete, id: field.tagId });
            }));
        }));
        this.Submit$ = this.actions$.pipe(ofType(dialogActions.SUBMIT_FORM), withLatestFrom(this.store.pipe(select(fromEventActivity.getEventActivityIsValid))), filter(function (_a) {
            var isValid = _a[1];
            return isValid;
        }), withLatestFrom(this.store.pipe(select(fromErrors.getEventActivityError)), this.store.pipe(select(fromDialog.getChangeLog)), this.store.pipe(select(fromEventActivity.getEventActivityEntity)), this.store.pipe(select(fromAdf.getSelectedFields)), this.store.pipe(select(fromAdf.getRemovedFields)), this.store.pipe(select(fromAttachment.getActivityAttachments)), this.store.pipe(select(fromSecurities.getSelectedSecurities))), map(function (_a) {
            var error = _a[1], changeLog = _a[2], entity = _a[3], activityDefinedFields = _a[4], removedFields = _a[5], attachments = _a[6], securities = _a[7];
            return error === Error.PartialSaveError ||
                _this.isActivityChanged(changeLog, entity, activityDefinedFields, removedFields, attachments, securities)
                ? new eventActivityActions.SaveEventActivity()
                : new fromRoot.CloseDialog();
        }));
        this.ResetChangeLog$ = this.actions$.pipe(ofType(eventActivityActions.SAVE_EVENT_ACTIVITY_SUCCESS), map(function () { return new dialogActions.ResetChangeLog(); }));
        this.ResetDirtyFlag$ = this.actions$.pipe(ofType(eventActivityActions.SAVE_EVENT_ACTIVITY_SUCCESS), map(function () { return new dialogActions.ResetDirtyFlag(); }));
        this.ResetFailedParticipants$ = this.actions$.pipe(ofType(eventActivityActions.SAVE_EVENT_ACTIVITY_SUCCESS), withLatestFrom(this.store.pipe(select(fromDialog.getFailedParticipantsInformation))), filter(function (_a) {
            var _ = _a[0], info = _a[1];
            return !!info;
        }), map(function () { return new dialogActions.UpdateFailedParticipants(null); }));
        this.TrackLocationUpdate$ = this.actions$.pipe(ofType(dialogActions.SUBMIT_LOCATION), map(function (action) { return action.payload; }), withLatestFrom(this.store.pipe(select(fromDialog.getChangeLog)), this.store.pipe(select(fromEventActivity.getEventActivityEntity))), filter(function (_a) {
            var location = _a[0], changeLog = _a[1], entity = _a[2];
            return _this.filterLocationActions(location, changeLog, entity);
        }), map(function (_a) {
            var location = _a[0], entity = _a[2];
            return new dialogActions.UpdateChangeLog([
                {
                    entity: ChangeEntity.Location,
                    action: _this.getChangeLocationAction(location),
                    id: entity.location && "" + entity.location.activityLocationId
                }
            ]);
        }));
        this.TriggerLocationUpdate$ = this.actions$.pipe(ofType(dialogActions.SUBMIT_LOCATION), map(function (action) { return action.payload; }), map(function (location) { return new eventActivityActions.UpdateLocation(location); }));
        this.TrackParticipantUpdate$ = this.actions$.pipe(ofType(dialogActions.SUBMIT_INTERNAL_PARTICIPANTS), map(function (action) { return action.payload; }), withLatestFrom(this.store.pipe(select(fromEventActivity.getEventActivityInternalParticipants))), map(function (_a) {
            var newParticipants = _a[0], oldParticipants = _a[1];
            return _this.trackParticipantChange(newParticipants, oldParticipants, ChangeEntity.InternalParticipant);
        }), filter(function (changes) { return !!changes.length; }), map(function (changes) { return new dialogActions.UpdateChangeLog(changes); }));
        this.TriggerParticipantUpdate$ = this.actions$.pipe(ofType(dialogActions.SUBMIT_INTERNAL_PARTICIPANTS), map(function (action) { return action.payload; }), map(function (payload) { return new eventActivityActions.UpdateInternalParticipants(payload); }));
        this.TrackSubjectUpdate$ = this.log(eventActivityActions.UPDATE_SUBJECT);
        this.TrackStartDateUpdate$ = this.log(eventActivityActions.UPDATE_START_DATE);
        this.TrackDurationUpdate$ = this.log(eventActivityActions.UPDATE_DURATION);
        this.TrackEndDateUpdate$ = this.log(eventActivityActions.UPDATE_END_DATE);
        this.TrackEventLocationUpdate$ = this.log(eventActivityActions.UPDATE_EVENT_LOCATION);
        this.TrackTemplateUpdate$ = this.log(eventActivityActions.UPDATE_ACTIVITY_TEMPLATE);
        this.TrackTypeUpdate$ = this.log(eventActivityActions.UPDATE_ACTIVITY_SUB_TYPE);
        this.TrackModeUpdate$ = this.log(eventActivityActions.UPDATE_ACTIVITY_MODE);
        this.TrackMethodUpdate$ = this.log(eventActivityActions.UPDATE_ACTIVITY_METHOD);
        this.TrackInitiatorUpdate$ = this.log(eventActivityActions.UPDATE_ACTIVITY_INITIATOR);
        this.TrackNotesUpdate$ = this.log(eventActivityActions.UPDATE_NOTES);
        this.TrackTimeZoneUpdate$ = this.log(eventActivityActions.UPDATE_TIMEZONE);
        this.TrackOnlineMeetingLinkUpdate$ = this.log(eventActivityActions.UPDATE_ONLINE_MEETING_LINK);
        this.TrackOnlineMeetingPasswordUpdate$ = this.log(eventActivityActions.UPDATE_ONLINE_MEETING_PASSWORD);
        this.TrackExternalParticipantDelete$ = this.actions$.pipe(ofType(eventActivityActions.DELETE_EXTERNAL_PARTICIPANT), map(function (action) { return action.payload; }), filter(function (d) { return !!d.id; }), map(function (participant) {
            return new dialogActions.UpdateChangeLog([
                {
                    entity: ChangeEntity.ExternalParticipant,
                    action: ChangeAction.Delete,
                    id: participant.id
                }
            ]);
        }));
        this.TrackExternalParticipantUpdateStatus$ = this.actions$.pipe(ofType(eventActivityActions.UPDATE_EXTERNAL_PARTICIPANT_STATUS), map(function (action) { return action.payload; }), filter(function (d) { return !!d.participant.id; }), map(function (payload) {
            return new dialogActions.UpdateChangeLog([
                {
                    entity: ChangeEntity.ExternalParticipant,
                    action: ChangeAction.Update,
                    id: payload.participant.id,
                    field: ChangeField.Status
                }
            ]);
        }));
        this.TrackAddressCommentUpdate$ = this.actions$.pipe(ofType(eventActivityActions.UPDATE_ADDRESS_COMMENT), map(function (action) { return action.payload; }), withLatestFrom(this.store.pipe(select(fromEventActivity.getAddressComment)), this.store.pipe(select(fromDialog.getChangeLog))), filter(function (_a) {
            var addressComment = _a[0], oldAddressComment = _a[1], changeLog = _a[2];
            return addressComment &&
                oldAddressComment &&
                oldAddressComment.tagId &&
                !changeLog.some(function (d) { return d.entity === ChangeEntity.AddressComment; });
        }), map(function (_a) {
            var oldAddressComment = _a[1];
            return new dialogActions.UpdateChangeLog([{
                    entity: ChangeEntity.AddressComment,
                    action: ChangeAction.Update,
                    id: oldAddressComment.tagId
                }]);
        }));
        this.UpdateExternalContacts$ = this.actions$.pipe(ofType(dialogActions.SUBMIT_EXTERNAL_PARTICIPANTS), map(function (action) { return action.payload; }), withLatestFrom(this.store.pipe(select(fromEventActivity.getExternalParticipants))), switchMap(function (_a) {
            var newParticipants = _a[0], oldParticipants = _a[1];
            var contacts = newParticipants
                .filter(function (d) { return d.type === EntityType.Contact; })
                .map(function (d) {
                var participant = oldParticipants.find(function (a) {
                    return a.contact &&
                        ((d.prId && a.contact.id.prId === d.prId) ||
                            (d.crmId && a.contact.id.crmId === d.crmId));
                });
                return new ContactExternalParticipant(d.prId, d.crmId, d.name, d.accountName, d.prCompanyId, d.crmAccountId, d.firstName, d.lastName, participant && participant.id, d.mobilePhone, d.phone, d.email, d.companyCity, d.companyCountry, participant && participant.status);
            });
            var institutions = newParticipants
                .filter(function (d) { return d.type === EntityType.Institution; })
                .map(function (d) {
                var participant = oldParticipants.find(function (a) { return !a.contact && a.company && a.company.id.prId === d.prId; });
                return new InstitutionExternalParticipant(d.prId, d.crmId, d.name, d.hasContacts, participant && participant.id, d.city, d.country);
            });
            var combinedParticipants = institutions.concat(contacts);
            var difference = _this.trackParticipantChange(combinedParticipants, oldParticipants, ChangeEntity.ExternalParticipant);
            return [
                new eventActivityActions.UpdateExternalParticipants(combinedParticipants),
                new dialogActions.UpdateChangeLog(difference)
            ];
        }));
        this.TrackAdfUpdate$ = this.actions$.pipe(ofType(adfActions.UPDATE_ACTIVITY_DEFINED_FIELD), map(function (action) { return action.payload.field; }), withLatestFrom(this.store.pipe(select(fromDialog.getChangeLog)), this.store.pipe(select(fromEventActivity.getEventActivityEntity))), filter(function (_a) {
            var field = _a[0], changeLog = _a[1], entity = _a[2];
            return !!entity.activityId &&
                !!field.tagId &&
                field.values.length > 0 &&
                !changeLog.find(function (d) { return d.entity === ChangeEntity.Adf && d.id === field.tagId; });
        }), map(function (_a) {
            var field = _a[0];
            return new dialogActions.UpdateChangeLog([
                {
                    entity: ChangeEntity.Adf,
                    action: ChangeAction.Update,
                    id: field.tagId
                }
            ]);
        }));
        this.MarkFormDirty$ = this.actions$.pipe(ofType(eventActivityActions.UPDATE_START_DATE, eventActivityActions.UPDATE_END_DATE, eventActivityActions.UPDATE_DURATION, eventActivityActions.UPDATE_EXTERNAL_PARTICIPANT_STATUS, eventActivityActions.CHANGE_EXTERNAL_PARTICIPANTS_COMPANY_CONTACTS, eventActivityActions.DELETE_EXTERNAL_PARTICIPANT, dialogActions.SUBMIT_EXTERNAL_PARTICIPANTS, dialogActions.SUBMIT_EXTERNAL_PARTICIPANTS_LIST, dialogActions.SUBMIT_INTERNAL_PARTICIPANTS, eventActivityActions.UPDATE_ACTIVITY_TEMPLATE, eventActivityActions.UPDATE_ACTIVITY_INITIATOR, eventActivityActions.UPDATE_SUBJECT, eventActivityActions.UPDATE_LOCATION, eventActivityActions.UPDATE_EVENT_LOCATION, eventActivityActions.UPDATE_NOTES, adfActions.UPDATE_ACTIVITY_DEFINED_FIELD, adfActions.HIDE_ACTIVITY_DEFINED_FIELD, attachmentActions.ADD_ACTIVITY_ATTACHMENTS, attachmentActions.REMOVE_ACTIVITY_ATTACHMENT, securitiesActions.SET_SELECTED_SECURITIES, eventActivityActions.UPDATE_ADDRESS_COMMENT, eventActivityActions.UPDATE_ONLINE_MEETING_LINK, eventActivityActions.UPDATE_ONLINE_MEETING_PASSWORD), map(function (action) { return new dialogActions.MarkFormDirty(); }));
        this.TriggerTimeZoneUpdate$ = this.actions$.pipe(ofType(eventActivityActions.UPDATE_LOCATION), map(function (action) { return action.payload; }), withLatestFrom(this.store.pipe(select(fromSelectors.getIsEventActivity))), filter(function (_a) {
            var location = _a[0], eventActivity = _a[1];
            return !eventActivity && !!location && !!location.googlePlaceId;
        }), map(function (_a) {
            var location = _a[0];
            return new dialogActions.SubmitTimeZoneUpdate(location.googlePlaceId);
        }));
        this.SetDefaultTimeZone$ = this.actions$.pipe(ofType(dialogActions.SUBMIT_LOCATION), map(function (action) { return action.payload; }), withLatestFrom(this.store.pipe(select(fromSelectors.getIsEventActivity)), this.store.pipe(select(fromSelectors.getEventActivityLocation)), this.store.pipe(select(fromRoot.getTimeZone))), filter(function (_a) {
            var location = _a[0], isEventActivity = _a[1], oldLocation = _a[2];
            return !isEventActivity && !!oldLocation && !location;
        }), map(function (_a) {
            var defaultTimeZone = _a[3];
            return new eventActivityActions.UpdateTimeZone(defaultTimeZone);
        }));
        this.UpdateTimeZone$ = this.actions$.pipe(ofType(dialogActions.SUBMIT_TIME_ZONE_UPDATE), map(function (action) { return action.payload; }), switchMap(function (googlePlaceId) {
            return _this.mapsService.getTimeZone(googlePlaceId).pipe(switchMap(function (timeZone) { return timeZone ? [
                new eventActivityActions.UpdateTimeZone(timeZone),
                new dialogActions.UpdateTimeZoneSuccess()
            ] : throwError(null); }), catchError(function () { return of(new dialogActions.UpdateTimeZoneFail()); }));
        }));
        this.TimeZoneFailed$ = this.actions$.pipe(ofType(dialogActions.UPDATE_TIME_ZONE_FAIL), withLatestFrom(this.store.pipe(select(fromRoot.getTimeZone))), switchMap(function (_a) {
            var timeZone = _a[1];
            return [
                new eventActivityActions.UpdateLocation(null),
                new eventActivityActions.UpdateTimeZone(timeZone)
            ];
        }));
        this.SetPrototypeWarnings$ = this.actions$.pipe(ofType(eventActivityActions.LOAD_EVENT_ACTIVITY_MODEL_SUCCESS), map(function (action) { return action.payload; }), map(function (model) { return new dialogActions.SetPreselectWarnings(model.activityPrototypeWarnings); }));
    }
    DialogViewEffects.prototype.trackParticipantChange = function (newParticipants, oldParticipants, entity) {
        var newParticipantsWithId = newParticipants.filter(function (d) { return d.id; });
        var oldParticipantsWithId = oldParticipants.filter(function (d) { return d.id; });
        return differenceBy(oldParticipantsWithId, newParticipantsWithId, function (d) {
            return (d.contact && (d.contact.id.prId || d.contact.id.crmId)) ||
                (!d.contact && d.company && (d.company.id.prId || d.company.id.crmId));
        }).map(function (difference) { return ({
            entity: entity,
            action: ChangeAction.Delete,
            id: difference.id
        }); });
    };
    DialogViewEffects.prototype.filterAddActivityActions = function (changeLog, entity) {
        return !!entity.activityId && !changeLog.find(function (d) { return d.entity === ChangeEntity.Activity; });
    };
    DialogViewEffects.prototype.filterLocationActions = function (location, changeLog, entity) {
        var _this = this;
        return (!!entity.activityId &&
            !!entity.location &&
            !!entity.location.activityLocationId &&
            !changeLog.find(function (d) { return d.entity === ChangeEntity.Location && d.action === _this.getChangeLocationAction(location); }));
    };
    DialogViewEffects.prototype.log = function (action) {
        var _this = this;
        return this.actions$.pipe(ofType(action), withLatestFrom(this.store.pipe(select(fromDialog.getChangeLog)), this.store.pipe(select(fromEventActivity.getEventActivityEntity))), filter(function (_a) {
            var changeLog = _a[1], entity = _a[2];
            return _this.filterAddActivityActions(changeLog, entity);
        }), map(function () {
            return new dialogActions.UpdateChangeLog([
                {
                    entity: ChangeEntity.Activity,
                    action: ChangeAction.Update
                }
            ]);
        }));
    };
    DialogViewEffects.prototype.getChangeLocationAction = function (location) {
        if (!location) {
            return ChangeAction.Delete;
        }
        return ChangeAction.Update;
    };
    DialogViewEffects.prototype.isActivityChanged = function (changeLog, entity, activityDefinedFields, removedFields, attachments, securities) {
        if (changeLog.length > 0) {
            return true;
        }
        if (!entity.activityId) {
            return true;
        }
        if (entity.location && !entity.location.activityLocationId) {
            return true;
        }
        if (entity.internalParticipants && !!entity.internalParticipants.find(function (p) { return !p.id; })) {
            return true;
        }
        if (entity.externalParticipants && !!entity.externalParticipants.find(function (p) { return !p.id; })) {
            return true;
        }
        if (removedFields.length > 0) {
            return true;
        }
        if (activityDefinedFields &&
            !!activityDefinedFields.find(function (adf) { return !adf.tagId && adf.values.length > 0; })) {
            return true;
        }
        if (!!attachments && attachments.filter(function (a) { return !a.id; }).length > 0) {
            return true;
        }
        if (securities &&
            !!securities.find(function (s) { return !s.tagId; })) {
            return true;
        }
        if (entity.addressComment && (entity.addressComment.tagId &&
            !entity.addressComment.value ||
            !entity.addressComment.tagId &&
                entity.addressComment.value)) {
            return true;
        }
        return false;
    };
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], DialogViewEffects.prototype, "CloseAfterSave$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], DialogViewEffects.prototype, "CloseAfterCancel$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], DialogViewEffects.prototype, "AppendAdfChangeLog$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], DialogViewEffects.prototype, "Submit$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], DialogViewEffects.prototype, "ResetChangeLog$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], DialogViewEffects.prototype, "ResetDirtyFlag$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], DialogViewEffects.prototype, "ResetFailedParticipants$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], DialogViewEffects.prototype, "TrackLocationUpdate$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], DialogViewEffects.prototype, "TriggerLocationUpdate$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], DialogViewEffects.prototype, "TrackParticipantUpdate$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], DialogViewEffects.prototype, "TriggerParticipantUpdate$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], DialogViewEffects.prototype, "TrackSubjectUpdate$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], DialogViewEffects.prototype, "TrackStartDateUpdate$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], DialogViewEffects.prototype, "TrackDurationUpdate$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], DialogViewEffects.prototype, "TrackEndDateUpdate$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], DialogViewEffects.prototype, "TrackEventLocationUpdate$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], DialogViewEffects.prototype, "TrackTemplateUpdate$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], DialogViewEffects.prototype, "TrackTypeUpdate$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], DialogViewEffects.prototype, "TrackModeUpdate$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], DialogViewEffects.prototype, "TrackMethodUpdate$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], DialogViewEffects.prototype, "TrackInitiatorUpdate$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], DialogViewEffects.prototype, "TrackNotesUpdate$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], DialogViewEffects.prototype, "TrackTimeZoneUpdate$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], DialogViewEffects.prototype, "TrackOnlineMeetingLinkUpdate$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], DialogViewEffects.prototype, "TrackOnlineMeetingPasswordUpdate$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], DialogViewEffects.prototype, "TrackExternalParticipantDelete$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], DialogViewEffects.prototype, "TrackExternalParticipantUpdateStatus$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], DialogViewEffects.prototype, "TrackAddressCommentUpdate$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], DialogViewEffects.prototype, "UpdateExternalContacts$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], DialogViewEffects.prototype, "TrackAdfUpdate$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], DialogViewEffects.prototype, "MarkFormDirty$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], DialogViewEffects.prototype, "TriggerTimeZoneUpdate$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], DialogViewEffects.prototype, "SetDefaultTimeZone$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], DialogViewEffects.prototype, "UpdateTimeZone$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], DialogViewEffects.prototype, "TimeZoneFailed$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], DialogViewEffects.prototype, "SetPrototypeWarnings$", void 0);
    return DialogViewEffects;
}());
export { DialogViewEffects };
