import { Injectable } from '@angular/core';
import { Duration, EventActivity, ActivityDto } from '../models';

@Injectable()
export class EventActivityMapper {
  constructor() {}

  fromServer(entity: ActivityDto): EventActivity {
    const date = new Date(entity.date);
    const endDateSelected = this.getIsEndDate(entity.duration, date);

    return {
      ...entity,
      date,
      duration: !endDateSelected ? entity.duration : Duration.EndDate,
      endDate: endDateSelected
        ? new Date(new Date(+date).setMinutes(date.getMinutes() + entity.duration))
        : null
    };
  }

  toServer(entity: EventActivity): EventActivity {
    return {
      ...entity,
      duration:
        entity.duration === Duration.EndDate
          ? (+entity.endDate - +entity.date) / 60000
          : entity.duration
    };
  }

  private getIsEndDate(duration: number, startDate: Date) {
    return !(duration in Duration) || (
      duration === Duration.AllDay && (startDate.getHours() !== 0 || startDate.getMinutes() !== 0)
    );
  }
}
