import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import {
  CupcakePopoverOptions,
  CupcakeModalOptions,
  CupcakeModalService
} from '@ipreo/cupcake-components';
import {
    ExternalParticipant,
    ExternalParticipantPopoverResponse,
    PopoverResponseAction,
    EntityType,
    ContactExternalParticipant
} from '../../models';
  import { take } from 'rxjs/operators';
  import { CompanyContactsModalComponent, CompanyContactsModalContext, Contact } from '@ipreo/company-contacts-modal';
import { LinkService } from '../../services/link.service';
import { PagingService } from '../../../pager/services/paging.service';
import {
  ExternalParticipantPopoverContentComponent
} from '../external-participant-popover-content/external-participant-popover-content.component';
import { ExternalParticipantStatus } from '../../models/participant/external-participant-status.enum';

@Component({
  selector: 'ea-external-participant-list',
  templateUrl: './external-participant-list.component.html',
  styleUrls: ['./external-participant-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class ExternalParticipantListComponent implements OnChanges {
  @Input() participants: ExternalParticipant[];
  @Input() loading: boolean;
  @Output() remove = new EventEmitter();
  @Output() update = new EventEmitter();
  @Output() add = new EventEmitter<ExternalParticipant[]>();

  pageSize = 30;
  currentPage = 1;
  totalPages: number;

  constructor(
    private modal: CupcakeModalService,
    public linkService: LinkService,
    private pagingService: PagingService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.participants) {
      this.totalPages = this.pagingService.getTotalPages(this.pageSize, this.participants.length);
      if (this.totalPages > 0 && this.currentPage > this.totalPages) {
        this.currentPage = this.totalPages;
      }
    }
  }

  onPopoverResponse(item: ExternalParticipantPopoverResponse) {
    switch (item.action) {
      case PopoverResponseAction.Remove:
        this.remove.emit(item.entity);
        break;
      case PopoverResponseAction.Add:
        this.onAddContacts(item.entity);
        break;
      case PopoverResponseAction.Update:
        this.update.emit(item.entity);
    }
  }

  getPopoverOptions(item: ExternalParticipant) {
    return new CupcakePopoverOptions({
      rootCssClass: 'ea-participant-popover',
      position: 'left',
      trigger: 'click',
      maxWidth: 175,
      hideOnScroll: true,
      zIndex: 5,
      context: item,
      boundariesElement: 'window',
      view: ExternalParticipantPopoverContentComponent
    });
  }

  hasStatus(status: ExternalParticipantStatus) {
    return status in ExternalParticipantStatus;
  }

  trackByFn(_: number, item: ExternalParticipant) {
    return item.contact && `${item.contact.id.prId || ''}|${item.contact.id.crmId || ''}|${EntityType.Contact}` ||
           item.company && `${item.company.id.prId || ''}|${item.company.id.crmId || ''}|${EntityType.Institution}`;
  }

  private onAddContacts(participant: ExternalParticipant) {
    const options = <CupcakeModalOptions>{
      mode: 'component',
      type: 'borderless',
      view: CompanyContactsModalComponent,
      contentWidth: 360,
      contentHeight: 395,
      context: <CompanyContactsModalContext>{
        companyInfo: {
          id: participant.company.id,
          name: participant.company.name,
          city: participant.companyCity,
          country: participant.companyCountry
        },
        selectedParticipants: this.participants
          .filter(d => d.contact && d.company.id.prId === participant.company.id.prId)
          .map(p => p.contact.id)
      }
    };

    this.modal
      .open(options)
      .pipe(take(1))
      .subscribe((contacts: Contact[]) => {
        this.add.next(contacts.map(c => this.mapCompanyContact(c)));
      });
  }

  private mapCompanyContact(contact: Contact): ExternalParticipant {
    return new ContactExternalParticipant(
      contact.prId,
      contact.crmId,
      contact.name,
      contact.accountName,
      contact.prCompanyId,
      contact.crmAccountId,
      contact.firstName,
      contact.lastName,
      null,
      contact.mobilePhone,
      contact.phone,
      contact.email,
      contact.companyCity,
      contact.companyCountry,
    );
  }
}
