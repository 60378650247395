import { EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocalizationModel } from '@ipreo/bd-date-time-selector';
import { Duration, durationOptions } from '../../models';
var DurationComponent = /** @class */ (function () {
    function DurationComponent(translateService) {
        this.translateService = translateService;
        this.valueChange = new EventEmitter();
        this.endDateChange = new EventEmitter();
        this.options = durationOptions;
    }
    Object.defineProperty(DurationComponent.prototype, "endDateHasError", {
        get: function () {
            return !this.endDateValid || !this.dateRangeValid;
        },
        enumerable: true,
        configurable: true
    });
    DurationComponent.prototype.getEndDateErrorMsg = function () {
        if (!this.endDateValid) {
            return this.translateService.instant('bd/event-activity/form.end_date_invalid');
        }
        if (!this.dateRangeValid) {
            return this.translateService.instant('bd/event-activity/form.date_range_invalid');
        }
        return null;
    };
    DurationComponent.prototype.onChange = function (value) {
        this.valueChange.emit(+value);
    };
    return DurationComponent;
}());
export { DurationComponent };
