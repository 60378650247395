import { Duration } from './duration.enum';

export const inputDebounceTime = 200;

export const minLengthLimit = 2;
export const maxLengthLimit = 255;

export const defaultDuration = Duration.OneHour;

export const contactsCount = 500;
export const listsCount = 5;

export const durationOptions = [
  {
    value: Duration.FifteenMinutes,
    translation: 'fifteen_minutes'
  },
  {
    value: Duration.ThirtyMinutes,
    translation: 'thirty_minutes'
  },
  {
    value: Duration.FortyFiveMinutes,
    translation: 'forty_five_minutes'
  },
  {
    value: Duration.OneHour,
    translation: 'one_hour'
  },
  {
    value: Duration.AllDay,
    translation: 'all_day'
  },
  {
    value: Duration.EndDate,
    translation: 'end_date'
  }
];

export const countriesWithStates = ['United States', 'Canada'];
