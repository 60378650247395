import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Injectable } from '@angular/core';

export enum LogLevel {
  Error = 'Error',
  Warning = 'Warning',
  Info = 'Information'
}

export interface LogEntry {
  message: string;
  level: LogLevel;
}

@Injectable()
export class LoggingService {
  private baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) { }

  log(logEntry: LogEntry, auth?: string) {
    return this.http.post(`${this.baseUrl}/api/log`, logEntry, auth && { headers: { Authorization: auth } });
  }
}
