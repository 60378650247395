import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromTranslate from '../reducers/translation.reducer';

export const getTranslationState = createFeatureSelector<fromTranslate.State>('translation');

export const getTranslationLoading = createSelector(
  getTranslationState,
  fromTranslate.getTranslationLoading
);

export const getTranslationLoaded = createSelector(
  getTranslationState,
  fromTranslate.getTranslationLoaded
);
