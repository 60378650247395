import * as savedLocationActions from '../actions/saved-locations.action';
import { LocationModel, SavedLocationItemModel, SavedLocationSaveResult } from '../../models';

export interface State {
  data: LocationModel[];
  selected: LocationModel;
  editValue: LocationModel;
  isDefaultChecked: boolean;
  loading: boolean;
  saveResult: SavedLocationSaveResult;
  loadFailed: boolean;
}

export const initialState = (): State => ({
  data: [],
  loading: false,
  selected: null,
  editValue: null,
  isDefaultChecked: false,
  saveResult: null,
  loadFailed: null
});

export function reducer(
  state = initialState(),
  action: savedLocationActions.SavedLocationsAction
): State {
  switch (action.type) {
    case savedLocationActions.LOAD_SAVED_LOCATIONS: {
      return {
        ...state,
        loading: true,
        loadFailed: null
      };
    }

    case savedLocationActions.LOAD_SAVED_LOCATIONS_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: action.payload,
        loadFailed: false
      };
    }

    case savedLocationActions.LOAD_SAVED_LOCATIONS_FAIL: {
      return {
        ...state,
        loading: false,
        data: [],
        loadFailed: true
      };
    }

    case savedLocationActions.SET_LOCATION: {
      return {
        ...state,
        selected: action.payload
      };
    }

    case savedLocationActions.CREATE_SAVED_LOCATION_SUCCESS: {
      return {
        ...state,
        data: [...state.data, action.payload],
        selected: action.payload
      };
    }

    case savedLocationActions.DELETE_SAVED_LOCATION_SUCCESS: {
      return {
        ...state,
        data: [...state.data.filter(d => d.locationId !== action.payload)],
        selected: null
      };
    }

    case savedLocationActions.EDIT_FINISH: {
      return {
        ...state,
        editValue: null,
        isDefaultChecked: false
      };
    }

    case savedLocationActions.EDIT_SAVED_LOCATION_SUCCESS: {
      const foundIndex = state.data.findIndex(d => d.locationId === action.payload.locationId);
      return {
        ...state,
        selected: action.payload,
        data: foundIndex >= 0 ?
          [...state.data.slice(0, foundIndex), action.payload, ...state.data.slice(foundIndex + 1)] :
          state.data
      };
    }

    case savedLocationActions.UPDATE_NAME: {
      return {
        ...state,
        saveResult: null,
        editValue: {
          ...state.editValue,
          locationName: action.payload
        }
      };
    }

    case savedLocationActions.UPDATE_ADDRESS: {
      return {
        ...state,
        saveResult: state.saveResult === SavedLocationSaveResult.Warning ?
          state.saveResult :
          null,
        editValue: {
          ...action.payload,
          locationId: state.selected && state.selected.locationId,
          locationName: state.editValue && state.editValue.locationName
        }
      };
    }

    case savedLocationActions.UPDATE_DEFAULT: {
      return {
        ...state,
        isDefaultChecked: action.isDefault
      };
    }

    case savedLocationActions.SET_RESULT: {
      return {
        ...state,
        saveResult: action.payload
      };
    }
  }

  return state;
}

export const getSavedLocations = (state: State) =>
  state.data.map(d => (
    <SavedLocationItemModel>{ ...d, placeId: d.locationId, description: d.locationName }
    )
  );

export const getSavedLocationsLoading = (state: State) =>
  state.loading;

export const getSelectedLocation = (state: State) =>
  state.selected;

export const getEditValue = (state: State) =>
  state.editValue;

export const getIsDefaultChecked = (state: State) => {
  return state.isDefaultChecked;
};

export const getWarning = (state: State) =>
  state.saveResult === SavedLocationSaveResult.Warning;

export const getError = (state: State) =>
  state.saveResult === SavedLocationSaveResult.Error;

export const getLoadFailed = (state: State) =>
  state.loadFailed;

export const getDefaultDisabled = (state: State) =>
  state && state.editValue && !state.editValue.googlePlaceId && !state.isDefaultChecked;
