var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as translationActions from '../actions/translation.action';
export var initialState = function () { return ({
    loading: false,
    loaded: false
}); };
export function reducer(state, action) {
    if (state === void 0) { state = initialState(); }
    switch (action.type) {
        case translationActions.LOAD_TRANSLATION: {
            return __assign({}, state, { loading: true });
        }
        case translationActions.LOAD_TRANSLATION_SUCCESS: {
            return {
                loading: false,
                loaded: true
            };
        }
        case translationActions.LOAD_TRANSLATION_FAIL: {
            return __assign({}, state, { loading: false });
        }
    }
    return state;
}
export var getTranslationLoading = function (state) { return state.loading; };
export var getTranslationLoaded = function (state) { return state.loaded; };
