import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output, Type, ChangeDetectionStrategy
} from '@angular/core';
import { ActivityDefinedField, CdfDataType, CdfSelectType } from '../../models';
import {
    TextAdfComponent,
    NumericAdfComponent,
    MultiSelectAdfComponent,
    SingleSelectAdfComponent
} from '../adf-types';
import { AdfComponentInterface } from '../adf-types/adf-component.interface';
import { DateAdfComponent } from '../adf-types/date-adf/date-adf.component';

@Component({
  selector: 'ea-adf-input',
  templateUrl: './adf-input.component.html',
  styleUrls: ['./adf-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdfInputComponent implements OnInit {
  @Input()
  field: ActivityDefinedField;

  @Output()
  valueChange = new EventEmitter();

  component: Type<AdfComponentInterface>;
  outputs: { valueChange: () => {} };
  inputs: { field: ActivityDefinedField };

  constructor() {
    this.outputs = { valueChange: this.onChange.bind(this) };
  }

  ngOnInit() {
    this.inputs = { field: this.field };
    switch (this.field.selectType) {
      case CdfSelectType.TextNumeric:
        this.component = this.resolveTextNumericType(this.field.dataType);
        break;
      case CdfSelectType.MultiSelect:
        this.component = MultiSelectAdfComponent;
        break;
      case CdfSelectType.SingleSelect:
      case CdfSelectType.Boolean:
        this.component = SingleSelectAdfComponent;
        break;
      default:
        this.component = null;
    }
  }

  onChange(field: ActivityDefinedField) {
    this.valueChange.emit(field);
  }

  private resolveTextNumericType(dataType: CdfDataType) {
    switch (dataType) {
      case CdfDataType.Alpha:
      case CdfDataType.AlphaNumeric:
        return TextAdfComponent;
      case CdfDataType.Numeric:
        return NumericAdfComponent;
      case CdfDataType.Date:
        return DateAdfComponent;
      case CdfDataType.None:
      default:
        return null;
    }
  }
}
