import { Component, Input, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';

// generator function
export function* counter(from: number, to: number) {
  for (let i = from; i <= to; i++) {
    yield i;
  }
}

@Component({
  selector: 'ea-pager',
  templateUrl: './pager.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PagerComponent {
  @Input() totalPages: number;
  @Input() visiblePagesCount = 10;
  @Input() set selectedPage(page: number) {
    this._selectPage(page);
  }
  @Output() pageSelect = new EventEmitter<number>();

  public currentViewIndex = 0;
  public currentPage = 1;

  public get pages() {
    return counter(
      this.firstVisiblePage,
      Math.min(this.lastVisiblePage, this.totalPages)
    );
  }

  public get isFirstPage() {
    return this.currentPage === 1;
  }

  public get isLastPage() {
    return this.currentPage === this.totalPages;
  }

  public get isLeftEllipsisVisible() {
    return this.currentViewIndex > 0;
  }

  public get isRightEllipsisVisible() {
    return this.totalPages - this.lastVisiblePage > 0;
  }

  public onPageClick(page: number) {
    if (this.currentPage !== page) {
      this.pageSelect.emit(page);
    }
  }

  public onNextPageClick() {
    if (!this.isLastPage) {
      this.pageSelect.emit(this.currentPage + 1);
    }
  }

  public onPreviousPageClick() {
    if (!this.isFirstPage) {
      this.pageSelect.emit(this.currentPage - 1);
    }
  }

  public toPreviousView() {
    this.pageSelect.emit(this.firstVisiblePage - 1);
  }

  public toNextView() {
    this.pageSelect.emit(this.lastVisiblePage + 1);
  }

  private _selectPage(page: number) {
    this.currentPage = page;
    this.currentViewIndex = Math.floor((page - 1) / this.visiblePagesCount);
  }

  private get lastVisiblePage() {
    return (this.currentViewIndex + 1) * this.visiblePagesCount;
  }

  private get firstVisiblePage() {
    return this.currentViewIndex * this.visiblePagesCount + 1;
  }
}
