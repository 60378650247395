/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./subject.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "./subject.component";
var styles_SubjectComponent = [i0.styles];
var RenderType_SubjectComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SubjectComponent, data: {} });
export { RenderType_SubjectComponent as RenderType_SubjectComponent };
function View_SubjectComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "c-popover c-popover-bottom c-popover-danger ea-subject-popover"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "c-popover-arrow"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "c-popover-content"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("bd/event-activity/form.subject_too_short")); _ck(_v, 3, 0, currVal_0); }); }
export function View_SubjectComponent_0(_l) { return i1.ɵvid(2, [i1.ɵqud(402653184, 1, { subjectInput: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 10, "div", [["class", "c-row c-row-align-center c-m-bottom-sm"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "c-col-2 c-p-right-0"], ["data-automation-id", "subject-name"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(6, 0, null, null, 5, "div", [["class", "c-col-10"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 4, "div", [["class", "ea-subject"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, [[1, 0], ["subjectInput", 1]], null, 1, "input", [["class", "c-input ea-subject-input"], ["data-automation-id", "subject-activity-input"], ["type", "text"]], [[2, "c-input-error", null], [1, "maxlength", 0], [8, "placeholder", 0]], [[null, "input"], [null, "blur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (_co.valueChange$.next($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (_co.onBlur() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SubjectComponent_1)), i1.ɵdid(11, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_4 = (_co.invalid && _co.showError); _ck(_v, 11, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("bd/event-activity/form.subject")); _ck(_v, 4, 0, currVal_0); var currVal_1 = (_co.invalid && _co.showError); var currVal_2 = _co.maxLength; var currVal_3 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 8, 2, i1.ɵnov(_v, 9).transform("bd/event-activity/form.activity_subject")), ""); _ck(_v, 8, 0, currVal_1, currVal_2, currVal_3); }); }
export function View_SubjectComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ea-subject", [], null, null, null, View_SubjectComponent_0, RenderType_SubjectComponent)), i1.ɵdid(1, 245760, null, 0, i4.SubjectComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SubjectComponentNgFactory = i1.ɵccf("ea-subject", i4.SubjectComponent, View_SubjectComponent_Host_0, { invalid: "invalid", value: "value" }, { subjectChange: "subjectChange" }, []);
export { SubjectComponentNgFactory as SubjectComponentNgFactory };
