/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./alert.component";
var styles_AlertComponent = [];
var RenderType_AlertComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AlertComponent, data: {} });
export { RenderType_AlertComponent as RenderType_AlertComponent };
export function View_AlertComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "c-row c-row-collapsed"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "div", [["class", "c-col c-p-horizontal-lg"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 3, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-exclamation-circle c-alert-icon"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i0.ɵted(5, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "c-alert ", _co.alert.alertClass, " c-alert-top c-alert-has-icon c-m-bottom-sm"); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.alert.message; _ck(_v, 5, 0, currVal_1); }); }
export function View_AlertComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ea-alert", [], null, null, null, View_AlertComponent_0, RenderType_AlertComponent)), i0.ɵdid(1, 49152, null, 0, i1.AlertComponent, [], null, null)], null, null); }
var AlertComponentNgFactory = i0.ɵccf("ea-alert", i1.AlertComponent, View_AlertComponent_Host_0, { alert: "alert" }, {}, []);
export { AlertComponentNgFactory as AlertComponentNgFactory };
