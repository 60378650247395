<div class="c-p-bottom-sm">
  <div class="c-row c-row-collapsed c-row-align-center c-full-width">
    <span class="c-col-6 c-p-right-md c-text-ellipsis c-text-right" [title]="field?.cdfName" data-automation-id="adf-date-name">{{field?.cdfName}}</span>
    <span class="c-col-6 c-kendo-dateinput">
      <kendo-datepicker
        #datePicker="kendo-datepicker"
        [class.has-error]="isInvalid"
        [value]="value"
        [placeholder]="'bd/event-activity/form.enter_date' | translate"
        [popupSettings]="{popupClass: 'c-kendo-calendar', animate: false}"
        (valueChange)="onValueChange($event)"
        (blur)="onBlur()">
        <kendo-datepicker-messages
          [today]="'bd/event-activity/form.today' | translate"
          [toggle]="'bd/event-activity/form.toggle_calendar' | translate"
        ></kendo-datepicker-messages>
      </kendo-datepicker>
    </span>
  </div>
</div>
