var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FailAction } from './fail-action';
export var LOAD_TRANSLATION = '[TRANSLATION] Load';
export var LOAD_TRANSLATION_SUCCESS = '[TRANSLATION] Load Success';
export var LOAD_TRANSLATION_FAIL = '[TRANSLATION] Load Fail';
export var LOAD_LOCALIZATION_FAIL = '[TRANSLATION] Load Localization Fail';
var LoadTranslation = /** @class */ (function () {
    function LoadTranslation(payload) {
        this.payload = payload;
        this.type = LOAD_TRANSLATION;
    }
    return LoadTranslation;
}());
export { LoadTranslation };
var LoadTranslationSuccess = /** @class */ (function () {
    function LoadTranslationSuccess() {
        this.type = LOAD_TRANSLATION_SUCCESS;
    }
    return LoadTranslationSuccess;
}());
export { LoadTranslationSuccess };
var LoadTranslationFail = /** @class */ (function (_super) {
    __extends(LoadTranslationFail, _super);
    function LoadTranslationFail(error) {
        var _this = _super.call(this) || this;
        _this.error = error;
        _this.type = LOAD_TRANSLATION_FAIL;
        return _this;
    }
    return LoadTranslationFail;
}(FailAction));
export { LoadTranslationFail };
var LoadLocalizationFail = /** @class */ (function (_super) {
    __extends(LoadLocalizationFail, _super);
    function LoadLocalizationFail(error) {
        var _this = _super.call(this) || this;
        _this.error = error;
        _this.type = LOAD_LOCALIZATION_FAIL;
        return _this;
    }
    return LoadLocalizationFail;
}(FailAction));
export { LoadLocalizationFail };
