var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { AuthRedirectNavigator } from '../../authorization/contracts/redirect-navigator';
var IframeRedirectNavigator = /** @class */ (function (_super) {
    __extends(IframeRedirectNavigator, _super);
    function IframeRedirectNavigator(window) {
        var _this = _super.call(this) || this;
        _this.window = window;
        return _this;
    }
    Object.defineProperty(IframeRedirectNavigator.prototype, "url", {
        get: function () {
            return this._openedWindow && this._openedWindow.location.href;
        },
        enumerable: true,
        configurable: true
    });
    IframeRedirectNavigator.prototype.navigate = function (params) {
        if (!params && !params.url) {
            return Promise.reject(new Error('No url provided'));
        }
        this._openedWindow = this.window.open(params.url);
        return Promise.resolve();
    };
    return IframeRedirectNavigator;
}(AuthRedirectNavigator));
export { IframeRedirectNavigator };
