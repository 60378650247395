/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./adf-input.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ipreo/cupcake-components/dist/cjs/common/cupcake-dynamic.directive";
import * as i3 from "./adf-input.component";
var styles_AdfInputComponent = [i0.styles];
var RenderType_AdfInputComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AdfInputComponent, data: {} });
export { RenderType_AdfInputComponent as RenderType_AdfInputComponent };
export function View_AdfInputComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 16777216, [["dynamic", 1]], null, 1, "div", [["cupcakeDynamic", ""]], null, null, null, null, null)), i1.ɵdid(1, 147456, null, 0, i2.CupcakeDynamicDirective, [i1.ViewContainerRef, i1.ComponentFactoryResolver, i1.Injector], { inputs: [0, "inputs"], outputs: [1, "outputs"], type: [2, "type"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.inputs; var currVal_1 = _co.outputs; var currVal_2 = _co.component; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_AdfInputComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ea-adf-input", [], null, null, null, View_AdfInputComponent_0, RenderType_AdfInputComponent)), i1.ɵdid(1, 114688, null, 0, i3.AdfInputComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AdfInputComponentNgFactory = i1.ɵccf("ea-adf-input", i3.AdfInputComponent, View_AdfInputComponent_Host_0, { field: "field" }, { valueChange: "valueChange" }, []);
export { AdfInputComponentNgFactory as AdfInputComponentNgFactory };
