var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FailAction } from '../../../store/actions/fail-action';
export var CHECK_HAS_ANY = '[ADF] Check Has Any';
export var CHECK_HAS_ANY_SUCCESS = '[ADF] Check Has Any Success';
export var CHECK_HAS_ANY_FAIL = '[ADF] Check Has Any Fail';
export var LOAD_ACTIVITY_DEFINED_FIELDS = '[ADF] Load';
export var LOAD_ACTIVITY_DEFINED_FIELDS_SUCCESS = '[ADF] Load Success';
export var LOAD_ACTIVITY_DEFINED_FIELDS_FAIL = '[ADF] Load Fail';
export var SET_SELECTED_ACTIVITY_DEFINED_FIELDS = '[ADF] Set Selected Activity Defined Fields';
export var SELECT_ACTIVITY_DEFINED_FIELD = '[ADF] Select Activity Defined Fields';
export var UPDATE_ACTIVITY_DEFINED_FIELD = '[ADF] Update Activity Defined Field';
export var HIDE_ACTIVITY_DEFINED_FIELD = '[ADF] Hide Activity Defined Field';
var CheckHasAny = /** @class */ (function () {
    function CheckHasAny() {
        this.type = CHECK_HAS_ANY;
    }
    return CheckHasAny;
}());
export { CheckHasAny };
var CheckHasAnySuccess = /** @class */ (function () {
    function CheckHasAnySuccess(payload) {
        this.payload = payload;
        this.type = CHECK_HAS_ANY_SUCCESS;
    }
    return CheckHasAnySuccess;
}());
export { CheckHasAnySuccess };
var CheckHasAnyFail = /** @class */ (function (_super) {
    __extends(CheckHasAnyFail, _super);
    function CheckHasAnyFail(error) {
        var _this = _super.call(this) || this;
        _this.error = error;
        _this.type = CHECK_HAS_ANY_FAIL;
        return _this;
    }
    return CheckHasAnyFail;
}(FailAction));
export { CheckHasAnyFail };
var LoadActivityDefinedFields = /** @class */ (function () {
    function LoadActivityDefinedFields() {
        this.type = LOAD_ACTIVITY_DEFINED_FIELDS;
    }
    return LoadActivityDefinedFields;
}());
export { LoadActivityDefinedFields };
var LoadActivityDefinedFieldsSuccess = /** @class */ (function () {
    function LoadActivityDefinedFieldsSuccess(payload) {
        this.payload = payload;
        this.type = LOAD_ACTIVITY_DEFINED_FIELDS_SUCCESS;
    }
    return LoadActivityDefinedFieldsSuccess;
}());
export { LoadActivityDefinedFieldsSuccess };
var LoadActivityDefinedFieldsFail = /** @class */ (function (_super) {
    __extends(LoadActivityDefinedFieldsFail, _super);
    function LoadActivityDefinedFieldsFail(error) {
        var _this = _super.call(this) || this;
        _this.error = error;
        _this.type = LOAD_ACTIVITY_DEFINED_FIELDS_FAIL;
        return _this;
    }
    return LoadActivityDefinedFieldsFail;
}(FailAction));
export { LoadActivityDefinedFieldsFail };
var SetSelectedActivityDefinedFields = /** @class */ (function () {
    function SetSelectedActivityDefinedFields(payload) {
        this.payload = payload;
        this.type = SET_SELECTED_ACTIVITY_DEFINED_FIELDS;
    }
    return SetSelectedActivityDefinedFields;
}());
export { SetSelectedActivityDefinedFields };
var SelectActivityDefinedField = /** @class */ (function () {
    function SelectActivityDefinedField(payload) {
        this.payload = payload;
        this.type = SELECT_ACTIVITY_DEFINED_FIELD;
    }
    return SelectActivityDefinedField;
}());
export { SelectActivityDefinedField };
var UpdateActivityDefinedField = /** @class */ (function () {
    function UpdateActivityDefinedField(payload) {
        this.payload = payload;
        this.type = UPDATE_ACTIVITY_DEFINED_FIELD;
    }
    return UpdateActivityDefinedField;
}());
export { UpdateActivityDefinedField };
var HideActivityDefinedField = /** @class */ (function () {
    function HideActivityDefinedField(payload) {
        this.payload = payload;
        this.type = HIDE_ACTIVITY_DEFINED_FIELD;
    }
    return HideActivityDefinedField;
}());
export { HideActivityDefinedField };
