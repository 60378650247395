import { createSelector } from '@ngrx/store';
import * as fromFeature from '../reducers';
import * as fromEventActivity from '../reducers/event-activity.reducer';
import { getSelectedLocation } from './saved-locations.selector';
var ɵ0 = function (state) { return state.eventActivity; };
export var getEventActivityState = createSelector(fromFeature.getEventActivityDialogState, ɵ0);
// Entity selectors
export var getEventActivityEntity = createSelector(getEventActivityState, fromEventActivity.getEventActivityEntity);
var ɵ1 = function (entity) { return entity && entity.subject; };
export var getEventActivitySubject = createSelector(getEventActivityEntity, ɵ1);
var ɵ2 = function (entity, selected) { return entity.location && getFullAddress(entity.location, selected); };
export var getEventActivityLocationFullAddress = createSelector(getEventActivityEntity, getSelectedLocation, ɵ2);
var ɵ3 = function (entity) { return entity && entity.location; };
export var getEventActivityLocation = createSelector(getEventActivityEntity, ɵ3);
var ɵ4 = function (entity) { return entity && entity.locationId && getAddress(entity) || ''; };
export var getSavedLocationAddress = createSelector(getEventActivityLocation, ɵ4);
var ɵ5 = function (entity) { return entity && entity.activityId; };
export var getEventActivityId = createSelector(getEventActivityEntity, ɵ5);
var ɵ6 = function (entity) { return entity && entity.timeZone; };
export var getEventActivityTimezone = createSelector(getEventActivityEntity, ɵ6);
var ɵ7 = function (entity) { return entity && entity.date; };
export var getEventActivityStartDate = createSelector(getEventActivityEntity, ɵ7);
var ɵ8 = function (entity) { return entity && entity.endDate; };
export var getEventActivityEndDate = createSelector(getEventActivityEntity, ɵ8);
var ɵ9 = function (entity) { return entity && entity.internalParticipants; };
export var getEventActivityInternalParticipants = createSelector(getEventActivityEntity, ɵ9);
var ɵ10 = function (entity) { return entity && entity.scheduleId; };
export var getEventScheduleId = createSelector(getEventActivityEntity, ɵ10);
var ɵ11 = function (entity) { return entity && entity.eventId; };
export var getEventId = createSelector(getEventActivityEntity, ɵ11);
export var getExternalParticipants = createSelector(getEventActivityEntity, fromEventActivity.getExternalParticipants);
export var getNewExternalParticipantsCount = createSelector(getEventActivityEntity, fromEventActivity.getNewExternalParticipantsCount);
export var getSuggesterExternalParticipants = createSelector(getEventActivityEntity, fromEventActivity.getSuggesterExternalParticipants);
export var getEventActivityLoading = createSelector(getEventActivityState, fromEventActivity.getEventActivityLoading);
export var getEventActivityLoaded = createSelector(getEventActivityState, fromEventActivity.getEventActivityLoaded);
export var getEventActivitySubjectIsValid = createSelector(getEventActivityState, fromEventActivity.getEventActivitySubjectIsValid);
export var getEventActivityStartDateIsValid = createSelector(getEventActivityState, fromEventActivity.getEventActivityStartDateIsValid);
export var getEventActivityEndDateIsValid = createSelector(getEventActivityState, fromEventActivity.getEventActivityEndDateIsValid);
export var getEventActivityDateRangeIsValid = createSelector(getEventActivityState, fromEventActivity.getEventActivityDateRangeIsValid);
var ɵ12 = function (startDateIsValid, endDateIsValid, dateRangeIsValid) {
    return startDateIsValid && endDateIsValid && dateRangeIsValid;
};
export var getEventActivityPredefinedFieldsIsValid = createSelector(getEventActivityStartDateIsValid, getEventActivityEndDateIsValid, getEventActivityDateRangeIsValid, ɵ12);
export var getEventActivityVirtualMeetingLinkIsValid = createSelector(getEventActivityState, fromEventActivity.getEventActivityVirtualMeetingLinkIsValid);
export var getEventActivityVirtualMeetingPasswordIsValid = createSelector(getEventActivityState, fromEventActivity.getEventActivityVirtualMeetingPasswordIsValid);
var ɵ13 = function (linkValidity, passwordValidity) { return linkValidity && passwordValidity; };
export var getEventActivityVirtualMeetingIsValid = createSelector(getEventActivityVirtualMeetingLinkIsValid, getEventActivityVirtualMeetingPasswordIsValid, ɵ13);
var ɵ14 = function (subjectIsValid, datesIsValid, virtualMeetingIsValid) {
    return subjectIsValid && datesIsValid && virtualMeetingIsValid;
};
export var getEventActivityIsValid = createSelector(getEventActivitySubjectIsValid, getEventActivityPredefinedFieldsIsValid, getEventActivityVirtualMeetingIsValid, ɵ14);
export var getIsNewActivity = createSelector(getEventActivityState, fromEventActivity.getIsNewActivity);
export var getEventActivityDuration = createSelector(getEventActivityState, fromEventActivity.getEventActivityDuration);
var ɵ15 = function (entity) { return entity.comment; };
export var getNotes = createSelector(getEventActivityEntity, ɵ15);
export var getIsEventActivity = createSelector(getEventActivityEntity, fromEventActivity.getIsEventActivity);
export var isSavedLocationSelected = createSelector(getEventActivityEntity, fromEventActivity.isSavedLocationSelected);
export var getLocationType = createSelector(getEventActivityEntity, fromEventActivity.getLocationType);
export var getAddressComment = createSelector(getEventActivityEntity, fromEventActivity.getAddressComment);
export var getOnlineMeetingLink = createSelector(getEventActivityEntity, fromEventActivity.getOnlineMeetingLink);
export var getOnlineMeetingPassword = createSelector(getEventActivityEntity, fromEventActivity.getOnlineMeetingPassword);
export function getFullAddress(location, selected) {
    if (selected && selected.locationName) {
        return selected.locationName;
    }
    return getAddress(location);
}
export function getAddress(location) {
    if (!location) {
        return '';
    }
    if (!location.googlePlaceId) {
        return getUnmappedLocationAddress(location);
    }
    if (!location.address) {
        return '';
    }
    var venuePart = location.address.startsWith(location.venueName) ? '' : location.venueName;
    return [venuePart, location.address].filter(Boolean).join(', ');
}
function getUnmappedLocationAddress(location) {
    return [location.address, location.city, location.state, location.country].filter(Boolean).join(', ');
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14, ɵ15 };
