import { Injectable } from '@angular/core';
import { InternalParticipant } from '../models';
import { InternalParticipantSuggesterModel } from '../models/participant/internal-participant-suggester-item';

@Injectable({
  providedIn: 'root'
})
export class SuggesterModelMapperService {
  public mapSelectedInternalParticipants(participants: InternalParticipant[]) {
    return participants.map(p => <InternalParticipantSuggesterModel> {
      id: p.contact.id.crmId,
      value: p.contact.name,
      disabled: false,
      participant: p
    });
  }

  public mapInternalParticipants(participants: InternalParticipant[]) {
    return participants.map(p => {
      const item = <InternalParticipantSuggesterModel> {
        id: p.contact.id.crmId,
        value: p.contact.name,
        participant: p,
        missingContactFields: []
      };

      if (!p.company || !p.company.name) {
        item.missingContactFields.push('institution');
      }

      item.disabled = !!item.missingContactFields.length;

      return item;
    });
  }
}
