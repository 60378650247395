<ul *ngIf="totalPages > 1" class="c-pagination c-m-bottom-0">
  <li  [class.c-pagination-disabled]="isFirstPage">
      <a class="c-pagination-link"(click)="onPageClick(1)">
          <i class="fa fa-angle-double-left"></i>
      </a>
  </li>
  <li [class.c-pagination-disabled]="isFirstPage">
    <a class="c-pagination-previous c-pagination-link" (click)="onPreviousPageClick()"></a>
  </li>
  <li *ngIf="isLeftEllipsisVisible">
      <a class="c-pagination-link c-pagination-ellipsis" (click)="toPreviousView()"></a>
  </li>
  <li *ngFor="let page of pages">
      <a class="c-pagination-link" [class.c-pagination-current]="page === currentPage" (click)="onPageClick(page)">{{ page }}</a>
  </li>
  <li *ngIf="isRightEllipsisVisible">
    <a class="c-pagination-link c-pagination-ellipsis" (click)="toNextView()"></a>
  </li>
  <li [class.c-pagination-disabled]="isLastPage">
    <a class="c-pagination-link c-pagination-next" (click)="onNextPageClick()"></a>
  </li>
  <li [class.c-pagination-disabled]="isLastPage">
    <a class="c-pagination-link" (click)="onPageClick(totalPages)">
        <i class="fa fa-angle-double-right"></i>
    </a>
  </li>
</ul>
