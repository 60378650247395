var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FailAction } from './fail-action';
export var LOAD_USER_PROFILE = '[USER PROFILE] Load';
export var LOAD_USER_PROFILE_SUCCESS = '[USER PROFILE] Load Success';
export var LOAD_USER_PROFILE_FAIL = '[USER PROFILE] Load Fail';
export var SET_DEFAULT_LOCATION = '[USER PROFILE] Set Default Location';
export var SET_DEFAULT_LOCATION_SUCCESS = '[USER PROFILE] Set Default Location Success';
export var SET_DEFAULT_LOCATION_FAIL = '[USER PROFILE] Set Default Location Fail';
var LoadUserProfile = /** @class */ (function () {
    function LoadUserProfile() {
        this.type = LOAD_USER_PROFILE;
    }
    return LoadUserProfile;
}());
export { LoadUserProfile };
var LoadUserProfileSuccess = /** @class */ (function () {
    function LoadUserProfileSuccess(payload) {
        this.payload = payload;
        this.type = LOAD_USER_PROFILE_SUCCESS;
    }
    return LoadUserProfileSuccess;
}());
export { LoadUserProfileSuccess };
var LoadUserProfileFail = /** @class */ (function (_super) {
    __extends(LoadUserProfileFail, _super);
    function LoadUserProfileFail(error) {
        var _this = _super.call(this) || this;
        _this.error = error;
        _this.type = LOAD_USER_PROFILE_FAIL;
        return _this;
    }
    return LoadUserProfileFail;
}(FailAction));
export { LoadUserProfileFail };
var SetDefaultLocation = /** @class */ (function () {
    function SetDefaultLocation(payload) {
        this.payload = payload;
        this.type = SET_DEFAULT_LOCATION;
    }
    return SetDefaultLocation;
}());
export { SetDefaultLocation };
var SetDefaultLocationSuccess = /** @class */ (function () {
    function SetDefaultLocationSuccess(payload) {
        this.payload = payload;
        this.type = SET_DEFAULT_LOCATION_SUCCESS;
    }
    return SetDefaultLocationSuccess;
}());
export { SetDefaultLocationSuccess };
var SetDefaultLocationFail = /** @class */ (function (_super) {
    __extends(SetDefaultLocationFail, _super);
    function SetDefaultLocationFail(error) {
        var _this = _super.call(this) || this;
        _this.error = error;
        _this.type = SET_DEFAULT_LOCATION_FAIL;
        return _this;
    }
    return SetDefaultLocationFail;
}(FailAction));
export { SetDefaultLocationFail };
