import { Component, ChangeDetectionStrategy, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { CupcakeModalContentInterface, CupcakeModalInterface } from '@ipreo/cupcake-components';
import { Store, select } from '@ngrx/store';
import * as fromRoot from '../../../store';
import * as fromStore from '../../store';
import * as fromModels from '../../models';

@Component({
  selector: 'ea-rixml-fields-modal',
  templateUrl: './rixml-fields-modal.component.html',
  styleUrls: ['./rixml-fields-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RixmlFieldsModalComponent implements CupcakeModalContentInterface, OnInit, OnDestroy {
  context: Observable<any>;
  response: Subject<any>;
  modalInstance: CupcakeModalInterface;

  selectedSubType: fromModels.ActivitySubType;
  subTypes$: Observable<fromModels.ActivitySubType[]>;
  selectedMode: fromModels.ActivityMode;
  modes$: Observable<fromModels.ActivityMode[]>;
  initiators$: Observable<fromModels.ActivityInitiator[]>;
  selectedInitiator: fromModels.ActivityInitiator;
  selectedMethod: fromModels.ActivityMethod;

  subscription: Subscription;

  constructor(private store: Store<fromRoot.State>) {
    this.subTypes$ = this.store.pipe(select(fromStore.getSubTypeOptions));
    this.modes$ = this.store.pipe(select(fromStore.getModeOptions));
    this.initiators$ = this.store.pipe(select(fromStore.getInitiatorOptions));
  }

  ngOnInit() {
    this.subscription = this.context.subscribe(d => {
      this.selectedSubType = d.type;
      this.selectedMode = d.mode;
      this.selectedInitiator = d.initiator;
      this.selectedMethod = d.method;
    });
  }

  onSubTypeChange($event: fromModels.ActivitySubType) {
    this.selectedSubType = $event;
  }

  onModeChange($event: fromModels.ActivityMode) {
    this.selectedMode = $event;
  }

  onInitiatorChange($event: fromModels.ActivityInitiator) {
    this.selectedInitiator = $event;
  }

  onCancel() {
    this.response.complete();
  }

  onSave() {
    this.response.next(<fromModels.RIXMLFieldsChangeModel>{
      subType: this.selectedSubType,
      mode: this.selectedMode,
      initiator: this.selectedInitiator
    });

    this.response.complete();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
