import { Component, Inject, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { State, getBillfoldHost, CloseDialog } from '../../store';
import { WINDOW_REF } from '../../services/window-ref.provider';
import { AuthType } from '../../authorization/contracts/auth-type.enum';

@Component({
  selector: 'ea-not-authorized-page',
  templateUrl: './not-authorized-page.component.html',
  styleUrls: ['./not-authorized-page.component.scss']
})
export class NotAuthorizedPageComponent implements OnDestroy {
  private billfoldHost: string;
  private subscription: Subscription;
  private initialUrl: AuthType;
  private authType: AuthType;

  constructor(@Inject(WINDOW_REF) private window: Window, private store: Store<State>, private router: Router) {
    const extras = this.router.getCurrentNavigation().extras;

    if (extras && extras.state) {
      this.authType = extras.state.authType;
      this.initialUrl = extras.state.initialUrl;
    }

    this.subscription = this.store.pipe(select(getBillfoldHost)).subscribe((host) => {
      this.billfoldHost = host;
    });
  }

  onLogIn() {
    if (this.authType === AuthType.SAM) {
      this.router.navigateByUrl(this.initialUrl);
      return;
    }
    this.window.open(`https://${this.billfoldHost}`, '_blank');
    this.store.dispatch(new CloseDialog());
  }

  ngOnDestroy(): void {
    if (this.subscription && !this.subscription.closed) {
      this.subscription.unsubscribe();
    }
  }
}
