var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FailAction } from '../../../store/actions/fail-action';
export var INIT = '[EVENT ACTIVITY] Init';
export var INIT_NEW = '[EVENT ACTIVITY] Init New';
export var LOAD_EVENT_ACTIVITY = '[EVENT ACTIVITY] Load';
export var LOAD_EVENT_ACTIVITY_MODEL = '[EVENT ACTIVITY MODEL] Load';
export var LOAD_EVENT_ACTIVITY_MODEL_SUCCESS = '[EVENT ACTIVITY MODEL] Load Success';
export var LOAD_EVENT_ACTIVITY_MODEL_FAIL = '[EVENT ACTIVITY MODEL] Load Fail';
export var SAVE_EVENT_ACTIVITY = '[EVENT ACTIVITY] Save';
export var SAVE_EVENT_ACTIVITY_SUCCESS = '[EVENT ACTIVITY] Save Success';
export var SAVE_EVENT_ACTIVITY_FAIL = '[EVENT ACTIVITY] Save Fail';
export var UPDATE_SUBJECT = '[EVENT ACTIVITY] Update Subject';
export var UPDATE_START_DATE = '[EVENT ACTIVITY] Update Start Date';
export var UPDATE_END_DATE = '[EVENT ACTIVITY] Update End Date';
export var UPDATE_DURATION = '[EVENT ACTIVITY] Update Duration';
export var UPDATE_INTERNAL_PARTICIPANTS = '[EVENT ACTIVITY] Update Internal Participants';
export var UPDATE_ACTIVITY_SUB_TYPE = '[EVENT ACTIVITY] Update Activity Type';
export var UPDATE_ACTIVITY_MODE = '[EVENT ACTIVITY] Update Activity Mode';
export var UPDATE_ACTIVITY_METHOD = '[EVENT ACTIVITY] Update Activity Method';
export var UPDATE_ACTIVITY_INITIATOR = '[EVENT ACTIVITY] Update Activity Initiator';
export var UPDATE_ACTIVITY_TEMPLATE = '[EVENT ACTIVITY] Update Activity Template';
export var UPDATE_LOCATION = '[EVENT ACTIVITY] Update Location';
export var UPDATE_EVENT_LOCATION = '[EVENT ACTIVITY] Update Event Location';
export var UPDATE_EXTERNAL_PARTICIPANTS = '[EVENT ACTIVITY] Update External Participants';
export var ADD_EXTERNAL_PARTICIPANTS = '[EVENT ACTIVITY] Add External Participants';
export var UPDATE_EXTERNAL_PARTICIPANT_STATUS = '[EVENT ACTIVITY] Update External Participant Status';
export var CHANGE_EXTERNAL_PARTICIPANTS_COMPANY_CONTACTS = '[EVENT ACTIVITY] Change External Participants Company Contacts';
export var DELETE_EXTERNAL_PARTICIPANT = '[EVENT ACTIVITY] Delete External Participant';
export var UPDATE_NOTES = '[EVENT ACTIVITY] Update Notes';
export var DELETE_ACTIVITY = '[EVENT ACTIVITY] Delete Activity';
export var DELETE_ACTIVITY_SUCCESS = '[EVENT ACTIVITY] Delete Success';
export var DELETE_ACTIVITY_FAIL = '[EVENT ACTIVITY] Delete Fail';
export var RESET_PARTIAL_ERRORS = '[EVENT ACTIVITY] Reset Activity Errors';
export var UPDATE_TIMEZONE = '[EVENT ACTIVITY] Update Time Zone';
export var UPDATE_ADDRESS_COMMENT = '[EVENT ACTIVITY] Update Address Comment';
export var UPDATE_ONLINE_MEETING_LINK = '[EVENT ACTIVITY] Update Online Meeting Link';
export var UPDATE_ONLINE_MEETING_PASSWORD = '[EVENT ACTIVITY] Update Online Meeting Password';
var Init = /** @class */ (function () {
    function Init() {
        this.type = INIT;
    }
    return Init;
}());
export { Init };
var InitNew = /** @class */ (function () {
    function InitNew() {
        this.type = INIT_NEW;
    }
    return InitNew;
}());
export { InitNew };
var LoadEventActivity = /** @class */ (function () {
    function LoadEventActivity(payload) {
        this.payload = payload;
        this.type = LOAD_EVENT_ACTIVITY;
    }
    return LoadEventActivity;
}());
export { LoadEventActivity };
var LoadEventActivityModel = /** @class */ (function () {
    function LoadEventActivityModel(payload) {
        this.payload = payload;
        this.type = LOAD_EVENT_ACTIVITY_MODEL;
    }
    return LoadEventActivityModel;
}());
export { LoadEventActivityModel };
var LoadEventActivityModelSuccess = /** @class */ (function () {
    function LoadEventActivityModelSuccess(payload) {
        this.payload = payload;
        this.type = LOAD_EVENT_ACTIVITY_MODEL_SUCCESS;
    }
    return LoadEventActivityModelSuccess;
}());
export { LoadEventActivityModelSuccess };
var LoadEventActivityModelFail = /** @class */ (function (_super) {
    __extends(LoadEventActivityModelFail, _super);
    function LoadEventActivityModelFail(payload, error) {
        var _this = _super.call(this) || this;
        _this.payload = payload;
        _this.error = error;
        _this.type = LOAD_EVENT_ACTIVITY_MODEL_FAIL;
        return _this;
    }
    return LoadEventActivityModelFail;
}(FailAction));
export { LoadEventActivityModelFail };
var SaveEventActivity = /** @class */ (function () {
    function SaveEventActivity() {
        this.type = SAVE_EVENT_ACTIVITY;
    }
    return SaveEventActivity;
}());
export { SaveEventActivity };
var SaveEventActivitySuccess = /** @class */ (function () {
    function SaveEventActivitySuccess(payload) {
        this.payload = payload;
        this.type = SAVE_EVENT_ACTIVITY_SUCCESS;
    }
    return SaveEventActivitySuccess;
}());
export { SaveEventActivitySuccess };
var SaveEventActivityFail = /** @class */ (function (_super) {
    __extends(SaveEventActivityFail, _super);
    function SaveEventActivityFail(payload, error) {
        var _this = _super.call(this) || this;
        _this.payload = payload;
        _this.error = error;
        _this.type = SAVE_EVENT_ACTIVITY_FAIL;
        return _this;
    }
    return SaveEventActivityFail;
}(FailAction));
export { SaveEventActivityFail };
var UpdateSubject = /** @class */ (function () {
    function UpdateSubject(payload) {
        this.payload = payload;
        this.type = UPDATE_SUBJECT;
    }
    return UpdateSubject;
}());
export { UpdateSubject };
var UpdateStartDate = /** @class */ (function () {
    function UpdateStartDate(payload) {
        this.payload = payload;
        this.type = UPDATE_START_DATE;
    }
    return UpdateStartDate;
}());
export { UpdateStartDate };
var UpdateDuration = /** @class */ (function () {
    function UpdateDuration(payload) {
        this.payload = payload;
        this.type = UPDATE_DURATION;
    }
    return UpdateDuration;
}());
export { UpdateDuration };
var UpdateEndDate = /** @class */ (function () {
    function UpdateEndDate(payload) {
        this.payload = payload;
        this.type = UPDATE_END_DATE;
    }
    return UpdateEndDate;
}());
export { UpdateEndDate };
var UpdateInternalParticipants = /** @class */ (function () {
    function UpdateInternalParticipants(payload) {
        this.payload = payload;
        this.type = UPDATE_INTERNAL_PARTICIPANTS;
    }
    return UpdateInternalParticipants;
}());
export { UpdateInternalParticipants };
var UpdateActivitySubType = /** @class */ (function () {
    function UpdateActivitySubType(payload) {
        this.payload = payload;
        this.type = UPDATE_ACTIVITY_SUB_TYPE;
    }
    return UpdateActivitySubType;
}());
export { UpdateActivitySubType };
var UpdateActivityMode = /** @class */ (function () {
    function UpdateActivityMode(payload) {
        this.payload = payload;
        this.type = UPDATE_ACTIVITY_MODE;
    }
    return UpdateActivityMode;
}());
export { UpdateActivityMode };
var UpdateActivityMethod = /** @class */ (function () {
    function UpdateActivityMethod(payload) {
        this.payload = payload;
        this.type = UPDATE_ACTIVITY_METHOD;
    }
    return UpdateActivityMethod;
}());
export { UpdateActivityMethod };
var UpdateActivityInitiator = /** @class */ (function () {
    function UpdateActivityInitiator(payload) {
        this.payload = payload;
        this.type = UPDATE_ACTIVITY_INITIATOR;
    }
    return UpdateActivityInitiator;
}());
export { UpdateActivityInitiator };
var UpdateActivityTemplate = /** @class */ (function () {
    function UpdateActivityTemplate(payload) {
        this.payload = payload;
        this.type = UPDATE_ACTIVITY_TEMPLATE;
    }
    return UpdateActivityTemplate;
}());
export { UpdateActivityTemplate };
var UpdateLocation = /** @class */ (function () {
    function UpdateLocation(payload) {
        this.payload = payload;
        this.type = UPDATE_LOCATION;
    }
    return UpdateLocation;
}());
export { UpdateLocation };
var UpdateEventLocation = /** @class */ (function () {
    function UpdateEventLocation(payload) {
        this.payload = payload;
        this.type = UPDATE_EVENT_LOCATION;
    }
    return UpdateEventLocation;
}());
export { UpdateEventLocation };
var UpdateExternalParticipants = /** @class */ (function () {
    function UpdateExternalParticipants(payload) {
        this.payload = payload;
        this.type = UPDATE_EXTERNAL_PARTICIPANTS;
    }
    return UpdateExternalParticipants;
}());
export { UpdateExternalParticipants };
var AddExternalParticipants = /** @class */ (function () {
    function AddExternalParticipants(payload) {
        this.payload = payload;
        this.type = ADD_EXTERNAL_PARTICIPANTS;
    }
    return AddExternalParticipants;
}());
export { AddExternalParticipants };
var UpdateExternalParticipantStatus = /** @class */ (function () {
    function UpdateExternalParticipantStatus(payload) {
        this.payload = payload;
        this.type = UPDATE_EXTERNAL_PARTICIPANT_STATUS;
    }
    return UpdateExternalParticipantStatus;
}());
export { UpdateExternalParticipantStatus };
var DeleteExternalParticipant = /** @class */ (function () {
    function DeleteExternalParticipant(payload) {
        this.payload = payload;
        this.type = DELETE_EXTERNAL_PARTICIPANT;
    }
    return DeleteExternalParticipant;
}());
export { DeleteExternalParticipant };
var ChangeExternalParticipantsCompanyContacts = /** @class */ (function () {
    function ChangeExternalParticipantsCompanyContacts(payload) {
        this.payload = payload;
        this.type = CHANGE_EXTERNAL_PARTICIPANTS_COMPANY_CONTACTS;
    }
    return ChangeExternalParticipantsCompanyContacts;
}());
export { ChangeExternalParticipantsCompanyContacts };
var UpdateNotes = /** @class */ (function () {
    function UpdateNotes(payload) {
        this.payload = payload;
        this.type = UPDATE_NOTES;
    }
    return UpdateNotes;
}());
export { UpdateNotes };
var DeleteActivity = /** @class */ (function () {
    function DeleteActivity() {
        this.type = DELETE_ACTIVITY;
    }
    return DeleteActivity;
}());
export { DeleteActivity };
var DeleteActivitySuccess = /** @class */ (function () {
    function DeleteActivitySuccess() {
        this.type = DELETE_ACTIVITY_SUCCESS;
    }
    return DeleteActivitySuccess;
}());
export { DeleteActivitySuccess };
var ResetActivityPartialErrors = /** @class */ (function () {
    function ResetActivityPartialErrors(payload) {
        this.payload = payload;
        this.type = RESET_PARTIAL_ERRORS;
    }
    return ResetActivityPartialErrors;
}());
export { ResetActivityPartialErrors };
var DeleteActivityFail = /** @class */ (function (_super) {
    __extends(DeleteActivityFail, _super);
    function DeleteActivityFail(payload, error) {
        var _this = _super.call(this) || this;
        _this.payload = payload;
        _this.error = error;
        _this.type = DELETE_ACTIVITY_FAIL;
        return _this;
    }
    return DeleteActivityFail;
}(FailAction));
export { DeleteActivityFail };
var UpdateTimeZone = /** @class */ (function () {
    function UpdateTimeZone(payload) {
        this.payload = payload;
        this.type = UPDATE_TIMEZONE;
    }
    return UpdateTimeZone;
}());
export { UpdateTimeZone };
var UpdateAddressComment = /** @class */ (function () {
    function UpdateAddressComment(payload) {
        this.payload = payload;
        this.type = UPDATE_ADDRESS_COMMENT;
    }
    return UpdateAddressComment;
}());
export { UpdateAddressComment };
var UpdateOnlineMeetingLink = /** @class */ (function () {
    function UpdateOnlineMeetingLink(payload) {
        this.payload = payload;
        this.type = UPDATE_ONLINE_MEETING_LINK;
    }
    return UpdateOnlineMeetingLink;
}());
export { UpdateOnlineMeetingLink };
var UpdateOnlineMeetingPassword = /** @class */ (function () {
    function UpdateOnlineMeetingPassword(payload) {
        this.payload = payload;
        this.type = UPDATE_ONLINE_MEETING_PASSWORD;
    }
    return UpdateOnlineMeetingPassword;
}());
export { UpdateOnlineMeetingPassword };
