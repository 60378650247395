import { HttpClient } from '@angular/common/http';
import { of, throwError } from 'rxjs';
import { delay, switchMap } from 'rxjs/operators';
import { environment } from '@env/environment';
import { JobProcessingStatus } from '../models/polling/job-processing-status.enum';
import { SaveType } from '../models/polling/save-type.enum';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var INIT_CREATE_DELAY_TIME = 500;
var INIT_UPDATE_DELAY_TIME = 300;
var INCREMENT_COEFFICIENT = 2;
var MAX_DELAY_TIME = 5000;
var PollingService = /** @class */ (function () {
    function PollingService(http) {
        this.http = http;
        this.baseUrl = environment.baseUrl;
    }
    PollingService.prototype.getSavingJobResult = function (jobId, saveType) {
        var initDelay = this.getInitDelayTime(saveType);
        return this.handleJobStatus(jobId, initDelay);
    };
    PollingService.prototype.handleJobStatus = function (jobId, delayTime, iterationNumber) {
        var _this = this;
        if (iterationNumber === void 0) { iterationNumber = 0; }
        return this.getSavingJobStatus(jobId, delayTime).pipe(switchMap(function (job) {
            if (_this.isJobInProgress(job.processingStatus)) {
                return of(job).pipe(switchMap(function () {
                    return _this.handleJobStatus(jobId, _this.getNextDelayTime(delayTime, ++iterationNumber), iterationNumber);
                }));
            }
            if (job.processingStatus === JobProcessingStatus.Completed) {
                return of(job.result);
            }
            if (job.processingStatus === JobProcessingStatus.Failed) {
                return throwError(new Error(job.error));
            }
            if (job.processingStatus === JobProcessingStatus.Cancelled) {
                return throwError(new Error('Activity saving was canceled'));
            }
            return throwError(new Error('Unexpected job processing status'));
        }));
    };
    PollingService.prototype.getInitDelayTime = function (saveType) {
        return saveType === SaveType.Create ? INIT_CREATE_DELAY_TIME : INIT_UPDATE_DELAY_TIME;
    };
    PollingService.prototype.getNextDelayTime = function (currentDelayTime, iterationNumber) {
        if (iterationNumber === 1) {
            return currentDelayTime;
        }
        return Math.min(MAX_DELAY_TIME, INCREMENT_COEFFICIENT * currentDelayTime);
    };
    PollingService.prototype.isJobInProgress = function (processingStatus) {
        return processingStatus === JobProcessingStatus.Queued || processingStatus === JobProcessingStatus.Executing;
    };
    PollingService.prototype.getSavingJobStatus = function (jobId, delayTime) {
        var _this = this;
        return of(null).pipe(delay(delayTime), switchMap(function (_) { return _this.http.get(_this.baseUrl + "/api/job/save-activity/" + jobId); }));
    };
    PollingService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PollingService_Factory() { return new PollingService(i0.ɵɵinject(i1.HttpClient)); }, token: PollingService, providedIn: "root" });
    return PollingService;
}());
export { PollingService };
