import { OnInit, OnDestroy, EventEmitter, ElementRef } from '@angular/core';
import { takeUntil, debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { maxLengthLimit, inputDebounceTime } from '../../models';
var SubjectComponent = /** @class */ (function () {
    function SubjectComponent() {
        this.invalid = false;
        this.subjectChange = new EventEmitter();
        this.showError = true;
        this.maxLength = maxLengthLimit;
        this.valueChange$ = new Subject();
        this.destroyed = new Subject();
    }
    Object.defineProperty(SubjectComponent.prototype, "value", {
        set: function (val) {
            var value = val || '';
            this.subjectInput.nativeElement.value = value;
            this.currentValue = value;
        },
        enumerable: true,
        configurable: true
    });
    SubjectComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.valueChange$
            .pipe(debounceTime(inputDebounceTime), distinctUntilChanged(), takeUntil(this.destroyed), filter(function (v) { return !!v || !!_this.currentValue; }))
            .subscribe(function (v) {
            _this.showError = false;
            _this.subjectChange.emit(v);
        });
    };
    SubjectComponent.prototype.focus = function () {
        this.showError = false;
        this.subjectInput.nativeElement.focus();
    };
    SubjectComponent.prototype.onBlur = function () {
        this.showError = true;
    };
    SubjectComponent.prototype.ngOnDestroy = function () {
        this.destroyed.next();
        this.destroyed.unsubscribe();
    };
    return SubjectComponent;
}());
export { SubjectComponent };
