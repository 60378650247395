import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '@env/environment';
import { InternalParticipant } from '../models';
import { map } from 'rxjs/operators';

@Injectable()
export class EventActivityDataService {
  private baseUrl = `${environment.domain}/corporate/activities/api`;

  constructor(private http: HttpClient) {}

  getInternalParticipants(): Observable<InternalParticipant[]> {
    return this.http.get<InternalParticipant[]>(`${this.baseUrl}/participants/extended-internal`)
      .pipe(this.removeIds());
  }

  private removeIds() {
    return map((participants: InternalParticipant[]) => {
      return participants.map((p) => {
        p.id = null;
        return p;
      });
    });
  }
}
