import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BillfoldAuthClient } from './auth-client.service';
import { map, switchMap, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { AuthType } from '../contracts/auth-type.enum';

@Injectable()
export class BillfoldAuthInterceptor implements HttpInterceptor {
  constructor(private authClient: BillfoldAuthClient) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.getAuthHeader(req.url).pipe(
      take(1),
      switchMap(authHeader => {
        if (authHeader) {
          const request = req.clone({ setHeaders: { Authorization: authHeader } });
          return next.handle(request);
        }
        return next.handle(req);
      })
    );
  }

  private getAuthHeader(url: string): Observable<string> {
    const tokenType = this.authClient.getAuthenticationType() === AuthType.IpreoAccount
      ? url.indexOf(environment.hubBaseUrl) > -1 ? 'Bearer' : 'IA'
      : 'Bearer';

    return this.authClient.getAuthorizationToken().pipe(map((token: string) => token && `${tokenType} ${token}`));
  }
}
