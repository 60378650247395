export class WarningPopover {
  public showMatchPopover: boolean;
  public showTzPopover: boolean;
  public showBrokenLocationPopover: boolean;
  public warningMessage: string;

  get showPopover() {
    return this.showMatchPopover || this.showTzPopover || this.showBrokenLocationPopover;
  }
}
