import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromSecurities from '../reducers/securities.reducer';
import { SourceSecurity } from '../../models';

export const getEventActivitySecuritiesState = createSelector(
  fromFeature.getEventActivityDialogState,
  (state: fromFeature.State) => state.securities
);

export const getSecurities = createSelector(getEventActivitySecuritiesState, fromSecurities.getSecurities);

export const getSecuritiesLoading = createSelector(
  getEventActivitySecuritiesState, fromSecurities.getSecuritiesLoading
);

export const getSelectedSecurities = createSelector(
  getEventActivitySecuritiesState,
  fromSecurities.getSelected
);

export const getSourceSelected = createSelector(getSelectedSecurities,
  securities => securities.map(s => ({
    key: s.id,
    name: s.symbol,
    issuerName: s.name
  } as SourceSecurity))
);
