var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef } from '@angular/core';
import { PlaceFacade } from '../../services/place.facade';
import { LocationsSourceService } from '../../services/locations-source.service';
import { BaseActivityLocationComponent } from './base-activity-location-input.component';
var ActivityLocationComponent = /** @class */ (function (_super) {
    __extends(ActivityLocationComponent, _super);
    function ActivityLocationComponent(placeFacade, cdr, sourceService) {
        var _this = _super.call(this, placeFacade, cdr, sourceService) || this;
        _this.viewName = 'activity-location-address';
        return _this;
    }
    ActivityLocationComponent.prototype.fetchResult = function (value) {
        this.sourceService.fetchGoogleLocations(value);
    };
    return ActivityLocationComponent;
}(BaseActivityLocationComponent));
export { ActivityLocationComponent };
