/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./not-authorized-page.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@ipreo/cupcake-components/dist/cjs/integration/cupcake-integration-iframe-client.component.ngfactory";
import * as i3 from "@ipreo/cupcake-components/dist/cjs/integration/cupcake-integration-iframe-client.component";
import * as i4 from "@ipreo/cupcake-components/dist/cjs/common/cupcake-globals.service";
import * as i5 from "./not-authorized-page.component";
import * as i6 from "../../services/window-ref.provider";
import * as i7 from "@ngrx/store";
import * as i8 from "@angular/router";
var styles_NotAuthorizedPageComponent = [i0.styles];
var RenderType_NotAuthorizedPageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NotAuthorizedPageComponent, data: {} });
export { RenderType_NotAuthorizedPageComponent as RenderType_NotAuthorizedPageComponent };
export function View_NotAuthorizedPageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "c-row c-m-0 c-justify-center c-align-center c-radius-md c-full-height ea-not-authorized"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "c-text-center c-bg-white"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["class", " icon-empty-noaccess"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "c-text-lg c-m-top-lg c-m-bottom-xs text-wrap"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Activity form is not available "])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "c-text-md c-m-bottom-lg text-wrap"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Please, log in to access "])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "button", [["class", "c-btn c-btn-primary c-btn-sm c-p-vertical-sm c-p-horizontal-lg c-m-bottom-xs"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onLogIn() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Log In"])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "cupcake-integration-iframe-client", [], null, null, null, i2.View_CupcakeIntegrationIframeClientComponent_0, i2.RenderType_CupcakeIntegrationIframeClientComponent)), i1.ɵdid(10, 114688, null, 0, i3.CupcakeIntegrationIframeClientComponent, [i4.CupcakeGlobalsService, i1.NgZone], null, null)], function (_ck, _v) { _ck(_v, 10, 0); }, null); }
export function View_NotAuthorizedPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ea-not-authorized-page", [], null, null, null, View_NotAuthorizedPageComponent_0, RenderType_NotAuthorizedPageComponent)), i1.ɵdid(1, 180224, null, 0, i5.NotAuthorizedPageComponent, [i6.WINDOW_REF, i7.Store, i8.Router], null, null)], null, null); }
var NotAuthorizedPageComponentNgFactory = i1.ɵccf("ea-not-authorized-page", i5.NotAuthorizedPageComponent, View_NotAuthorizedPageComponent_Host_0, {}, {}, []);
export { NotAuthorizedPageComponentNgFactory as NotAuthorizedPageComponentNgFactory };
