var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { HttpClient, HttpParams } from '@angular/common/http';
import { of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { listsCount, ListType, isEqualCompositeId } from '../models';
import { environment } from '@env/environment';
import { AccountQueryService } from './account-query.service';
var ListsService = /** @class */ (function () {
    function ListsService(http, accountQueryService) {
        this.http = http;
        this.accountQueryService = accountQueryService;
        this.baseUrl = environment.domain + "/app/bd/lists/api";
    }
    ListsService.prototype.getInstitutionLists = function (searchText) {
        var params = new HttpParams({
            fromObject: {
                type: ListType.Account,
                size: listsCount.toString(),
                institutionsOnly: 'true'
            }
        });
        if (searchText) {
            params = params.append('searchText', searchText);
        }
        return this.http.get(this.baseUrl + "/lists", { params: params });
    };
    ListsService.prototype.getContactsLists = function (searchText) {
        var params = new HttpParams({
            fromObject: {
                type: ListType.Contact,
                size: listsCount.toString()
            }
        });
        if (searchText) {
            params = params.append('searchText', searchText);
        }
        return this.http.get(this.baseUrl + "/lists", { params: params });
    };
    ListsService.prototype.getContactsFromList = function (id) {
        var _this = this;
        return this.http.get(this.baseUrl + "/lists/" + id + "/contacts").pipe(mergeMap(function (r) { return _this.mapContactsAccountInfo(r.data); }), map(function (data) { return ({ data: data }); }));
    };
    ListsService.prototype.getInstitutionsFromList = function (id) {
        return this.http.get(this.baseUrl + "/lists/" + id + "/accounts?institutionsOnly=true");
    };
    ListsService.prototype.mapContactsAccountInfo = function (contacts) {
        var unmappedCompanyContacts = contacts.filter(function (c) { return !c.ipreoCompanyId && c.companyId; });
        if (!unmappedCompanyContacts || unmappedCompanyContacts.length === 0) {
            return of(contacts);
        }
        return this.accountQueryService.getAccountsFromContactsList(unmappedCompanyContacts)
            .pipe(map(function (accounts) { return contacts.map(function (c) {
            var account = accounts.find(function (a) { return isEqualCompositeId(a.id, { prId: c.ipreoCompanyId, crmId: c.companyId }); });
            return __assign({}, c, { companyCity: account && account.city, companyCountry: account && account.country });
        }); }));
    };
    return ListsService;
}());
export { ListsService };
