var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { select, Store } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { of, pipe } from 'rxjs';
import { EventActivityService } from '../../services';
import { ChangeAction, ChangeEntity, Error, isEqualCompositeId } from '../../models';
import { CloseDialogAction, ProgressState } from '../../../models';
import * as eventActivityAction from '../actions/event-activity.action';
import * as eventActivityDataAction from '../actions/event-activity-data.action';
import * as attachmentAction from '../actions/attachment.action';
import * as dialogAction from '../actions/dialog-view.action';
import * as adfAction from '../actions/activity-defined-field.action';
import * as securitiesAction from '../actions/securities.action';
import * as fromRoot from '../../../store';
import * as fromSelectors from '../selectors';
import { ActivityAttachmentMapper } from '../../services/activity-attachment-mapper.service';
import { MapsService } from '../../services/maps.service';
var EventActivityEffects = /** @class */ (function () {
    function EventActivityEffects(actions$, store, eventService, attachmentMapper, mapsService) {
        var _this = this;
        this.actions$ = actions$;
        this.store = store;
        this.eventService = eventService;
        this.attachmentMapper = attachmentMapper;
        this.mapsService = mapsService;
        this.Init$ = this.actions$.pipe(ofType(eventActivityAction.INIT), withLatestFrom(this.store.pipe(select(fromRoot.getActivityId)), this.store.pipe(select(fromRoot.getUserProfileProgressState))), filter(function (_a) {
            var userProfileProgressState = _a[2];
            return userProfileProgressState !== ProgressState.Failed;
        }), map(function (_a) {
            var id = _a[1];
            return id ? new eventActivityAction.LoadEventActivityModel(id) : new eventActivityAction.InitNew();
        }));
        this.ResetPartialSaveExternals$ = this.resetError([
            eventActivityAction.UPDATE_EXTERNAL_PARTICIPANT_STATUS,
            eventActivityAction.DELETE_EXTERNAL_PARTICIPANT,
            dialogAction.SUBMIT_EXTERNAL_PARTICIPANTS,
            dialogAction.SUBMIT_EXTERNAL_PARTICIPANTS_LIST
        ], ChangeEntity.ExternalParticipant);
        this.ResetPartialSaveInternals$ = this.resetError([dialogAction.SUBMIT_INTERNAL_PARTICIPANTS], ChangeEntity.InternalParticipant);
        this.ResetPartialSaveLocation$ = this.resetError([eventActivityAction.UPDATE_LOCATION], ChangeEntity.Location);
        this.ResetPartialSaveAdf$ = this.resetError([
            adfAction.UPDATE_ACTIVITY_DEFINED_FIELD,
            adfAction.HIDE_ACTIVITY_DEFINED_FIELD
        ], ChangeEntity.Adf);
        this.ResetPartialSaveSecurities$ = this.resetError([securitiesAction.SUBMIT_SECURITIES], ChangeEntity.Security);
        this.ResetPartialSaveAttachments$ = this.resetError([
            attachmentAction.ADD_ACTIVITY_ATTACHMENTS,
            attachmentAction.REMOVE_ACTIVITY_ATTACHMENT
        ], ChangeEntity.Attachment);
        this.LoadEventActivityModel$ = this.actions$.pipe(ofType(eventActivityAction.LOAD_EVENT_ACTIVITY_MODEL), switchMap(function (action) {
            return _this.eventService.get(action.payload).pipe(map(function (eventActivity) { return new eventActivityAction.LoadEventActivityModelSuccess(eventActivity); }), catchError(function (error) {
                return of(new eventActivityAction.LoadEventActivityModelFail(error.status, error));
            }));
        }));
        this.LoadEventActivityModelEntities$ = this.actions$.pipe(ofType(eventActivityAction.LOAD_EVENT_ACTIVITY_MODEL_SUCCESS), map(function (action) { return action.payload; }), filter(function (model) { return !_this.isLoadTimeZoneRequired(model.activity); }), switchMap(function (model) { return [
            new eventActivityAction.LoadEventActivity(model.activity),
            new eventActivityDataAction.LoadEventData(model.event)
        ]; }));
        this.LoadDefaultLocationTimeZone$ = this.actions$.pipe(ofType(eventActivityAction.LOAD_EVENT_ACTIVITY_MODEL_SUCCESS), map(function (action) { return action.payload; }), filter(function (model) { return _this.isLoadTimeZoneRequired(model.activity); }), switchMap(function (model) {
            return _this.mapsService.getTimeZone(model.activity.location.googlePlaceId).pipe(map(function (timeZone) { return new eventActivityAction.LoadEventActivity(__assign({}, model.activity, { timeZone: timeZone })); }), catchError(function (error) { return of(new eventActivityAction.LoadEventActivity(model.activity)); }));
        }));
        this.InitNew$ = this.actions$.pipe(ofType(eventActivityAction.INIT_NEW), withLatestFrom(this.store.pipe(select(fromRoot.getEventId)), this.store.pipe(select(fromRoot.getStartDate)), this.store.pipe(select(fromRoot.getActionDataKey))), switchMap(function (_a) {
            var _ = _a[0], eventId = _a[1], startDate = _a[2], actionDataKey = _a[3];
            return _this.eventService.prototype({ eventId: eventId, startDate: startDate, actionDataKey: actionDataKey }).pipe(map(function (model) { return new eventActivityAction.LoadEventActivityModelSuccess(model); }), catchError(function (error) {
                return of(new eventActivityAction.LoadEventActivityModelFail(error.status, error));
            }));
        }));
        this.Save$ = this.actions$.pipe(ofType(eventActivityAction.SAVE_EVENT_ACTIVITY), withLatestFrom(this.store.pipe(select(fromSelectors.getEventActivityEntity)), this.store.pipe(select(fromSelectors.getChangeLog)), this.store.pipe(select(fromSelectors.getSelectedFields)), this.store.pipe(select(fromSelectors.getActivityAttachments)), this.store.pipe(select(fromSelectors.getSelectedSecurities))), switchMap(function (_a) {
            var entity = _a[1], changeLog = _a[2], selectedFields = _a[3], attachments = _a[4], selectedSecurities = _a[5];
            var adjustedChangeLog = changeLog.slice();
            var activity = __assign({}, entity, { activityDefinedFields: selectedFields.filter(function (field) { return field.values.length > 0; }), attachments: attachments.filter(function (a) { return !!a.id; }).map(function (a) { return _this.attachmentMapper.toDto(a); }), securities: selectedSecurities });
            if (entity.addressComment && !entity.addressComment.value && entity.addressComment.tagId) {
                adjustedChangeLog.push({
                    id: entity.addressComment.tagId,
                    action: ChangeAction.Delete,
                    entity: ChangeEntity.AddressComment
                });
                activity = __assign({}, activity, { addressComment: null });
            }
            var newAttachments = attachments.filter(function (a) { return !a.id; });
            var saveResult = entity.activityId
                ? _this.eventService.update(activity, adjustedChangeLog, newAttachments)
                : _this.eventService.create(activity, newAttachments);
            return saveResult.pipe(map(function (saved) { return new eventActivityAction.SaveEventActivitySuccess({
                activity: saved.activity,
                hubLoadActivity: saved.hubLoadActivity,
                savingErrors: saved.savingErrors,
                isParticipantsChanged: _this.isParticipantsCreated(activity) || _this.isParticipantsUpdated(adjustedChangeLog)
            }); }), catchError(function (error) { return of(new eventActivityAction.SaveEventActivityFail(error.status, error)); }));
        }));
        this.Delete$ = this.actions$.pipe(ofType(eventActivityAction.DELETE_ACTIVITY), withLatestFrom(this.store.pipe(select(fromSelectors.getEventActivityId))), switchMap(function (_a) {
            var action = _a[0], activityId = _a[1];
            return _this.eventService.delete(activityId).pipe(map(function (model) { return new eventActivityAction.DeleteActivitySuccess(); }), catchError(function (error) { return of(new eventActivityAction.DeleteActivityFail(error.status, error)); }));
        }));
        this.CloseDialog$ = this.actions$.pipe(ofType(eventActivityAction.DELETE_ACTIVITY_SUCCESS), map(function (action) { return new fromRoot.CloseDialog(CloseDialogAction.Delete); }));
        this.SetHasContactsForLoadedParticipants$ = this.actions$.pipe(ofType(eventActivityAction.LOAD_EVENT_ACTIVITY), map(function (action) { return action.payload; }), this.mapParticipants());
        this.SetHasContactsForSavedParticipants$ = this.actions$.pipe(ofType(eventActivityAction.SAVE_EVENT_ACTIVITY_SUCCESS), map(function (action) { return action.payload.activity; }), this.mapParticipants());
        this.UpdateScheduleIdAfterStartDate$ = this.updateEventLocation(eventActivityAction.UPDATE_START_DATE);
        this.UpdateScheduleIdAfterEndDate$ = this.updateEventLocation(eventActivityAction.UPDATE_END_DATE);
    }
    EventActivityEffects.prototype.resetError = function (actionNames, entity) {
        return this.actions$.pipe(ofType.apply(void 0, actionNames), withLatestFrom(this.store.pipe(select(fromSelectors.getEventActivityError))), filter(function (_a) {
            var _ = _a[0], error = _a[1];
            return !!error && error === Error.PartialSaveError;
        }), switchMap(function () {
            return of(new eventActivityAction.ResetActivityPartialErrors(entity));
        }));
    };
    EventActivityEffects.prototype.updateEventLocation = function (actionName) {
        return this.actions$.pipe(ofType(actionName), withLatestFrom(this.store.pipe(select(fromSelectors.getAvailableSchedules)), this.store.pipe(select(fromSelectors.getEventSchedule))), filter(function (_a) {
            var schedules = _a[1], selectedSchedule = _a[2];
            return schedules.length > 0 &&
                (!selectedSchedule || !schedules.find(function (d) { return d.eventScheduleId === selectedSchedule.eventScheduleId; }));
        }), switchMap(function (_a) {
            var schedules = _a[1], selectedSchedule = _a[2];
            var firstAvailable = (selectedSchedule && schedules.find(function (s) { return s.googlePlaceId === selectedSchedule.googlePlaceId; }))
                || schedules[0];
            return [
                new eventActivityAction.UpdateEventLocation({
                    scheduleId: firstAvailable ? firstAvailable.eventScheduleId : null,
                    timeZone: firstAvailable ? firstAvailable.timeZone : null
                })
            ];
        }));
    };
    EventActivityEffects.prototype.isParticipantsCreated = function (activity) {
        return (!!activity.externalParticipants && activity.externalParticipants.findIndex(function (p) { return !p.id; }) > -1)
            || (!!activity.internalParticipants && activity.internalParticipants.findIndex(function (p) { return !p.id; }) > -1);
    };
    EventActivityEffects.prototype.isParticipantsUpdated = function (changeLog) {
        return !!changeLog && changeLog.findIndex(function (item) {
            return (item.entity === ChangeEntity.InternalParticipant || item.entity === ChangeEntity.ExternalParticipant)
                && item.action === ChangeAction.Update;
        }) > -1;
    };
    EventActivityEffects.prototype.mapParticipants = function () {
        var _this = this;
        return pipe(map(function (entity) { return entity.externalParticipants; }), filter(function (externalParticipants) {
            return externalParticipants && externalParticipants.filter(function (d) { return !d.contact; }).length > 0;
        }), switchMap(function (externalParticipants) {
            var companyKeys = externalParticipants.filter(function (d) { return !d.contact; }).map(function (d) { return d.company.id; });
            return _this.eventService.getHasContacts(companyKeys).pipe(map(function (d) {
                return externalParticipants.map(function (participant) {
                    return participant.contact
                        ? participant
                        : __assign({}, participant, { hasContacts: !!d.find(function (a) { return isEqualCompositeId(participant.company.id, a); }) });
                });
            }));
        }), map(function (d) { return new eventActivityAction.UpdateExternalParticipants(d); }));
    };
    EventActivityEffects.prototype.isLoadTimeZoneRequired = function (activity) {
        if (!!activity.activityId || !!activity.eventId) {
            return false;
        }
        return !!activity.location && !!activity.location.googlePlaceId;
    };
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], EventActivityEffects.prototype, "Init$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], EventActivityEffects.prototype, "ResetPartialSaveExternals$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], EventActivityEffects.prototype, "ResetPartialSaveInternals$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], EventActivityEffects.prototype, "ResetPartialSaveLocation$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], EventActivityEffects.prototype, "ResetPartialSaveAdf$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], EventActivityEffects.prototype, "ResetPartialSaveSecurities$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], EventActivityEffects.prototype, "ResetPartialSaveAttachments$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], EventActivityEffects.prototype, "LoadEventActivityModel$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], EventActivityEffects.prototype, "LoadEventActivityModelEntities$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], EventActivityEffects.prototype, "LoadDefaultLocationTimeZone$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], EventActivityEffects.prototype, "InitNew$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], EventActivityEffects.prototype, "Save$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], EventActivityEffects.prototype, "Delete$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], EventActivityEffects.prototype, "CloseDialog$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], EventActivityEffects.prototype, "SetHasContactsForLoadedParticipants$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], EventActivityEffects.prototype, "SetHasContactsForSavedParticipants$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], EventActivityEffects.prototype, "UpdateScheduleIdAfterStartDate$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], EventActivityEffects.prototype, "UpdateScheduleIdAfterEndDate$", void 0);
    return EventActivityEffects;
}());
export { EventActivityEffects };
