var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as securityActions from '../actions/securities.action';
export var initialState = function () { return ({
    data: [],
    selected: [],
    loaded: false,
    loading: false
}); };
export function reducer(state, action) {
    if (state === void 0) { state = initialState(); }
    switch (action.type) {
        case securityActions.LOAD_SECURITIES: {
            return __assign({}, state, { loaded: false, loading: true });
        }
        case securityActions.LOAD_SECURITIES_SUCCESS: {
            return __assign({}, state, { data: action.payload, loaded: true, loading: false });
        }
        case securityActions.SET_SELECTED_SECURITIES:
        case securityActions.INIT_SELECTED_SECURITIES: {
            return __assign({}, state, { selected: action.payload });
        }
    }
    return state;
}
export var getSecurities = function (state) {
    return state.data.filter(function (d) { return !state.selected.find(function (s) { return s.id === d.key; }); });
};
export var getSecuritiesLoading = function (state) {
    return state.loading;
};
export var getSelected = function (state) {
    return state.selected;
};
