<ng-container *ngIf="isVisible$ | async">
  <div class="c-row ea-location c-m-bottom-sm">
    <div class="c-col-2 c-p-right-0" data-automation-id="activity-location-name">
        <label class="ea-location-label">
            {{ 'bd/event-activity/form.activity_location' | translate }}
        </label>
    </div>
    <div class="c-col-10">
      <ea-location
              *ngIf="!(savedLocationsEnabled$ | async); else extendedLocation"
                [value$]="eventActivityLocationFullAddress$"
                [eventLocationMatched$]="eventLocationMatched$"
                [timeZoneFailed$]="timeZoneFailed$"
                (locationSelected)="onLocationSelected($event)">
      </ea-location>
      <div class="c-text-warning c-m-top-xs" *ngIf="alert$ | async">
        {{ 'bd/event-activity/form.location_saving_error_message' | translate }}
      </div>
      <div class="c-m-top-xs" *ngIf="showVirtualMeetingBtn$ | async">
        <button class="c-btn-link c-btn-link-sm c-p-0" (click)="onVirtualMeeting()">{{ 'bd/event-activity/form.virtual_meeting' | translate }}</button>
      </div>
    </div>
  </div>
  <ng-template #extendedLocation>
      <div class="c-d-flex">
          <div class="c-p-relative ea-address">
              <ea-extended-location
                      *ngIf="savedLocationsEnabled$ | async"
                      [value$]="eventActivityLocationFullAddress$"
                      [eventLocationMatched$]="eventLocationMatched$"
                      [timeZoneFailed$]="timeZoneFailed$"
                      [locationType$]="locationType$"
                      [savedLocation]="savedLocationSelected$ | async"
                      [address$]="address$"
                      (locationSelected)="onLocationSelected($event)">
              </ea-extended-location>
          </div>
          <div class="c-m-left-sm ea-address-comment">
              <ea-address-comment
                      [value]="addressComment$ | async"
                      (commentChange)="onCommentChange($event)"
              ></ea-address-comment>
          </div>
      </div>
  </ng-template>  
</ng-container>