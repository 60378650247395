<div class="c-text attendee-popover-wrapper" *ngIf="context | async as participant">
      <ng-container *ngIf="participant.contact">
          <div class="c-dropdown-heading c-text-xs c-text-muted c-text-uppercase c-text-bold c-p-xs">
              <span>
                  {{ 'bd/event-activity/form.participation' | translate }}
              </span>
            </div>
           <div class="c-dropdown-divider"></div>
             <div *ngFor="let status of statuses" data-qa-id='status'>
               <a  (click)="onSetStatus(participant, status)" class="c-dropdown-item c-cursor-pointer"
                 [ngClass]="{ 'c-dropdown-item-selected': participant.status === status }">
               <i [ngClass]="status | participantStatusIcon" class="c-p-right-xs"></i>
               <span class="c-p-right-xs">{{ status | participantStatusLocalizationKey | translate }}</span>
              </a>
            </div>
          <div class="c-dropdown-divider"></div>
      </ng-container>
      <ng-container *ngIf="!participant.contact">
        <button (click)="onAddContacts(participant, participant.hasContacts)"
            [disabled]="!participant.hasContacts"
            [title]="!participant.hasContacts? ('bd/event-activity/form.no_contacts_to_display' | translate) : ''"
            class="c-dropdown-item ea-add-institution-contacts-link c-p-xs" [class.c-text-muted]="!participant.hasContacts">
            {{ 'bd/event-activity/form.add_institution_contacts' | translate }}
        </button>
      </ng-container>
      <div class="c-dropdown-divider" *ngIf="!participant.contact"></div>
      <div>
        <a (click)="onRemove(participant)" class="c-dropdown-item c-cursor-pointer c-text-danger c-p-xs" data-qa-id='removeButton'>
            {{ 'bd/event-activity/form.remove_external_participant' | translate }}
        </a>
      </div>
</div>