import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';

import { switchMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';

import * as translateActions from '../actions/translation.action';

@Injectable()
export class TranslationEffects {
  constructor(private actions$: Actions, private translation: TranslateService) {}

  @Effect()
  loadTranslation$ = this.actions$.pipe(
    ofType<translateActions.LoadTranslation>(translateActions.LOAD_TRANSLATION),
    switchMap(action =>
      this.translation.use(action.payload).pipe(
        map(() => new translateActions.LoadTranslationSuccess()),
        catchError(error => of(new translateActions.LoadTranslationFail(error)))
      )
    )
  );
}
