<ng-container *ngIf="!item.disabled else disabledTemplate">
    <span>{{ item.value }}</span>
</ng-container>

<ng-template #disabledTemplate>
  <div class="c-d-flex c-align-center ea-disabled-item c-p-relative" [title]="'bd/event-activity/form.missing_information' | translate">
    <i class="fa fa-exclamation-circle c-m-horizontal-xs c-text-danger"></i>
    <span>{{ item.value }}</span>
    <a class="c-btn-link ea-edit-btn"
        target="_blank"
        [href]="editLink | async">
        {{ 'bd/event-activity/form.edit_contact' | translate }}
    </a>
  </div>
</ng-template>
