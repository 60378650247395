import { AuthRedirectNavigator, NavigationParams } from '../../authorization/contracts/redirect-navigator';
import { Inject, Injectable } from '@angular/core';
import { WINDOW_REF } from '../window-ref.provider';

@Injectable()
export class IframeRedirectNavigator extends AuthRedirectNavigator {
  public get url() {
    return this._openedWindow && this._openedWindow.location.href;
  }

  private _openedWindow: Window;

  constructor(@Inject(WINDOW_REF) private window: Window) {
    super();
  }

  navigate(params: NavigationParams): Promise<void> {
    if (!params && !params.url) {
      return Promise.reject(new Error('No url provided'));
    }

    this._openedWindow = this.window.open(params.url);

    return Promise.resolve();
  }
}
