import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';

export const KENDO_FILE_UPLOAD_REQUEST_HEADER = 'KendoFileUploadInterceptorData';

@Injectable()
export class KendoFileUploadInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<FormData>, next: HttpHandler): Observable<HttpEvent<{}>> {
    if (req.url === 'uploadSaveUrl') {
      return of(new HttpResponse({ status: 200 }));
    }
    return next.handle(req);
  }
}
