import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocalizationModel } from '@ipreo/bd-date-time-selector';
import { Duration, durationOptions } from '../../models';

@Component({
  selector: 'ea-duration',
  templateUrl: './duration.component.html',
  styleUrls: ['./duration.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DurationComponent {
  @Input() selected: Duration;
  @Input() endDate: Date;
  @Input() endDateVisible: boolean;
  @Input() endDateValid: boolean;
  @Input() dateRangeValid: boolean;
  @Input() is12HourFormat: boolean;
  @Input() localization: LocalizationModel;

  @Output() valueChange = new EventEmitter();
  @Output() endDateChange = new EventEmitter();

  options = durationOptions;

  constructor(private translateService: TranslateService) {
  }

  public get endDateHasError() {
    return !this.endDateValid || !this.dateRangeValid;
  }

  public getEndDateErrorMsg() {
    if (!this.endDateValid) {
      return this.translateService.instant('bd/event-activity/form.end_date_invalid');
    }
    if (!this.dateRangeValid) {
      return this.translateService.instant('bd/event-activity/form.date_range_invalid');
    }
    return null;
  }


  onChange(value: string) {
    this.valueChange.emit(+value);
  }
}
