import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { ExternalParticipant, ExternalParticipantPopoverResponse, PopoverResponseAction} from '../../models';
import { ExternalParticipantStatus } from '../../models/participant/external-participant-status.enum';
import {
  CupcakePopoverContentInterface,
  CupcakePopoverTemplateInterface
} from '@ipreo/cupcake-components';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'ea-external-participant-popover-content',
  templateUrl: './external-participant-popover-content.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExternalParticipantPopoverContentComponent implements OnInit, CupcakePopoverContentInterface {
  context: Observable<ExternalParticipant>;
  response: Subject<ExternalParticipantPopoverResponse>;
  popoverInstance: CupcakePopoverTemplateInterface;

  destroyed$: Subject<boolean> = new Subject<boolean>();
  statuses: ExternalParticipantStatus[];

  ngOnInit() {
    this.statuses = Object.values(ExternalParticipantStatus)
      .filter(s => s !== ExternalParticipantStatus.Invited && s !== ExternalParticipantStatus.None);
  }

  onRemove(item: ExternalParticipant) {
    this.response.next({
      action: PopoverResponseAction.Remove,
      entity: item
    });
    this.response.complete();
  }

  onSetStatus(participant: ExternalParticipant, newStatus: ExternalParticipantStatus) {
    if (newStatus) {
      if (!participant.status || (participant.status.toString() !== ExternalParticipantStatus[newStatus])) {
        this.response.next({
          action: PopoverResponseAction.Update,
          entity: {participant, newStatus}
        });
      }
    }
    this.response.complete();
   }

  onAddContacts(participant: ExternalParticipant, hasContacts: boolean) {
    if (!hasContacts) {
      return;
     }
    this.response.next({action: PopoverResponseAction.Add, entity: participant});
    this.response.complete();
  }
}
