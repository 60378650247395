import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, takeUntil, withLatestFrom } from 'rxjs/operators';
import { ActivityLocation, ViewMode, inputDebounceTime } from '../../models';
import * as fromStore from '../../store';
import { getAddress } from '../../store';
import * as fromRoot from '../../../store';
import * as savedLocationsActions from '../../store/actions/saved-locations.action';
import { LocationMapperService, ModalService } from '../../services';
import { LocationType } from '../../models/saved-locations/location-type';
import { ActivityLocationComponent } from '../activity-location-input/activity-location-input.component';

@Component({
  selector: 'ea-save-new-location',
  templateUrl: 'activity-save-new-location.component.html',
  styleUrls: ['activity-save-new-location.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class ActivitySaveNewLocationComponent implements OnDestroy, OnInit {
  @Output() collapse = new EventEmitter<void>();

  @ViewChild('locationName', { static: false }) locationName: ElementRef;
  @ViewChild('location', { static: false }) locationComponent: ActivityLocationComponent;

  saveDisabled$: Observable<boolean>;
  defaultDisabled$: Observable<boolean>;
  warning$: Observable<boolean>;
  error$: Observable<boolean>;
  viewMode$: Observable<ViewMode>;
  selectedAddressSubject$: ReplaySubject<string> = new ReplaySubject<string>(1);
  selectedAddress$ = this.selectedAddressSubject$.asObservable();
  isDefault$: Observable<boolean>;

  name = new FormControl('');
  mode = ViewMode;
  updateAll = false;

  private destroySubject$: Subject<void> = new Subject();

  constructor(
    private store: Store<fromRoot.State>,
    private mapper: LocationMapperService,
    private modalService: ModalService) {
  }

  ngOnInit() {
    this.store.pipe(select(fromStore.getEditValue)).pipe(
      takeUntil(this.destroySubject$)).subscribe((value) => {
        this.selectedAddressSubject$.next(value && getAddress(value) || null);
        this.name.setValue(value && value.locationName || '');
        if (value === null) {
          this.hide();
        }
    });

    this.viewMode$ = this.store.pipe(select(fromStore.isSavedLocationSelected),
      map(selected => selected ? ViewMode.Edit : ViewMode.Create));

    this.saveDisabled$ = this.store.pipe(select(fromStore.getSaveDisabled));
    this.defaultDisabled$ = this.store.pipe(select(fromStore.getDefaultDisabled));
    this.warning$ = this.store.pipe(select(fromStore.getWarning));
    this.isDefault$ = this.store.pipe(select(fromStore.isDefaultChecked));
    this.error$ = this.store.pipe(select(fromStore.getError));

    this.name.valueChanges.pipe(
      debounceTime(inputDebounceTime),
      distinctUntilChanged(),
      withLatestFrom(this.store.pipe(select(fromStore.getEditValue))),
      filter(([newValue, existingValue]) => existingValue && newValue !== existingValue.locationName),
      takeUntil(this.destroySubject$)
    )
      .subscribe(([value]) => {
        this.store.dispatch(new savedLocationsActions.UpdateName(value));
      });
  }

  ngOnDestroy(): void {
    this.destroySubject$.next();
    this.destroySubject$.complete();
  }

  public open(locationType: LocationType) {
    this.updateAll = false;
    this.store.dispatch(new savedLocationsActions.EditStart());
    if (locationType === LocationType.InvalidSaved) {
      this.updateAll = true;
      this.locationComponent.suggester.setFocusOnInput();
    } else {
      this.locationName.nativeElement.focus();
    }
  }

  public hide() {
    this.updateAll = false;
    this.collapse.emit();
  }

  public save(mode: ViewMode) {
    this.store.dispatch(new savedLocationsActions.UpdateName(this.name.value));

    this.store.dispatch(
      new savedLocationsActions.SubmitSavedLocation({
        updateAll: this.updateAll
      })
    );
  }

  public onLocationsSelected($event: ActivityLocation) {
    const location = $event ? this.mapper.fromActivityLocation($event, this.name.value) : null;
    this.store.dispatch(new savedLocationsActions.UpdateAddress(location));
  }

  public onDelete() {
    this.openDeleteModal();
  }

  public delete() {
    this.store.dispatch(new savedLocationsActions.DeleteSavedLocation());
  }

  public toggleUpdateAll() {
    this.updateAll = !this.updateAll;
  }

  public toggleIsDefault(checked: boolean) {
    this.store.dispatch(new savedLocationsActions.UpdateDefault(checked));
  }

  private openDeleteModal() {
    this.modalService.openModal({
      title: 'bd/event-activity/form.delete_location_title',
      text: 'bd/event-activity/form.delete_location_warning',
      titleCancelButton: 'bd/event-activity/form.cancel',
      titleConfirmButton: 'bd/event-activity/form.delete',
      type: 'danger',
      confirmEventEmit: () => this.delete()});
  }
}
