var _a;
import { ActivitySubType } from './activity-type.enum';
import { ActivityInitiator } from './activity-initiator.enum';
import { ActivityMethod } from './activity-method.enum';
import { ActivityMode } from './activity-mode.enum';
export var RIXMLFieldsLocalization = (_a = {},
    _a[ActivitySubType.OneOnOne] = 'activity_sub_type_one_to_one',
    _a[ActivitySubType.TwoOnOne] = 'activity_sub_type_two_to_one',
    _a[ActivitySubType.ThreeOnOne] = 'activity_sub_type_three_to_one',
    _a[ActivitySubType.Group] = 'activity_sub_type_group',
    _a[ActivitySubType.None] = 'activity_sub_type_none',
    _a[ActivityInitiator.Corporate] = 'activity_initiator_corporate',
    _a[ActivityInitiator.Consumer] = 'activity_initiator_consumer',
    _a[ActivityInitiator.Provider] = 'activity_initiator_provider',
    _a[ActivityMethod.Interaction] = 'activity_method_interaction',
    _a[ActivityMethod.Electronic] = 'activity_method_electronic',
    _a[ActivityMode.Inperson] = 'activity_mode_in_person',
    _a[ActivityMode.Call] = 'activity_mode_call',
    _a[ActivityMode.VoiceMail] = 'activity_mode_voicemail',
    _a[ActivityMode.Video] = 'activity_mode_video',
    _a[ActivityMode.Email] = 'activity_mode_email',
    _a['N/A'] = 'activity_initiator_undefined',
    _a);
