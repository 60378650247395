var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { sortBy } from 'lodash';
import * as adfActions from '../actions/activity-defined-field.action';
export var initialState = function () { return ({
    hasAny: {
        value: false,
        loaded: false,
        loading: false
    },
    fields: {
        value: [],
        loaded: false,
        loading: false
    },
    selectedFields: [],
    cachedFields: []
}); };
export function reducer(state, action) {
    if (state === void 0) { state = initialState(); }
    switch (action.type) {
        case adfActions.CHECK_HAS_ANY: {
            return __assign({}, state, { hasAny: __assign({}, state.hasAny, { loading: true }) });
        }
        case adfActions.CHECK_HAS_ANY_SUCCESS: {
            return __assign({}, state, { hasAny: {
                    loading: false,
                    loaded: true,
                    value: action.payload.hasAny
                } });
        }
        case adfActions.CHECK_HAS_ANY_FAIL: {
            return __assign({}, state, { hasAny: __assign({}, state.hasAny, { loading: false }) });
        }
        case adfActions.LOAD_ACTIVITY_DEFINED_FIELDS: {
            return __assign({}, state, { fields: __assign({}, state.fields, { loading: true }) });
        }
        case adfActions.LOAD_ACTIVITY_DEFINED_FIELDS_SUCCESS: {
            return __assign({}, state, { fields: {
                    loading: false,
                    loaded: true,
                    value: action.payload.fields
                } });
        }
        case adfActions.LOAD_ACTIVITY_DEFINED_FIELDS_FAIL: {
            return __assign({}, state, { fields: __assign({}, state.fields, { loading: false }) });
        }
        case adfActions.SET_SELECTED_ACTIVITY_DEFINED_FIELDS: {
            return __assign({}, state, { selectedFields: action.payload.fields, cachedFields: [] });
        }
        case adfActions.SELECT_ACTIVITY_DEFINED_FIELD: {
            var cachedValue = state.cachedFields.find(function (d) { return d.cdfId === action.payload.fieldId; });
            var selected = void 0;
            if (cachedValue) {
                selected = cachedValue;
            }
            else {
                selected = state.fields.value.find(function (d) { return d.cdfId === action.payload.fieldId; });
            }
            return __assign({}, state, { selectedFields: selected ? state.selectedFields.concat([selected]) : state.selectedFields, cachedFields: cachedValue
                    ? state.cachedFields.filter(function (d) { return d.cdfId !== action.payload.fieldId; })
                    : state.cachedFields });
        }
        case adfActions.UPDATE_ACTIVITY_DEFINED_FIELD: {
            var index = state.selectedFields.findIndex(function (d) { return d.cdfId === action.payload.field.cdfId; });
            return __assign({}, state, { selectedFields: state.selectedFields.slice(0, index).concat([
                    action.payload.field
                ], state.selectedFields.slice(index + 1)) });
        }
        case adfActions.HIDE_ACTIVITY_DEFINED_FIELD: {
            var index = state.selectedFields.findIndex(function (d) { return d.cdfId === action.payload.fieldId; });
            var values = state.selectedFields[index].values;
            return __assign({}, state, { selectedFields: state.selectedFields.slice(0, index).concat(state.selectedFields.slice(index + 1)), cachedFields: values
                    ? state.cachedFields.concat([state.selectedFields[index]]) : state.cachedFields });
        }
        default: {
            return state;
        }
    }
}
export var getHasAnyLoaded = function (state) { return state.hasAny.loaded; };
export var getHasAnyFields = function (state) { return state.hasAny.value; };
export var getFieldsLoading = function (state) { return state.fields.loading; };
export var getFieldsLoaded = function (state) { return state.fields.loaded; };
export var getFields = function (state) { return state.fields.value || []; };
export var getSelectedFields = function (state) { return sortBy(state.selectedFields || [], 'cdfName'); };
export var getAdfSelectionViewItems = function (fields, selectedFields) {
    return fields.map(function (field) {
        return ({
            id: field.cdfId,
            name: field.cdfName,
            selected: !!selectedFields.find(function (d) { return d.cdfId === field.cdfId; })
        });
    });
};
export var getRemovedFields = function (state) {
    return state.cachedFields.concat(state.selectedFields.filter(function (d) { return d.values.length === 0; })).filter(function (field) { return !!field.tagId; });
};
