var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { of, pipe } from 'rxjs';
import { switchMap, map, catchError, filter, withLatestFrom } from 'rxjs/operators';
import { differenceBy } from 'lodash';
import { SecuritiesService } from '../../services';
import * as fromSecurities from '../selectors/securities.selector';
import * as securitiesActions from '../actions/securities.action';
import * as eventActivityAction from '../actions/event-activity.action';
import * as dialogActions from '../actions/dialog-view.action';
import { ChangeAction, ChangeEntity } from '../../models';
var SecuritiesEffect = /** @class */ (function () {
    function SecuritiesEffect(actions$, store, securitiesService) {
        var _this = this;
        this.actions$ = actions$;
        this.store = store;
        this.securitiesService = securitiesService;
        this.LoadSecurities$ = this.actions$.pipe(ofType(securitiesActions.LOAD_SECURITIES), map(function (action) { return action.payload; }), filter(function (searchText) { return !!searchText; }), switchMap(function (searchText) {
            return _this.securitiesService.getSecurities(searchText)
                .pipe(map(function (response) { return new securitiesActions.LoadSecuritiesSuccess(response); }), catchError(function (error) { return of(new securitiesActions.LoadSecuritiesFail(error)); }));
        }));
        this.LoadSelectedSecurities$ = this.actions$.pipe(ofType(eventActivityAction.LOAD_EVENT_ACTIVITY), map(function (action) { return action.payload; }), this.loadSelectedSecurities());
        this.LoadSelectedSecuritiesAfterSave$ = this.actions$.pipe(ofType(eventActivityAction.SAVE_EVENT_ACTIVITY_SUCCESS), map(function (action) { return action.payload.activity; }), this.loadSelectedSecurities());
        this.SubmitSecurities$ = this.actions$.pipe(ofType(securitiesActions.SUBMIT_SECURITIES), map(function (action) { return action.payload; }), withLatestFrom(this.store.pipe(select(fromSecurities.getSelectedSecurities))), switchMap(function (_a) {
            var newSelected = _a[0], securities = _a[1];
            var mapped = newSelected.map(function (d) {
                var found = securities.find(function (s) { return s.id === d.key; });
                var security = {
                    id: d.key,
                    name: d.issuerName,
                    symbol: d.name
                };
                return found ? __assign({}, security, { tagId: found.tagId }) : security;
            });
            var deleted = differenceBy(securities, mapped, function (d) { return d.id; })
                .filter(function (d) { return !!d.id; })
                .map(function (d) { return ({
                entity: ChangeEntity.Security,
                action: ChangeAction.Delete,
                id: d.tagId
            }); });
            return [
                new securitiesActions.SetSelectedSecurities(mapped),
                deleted && deleted.length > 0 && new dialogActions.UpdateChangeLog(deleted)
            ].filter(Boolean);
        }));
    }
    SecuritiesEffect.prototype.loadSelectedSecurities = function () {
        return pipe(map(function (activity) { return activity.securities; }), map(function (securities) { return new securitiesActions.InitSelectedSecurities(securities); }));
    };
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], SecuritiesEffect.prototype, "LoadSecurities$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], SecuritiesEffect.prototype, "LoadSelectedSecurities$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], SecuritiesEffect.prototype, "LoadSelectedSecuritiesAfterSave$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], SecuritiesEffect.prototype, "SubmitSecurities$", void 0);
    return SecuritiesEffect;
}());
export { SecuritiesEffect };
