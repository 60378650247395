var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { EventEmitter } from '@angular/core';
import { FileListSingleItemComponent } from '@progress/kendo-angular-upload';
var AttachmentListItemComponent = /** @class */ (function (_super) {
    __extends(AttachmentListItemComponent, _super);
    function AttachmentListItemComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.download = new EventEmitter();
        _this.remove = new EventEmitter();
        return _this;
    }
    AttachmentListItemComponent.prototype.downloadFile = function () {
        this.download.emit(this.file);
    };
    AttachmentListItemComponent.prototype.onRemove = function () {
        this.remove.emit(this.file.uid);
    };
    return AttachmentListItemComponent;
}(FileListSingleItemComponent));
export { AttachmentListItemComponent };
