import { Injectable, Inject, NgZone } from '@angular/core';
import { first } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { PendoAnalyticsService } from './pendo-analytics-service';

@Injectable({
  providedIn: 'root'
})
export class PendoScriptLoaderService {
  private isActivated = false;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private zone: NgZone,
    private pendoAnalytics: PendoAnalyticsService
  ) {}

  public loadPendoAnalyticsScript() {
    if (!this.isActivated) {
      this.zone.runOutsideAngular(() => {
        this.pendoAnalytics
          .getPendoAnalyticsScript()
          .pipe(first())
          .subscribe((script) => {
            const node = this.document.createElement('script');
            node.type = 'text/javascript';
            node.async = true;
            node.defer = true;
            node.innerHTML = script;
            this.document.head.appendChild(node);
            this.isActivated = true;
          });
      });
    }
  }
}
