import { createSelector } from '@ngrx/store';
import { uniqBy, orderBy } from 'lodash';

import * as fromFeature from './event-activity.selector';
import * as fromData from './event-activity-data.selector';
import { Schedule, countriesWithStates } from '../../models';

export const getEventSchedule = createSelector(
  fromFeature.getEventScheduleId,
  fromData.getEventSchedules,
  (scheduleId, schedules) => {
    return schedules.find(i => i.eventScheduleId === scheduleId);
  }
);

export const getEventLocation = createSelector(
  getEventSchedule,
  (schedule) => {
    return schedule && schedule.location;
  }
);

export const getEventLocationCity = createSelector(
  getEventLocation,
  (location) => {
    return location && location.city;
  }
);

export const getEventLocationTimezone = createSelector(
  getEventSchedule,
  (schedule) => {
    return schedule && schedule.timeZone;
  }
);

export const getEventLocationPlaceId = createSelector(
  getEventSchedule,
  (schedule) => {
    return schedule && schedule.googlePlaceId;
  }
);

export const getEventLocationMatched = createSelector(
  getEventLocation,
  fromFeature.getEventActivityLocation,
  (eventLocation, activityLocation) => {
    if (
      !eventLocation ||
      !activityLocation ||
      (!activityLocation.city && !activityLocation.country && !activityLocation.stateCode)
    ) {
      return true;
    }
    if (
      activityLocation.city !== eventLocation.city ||
      activityLocation.country !== eventLocation.countryName ||
      countriesWithStates.includes(activityLocation.country) &&
      activityLocation.stateCode && activityLocation.stateCode !== eventLocation.stateProvinceCode
    ) {
      return false;
    }

    return true;
  }
);

export const getUniqueByPlaceSchedules = createSelector(
  fromFeature.getEventActivityStartDate,
  fromData.getEventSchedules,
  (startDate, schedules) => uniqBy(
    orderBy(schedules, (s) => Math.abs(s.startDate.getDate() - (startDate ? startDate.getDate() : 0)), 'asc'),
    'googlePlaceId')
);

export const getSchedulesForDate = (startDate: Date, endDate: Date, schedules: Schedule[]) => {
  const startWithoutHours = startDate && new Date(startDate).setHours(0, 0, 0, 0);
  const endWithoutHours = endDate && new Date(endDate).setHours(0, 0, 0, 0);
  const filtered =
    startDate &&
    schedules.filter(d => isInDateRange(startWithoutHours, endWithoutHours, d.startDate));

  return filtered || [];
};

export const getAvailableSchedules = createSelector(
  fromFeature.getEventActivityStartDate,
  fromFeature.getEventActivityEndDate,
  getUniqueByPlaceSchedules,
  (startDate, endDate, schedules) => getSchedulesForDate(startDate, endDate, schedules || [])
);

function isInDateRange(start: number, end: number, scheduleDate: Date) {
  return !end ? start === +scheduleDate : +scheduleDate >= start && +scheduleDate <= end;
}
