import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { of, pipe } from 'rxjs';
import { switchMap, map, catchError, filter, withLatestFrom } from 'rxjs/operators';
import { differenceBy } from 'lodash';

import { SecuritiesService } from '../../services';

import * as fromSecurities from '../selectors/securities.selector';

import * as securitiesActions from '../actions/securities.action';
import * as eventActivityAction from '../actions/event-activity.action';
import * as dialogActions from '../actions/dialog-view.action';

import { ChangeAction, ChangeEntity, EventActivity, Security } from '../../models';
import * as fromStore from '../../store';

@Injectable()
export class SecuritiesEffect {
  constructor(
    private actions$: Actions,
    private store: Store<fromStore.State>,
    private securitiesService: SecuritiesService
  ) {}

  @Effect()
  LoadSecurities$ = this.actions$.pipe(
    ofType<securitiesActions.LoadSecurities>(securitiesActions.LOAD_SECURITIES),
    map(action => action.payload),
    filter(searchText => !!searchText),
    switchMap(searchText =>
      this.securitiesService.getSecurities(searchText)
        .pipe(
          map(response => new securitiesActions.LoadSecuritiesSuccess(response)),
          catchError(error => of(new securitiesActions.LoadSecuritiesFail(error)))
        )
    )
  );

  @Effect()
  LoadSelectedSecurities$ = this.actions$.pipe(
    ofType(eventActivityAction.LOAD_EVENT_ACTIVITY),
    map((action: eventActivityAction.LoadEventActivity) => action.payload),
    this.loadSelectedSecurities()
  );

  @Effect()
  LoadSelectedSecuritiesAfterSave$ = this.actions$.pipe(
    ofType(eventActivityAction.SAVE_EVENT_ACTIVITY_SUCCESS),
    map((action: eventActivityAction.SaveEventActivitySuccess) => action.payload.activity),
    this.loadSelectedSecurities()
  );

  @Effect()
  SubmitSecurities$ = this.actions$.pipe(
    ofType<securitiesActions.SubmitSecurities>(securitiesActions.SUBMIT_SECURITIES),
    map(action => action.payload),
    withLatestFrom(
      this.store.pipe(select(fromSecurities.getSelectedSecurities))
    ),
    switchMap(([newSelected, securities]) => {
      const mapped = newSelected.map(d => {
        const found = securities.find(s => s.id === d.key);
        const security = {
          id: d.key,
          name: d.issuerName,
          symbol: d.name
        } as Security;
        return found ? {...security, tagId: found.tagId} : security;
      });
      const deleted = differenceBy(securities, mapped, d => d.id)
      .filter(d => !!d.id)
      .map(d => ({
        entity: ChangeEntity.Security,
        action: ChangeAction.Delete,
        id: d.tagId
      }));

      return [
        new securitiesActions.SetSelectedSecurities(mapped),
        deleted && deleted.length > 0 && new dialogActions.UpdateChangeLog(deleted)
      ].filter(Boolean);
    })
  );

  private loadSelectedSecurities() {
    return pipe(
      map((activity: EventActivity) => activity.securities),
      map(securities => new securitiesActions.InitSelectedSecurities(securities))
    );
  }
}
