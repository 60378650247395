import { Action } from '@ngrx/store';
import { InternalParticipant } from '../../models/participant/internal-participant.model';
import { ListSearchResponse, Event } from '../../models';
import { FailAction } from '../../../store/actions/fail-action';

export const LOAD_INTERNAL_PARTICIPANTS = '[INTERNAL PARTICIPANTS] Load Internal Participants';
export const LOAD_INTERNAL_PARTICIPANTS_SUCCESS =
  '[INTERNAL PARTICIPANTS] Load Internal Participants Success';
export const LOAD_INTERNAL_PARTICIPANTS_FAIL =
  '[INTERNAL PARTICIPANTS] Load Internal Participants Fail';

export const LOAD_LISTS = '[LISTS] Load Lists';
export const LOAD_LISTS_SUCCESS = '[LISTS] Load Lists Success';
export const LOAD_LISTS_FAIL = '[LISTS] Load Lists Fail';

export const LOAD_EVENT_DATA = '[EVENT] Load Event Data';

export class LoadInternalParticipants implements Action {
  readonly type = LOAD_INTERNAL_PARTICIPANTS;
}

export class LoadInternalParticipantsSuccess implements Action {
  readonly type = LOAD_INTERNAL_PARTICIPANTS_SUCCESS;

  constructor(public payload: InternalParticipant[]) {}
}

export class LoadInternalParticipantsFail extends FailAction {
  readonly type = LOAD_INTERNAL_PARTICIPANTS_FAIL;

  constructor(public error: any) {
    super();
  }
}

export class LoadLists implements Action {
  readonly type = LOAD_LISTS;

  constructor(public payload?: string) {}
}

export class LoadListsSuccess implements Action {
  readonly type = LOAD_LISTS_SUCCESS;

  constructor(
    public payload: { institutionLists: ListSearchResponse; contactLists: ListSearchResponse }
  ) {}
}

export class LoadListsFail extends FailAction {
  readonly type = LOAD_LISTS_FAIL;

  constructor(public error: any) {
    super();
  }
}

export class LoadEventData implements Action {
  readonly type = LOAD_EVENT_DATA;

  constructor(
    public payload: Event
  ) {}
}

export type EventActivityDataAction =
  | LoadInternalParticipants
  | LoadInternalParticipantsSuccess
  | LoadInternalParticipantsFail
  | LoadLists
  | LoadListsSuccess
  | LoadListsFail
  | LoadEventData;
