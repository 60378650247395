var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as savedLocationActions from '../actions/saved-locations.action';
import { SavedLocationSaveResult } from '../../models';
export var initialState = function () { return ({
    data: [],
    loading: false,
    selected: null,
    editValue: null,
    isDefaultChecked: false,
    saveResult: null,
    loadFailed: null
}); };
export function reducer(state, action) {
    if (state === void 0) { state = initialState(); }
    switch (action.type) {
        case savedLocationActions.LOAD_SAVED_LOCATIONS: {
            return __assign({}, state, { loading: true, loadFailed: null });
        }
        case savedLocationActions.LOAD_SAVED_LOCATIONS_SUCCESS: {
            return __assign({}, state, { loading: false, data: action.payload, loadFailed: false });
        }
        case savedLocationActions.LOAD_SAVED_LOCATIONS_FAIL: {
            return __assign({}, state, { loading: false, data: [], loadFailed: true });
        }
        case savedLocationActions.SET_LOCATION: {
            return __assign({}, state, { selected: action.payload });
        }
        case savedLocationActions.CREATE_SAVED_LOCATION_SUCCESS: {
            return __assign({}, state, { data: state.data.concat([action.payload]), selected: action.payload });
        }
        case savedLocationActions.DELETE_SAVED_LOCATION_SUCCESS: {
            return __assign({}, state, { data: state.data.filter(function (d) { return d.locationId !== action.payload; }).slice(), selected: null });
        }
        case savedLocationActions.EDIT_FINISH: {
            return __assign({}, state, { editValue: null, isDefaultChecked: false });
        }
        case savedLocationActions.EDIT_SAVED_LOCATION_SUCCESS: {
            var foundIndex = state.data.findIndex(function (d) { return d.locationId === action.payload.locationId; });
            return __assign({}, state, { selected: action.payload, data: foundIndex >= 0 ? state.data.slice(0, foundIndex).concat([action.payload], state.data.slice(foundIndex + 1)) :
                    state.data });
        }
        case savedLocationActions.UPDATE_NAME: {
            return __assign({}, state, { saveResult: null, editValue: __assign({}, state.editValue, { locationName: action.payload }) });
        }
        case savedLocationActions.UPDATE_ADDRESS: {
            return __assign({}, state, { saveResult: state.saveResult === SavedLocationSaveResult.Warning ?
                    state.saveResult :
                    null, editValue: __assign({}, action.payload, { locationId: state.selected && state.selected.locationId, locationName: state.editValue && state.editValue.locationName }) });
        }
        case savedLocationActions.UPDATE_DEFAULT: {
            return __assign({}, state, { isDefaultChecked: action.isDefault });
        }
        case savedLocationActions.SET_RESULT: {
            return __assign({}, state, { saveResult: action.payload });
        }
    }
    return state;
}
export var getSavedLocations = function (state) {
    return state.data.map(function (d) { return (__assign({}, d, { placeId: d.locationId, description: d.locationName })); });
};
export var getSavedLocationsLoading = function (state) {
    return state.loading;
};
export var getSelectedLocation = function (state) {
    return state.selected;
};
export var getEditValue = function (state) {
    return state.editValue;
};
export var getIsDefaultChecked = function (state) {
    return state.isDefaultChecked;
};
export var getWarning = function (state) {
    return state.saveResult === SavedLocationSaveResult.Warning;
};
export var getError = function (state) {
    return state.saveResult === SavedLocationSaveResult.Error;
};
export var getLoadFailed = function (state) {
    return state.loadFailed;
};
export var getDefaultDisabled = function (state) {
    return state && state.editValue && !state.editValue.googlePlaceId && !state.isDefaultChecked;
};
