/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./activity-defined-fields-selector.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../directives/click-outside.directive";
import * as i3 from "@angular/common";
import * as i4 from "@ipreo/cupcake-components/dist/cjs/dropdowns/cupcake-dropdown-trigger.directive";
import * as i5 from "@ipreo/cupcake-components/dist/cjs/common/cupcake-dom.service";
import * as i6 from "@ngx-translate/core";
import * as i7 from "./activity-defined-fields-selector.component";
var styles_ActivityDefinedFieldsSelectorComponent = [i0.styles];
var RenderType_ActivityDefinedFieldsSelectorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ActivityDefinedFieldsSelectorComponent, data: {} });
export { RenderType_ActivityDefinedFieldsSelectorComponent as RenderType_ActivityDefinedFieldsSelectorComponent };
function View_ActivityDefinedFieldsSelectorComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "li", [["class", "c-row c-row-collapsed c-p-vertical-xs c-text-ellipsis c-bg-gray-0-hover"]], [[2, "c-bg-selected", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "c-col c-text-ellipsis"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "label", [["class", "c-checkbox c-p-left-sm"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "input", [["type", "checkbox"]], [[8, "checked", 0]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.onSelect(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "i", [["class", "c-bg-primary"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["class", "c-p-left-sm"]], [[8, "title", 0]], null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.selected; _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.context.$implicit.selected; _ck(_v, 3, 0, currVal_1); var currVal_2 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.name, ""); _ck(_v, 5, 0, currVal_2); var currVal_3 = _v.context.$implicit.name; _ck(_v, 6, 0, currVal_3); }); }
export function View_ActivityDefinedFieldsSelectorComponent_0(_l) { return i1.ɵvid(2, [i1.ɵqud(671088640, 1, { dropdown: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 12, "div", [["class", "c-dropdown"], ["data-automation-id", "adf-dropdown"], ["eaClickOutside", ""]], null, [[null, "clickOutside"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).attachOnClick() !== false);
        ad = (pd_0 && ad);
    } if (("clickOutside" === en)) {
        var pd_1 = (_co.collapseDropdown() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 16384, null, 0, i2.ClickOutsideDirective, [i1.ElementRef, i3.DOCUMENT], null, { clickOutside: "clickOutside" }), (_l()(), i1.ɵeld(3, 0, [["dropdown", 1]], null, 7, "button", [["class", "c-btn c-btn-default"]], null, [[null, "click"], [null, "focus"], [null, "blur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("focus" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4)._focusExpand() !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 4)._focusCollapse() !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (i1.ɵnov(_v, 4)._clickButton() !== false);
        ad = (pd_2 && ad);
    } if (("click" === en)) {
        var pd_3 = (_co.toggleDropdown() !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 4341760, [[1, 4]], 0, i4.CupcakeDropdownTriggerDirective, [i1.ElementRef, i5.CupcakeDomService, i3.DOCUMENT], { options: [0, "options"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 2, "span", [["class", "c-m-right-xs"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), i1.ɵpid(131072, i6.TranslatePipe, [i6.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(8, 0, null, null, 2, "i", [["aria-hidden", "true"], ["class", "fa"]], null, null, null, null, null)), i1.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(10, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 2, "ul", [["class", "c-dropdown-list c-dropdown-list-below adf-list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ActivityDefinedFieldsSelectorComponent_1)), i1.ɵdid(13, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dropdownOptions; _ck(_v, 4, 0, currVal_0); var currVal_2 = "fa"; var currVal_3 = (_co.loading ? "fa-spinner fa-pulse" : "fa-caret-down"); _ck(_v, 10, 0, currVal_2, currVal_3); var currVal_4 = _co.fields; var currVal_5 = _co.trackByFn; _ck(_v, 13, 0, currVal_4, currVal_5); }, function (_ck, _v) { var currVal_1 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform("bd/event-activity/form.add_adf")); _ck(_v, 6, 0, currVal_1); }); }
export function View_ActivityDefinedFieldsSelectorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ea-defined-fields-selector", [], null, null, null, View_ActivityDefinedFieldsSelectorComponent_0, RenderType_ActivityDefinedFieldsSelectorComponent)), i1.ɵdid(1, 49152, null, 0, i7.ActivityDefinedFieldsSelectorComponent, [], null, null)], null, null); }
var ActivityDefinedFieldsSelectorComponentNgFactory = i1.ɵccf("ea-defined-fields-selector", i7.ActivityDefinedFieldsSelectorComponent, View_ActivityDefinedFieldsSelectorComponent_Host_0, { fields: "fields", loaded: "loaded", loading: "loading" }, { loadFields: "loadFields", select: "select", hide: "hide" }, []);
export { ActivityDefinedFieldsSelectorComponentNgFactory as ActivityDefinedFieldsSelectorComponentNgFactory };
