var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FailAction } from '../../../store/actions/fail-action';
export var LOAD_SAVED_LOCATIONS = '[SAVED LOCATIONS] Load Saved Locations';
export var LOAD_SAVED_LOCATIONS_SUCCESS = '[SAVED LOCATIONS] Load Saved Locations Success';
export var LOAD_SAVED_LOCATIONS_FAIL = '[SAVED LOCATIONS] Load Saved Locations Fail';
export var SET_LOCATION = '[SAVED LOCATIONS] Set Location';
export var CREATE_SAVED_LOCATION = '[SAVED LOCATION] Create Saved Location';
export var CREATE_SAVED_LOCATION_SUCCESS = '[SAVED LOCATION] Create Saved Location Success';
export var CREATE_SAVED_LOCATION_FAIL = '[SAVED LOCATION] Create Saved Location Fail';
export var DELETE_SAVED_LOCATION = '[SAVED LOCATION] Delete Saved Location';
export var DELETE_SAVED_LOCATION_SUCCESS = '[SAVED LOCATION] Delete Saved Location Success';
export var DELETE_SAVED_LOCATION_FAIL = '[SAVED LOCATION] Delete Saved Location Fail';
export var SUBMIT_SAVED_LOCATION = '[SAVED LOCATION] Submit Saved Location';
export var EDIT_SAVED_LOCATION_SUCCESS = '[SAVED LOCATION] Edit Saved Location Success';
export var EDIT_SAVED_LOCATION_FAIL = '[SAVED LOCATION] Edit Saved Location Fail';
export var UPDATE_NAME = '[SAVED LOCATION] Update Name';
export var UPDATE_ADDRESS = '[SAVED LOCATION] Update Address';
export var UPDATE_DEFAULT = '[SAVED LOCATION] Update Default';
export var EDIT_START = '[SAVED LOCATION] Start Saved Location edit';
export var EDIT_FINISH = '[SAVED LOCATION] Finish Saved Location edit';
export var SET_RESULT = '[SAVED LOCATION] Set Result';
var LoadSavedLocations = /** @class */ (function () {
    function LoadSavedLocations(searchText) {
        if (searchText === void 0) { searchText = ''; }
        this.searchText = searchText;
        this.type = LOAD_SAVED_LOCATIONS;
    }
    return LoadSavedLocations;
}());
export { LoadSavedLocations };
var LoadSavedLocationsSuccess = /** @class */ (function () {
    function LoadSavedLocationsSuccess(payload) {
        this.payload = payload;
        this.type = LOAD_SAVED_LOCATIONS_SUCCESS;
    }
    return LoadSavedLocationsSuccess;
}());
export { LoadSavedLocationsSuccess };
var LoadSavedLocationsFail = /** @class */ (function (_super) {
    __extends(LoadSavedLocationsFail, _super);
    function LoadSavedLocationsFail(error) {
        var _this = _super.call(this) || this;
        _this.error = error;
        _this.type = LOAD_SAVED_LOCATIONS_FAIL;
        return _this;
    }
    return LoadSavedLocationsFail;
}(FailAction));
export { LoadSavedLocationsFail };
var SetLocation = /** @class */ (function () {
    function SetLocation(payload) {
        this.payload = payload;
        this.type = SET_LOCATION;
    }
    return SetLocation;
}());
export { SetLocation };
var CreateSavedLocation = /** @class */ (function () {
    function CreateSavedLocation() {
        this.type = CREATE_SAVED_LOCATION;
    }
    return CreateSavedLocation;
}());
export { CreateSavedLocation };
var CreateSavedLocationSuccess = /** @class */ (function () {
    function CreateSavedLocationSuccess(payload) {
        this.payload = payload;
        this.type = CREATE_SAVED_LOCATION_SUCCESS;
    }
    return CreateSavedLocationSuccess;
}());
export { CreateSavedLocationSuccess };
var CreateSavedLocationFail = /** @class */ (function () {
    function CreateSavedLocationFail(error) {
        this.error = error;
        this.type = CREATE_SAVED_LOCATION_FAIL;
    }
    return CreateSavedLocationFail;
}());
export { CreateSavedLocationFail };
var DeleteSavedLocation = /** @class */ (function () {
    function DeleteSavedLocation() {
        this.type = DELETE_SAVED_LOCATION;
    }
    return DeleteSavedLocation;
}());
export { DeleteSavedLocation };
var DeleteSavedLocationSuccess = /** @class */ (function () {
    function DeleteSavedLocationSuccess(payload) {
        this.payload = payload;
        this.type = DELETE_SAVED_LOCATION_SUCCESS;
    }
    return DeleteSavedLocationSuccess;
}());
export { DeleteSavedLocationSuccess };
var DeleteSavedLocationFail = /** @class */ (function () {
    function DeleteSavedLocationFail(error) {
        this.error = error;
        this.type = DELETE_SAVED_LOCATION_FAIL;
    }
    return DeleteSavedLocationFail;
}());
export { DeleteSavedLocationFail };
var SubmitSavedLocation = /** @class */ (function () {
    function SubmitSavedLocation(payload) {
        this.payload = payload;
        this.type = SUBMIT_SAVED_LOCATION;
    }
    return SubmitSavedLocation;
}());
export { SubmitSavedLocation };
var EditSavedLocationSuccess = /** @class */ (function () {
    function EditSavedLocationSuccess(payload) {
        this.payload = payload;
        this.type = EDIT_SAVED_LOCATION_SUCCESS;
    }
    return EditSavedLocationSuccess;
}());
export { EditSavedLocationSuccess };
var EditSavedLocationFail = /** @class */ (function () {
    function EditSavedLocationFail(error) {
        this.error = error;
        this.type = EDIT_SAVED_LOCATION_FAIL;
    }
    return EditSavedLocationFail;
}());
export { EditSavedLocationFail };
var UpdateName = /** @class */ (function () {
    function UpdateName(payload) {
        this.payload = payload;
        this.type = UPDATE_NAME;
    }
    return UpdateName;
}());
export { UpdateName };
var UpdateAddress = /** @class */ (function () {
    function UpdateAddress(payload) {
        this.payload = payload;
        this.type = UPDATE_ADDRESS;
    }
    return UpdateAddress;
}());
export { UpdateAddress };
var UpdateDefault = /** @class */ (function () {
    function UpdateDefault(isDefault) {
        this.isDefault = isDefault;
        this.type = UPDATE_DEFAULT;
    }
    return UpdateDefault;
}());
export { UpdateDefault };
var EditStart = /** @class */ (function () {
    function EditStart() {
        this.type = EDIT_START;
    }
    return EditStart;
}());
export { EditStart };
var EditFinish = /** @class */ (function () {
    function EditFinish() {
        this.type = EDIT_FINISH;
    }
    return EditFinish;
}());
export { EditFinish };
var SetResult = /** @class */ (function () {
    function SetResult(payload) {
        this.payload = payload;
        this.type = SET_RESULT;
    }
    return SetResult;
}());
export { SetResult };
