import { defaultCulture, supportedCultures, UserProfile, ProgressState } from '../../models';
import * as userActions from '../actions/user.action';

export interface State {
  userProfile: UserProfile;
  progressState: ProgressState;
}

export const initialState = (): State => ({
  userProfile: null,
  progressState: ProgressState.NotLoaded,
});

export function reducer(state = initialState(), action: userActions.UserActions): State {
  switch (action.type) {
    case userActions.LOAD_USER_PROFILE: {
      return {
        ...state,
        progressState: ProgressState.Loading
      };
    }

    case userActions.LOAD_USER_PROFILE_SUCCESS: {
      return {
        userProfile: action.payload,
        progressState: ProgressState.Loaded
      };
    }

    case userActions.LOAD_USER_PROFILE_FAIL: {
      return {
        ...state,
        progressState: ProgressState.Failed
      };
    }

    case userActions.SET_DEFAULT_LOCATION_SUCCESS: {
      return {
        ... state,
        userProfile: {
          ...state.userProfile,
          companyPreferences: { hubLocationId: action.payload }
        }
      };
    }
  }

  return state;
}

export const getUserProfile = (state: State) => state.userProfile;
export const getUserProfileLoading = (state: State) => state.progressState === ProgressState.Loading;
export const getUserProfileLoaded = (state: State) => state.progressState === ProgressState.Loaded;
export const getProgressState = (state: State) => state.progressState;

export const getCulture = (state: State) => {
  const culture = getSelectedCulture(state);

  if (!culture) {
    return null;
  }

  return isSupportedCulture(culture) ? culture : defaultCulture;
};

export const getLanguage = (state: State) => state.userProfile && state.userProfile.userPreferences.language;

export const getCountry = (state: State) => state.userProfile && state.userProfile.userPreferences.country;

export const getTimeZone = (state: State) => state.userProfile &&
  state.userProfile.userPreferences.dstTimeZone.timezone_nm;

export const getDefaultToNewProfiles = (state: State) => state.userProfile && state.userProfile.defaultToNewProfiles;

export const getIsSymbolEnabled = (state: State) => state.userProfile && state.userProfile.isSymbolEnabled;

export const getIsAttachmentEnabled = (state: State) => state.userProfile && state.userProfile.isAttachmentEnabled;

export const getIsSavedLocationEnabled = (state: State) => state.userProfile &&
  state.userProfile.isSavedLocationEnabled;

export const getIsHubActivityFormEnabled = (state: State) => state.userProfile &&
  state.userProfile.isHubActivityFormEnabled;

export const getDefaultLocation = (state: State) => {
  return state.userProfile && state.userProfile.companyPreferences && state.userProfile.companyPreferences.hubLocationId
        || null;
};

export const getIs12HourFormat = (state: State) => {
  const culture = getSelectedCulture(state);

  if (!culture) {
    return null;
  }

  return isSupportedCulture(culture)
    ? state.userProfile.userPreferences.shortTimePattern.indexOf('h') !== -1
    : true;
};

function getSelectedCulture(state: State): string {
  return state.userProfile && state.userProfile.userPreferences.culture;
}

function isSupportedCulture(culture: string): string {
  return supportedCultures.find(l => culture.startsWith(l));
}

