import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromDialogView from '../reducers/dialog-view.reducer';

export const getDialogViewState = createSelector(
  fromFeature.getEventActivityDialogState,
  (state: fromFeature.State) => state.dialogView
);

export const getChangeLog = createSelector(getDialogViewState, fromDialogView.getChangeLog);
export const getListsFiltered = createSelector(getDialogViewState, fromDialogView.getListsFiltered);
export const getExternalParticipantsLoading = createSelector(
  getDialogViewState,
  fromDialogView.getExternalParticipantsLoading);
export const getFormDirty = createSelector(getDialogViewState, fromDialogView.getFormDirty);

export const getFailedParticipantsInformation = createSelector(
    getDialogViewState,
    fromDialogView.getFailedParticipantsInformation
);
export const getTimeZoneLoading = createSelector(
    getDialogViewState,
    fromDialogView.getTimeZoneLoading
);
export const getTimeZoneFailed = createSelector(
    getDialogViewState,
    fromDialogView.getTimeZoneFailed
);

export const getFundsPreselected = createSelector(
  getDialogViewState,
  fromDialogView.getFundsPreselected
);

export const getVirtualMeetingVisibility = createSelector(
  getDialogViewState,
  fromDialogView.getDisplayVirtualMeeting
);

export const getActivityLocationVisibility = createSelector(
  getDialogViewState,
  fromDialogView.getDisplayActivityLocation
);
