import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, ElementRef, Input,
  OnInit, ViewChild
} from '@angular/core';
import { CupcakeDropdownTriggerDirective, CupcakeDropdownTriggerOptions } from '@ipreo/cupcake-components';
import { PlaceFacade } from '../../../services/place.facade';
import { LocationsSourceService } from '../../../services/locations-source.service';
import {
  LocationGroupEnum,
  LocationViewModel,
  SavedLocationItemModel
} from '../../../models';
// tslint:disable-next-line:max-line-length
import { ActivitySaveNewLocationComponent } from '../../activity-save-new-location/activity-save-new-location.component';
import { BaseActivityLocationComponent } from '../base-activity-location-input.component';
import { LocationMapperService } from '../../../services';
import { LocationType } from '../../../models/saved-locations/location-type';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ea-extended-location',
  templateUrl: 'extended-activity-location-input.component.html',
  styleUrls: ['../activity-location-input.component.scss', 'extended-activity-location-input.component.scss'],
  providers: [LocationsSourceService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExtendedActivityLocationComponent extends BaseActivityLocationComponent implements OnInit {
  @Input() savedLocation: boolean;
  @Input() locationType$: Observable<LocationType>;
  @Input() address$: Observable<string>;

  @ViewChild('eaSaveNewLocation', { static: false }) saveNewLocation: ActivitySaveNewLocationComponent;
  @ViewChild('dropdown', { read: CupcakeDropdownTriggerDirective, static: false })
  dropdown: CupcakeDropdownTriggerDirective;

  public savedLocationFocused: boolean;
  public savedLocationControl = false;
  public dropdownOptions: CupcakeDropdownTriggerOptions = <CupcakeDropdownTriggerOptions>{
    trigger: 'manual',
    expandAreaWidth: 440
  };

  public optionsLimit = 100;
  public LocationType = LocationType;

  constructor(
    placeFacade: PlaceFacade,
    cdr: ChangeDetectorRef,
    sourceService: LocationsSourceService,
    private mapper: LocationMapperService,
    private el: ElementRef
  ) {
    super(placeFacade, cdr, sourceService);
  }

  ngOnInit() {
    super.ngOnInit();

    this.hasGoogleResults$ = this.sourceService.hasGoogleResults();

    if (this.suggesterInput.value) {
      this.savedLocationControl = true;
      this.cdr.detectChanges();
    }

    this.locationType$.pipe(takeUntil(this.destroySubject$)).subscribe(value => {
      const invalidLocation = value === LocationType.InvalidSaved;
      this.popoverOptions.warningMessage = invalidLocation ?
        'bd/event-activity/form.selected_location_missing_information' :
        this.popoverOptions.warningMessage;
      this.popoverOptions.showBrokenLocationPopover = invalidLocation;
    });

    this.address$.pipe(takeUntil(this.destroySubject$)).subscribe(value => {
      const input = this.el.nativeElement.querySelector('.c-suggester-component input');

      if (input) {
        input.title = value;
      }
    });
  }

  public onLocationSelected(selectedItems: LocationViewModel[]) {
    const first = selectedItems && selectedItems.length > 0 && selectedItems[0];
    if (first && first.groupKey === LocationGroupEnum.SavedLocations) {
      const item = first.item as SavedLocationItemModel;
      this.locationSelected.emit(this.mapper.toActivityLocation(item));
      this.sourceService.setSavedLocation(this.mapper.toSavedLocation(item));
      this.cdr.detectChanges();
    } else {
      super.onLocationSelected(selectedItems);
      this.sourceService.setSavedLocation(null);
    }

    if (selectedItems.length === 0) {
      this.savedLocationControl = false;
      return;
    }

    this.savedLocationControl = true;
  }

  public fetchResult(value: string) {
    this.sourceService.fetchCombinedResults(value);
  }

  public onAddressClear(value: string) {
    super.onAddressClear(value);

    this.sourceService.setLoading(true);
    this.fetchSavedLocations();
  }

  public onFocus() {
    super.onFocus();

    if (this.savedLocationFocused) {
      this.collapse();
    }
    this.savedLocationControl = false;
    this.fetchSavedLocations();
  }

  public onBlur() {
    if (this.selectedAddress) {
      this.savedLocationControl = true;
    }
  }

  public onNoResult(noResults: boolean) {
    if (noResults && !this.suggesterInput.value) {
      this.suggester.destroyContainer();
    }
  }

  public toggleDropdown(locationType: LocationType): void {
    if (this.dropdown.isExpanded()) {
      this.collapse();
    } else {
      this.savedLocationFocused = true;
      this.dropdown.expand();
      this.saveNewLocation.open(locationType);
    }
  }

  public collapse() {
    this.savedLocationFocused = false;
    if (!!this.dropdown) {
      this.dropdown.collapse();
    }
  }

  private fetchSavedLocations() {
    if (!this.suggesterInput.value) {
      this.sourceService.fetchSavedLocations();
    }
  }
}
