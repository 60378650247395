var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Duration } from '../models';
var EventActivityMapper = /** @class */ (function () {
    function EventActivityMapper() {
    }
    EventActivityMapper.prototype.fromServer = function (entity) {
        var date = new Date(entity.date);
        var endDateSelected = this.getIsEndDate(entity.duration, date);
        return __assign({}, entity, { date: date, duration: !endDateSelected ? entity.duration : Duration.EndDate, endDate: endDateSelected
                ? new Date(new Date(+date).setMinutes(date.getMinutes() + entity.duration))
                : null });
    };
    EventActivityMapper.prototype.toServer = function (entity) {
        return __assign({}, entity, { duration: entity.duration === Duration.EndDate
                ? (+entity.endDate - +entity.date) / 60000
                : entity.duration });
    };
    EventActivityMapper.prototype.getIsEndDate = function (duration, startDate) {
        return !(duration in Duration) || (duration === Duration.AllDay && (startDate.getHours() !== 0 || startDate.getMinutes() !== 0));
    };
    return EventActivityMapper;
}());
export { EventActivityMapper };
