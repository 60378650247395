import { HttpInterceptor, HttpHandler, HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { Injectable } from '@angular/core';
import { from, Observable, throwError } from 'rxjs';
import { catchError, switchMap, take } from 'rxjs/operators';
import { BillfoldAuthClient } from '../../authorization/services/auth-client.service';
import { NotAuthorizedHandler } from './not-authorized.handler';
import { HttpStatusCodes } from '../../event-activity/models/http-status-codes';

@Injectable()
export class AuthorizationErrorInterceptor implements HttpInterceptor {
  constructor(
    private authClient: BillfoldAuthClient,
    private notAuthorizedHandler: NotAuthorizedHandler,
    private route: ActivatedRoute
  ) { }

  url = 'activity/api/user';
  retried = false;

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    return next.handle(req).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse && error.status === HttpStatusCodes.Unauthorized) {
          // reload user only on a form init
          if (!this.retried && req.url.includes(this.url)) {
            return this.retry();
          }

          this.logError(error);
        }

        return throwError(error);
      })
    );
  }

  private retry() {
    this.retried = true;

    return from(this.authClient.clearSession()).pipe(
      switchMap(() => from(this.authClient.silentSingIn()))
      );
  }

  private logError(error: HttpErrorResponse) {
    this.authClient.getAuthorizationToken().pipe(take(1))
      .subscribe(async (token) => {
        await this.authClient.clearSession();
        this.notAuthorizedHandler.handle({
          error,
          failedRouteSnapshot: this.route.snapshot,
          availableToken: token,
          authType: this.authClient.authType
        });
      });
  }
}
