var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@env/environment';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var MapsService = /** @class */ (function () {
    function MapsService(http) {
        this.http = http;
        this.baseUrl = environment.hubBaseUrl;
    }
    MapsService.prototype.search = function (text, addressTypes) {
        var params = new HttpParams().set('text', text);
        if (Array.isArray(addressTypes) && addressTypes.length >= 1) {
            params = params.set('addressType', addressTypes[0]);
        }
        return this.http.get(this.baseUrl + "/api/maps/search", { params: params }).pipe(map(function (response) { return response.candidates; }));
    };
    MapsService.prototype.autocomplete = function (text, addressTypes, sessionToken) {
        var params = new HttpParams()
            .set('text', text)
            .set('sessionToken', sessionToken);
        if (Array.isArray(addressTypes) && addressTypes.length >= 1) {
            params = params.set('type', addressTypes[0]);
        }
        return this.http.get(this.baseUrl + "/api/maps/autocomplete", { params: params }).pipe(map(function (response) { return response.predictions; }));
    };
    MapsService.prototype.getDetails = function (placeId) {
        return this.http.get(this.baseUrl + "/api/maps/places/" + placeId).pipe(map(function (response) { return (__assign({}, response.result, { place_id: placeId })); }));
    };
    MapsService.prototype.getTimeZone = function (placeId) {
        return this.http.get(this.baseUrl + "/api/maps/places/" + placeId + "/timezone", { responseType: 'text' });
    };
    MapsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MapsService_Factory() { return new MapsService(i0.ɵɵinject(i1.HttpClient)); }, token: MapsService, providedIn: "root" });
    return MapsService;
}());
export { MapsService };
