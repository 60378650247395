import {
  ChangeDetectionStrategy,
  ChangeDetectorRef, Component, Input
} from '@angular/core';
import { PlaceFacade } from '../../services/place.facade';
import { LocationsSourceService } from '../../services/locations-source.service';
import { BaseActivityLocationComponent } from './base-activity-location-input.component';

@Component({
  selector: 'ea-location',
  templateUrl: 'activity-location-input.component.html',
  styleUrls: ['activity-location-input.component.scss'],
  providers: [LocationsSourceService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActivityLocationComponent extends BaseActivityLocationComponent {
  @Input() viewName = 'activity-location-address';

  constructor(
    placeFacade: PlaceFacade,
    cdr: ChangeDetectorRef,
    sourceService: LocationsSourceService
  ) {
    super(placeFacade, cdr, sourceService);
  }

  public fetchResult(value: string) {
    this.sourceService.fetchGoogleLocations(value);
  }
}
