import { createSelector } from '@ngrx/store';
import { getEventActivityDialogState } from '../reducers';
import * as fromReducer from '../reducers/activity-defined-field.reducer';
var ɵ0 = function (state) { return state.activityDataField; };
export var getActivityDefinedFieldState = createSelector(getEventActivityDialogState, ɵ0);
export var getHasAnyActivityDefinedFieldsLoaded = createSelector(getActivityDefinedFieldState, fromReducer.getHasAnyLoaded);
export var getHasAnyActivityDefinedFields = createSelector(getActivityDefinedFieldState, fromReducer.getHasAnyFields);
export var getActivityDefinedFieldsLoading = createSelector(getActivityDefinedFieldState, fromReducer.getFieldsLoading);
export var getActivityDefinedFieldsLoaded = createSelector(getActivityDefinedFieldState, fromReducer.getFieldsLoaded);
export var getActivityDefinedFields = createSelector(getActivityDefinedFieldState, fromReducer.getFields);
export var getSelectedFields = createSelector(getActivityDefinedFieldState, fromReducer.getSelectedFields);
export var getAdfSelectionViewItems = createSelector(getActivityDefinedFields, getSelectedFields, fromReducer.getAdfSelectionViewItems);
export var getRemovedFields = createSelector(getActivityDefinedFieldState, fromReducer.getRemovedFields);
export { ɵ0 };
