import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { ActivityDefinedField } from '../models/activity-defined-field/activity-defined-field.model';

@Injectable()
export class ActivityDefinedFieldService {
  private baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) {}

  hasAny(): Observable<boolean> {
    return this.http.get<boolean>(`${this.baseUrl}/api/adf/has-any`);
  }

  list(): Observable<ActivityDefinedField[]> {
    return this.http.get<ActivityDefinedField[]>(`${this.baseUrl}/api/adf/list`);
  }
}
