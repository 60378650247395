import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, delay } from 'rxjs/operators';
import { CupcakePopoverOptions } from '@ipreo/cupcake-components';

import * as fromStore from '../../store';
// tslint:disable-next-line:max-line-length
import { InternalParticipantGroupPopoverComponent } from '../../components/internal-participant-pill/internal-participant-group-popover.component';
import { LinkService } from '../../services';
import { SuggesterModelMapperService } from '../../services/suggester-model-mapper.service';
import {
  InternalParticipantSuggesterModel
} from '../../models/participant/internal-participant-suggester-item';
import { CupcakeSuggesterContainerPosition } from '@ipreo/cupcake-suggester';

@Component({
  selector: 'ea-internal-participant-container',
  templateUrl: './event-activity-internal-participant.component.html',
  styleUrls: ['./event-activity-internal-participant.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventActivityInternalParticipantComponent implements OnInit {
  internalParticipants$: Observable<InternalParticipantSuggesterModel[]>;
  selectedInternalParticipants$: Observable<InternalParticipantSuggesterModel[]>;
  alert$: Observable<boolean>;

  optionsLimit = 100;
  groupPillPopoverOptions: CupcakePopoverOptions;

  ContainerPosition = CupcakeSuggesterContainerPosition;

  constructor(
    public linkService: LinkService,
    private store: Store<fromStore.State>,
    private suggesterModelMapper: SuggesterModelMapperService
  ) {}

  ngOnInit(): void {
    this.store.dispatch(new fromStore.LoadInternalParticipants());
    this.internalParticipants$ = this.store.pipe(
      select(fromStore.getInternalParticipants),
      map(this.suggesterModelMapper.mapInternalParticipants),
    );
    this.selectedInternalParticipants$ = this.getInitialSelectedValues();

    this.groupPillPopoverOptions = new CupcakePopoverOptions({
      view: InternalParticipantGroupPopoverComponent,
      position: 'top',
      maxWidth: 350
    });
    this.alert$ = this.store.pipe(select(fromStore.getSaveInternalParticipantsError));
  }

  onInternalParticipantChange(newValue: InternalParticipantSuggesterModel[]) {
    this.store.dispatch(new fromStore.SubmitInternalParticipants(newValue.map(m => m.participant)));
  }

  private getInitialSelectedValues() {
    return this.store.pipe(
      select(fromStore.getEventActivityInternalParticipants),
      map(this.suggesterModelMapper.mapSelectedInternalParticipants),
      delay(0)
    );
  }
}
