import { Injectable } from '@angular/core';
import * as moment from 'moment-timezone';

@Injectable()
export class TimeZoneService {
  private offsetFormat = 'UTC Z';
  constructor() {}

  getUtcOffset(date: Date, timeZone: string): string {
    return moment(date).tz(timeZone, true).format(this.offsetFormat);
  }
}
