var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { EventEmitter } from '@angular/core';
import { DatePickerComponent } from '@progress/kendo-angular-dateinputs';
var DateAdfComponent = /** @class */ (function () {
    function DateAdfComponent() {
        this.valueChange = new EventEmitter();
    }
    Object.defineProperty(DateAdfComponent.prototype, "field", {
        get: function () {
            return this._field;
        },
        set: function (value) {
            this._field = value;
            if (this._field && this._field.values[0]) {
                this.value = new Date(this._field.values[0].dateValue);
            }
        },
        enumerable: true,
        configurable: true
    });
    DateAdfComponent.prototype.onValueChange = function (newValue) {
        this.value = newValue;
    };
    DateAdfComponent.prototype.onBlur = function () {
        this.isInvalid = !this.value && !this.isInputControlEmpty();
        this.valueChange.emit(__assign({}, this._field, { values: this.value ? [{ dateValue: this.value.toDateString() }] : [] }));
    };
    DateAdfComponent.prototype.isInputControlEmpty = function () {
        var input = this.datePicker.input.dateInput.nativeElement;
        return input.value === this.datePicker.placeholder;
    };
    return DateAdfComponent;
}());
export { DateAdfComponent };
