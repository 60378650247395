import * as i0 from "@angular/core";
var SuggesterModelMapperService = /** @class */ (function () {
    function SuggesterModelMapperService() {
    }
    SuggesterModelMapperService.prototype.mapSelectedInternalParticipants = function (participants) {
        return participants.map(function (p) { return ({
            id: p.contact.id.crmId,
            value: p.contact.name,
            disabled: false,
            participant: p
        }); });
    };
    SuggesterModelMapperService.prototype.mapInternalParticipants = function (participants) {
        return participants.map(function (p) {
            var item = {
                id: p.contact.id.crmId,
                value: p.contact.name,
                participant: p,
                missingContactFields: []
            };
            if (!p.company || !p.company.name) {
                item.missingContactFields.push('institution');
            }
            item.disabled = !!item.missingContactFields.length;
            return item;
        });
    };
    SuggesterModelMapperService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SuggesterModelMapperService_Factory() { return new SuggesterModelMapperService(); }, token: SuggesterModelMapperService, providedIn: "root" });
    return SuggesterModelMapperService;
}());
export { SuggesterModelMapperService };
