import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { delay, switchMap } from 'rxjs/operators';
import { environment } from '@env/environment';
import { SavingJobStatusDto } from '../models/polling/job-status.dto';
import { JobProcessingStatus } from '../models/polling/job-processing-status.enum';
import { SavedActivityModelDto } from '../models/saved-activity-model.model';
import { SaveType } from '../models/polling/save-type.enum';

const INIT_CREATE_DELAY_TIME = 500;
const INIT_UPDATE_DELAY_TIME = 300;
const INCREMENT_COEFFICIENT = 2;
const MAX_DELAY_TIME = 5000;

@Injectable({ providedIn: 'root' })
export class PollingService {
  private baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) {
  }

  getSavingJobResult(jobId: string, saveType: SaveType):
      Observable<SavedActivityModelDto> {
    const initDelay = this.getInitDelayTime(saveType);

    return this.handleJobStatus(jobId, initDelay);
  }

  private handleJobStatus(jobId: string, delayTime: number, iterationNumber = 0): Observable<SavedActivityModelDto> {
      return this.getSavingJobStatus(jobId, delayTime).pipe(
          switchMap(job => {
              if (this.isJobInProgress(job.processingStatus)) {
                  return of(job).pipe(
                      switchMap(() =>
                          this.handleJobStatus(
                              jobId,
                              this.getNextDelayTime(delayTime, ++iterationNumber), iterationNumber
                          )
                      )
                  );
              }
              if (job.processingStatus === JobProcessingStatus.Completed) {
                  return of(job.result);
              }
              if (job.processingStatus === JobProcessingStatus.Failed) {
                  return throwError(new Error(job.error));
              }
              if (job.processingStatus === JobProcessingStatus.Cancelled) {
                  return throwError(new Error('Activity saving was canceled'));
              }
              return throwError(new Error('Unexpected job processing status'));
          })
      );
  }

  private getInitDelayTime(saveType: SaveType) {
      return saveType === SaveType.Create ? INIT_CREATE_DELAY_TIME : INIT_UPDATE_DELAY_TIME;
  }

  private getNextDelayTime(currentDelayTime: number, iterationNumber: number) {
      if (iterationNumber === 1) {
          return currentDelayTime;
      }

      return Math.min(MAX_DELAY_TIME, INCREMENT_COEFFICIENT * currentDelayTime);
  }

  private isJobInProgress(processingStatus: JobProcessingStatus): boolean {
    return processingStatus === JobProcessingStatus.Queued || processingStatus === JobProcessingStatus.Executing;
  }

  private getSavingJobStatus(jobId: string, delayTime: number): Observable<SavingJobStatusDto> {
    return of(null).pipe(
      delay(delayTime),
      switchMap(_ => this.http.get<SavingJobStatusDto>(`${this.baseUrl}/api/job/save-activity/${jobId}`))
    );
  }
}
