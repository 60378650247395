/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./missing-data-alert.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "./missing-data-alert.component";
import * as i4 from "@ipreo/cupcake-components/dist/cjs/modal/cupcake-modal.service";
var styles_MissingDataAlertComponent = [i0.styles];
var RenderType_MissingDataAlertComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MissingDataAlertComponent, data: {} });
export { RenderType_MissingDataAlertComponent as RenderType_MissingDataAlertComponent };
export function View_MissingDataAlertComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "c-alert c-alert-danger c-alert-top c-alert-has-icon c-m-bottom-0 ea-danger-alert"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "a", [["aria-hidden", "true"], ["class", "c-a c-alert-close"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-times"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.hideMessage() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-exclamation-circle c-alert-icon"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "])), (_l()(), i1.ɵeld(5, 0, null, null, 2, "a", [["class", "c-link"], ["data-qa-id", "review-failed"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openReviewModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(6, null, [" ", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.message; _ck(_v, 4, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform("bd/event-activity/form.view_failed")); _ck(_v, 6, 0, currVal_1); }); }
export function View_MissingDataAlertComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ea-missing-data-alert", [], null, null, null, View_MissingDataAlertComponent_0, RenderType_MissingDataAlertComponent)), i1.ɵdid(1, 573440, null, 0, i3.MissingDataAlertComponent, [i4.CupcakeModalService, i2.TranslateService], null, null)], null, null); }
var MissingDataAlertComponentNgFactory = i1.ɵccf("ea-missing-data-alert", i3.MissingDataAlertComponent, View_MissingDataAlertComponent_Host_0, { context: "context" }, { hide: "hide" }, []);
export { MissingDataAlertComponentNgFactory as MissingDataAlertComponentNgFactory };
