import * as i0 from "@angular/core";
var PagingService = /** @class */ (function () {
    function PagingService() {
    }
    PagingService.prototype.getTotalPages = function (pageSize, itemsTotal) {
        return Math.ceil(itemsTotal / pageSize);
    };
    PagingService.prototype.getPageItems = function (allItems, page, pageSize) {
        var start = (page - 1) * pageSize;
        var end = Math.min(page * pageSize, allItems.length);
        return allItems.slice(start, end);
    };
    PagingService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PagingService_Factory() { return new PagingService(); }, token: PagingService, providedIn: "root" });
    return PagingService;
}());
export { PagingService };
