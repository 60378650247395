<div class="ea-type-container">
    <div class="c-row c-row-align-center c-m-bottom-sm">
        <div class="c-col-2 c-p-right-0" data-automation-id="activity-type-name">
            <label>{{'bd/event-activity/form.activity_type' | translate}}
                <sup class="c-text-danger">*</sup>
            </label>
        </div>
        <div class="c-col-10" *ngIf="template$ | async as activityTemplate">
            <div class="c-row c-row-collapsed">
                <div class="c-col-6">
                    <select class="c-input" data-automation-id="activity-type-select" #template
                            (change)="onTemplateChange(template.value)">
                        <ng-container *ngFor="let option of (templates$ | async)">
                            <option *ngIf="!option.hidden || activityTemplate.id === option.id"
                                    [selected]="activityTemplate.id === option.id"
                                    [value]="option.id">{{ 'bd/event-activity/form.' + option.localizationKey | translate }}
                            </option>
                        </ng-container>
                        {{ templates$ | async }}
                    </select>
                </div>
            </div>
        </div>
    </div>

    <ea-rixml-fields [type]="type$ | async"
                     [mode]="mode$ | async"
                     [method]="method$ | async"
                     [initiator]="initiator$ | async"
                     [showChangeButton]="showChangeButton$ | async"
                     (change)="onFieldsChange($event)">
    </ea-rixml-fields>
</div>
