var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Actions, Effect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { switchMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import * as translateActions from '../actions/translation.action';
var TranslationEffects = /** @class */ (function () {
    function TranslationEffects(actions$, translation) {
        var _this = this;
        this.actions$ = actions$;
        this.translation = translation;
        this.loadTranslation$ = this.actions$.pipe(ofType(translateActions.LOAD_TRANSLATION), switchMap(function (action) {
            return _this.translation.use(action.payload).pipe(map(function () { return new translateActions.LoadTranslationSuccess(); }), catchError(function (error) { return of(new translateActions.LoadTranslationFail(error)); }));
        }));
    }
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], TranslationEffects.prototype, "loadTranslation$", void 0);
    return TranslationEffects;
}());
export { TranslationEffects };
