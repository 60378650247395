﻿<div class="c-full-width c-p-relative ea-activity-location-suggester" data-automation-id="activity-location-address"
      [class.combined-results]="hasGoogleResults$ | async">
    <cupcake-suggester [ngClass]="{ 'ea-suggester-warning': (timeZoneFailed$ | async), 'hide-clear-icon' : savedLocationControl }"
                       [formControl]="suggesterInput"
                       [suggesterDataSource]="locations$"
                       [suggesterValueField]="'item.description'"
                       [suggesterIdField]="'item.placeId'"
                       [suggesterGroupField]="'groupKey'"
                       [suggesterGroupHeaderTemplate]="groupTemplate"
                       [suggesterMultiselectMode]="false"
                       [suggesterFilterMode]="false"
                       [suggesterMinLength]="0"
                       [suggesterOptionsLimit]="optionsLimit"
                       [containerPosition]="suggesterPositions.Auto"
                       [suggesterFooterTemplate]="googleFooter"
                       [suggesterItemTemplate]="itemTemplate"
                       [suggesterPlaceholder]="'bd/event-activity/form.search_location' | translate"
                       [suggesterNoResultsText]="'bd/event-activity/form.no_results_found' | translate"
                       [suggesterControlStream]="suggesterControlStream"
                       [suggesterSelectedItems]="selectedValue"
                       (suggesterOnSelectEvent)="onLocationSelected($event)"
                       (suggesterNoResultsEvent)="onNoResult($event)"
                       (change)="mapToSelected()"
                       (mousedown)="onFocus()"
                       (blur)="onBlur()"
                       #suggester>
    </cupcake-suggester>

    <div class="ea-location-dropdown" *ngIf="(locationType$ | async) as locationType">
        <div class="c-dropdown"
            eaClickOutside
            [excludeRoot]="'.c-modal'"
            (clickOutside)="collapse()"
            >
            <button *ngIf="savedLocationControl && locationType !== LocationType.InvalidOneOff"
                    #dropdown (click)="toggleDropdown(locationType)"
                    [ngClass]="{ 'c-text-primary focused': savedLocationFocused, 'c-text-primary': savedLocation }"
                    [cupcakeDropdownTrigger]="dropdownOptions"
                    class="c-btn-dropdown c-text-muted ea-location-btn"
                    data-qa-id="save-location-control">
                <i class="fa fa-star" aria-hidden="true" *ngIf="locationType !== LocationType.InvalidSaved; else invalidSavedLocation"></i>

                <ng-template #invalidSavedLocation>
                    <i class="fa fa-pencil" aria-hidden="true"></i>
                </ng-template>
            </button>
            <div class="c-dropdown-list c-dropdown-list-right c-p-md ea-save-location" data-qa-id="save-location-dropdown">
                <ea-save-new-location (collapse)="collapse()"
                                      #eaSaveNewLocation>
                </ea-save-new-location>
            </div>
        </div>
    </div>
    <ng-template #googleFooter>
        <div class="c-pull-right c-m-xs" *ngIf="hasGoogleResults$ | async">
            <div class="powered-by-google-logo"></div>
        </div>
    </ng-template>

    <ng-template #groupTemplate let-model="item">
        {{ 'bd/event-activity/form.' + model.item.id | translate }}
        <i class="fa fa-star c-p-left-xs ea-saved-locations-title" aria-hidden="true" *ngIf="model.item.id === locationGroup.SavedLocations"></i>
    </ng-template>

    <ng-template #itemTemplate let-model="item">
        <ng-container *ngIf="!model.item.model.error; else errorTemplate">
            {{ model.item.model.item.description }}

            <i *ngIf="model.item.model.item.locationId && !model.item.model.item.googlePlaceId"
               [title]="'bd/event-activity/form.missing_information' | translate"
               class="fa fa-exclamation-circle c-text-warning c-pull-right"></i>
        </ng-container>

        <ng-template #errorTemplate>
            <i class="fa fa-exclamation-circle c-text-danger c-m-right-xs" aria-hidden="true"></i>
            {{ 'bd/event-activity/form.' + model.item.model.item.description | translate }}
        </ng-template>
    </ng-template>
</div>
<div *ngIf="popoverOptions.showPopover" class="c-popover c-popover-bottom c-popover-warning ea-location-popover">
    <div class="c-popover-arrow"></div>
    <div class="c-popover-content">
        {{ popoverOptions.warningMessage | translate }}
    </div>
</div>

