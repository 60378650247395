import { switchMap, map } from 'rxjs/operators';
import { GoogleHostSelectorService, GoogleHostLocation } from './google-host-selector.service';
import { PlaceService } from './place.service';
import { MapsService } from './maps.service';
import * as i0 from "@angular/core";
import * as i1 from "./google-host-selector.service";
import * as i2 from "./place.service";
import * as i3 from "./maps.service";
var PlaceFacade = /** @class */ (function () {
    function PlaceFacade(googleHostSelector, placeService, mapsService) {
        this.googleHostSelector = googleHostSelector;
        this.placeService = placeService;
        this.mapsService = mapsService;
    }
    PlaceFacade.prototype.getPlacePredictions = function (autocompletionRequest) {
        var _this = this;
        return this.googleHostSelector.selectHost().pipe(switchMap(function (hostLocation) {
            switch (hostLocation) {
                case GoogleHostLocation.China:
                    return _this.getHubPlacePredictions(autocompletionRequest);
                case GoogleHostLocation.Default:
                default:
                    return _this.placeService.getPlacePredictions(autocompletionRequest);
            }
        }));
    };
    PlaceFacade.prototype.createAutocompleteSessionToken = function () {
        return this.placeService.createAutocompleteSessionToken();
    };
    PlaceFacade.prototype.getDetails = function (req) {
        var _this = this;
        return this.googleHostSelector.selectHost().pipe(switchMap(function (hostLocation) {
            switch (hostLocation) {
                case GoogleHostLocation.China:
                    return _this.mapsService.getDetails(req.placeId);
                case GoogleHostLocation.Default:
                default:
                    return _this.placeService.getDetails(req);
            }
        }), map(function (placeDetails) { return _this.getFormattedAddress(placeDetails); }));
    };
    PlaceFacade.prototype.getFormattedAddress = function (place) {
        var components = place.address_components;
        var locationObj = {
            address: place.formatted_address,
            city: this.getLocationComponent(components, 'locality')
                || this.getLocationComponent(components, 'postal_town')
                || this.getLocationComponent(components, 'sublocality_level_1')
                || this.getLocationComponent(components, 'administrative_area_level_3'),
            country: this.getLocationComponent(components, 'country'),
            stateCode: this.getLocationComponent(components, 'administrative_area_level_1', true),
            googlePlaceId: place.place_id,
            venueName: place.name
        };
        return locationObj;
    };
    PlaceFacade.prototype.getDescription = function (venueName, address) {
        var venuePart = address.startsWith(venueName) ? '' : venueName;
        return [venuePart, address].filter(Boolean).join(', ');
    };
    PlaceFacade.prototype.getLocationComponent = function (place, prop, isShort) {
        if (isShort === void 0) { isShort = false; }
        var x = place.find(function (a) { return a.types.indexOf(prop) > -1; }) || {};
        return isShort ? x.short_name : x.long_name || x.short_name;
    };
    PlaceFacade.prototype.getHubPlacePredictions = function (req) {
        var _this = this;
        var input = req.input, types = req.types, sessionToken = req.sessionToken;
        return this.mapsService
            .autocomplete(input, types, sessionToken)
            .pipe(map(function (dtos) { return dtos.map(function (dto) { return _this.mapPlacePredictionToPlaceResult(dto); }); }));
    };
    PlaceFacade.prototype.mapPlacePredictionToPlaceResult = function (prediction) {
        return {
            placeId: prediction.place_id,
            name: prediction.structured_formatting.main_text,
            description: prediction.description,
            reference: prediction.reference
        };
    };
    PlaceFacade.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PlaceFacade_Factory() { return new PlaceFacade(i0.ɵɵinject(i1.GoogleHostSelectorService), i0.ɵɵinject(i2.PlaceService), i0.ɵɵinject(i3.MapsService)); }, token: PlaceFacade, providedIn: "root" });
    return PlaceFacade;
}());
export { PlaceFacade };
