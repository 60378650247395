import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var PendoAnalyticsService = /** @class */ (function () {
    function PendoAnalyticsService(http) {
        this.http = http;
        this.baseUrl = environment.domain + "/pendo";
    }
    PendoAnalyticsService.prototype.getPendoAnalyticsScript = function () {
        return this.http
            .get(this.baseUrl + "/installer.js", {
            responseType: 'text'
        });
    };
    PendoAnalyticsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PendoAnalyticsService_Factory() { return new PendoAnalyticsService(i0.ɵɵinject(i1.HttpClient)); }, token: PendoAnalyticsService, providedIn: "root" });
    return PendoAnalyticsService;
}());
export { PendoAnalyticsService };
