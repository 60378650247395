<div class="ea-internal-container c-row">
    <div class="c-col-2 c-p-right-0" data-automation-id="internal-name">
        <label class="ea-internal-label">
            {{ 'bd/event-activity/form.internal' | translate }}
        </label>
    </div>
    <div class="c-col-10">
        <div class="ea-internal-participant-selector" data-automation-id="internal-participant">
            <cupcake-suggester  [suggesterMinLength]="0"
                                [suggesterIdField]="'id'"
                                [suggesterDisabledField]="'disabled'"
                                [suggesterValueField]="'value'"
                                [suggesterMultiselectMode]="true"
                                [suggesterClearInputOnBlur]="true"
                                [suggesterFilterMode]="true"
                                [suggesterSelectAllEnabled]="false"
                                [suggesterClearAllEnabled]="false"
                                [suggesterSelectFilteredEnabled]="false"
                                [suggesterDataSource]="internalParticipants$ | async"
                                [suggesterSelectedItems]="selectedInternalParticipants$ | async"
                                [suggesterNoResultsText]="'bd/event-activity/form.no_result' | translate"
                                [suggesterPlaceholder]="'bd/event-activity/form.internal_placeholder' | translate"
                                [suggesterOptionsLimit]="optionsLimit"
                                [suggesterGroupField]="'participant.groupText'"
                                [suggesterItemTemplate]="resultsTemplate"
                                [suggesterGroupSelectedThreshold]="10"
                                [suggesterGroupPillTemplate]="groupPillTemplate"
                                [suggesterGroupPillPopoverOptions]="groupPillPopoverOptions"
                                [suggesterPillTemplate]="pillTemplate"
                                [containerPosition]="ContainerPosition.Auto"
                                class="ea-internal-participant-selector-suggester"
                                (suggesterOnSelectEvent)="onInternalParticipantChange($event)">
            </cupcake-suggester>

            <ng-template #resultsTemplate let-model="item">
                <ea-internal-participant-suggester-item [item]="model.item.model"></ea-internal-participant-suggester-item>
            </ng-template>

            <ng-template #pillTemplate let-model="item">
                <a class="c-link ea-internal-participant-pill"
                    target="_blank"
                    [href]="linkService.buildProfileLink(model.model.participant.contact) | async">
                    {{ model.model.participant.contact.name }}
                </a>
            </ng-template>

            <ng-template #groupPillTemplate let-model="items">
                <span data-qa-id="group-pill">{{model.length}} {{ 'bd/event-activity/form.internal_participants_group_pill_text' | translate }}</span>
            </ng-template>
        </div>
        <div class='c-text-warning c-m-top-xs' *ngIf="alert$ | async">
            {{ 'bd/event-activity/form.internals_saving_error_message' | translate }}
        </div>
    </div>
</div>
