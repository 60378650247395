import { NgZone } from '@angular/core';
import { first } from 'rxjs/operators';
import { PendoAnalyticsService } from './pendo-analytics-service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./pendo-analytics-service";
var PendoScriptLoaderService = /** @class */ (function () {
    function PendoScriptLoaderService(document, zone, pendoAnalytics) {
        this.document = document;
        this.zone = zone;
        this.pendoAnalytics = pendoAnalytics;
        this.isActivated = false;
    }
    PendoScriptLoaderService.prototype.loadPendoAnalyticsScript = function () {
        var _this = this;
        if (!this.isActivated) {
            this.zone.runOutsideAngular(function () {
                _this.pendoAnalytics
                    .getPendoAnalyticsScript()
                    .pipe(first())
                    .subscribe(function (script) {
                    var node = _this.document.createElement('script');
                    node.type = 'text/javascript';
                    node.async = true;
                    node.defer = true;
                    node.innerHTML = script;
                    _this.document.head.appendChild(node);
                    _this.isActivated = true;
                });
            });
        }
    };
    PendoScriptLoaderService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PendoScriptLoaderService_Factory() { return new PendoScriptLoaderService(i0.ɵɵinject(i1.DOCUMENT), i0.ɵɵinject(i0.NgZone), i0.ɵɵinject(i2.PendoAnalyticsService)); }, token: PendoScriptLoaderService, providedIn: "root" });
    return PendoScriptLoaderService;
}());
export { PendoScriptLoaderService };
