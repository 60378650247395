<div class="c-dropdown" data-automation-id="adf-dropdown" eaClickOutside (clickOutside)="collapseDropdown()">
  <button class="c-btn c-btn-default"
          #dropdown
          (click)="toggleDropdown()"
          [cupcakeDropdownTrigger]="dropdownOptions">
      <span class="c-m-right-xs">{{'bd/event-activity/form.add_adf' | translate}}</span>
      <i aria-hidden="true" class="fa" [ngClass]="loading ? 'fa-spinner fa-pulse' : 'fa-caret-down'"></i>
  </button>
  <ul class="c-dropdown-list c-dropdown-list-below adf-list">
    <li *ngFor="let fieldViewItem of fields; trackBy:trackByFn"
        class="c-row c-row-collapsed c-p-vertical-xs c-text-ellipsis c-bg-gray-0-hover"
        [class.c-bg-selected]="fieldViewItem.selected">
        <div class="c-col c-text-ellipsis">
            <label class="c-checkbox c-p-left-sm">
                <input type="checkbox"
                       [checked]="fieldViewItem.selected"
                       (change)="onSelect(fieldViewItem)">
                <i class="c-bg-primary"></i>
                <span class="c-p-left-sm" title="{{ fieldViewItem.name }}">{{ fieldViewItem.name }}</span>
            </label>
        </div>
    </li>
  </ul>
</div>
