import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as fromStore from '../../store';
import * as fromRoot from '../../../store';
import * as fromModel from '../../models';
import { ActivityTemplate } from '../../models/activity-type/activity-template.model';

@Component({
  selector: 'ea-activity-type-container',
  templateUrl: './event-activity-type.component.html',
  styleUrls: ['./event-activity-type.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventActivityTypeComponent implements OnInit {
  type$: Observable<fromModel.ActivitySubType>;
  mode$: Observable<fromModel.ActivityMode>;
  method$: Observable<fromModel.ActivityMethod>;
  initiator$: Observable<fromModel.ActivityInitiator>;
  template$: Observable<ActivityTemplate>;
  showChangeButton$: Observable<boolean>;
  templates$: Observable<ActivityTemplate[]>;

  constructor(private store: Store<fromRoot.State>) {}

  ngOnInit(): void {
    this.type$ = this.store.pipe(select(fromStore.getEventActivitySubType));
    this.mode$ = this.store.pipe(select(fromStore.getEventActivityMode));
    this.method$ = this.store.pipe(select(fromStore.getEventActivityMethod));
    this.initiator$ = this.store.pipe(select(fromStore.getEventActivityInitiator));
    this.template$ = this.store.pipe(select(fromStore.getEventActivityTemplate));
    this.showChangeButton$ = this.store.pipe(select(fromStore.getShowChangeButton));
    this.templates$ = this.store.pipe(select(fromStore.getActivityTemplates));
  }

  onTemplateChange(selected: string) {
    const template = fromModel.activityTypeOptions.find(d => d.id === +selected);
    if (template) {
      this.store.dispatch(new fromStore.UpdateActivityTemplate(template));
    }
  }

  onFieldsChange(d: fromModel.RIXMLFieldsChangeModel) {
    this.store.dispatch(new fromStore.UpdateActivityInitiator(d.initiator));

    if (d.mode) {
      this.store.dispatch(new fromStore.UpdateActivityMode(d.mode));
    }
    if (d.subType) {
      this.store.dispatch(new fromStore.UpdateActivitySubType(d.subType));
    }
  }
}
