var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Actions, Effect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError } from 'rxjs/operators';
import { of, pipe } from 'rxjs';
import { ActivityDefinedFieldService } from '../../services';
import * as eventActivityAction from '../actions/event-activity.action';
import * as adfAction from '../actions/activity-defined-field.action';
var ActivityDataFieldEffects = /** @class */ (function () {
    function ActivityDataFieldEffects(actions$, activityDefinedFieldService) {
        var _this = this;
        this.actions$ = actions$;
        this.activityDefinedFieldService = activityDefinedFieldService;
        this.Init$ = this.actions$.pipe(ofType(eventActivityAction.INIT), map(function () { return new adfAction.CheckHasAny(); }));
        this.GetHasAny$ = this.actions$.pipe(ofType(adfAction.CHECK_HAS_ANY), switchMap(function () {
            return _this.activityDefinedFieldService.hasAny().pipe(map(function (hasAny) { return new adfAction.CheckHasAnySuccess({ hasAny: hasAny }); }), catchError(function (error) { return of(new adfAction.CheckHasAnyFail(error)); }));
        }));
        this.LoadAdf$ = this.actions$.pipe(ofType(adfAction.LOAD_ACTIVITY_DEFINED_FIELDS), switchMap(function () {
            return _this.activityDefinedFieldService.list().pipe(map(function (fields) { return new adfAction.LoadActivityDefinedFieldsSuccess({ fields: fields }); }), catchError(function (error) { return of(new adfAction.LoadActivityDefinedFieldsFail(error)); }));
        }));
        this.LoadSelectedAdfs$ = this.actions$.pipe(ofType(eventActivityAction.LOAD_EVENT_ACTIVITY), map(function (action) { return action.payload; }), this.loadSelectedAdfs());
        this.LoadSelectedAdfsAfterSave$ = this.actions$.pipe(ofType(eventActivityAction.SAVE_EVENT_ACTIVITY_SUCCESS), map(function (action) { return action.payload.activity; }), this.loadSelectedAdfs());
    }
    ActivityDataFieldEffects.prototype.loadSelectedAdfs = function () {
        return pipe(map(function (activity) { return activity.activityDefinedFields; }), map(function (fields) { return new adfAction.SetSelectedActivityDefinedFields({ fields: fields }); }));
    };
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], ActivityDataFieldEffects.prototype, "Init$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], ActivityDataFieldEffects.prototype, "GetHasAny$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], ActivityDataFieldEffects.prototype, "LoadAdf$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], ActivityDataFieldEffects.prototype, "LoadSelectedAdfs$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], ActivityDataFieldEffects.prototype, "LoadSelectedAdfsAfterSave$", void 0);
    return ActivityDataFieldEffects;
}());
export { ActivityDataFieldEffects };
