<div class="c-p-bottom-sm">
  <div class="c-row c-row-collapsed c-row-align-center c-full-width">
    <span class="c-col-6 c-p-right-md c-text-ellipsis c-text-right" [title]="field?.cdfName" data-automation-id="adf-text-numeric-name">{{field?.cdfName}}</span>
    <span class="c-col-6">
      <input [formControl]="input"
             [placeholder]="'bd/event-activity/form.adf_placeholder' | translate"
             (blur)="onBlur()"
             class="c-input"
             type="text"
             data-automation-id="adf-text-numeric-input">
    </span>
  </div>
</div>
