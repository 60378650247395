import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '@env/environment';
import { SourceSecurity } from '../models/security/source-security.model';
import { map } from 'rxjs/operators';

@Injectable()
export class SecuritiesService {
  private baseUrl = `${environment.domain}/corporate/activities/api`;

  constructor(private http: HttpClient) {}

  getSecurities(searchText: string): Observable<SourceSecurity[]> {
    return this.http
      .post<SourceSecurity[]>(`${this.baseUrl}/securities`, { searchText })
      .pipe(
        map(items => items.map(i => ({
          ...i,
          name: i.name === 'N/A' ? '' : i.name
        })))
      );
  }
}
