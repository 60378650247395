import {
  Component,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
  Input,
  ChangeDetectionStrategy,
  ViewChild,
  ElementRef
} from '@angular/core';

import { takeUntil, debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { maxLengthLimit, inputDebounceTime } from '../../models';

@Component({
  selector: 'ea-subject',
  templateUrl: './subject.component.html',
  styleUrls: ['./subject.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubjectComponent implements OnInit, OnDestroy {
  @ViewChild('subjectInput', { static: true }) subjectInput: ElementRef<HTMLInputElement>;

  @Input() invalid = false;
  @Input()
  public set value(val: string) {
    const value = val || '';
    this.subjectInput.nativeElement.value = value;
    this.currentValue = value;
  }

  @Output() subjectChange = new EventEmitter<string>();

  showError = true;
  maxLength = maxLengthLimit;

  valueChange$ = new Subject();

  private destroyed = new Subject();
  private currentValue: string;

  ngOnInit() {
    this.valueChange$
      .pipe(
        debounceTime(inputDebounceTime),
        distinctUntilChanged(),
        takeUntil(this.destroyed),
        filter((v: string) => !!v || !!this.currentValue)
      )
      .subscribe((v: string) => {
        this.showError = false;
        this.subjectChange.emit(v);
      });
  }

  focus() {
    this.showError = false;
    this.subjectInput.nativeElement.focus();
  }

  onBlur() {
    this.showError = true;
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.unsubscribe();
  }
}
