import { Routes } from '@angular/router';
import { createTranslateLoader } from './translation/localization-loader';
import { EventActivityComponent } from './event-activity/containers';
import { BillfoldAuthGuard } from './authorization/services/auth.guard';
import { NotAuthorizedPageComponent } from './components/not-authorized-page/not-authorized-page.component';
import { FlavorInjectorService } from './services/flavor-injector.service';
import '@ipreo/common-client'; // CupcakeIntegrationModule requires to import Ipreo namespace from common-client to work
export var ROUTES = [
    {
        path: 'not-authorized',
        component: NotAuthorizedPageComponent
    },
    {
        path: '',
        canActivate: [BillfoldAuthGuard],
        children: [{
                path: '',
                canActivate: [FlavorInjectorService],
                component: EventActivityComponent,
                children: [{
                        path: ':id',
                        component: EventActivityComponent
                    }]
            }]
    }
];
var ɵ0 = createTranslateLoader, ɵ1 = window;
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0, ɵ1 };
