var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Actions, Effect, ofType } from '@ngrx/effects';
import { filter, mergeMap, withLatestFrom, map, catchError } from 'rxjs/operators';
import { FailAction, LOG_AUTH_ERROR_TYPE } from '../actions/fail-action';
import { LoggingService, LogLevel } from '../../services/logging.service';
import { BillfoldAuthClient } from '../../authorization/services/auth-client.service';
import { HttpErrorResponse } from '@angular/common/http';
import { HttpStatusCodes } from '../../event-activity/models';
import { EMPTY } from 'rxjs';
var LogEffects = /** @class */ (function () {
    function LogEffects(actions$, authClient, loggingService) {
        var _this = this;
        this.actions$ = actions$;
        this.authClient = authClient;
        this.loggingService = loggingService;
        this.LogFailActions$ = this.actions$.pipe(filter(function (action) { return action instanceof FailAction; }), filter(function (action) {
            return !(action.error instanceof HttpErrorResponse &&
                action.error.status === HttpStatusCodes.Unauthorized);
        }), mergeMap(function (failAction) {
            return _this.loggingService.log({
                message: _this.getErrorString(failAction.error),
                level: LogLevel.Error
            }).pipe(catchError(function () { return EMPTY; }));
        }));
        this.LogAuthError$ = this.actions$.pipe(ofType(LOG_AUTH_ERROR_TYPE), withLatestFrom(this.authClient.getAuthorizationToken()), map(function (_a) {
            var action = _a[0], token = _a[1];
            return ({
                error: action.error,
                availableToken: action.availableToken || token
            });
        }), filter(function (message) { return !!message.availableToken; }), mergeMap(function (message) {
            var error = message.error;
            var errorString = _this.getErrorString(error);
            return _this.loggingService.log({
                message: errorString,
                level: LogLevel.Error
            }, "IA " + message.availableToken).pipe(catchError(function () { return EMPTY; }));
        }));
    }
    LogEffects.prototype.getErrorString = function (error) {
        if (error instanceof Error) {
            return JSON.stringify({
                message: error.message,
                stack: error.stack
            });
        }
        else {
            return JSON.stringify(error);
        }
    };
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], LogEffects.prototype, "LogFailActions$", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], LogEffects.prototype, "LogAuthError$", void 0);
    return LogEffects;
}());
export { LogEffects };
