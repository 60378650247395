<div class="c-d-flex ea-duration">
    <div class="c-full-width ea-duration-selector" [class.ea-end-date-duration]="endDateVisible">
        <select class="c-input" data-automation-id="duration-select" #duration
                (change)="onChange(duration.value)">
            <option *ngFor="let option of options"
                    [selected]="selected === option.value"
                    [value]="option.value">{{ 'bd/event-activity/form.' + option.translation | translate }}
            </option>
        </select>
    </div>
    <div *ngIf="endDateVisible" class="c-m-left-sm ea-end-date" data-automation-id="end-date">
        <bd-date-time-selector [dateTime]="endDate"
                               [is12HourFormat]="is12HourFormat"
                               [localization]="localization"
                               [class.has-error]="endDateHasError"
                               [positionMode]="'fixed'"
                               (dateTimeChange)="endDateChange.emit($event)">
        </bd-date-time-selector>
        <div *ngIf="endDateHasError"
             class="c-popover c-popover-top c-popover-danger ea-end-date-tooltip">
            <div class="c-popover-arrow"></div>
            <div class="c-popover-content">
                {{ this.getEndDateErrorMsg() }}
            </div>
        </div>
    </div>
</div>
