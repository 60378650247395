import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivityDefinedField, ActivityDefinedFieldValue } from '../../../models';
import { AdfComponentInterface } from '../adf-component.interface';

@Component({
  selector: 'ea-text-adf',
  templateUrl: './text-adf.component.html',
  styleUrls: ['./text-adf.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextAdfComponent implements AdfComponentInterface, OnInit {
  @Input()
  field: ActivityDefinedField;

  @Output()
  valueChange = new EventEmitter<ActivityDefinedField>();

  input = new FormControl('');

  ngOnInit() {
    if (this.field && this.field.values[0]) {
      this.input.setValue(this.field.values[0].textValue);
    }
  }

  onBlur() {
    this.valueChange.emit(<ActivityDefinedField>{
      ...this.field,
      values: this.input.value ? [<ActivityDefinedFieldValue>{ textValue: this.input.value }] : []
    });
  }
}
