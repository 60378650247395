var WarningPopover = /** @class */ (function () {
    function WarningPopover() {
    }
    Object.defineProperty(WarningPopover.prototype, "showPopover", {
        get: function () {
            return this.showMatchPopover || this.showTzPopover || this.showBrokenLocationPopover;
        },
        enumerable: true,
        configurable: true
    });
    return WarningPopover;
}());
export { WarningPopover };
