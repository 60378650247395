import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromEventDataActivity from '../reducers/event-activity-data.reducer';

export const getEventActivityDataState = createSelector(
  fromFeature.getEventActivityDialogState,
  (state: fromFeature.State) => state.eventActivityData
);

export const getInternalParticipants = createSelector(
  getEventActivityDataState,
  fromEventDataActivity.getInternalParticipants
);

export const getInternalParticipantsLoaded = createSelector(
  getEventActivityDataState,
  fromEventDataActivity.getInternalParticipantsLoaded
);

export const getEventSchedules = createSelector(
  getEventActivityDataState,
  fromEventDataActivity.getEventSchedules
);

export const getListsTotalCount = createSelector(
  getEventActivityDataState,
  fromEventDataActivity.getTotalCount
);

export const getListsLoaded = createSelector(
  getEventActivityDataState,
  fromEventDataActivity.getListsLoaded
);

export const getLists = createSelector(getEventActivityDataState, fromEventDataActivity.getLists);

export const getEventName = createSelector(getEventActivityDataState, fromEventDataActivity.getEventName);

export const getIsVirtualEvent = createSelector(getEventActivityDataState, fromEventDataActivity.getIsVirtualEvent);
