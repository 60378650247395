import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { Alert } from '../../models/alert.model';

@Component({
  selector: 'ea-alert',
  templateUrl: './alert.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AlertComponent {
  @Input() alert: Alert;
}
