var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FailAction } from '../../../store/actions/fail-action';
export var LOAD_INTERNAL_PARTICIPANTS = '[INTERNAL PARTICIPANTS] Load Internal Participants';
export var LOAD_INTERNAL_PARTICIPANTS_SUCCESS = '[INTERNAL PARTICIPANTS] Load Internal Participants Success';
export var LOAD_INTERNAL_PARTICIPANTS_FAIL = '[INTERNAL PARTICIPANTS] Load Internal Participants Fail';
export var LOAD_LISTS = '[LISTS] Load Lists';
export var LOAD_LISTS_SUCCESS = '[LISTS] Load Lists Success';
export var LOAD_LISTS_FAIL = '[LISTS] Load Lists Fail';
export var LOAD_EVENT_DATA = '[EVENT] Load Event Data';
var LoadInternalParticipants = /** @class */ (function () {
    function LoadInternalParticipants() {
        this.type = LOAD_INTERNAL_PARTICIPANTS;
    }
    return LoadInternalParticipants;
}());
export { LoadInternalParticipants };
var LoadInternalParticipantsSuccess = /** @class */ (function () {
    function LoadInternalParticipantsSuccess(payload) {
        this.payload = payload;
        this.type = LOAD_INTERNAL_PARTICIPANTS_SUCCESS;
    }
    return LoadInternalParticipantsSuccess;
}());
export { LoadInternalParticipantsSuccess };
var LoadInternalParticipantsFail = /** @class */ (function (_super) {
    __extends(LoadInternalParticipantsFail, _super);
    function LoadInternalParticipantsFail(error) {
        var _this = _super.call(this) || this;
        _this.error = error;
        _this.type = LOAD_INTERNAL_PARTICIPANTS_FAIL;
        return _this;
    }
    return LoadInternalParticipantsFail;
}(FailAction));
export { LoadInternalParticipantsFail };
var LoadLists = /** @class */ (function () {
    function LoadLists(payload) {
        this.payload = payload;
        this.type = LOAD_LISTS;
    }
    return LoadLists;
}());
export { LoadLists };
var LoadListsSuccess = /** @class */ (function () {
    function LoadListsSuccess(payload) {
        this.payload = payload;
        this.type = LOAD_LISTS_SUCCESS;
    }
    return LoadListsSuccess;
}());
export { LoadListsSuccess };
var LoadListsFail = /** @class */ (function (_super) {
    __extends(LoadListsFail, _super);
    function LoadListsFail(error) {
        var _this = _super.call(this) || this;
        _this.error = error;
        _this.type = LOAD_LISTS_FAIL;
        return _this;
    }
    return LoadListsFail;
}(FailAction));
export { LoadListsFail };
var LoadEventData = /** @class */ (function () {
    function LoadEventData(payload) {
        this.payload = payload;
        this.type = LOAD_EVENT_DATA;
    }
    return LoadEventData;
}());
export { LoadEventData };
