import { combineLatest, forkJoin, of, ReplaySubject } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { getLoadFailed, getSavedLocations } from '../store/selectors/saved-locations.selector';
import { catchError, filter, map, take } from 'rxjs/operators';
import { PlaceFacade } from './place.facade';
import { LocationGroupEnum } from '../models';
import { LoadSavedLocations, SetLocation } from '../store/actions';
var LocationsSourceService = /** @class */ (function () {
    function LocationsSourceService(store, placeFacade) {
        this.store = store;
        this.placeFacade = placeFacade;
        this.locationSource$ = new ReplaySubject(1);
        this.savedLocationsCount = 10;
        this.loading = false;
    }
    LocationsSourceService.prototype.fetchSavedLocations = function () {
        var _this = this;
        this.getSavedLocations()
            .subscribe(function (locations) {
            _this.setLoading(false);
            _this.locationSource$.next(locations);
        });
    };
    LocationsSourceService.prototype.fetchGoogleLocations = function (query) {
        var _this = this;
        this.getGoogleLocations(query)
            .subscribe(function (locations) {
            _this.setLoading(false);
            _this.locationSource$.next(locations);
        });
    };
    LocationsSourceService.prototype.fetchCombinedResults = function (query) {
        var _this = this;
        forkJoin([this.getGoogleLocations(query),
            this.getSavedLocations(query)])
            .pipe(filter(function (_a) {
            var google = _a[0], saved = _a[1];
            return !!google && !!saved;
        }), map(function (_a) {
            var google = _a[0], saved = _a[1];
            return saved.slice(0, _this.savedLocationsCount).concat(google);
        }))
            .subscribe(function (locations) {
            _this.setLoading(false);
            _this.locationSource$.next(locations);
        });
    };
    LocationsSourceService.prototype.hasGoogleResults = function () {
        return this.locationSource$.asObservable()
            .pipe(map(function (locations) {
            return locations.some(function (l) { return l.groupKey === LocationGroupEnum.GoogleLocations; });
        }));
    };
    LocationsSourceService.prototype.getLocationsSource = function () {
        var _this = this;
        this.setAutocompleteSessionToken();
        return this.locationSource$.asObservable()
            .pipe(filter(function () { return !_this.loading; }));
    };
    LocationsSourceService.prototype.setAutocompleteSessionToken = function () {
        this.autocompleteSessionToken = this.placeFacade.createAutocompleteSessionToken();
    };
    LocationsSourceService.prototype.setSavedLocation = function (location) {
        this.store.dispatch(new SetLocation(location));
    };
    LocationsSourceService.prototype.setLoading = function (loading) {
        this.loading = loading;
    };
    LocationsSourceService.prototype.getSavedLocations = function (query) {
        var _this = this;
        if (query === void 0) { query = null; }
        this.store.dispatch(new LoadSavedLocations(query));
        return combineLatest([this.store.pipe(select(getSavedLocations)),
            this.store.pipe(select(getLoadFailed))])
            .pipe(filter(function (_a) {
            var loadFailed = _a[1];
            return loadFailed !== null;
        }), take(1), map(function (_a) {
            var locations = _a[0], loadFailed = _a[1];
            if (loadFailed) {
                return [_this.mapToError('saved_locations_not_available', LocationGroupEnum.SavedLocations)];
            }
            if (locations) {
                return _this.mapToViewModel(locations, LocationGroupEnum.SavedLocations);
            }
            return [];
        }));
    };
    LocationsSourceService.prototype.getGoogleLocations = function (query) {
        var _this = this;
        return this.placeFacade
            .getPlacePredictions({
            input: query,
            sessionToken: this.autocompleteSessionToken
        }).pipe(take(1), map(function (locations) { return locations && _this.mapToViewModel(locations, LocationGroupEnum.GoogleLocations); }), catchError(function () {
            return of([_this.mapToError('google_results_not_available', LocationGroupEnum.GoogleLocations)]);
        }));
    };
    LocationsSourceService.prototype.mapToViewModel = function (items, groupKey) {
        return items.map(function (i) { return ({
            item: i,
            groupKey: groupKey
        }); });
    };
    LocationsSourceService.prototype.mapToError = function (description, groupKey) {
        return { groupKey: groupKey, error: true, item: { description: description } };
    };
    return LocationsSourceService;
}());
export { LocationsSourceService };
