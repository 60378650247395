/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./pager.component";
var styles_PagerComponent = [];
var RenderType_PagerComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PagerComponent, data: {} });
export { RenderType_PagerComponent as RenderType_PagerComponent };
function View_PagerComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "li", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "a", [["class", "c-pagination-link c-pagination-ellipsis"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toPreviousView() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, null); }
function View_PagerComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "a", [["class", "c-pagination-link"]], [[2, "c-pagination-current", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onPageClick(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.context.$implicit === _co.currentPage); _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.context.$implicit; _ck(_v, 2, 0, currVal_1); }); }
function View_PagerComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "li", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "a", [["class", "c-pagination-link c-pagination-ellipsis"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toNextView() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, null); }
function View_PagerComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 16, "ul", [["class", "c-pagination c-m-bottom-0"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "li", [], [[2, "c-pagination-disabled", null]], null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "a", [["class", "c-pagination-link"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onPageClick(1) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 0, "i", [["class", "fa fa-angle-double-left"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "li", [], [[2, "c-pagination-disabled", null]], null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 0, "a", [["class", "c-pagination-previous c-pagination-link"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onPreviousPageClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PagerComponent_2)), i0.ɵdid(7, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PagerComponent_3)), i0.ɵdid(9, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PagerComponent_4)), i0.ɵdid(11, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(12, 0, null, null, 1, "li", [], [[2, "c-pagination-disabled", null]], null, null, null, null)), (_l()(), i0.ɵeld(13, 0, null, null, 0, "a", [["class", "c-pagination-link c-pagination-next"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onNextPageClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(14, 0, null, null, 2, "li", [], [[2, "c-pagination-disabled", null]], null, null, null, null)), (_l()(), i0.ɵeld(15, 0, null, null, 1, "a", [["class", "c-pagination-link"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onPageClick(_co.totalPages) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(16, 0, null, null, 0, "i", [["class", "fa fa-angle-double-right"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.isLeftEllipsisVisible; _ck(_v, 7, 0, currVal_2); var currVal_3 = _co.pages; _ck(_v, 9, 0, currVal_3); var currVal_4 = _co.isRightEllipsisVisible; _ck(_v, 11, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isFirstPage; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.isFirstPage; _ck(_v, 4, 0, currVal_1); var currVal_5 = _co.isLastPage; _ck(_v, 12, 0, currVal_5); var currVal_6 = _co.isLastPage; _ck(_v, 14, 0, currVal_6); }); }
export function View_PagerComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_PagerComponent_1)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.totalPages > 1); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_PagerComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ea-pager", [], null, null, null, View_PagerComponent_0, RenderType_PagerComponent)), i0.ɵdid(1, 49152, null, 0, i2.PagerComponent, [], null, null)], null, null); }
var PagerComponentNgFactory = i0.ɵccf("ea-pager", i2.PagerComponent, View_PagerComponent_Host_0, { totalPages: "totalPages", visiblePagesCount: "visiblePagesCount", selectedPage: "selectedPage" }, { pageSelect: "pageSelect" }, []);
export { PagerComponentNgFactory as PagerComponentNgFactory };
