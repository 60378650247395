var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Actions, Effect, ofType } from '@ngrx/effects';
import { filter, map } from 'rxjs/operators';
import { SetVirtualMeetingVisibility, SetActivityLocationVisibility, LOAD_EVENT_ACTIVITY_MODEL_SUCCESS, } from '../actions';
var ActivityTypeEffect = /** @class */ (function () {
    function ActivityTypeEffect(actions$) {
        this.actions$ = actions$;
        this.UpdateVirtualMeetingVisibilityOnActivityLoad = this.actions$.pipe(ofType(LOAD_EVENT_ACTIVITY_MODEL_SUCCESS), map(function (action) { return action.payload.activity; }), filter(function (activity) { return !!activity.onlineMeetingLink || !!activity.onlineMeetingPassword; }), map(function (_) { return new SetVirtualMeetingVisibility(true); }));
        this.UpdateActivityLocationVisibilityOnActivityLoad = this.actions$.pipe(ofType(LOAD_EVENT_ACTIVITY_MODEL_SUCCESS), map(function (action) { return action.payload.activity; }), filter(function (activity) { return !!activity.location || !!activity.addressComment; }), map(function (_) { return new SetActivityLocationVisibility(true); }));
    }
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], ActivityTypeEffect.prototype, "UpdateVirtualMeetingVisibilityOnActivityLoad", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], ActivityTypeEffect.prototype, "UpdateActivityLocationVisibilityOnActivityLoad", void 0);
    return ActivityTypeEffect;
}());
export { ActivityTypeEffect };
