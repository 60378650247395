<div class="c-row c-m-0 c-justify-center c-align-center c-radius-md c-full-height ea-not-authorized">
  <div class="c-text-center c-bg-white">
    <div class=" icon-empty-noaccess"></div>
    <div class="c-text-lg c-m-top-lg c-m-bottom-xs text-wrap">
      Activity form is not available
    </div>
    <div class="c-text-md c-m-bottom-lg text-wrap">
      Please, log in to access
    </div>
    <button
      type="button"
      class="c-btn c-btn-primary c-btn-sm c-p-vertical-sm c-p-horizontal-lg c-m-bottom-xs"
      (click)="onLogIn()"
    >Log In</button>
  </div>
</div>
<cupcake-integration-iframe-client></cupcake-integration-iframe-client>
