var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createSelector } from '@ngrx/store';
import { get } from 'lodash';
import { activityTypeOptions, } from '../../models';
import { getEventActivityEntity } from './event-activity.selector';
import { getUserProfile } from '../../../store/selectors';
import { ActivityTypePermissions } from '../../models/activity-type/activity-type-permissions';
import { getActivityTemplate } from '../reducers/event-activity.reducer';
var ɵ0 = function (entity) { return entity && entity.activityMethod; };
export var getEventActivityMethod = createSelector(getEventActivityEntity, ɵ0);
var ɵ1 = function (entity) { return entity && entity.activityType; };
export var getEventActivitySubType = createSelector(getEventActivityEntity, ɵ1);
var ɵ2 = function (entity) { return entity && entity.activityMode; };
export var getEventActivityMode = createSelector(getEventActivityEntity, ɵ2);
var ɵ3 = function (entity) { return entity && entity.initiator; };
export var getEventActivityInitiator = createSelector(getEventActivityEntity, ɵ3);
var ɵ4 = function (entity) {
    return entity &&
        getActivityTemplate(entity.activityMode, entity.activityMethod, entity.activityType, entity.initiator);
};
export var getEventActivityTemplate = createSelector(getEventActivityEntity, ɵ4);
var ɵ5 = function (userProfile) {
    return activityTypeOptions.map(function (option) {
        var permissions = get(ActivityTypePermissions, option.id);
        if (permissions && !get(userProfile, permissions)) {
            return __assign({}, option, { hidden: true });
        }
        return option;
    });
};
export var getActivityTemplates = createSelector(getUserProfile, ɵ5);
var ɵ6 = function (template) { return template.subTypes; };
export var getSubTypeOptions = createSelector(getEventActivityTemplate, ɵ6);
var ɵ7 = function (template) { return template.modes; };
export var getModeOptions = createSelector(getEventActivityTemplate, ɵ7);
var ɵ8 = function (template) { return template.initiators; };
export var getInitiatorOptions = createSelector(getEventActivityTemplate, ɵ8);
var ɵ9 = function (subTypes, modes, initiators) {
    return ((subTypes && subTypes.length > 1) ||
        (modes && modes.length > 1) ||
        (initiators && initiators.length > 1));
};
export var getShowChangeButton = createSelector(getSubTypeOptions, getModeOptions, getInitiatorOptions, ɵ9);
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9 };
