<div class="c-p-bottom-sm">
  <div class="c-row c-row-collapsed c-row-align-center c-full-width">
    <span class="c-col-6 c-p-right-md c-text-ellipsis c-text-right" [title]="field?.cdfName" data-automation-id="adf-numeric-name">{{field?.cdfName}}</span>
    <span class="c-col-6">
      <kendo-numerictextbox
        [formControl]="form"
        [spinners]="false"
        [format]="'n'"
        [placeholder]="'bd/event-activity/form.adf_placeholder' | translate"
        [decimals]="3"
        [max]="maxValue"
        [min]="minValue"
        (blur)="onBlur()">
      </kendo-numerictextbox>
    </span>
  </div>
</div>
