import { Component, EventEmitter, Input, Output, OnInit, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { AdfComponentInterface } from '../adf-component.interface';
import { ActivityDefinedField, ActivityDefinedFieldValue } from '../../../models';
import { FormControl } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

const E_SIGN = 'e';
const INVALID_STATUS = 'INVALID';

@Component({
  selector: 'ea-numeric-adf',
  templateUrl: './numeric-adf.component.html',
  styleUrls: ['./numeric-adf.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NumericAdfComponent implements AdfComponentInterface, OnInit, OnDestroy {
  @Input()
  public set field(value: ActivityDefinedField) {
    this._field = value;
    if (this._field && this._field.values[0]) {
      this.form.setValue(this._field.values[0].decimalValue);
    }
  }
  public get field() {
    return this._field;
  }

  @Output()
  valueChange = new EventEmitter<ActivityDefinedField>();

  form = new FormControl();

  maxValue: number;
  minValue: number;
  maxLength: number;

  private destroyedSubject$ = new Subject();
  private _field: ActivityDefinedField;

  constructor() {
    this.maxLength = Number.MAX_SAFE_INTEGER.toString().length - 2;
    this.maxValue = Number.parseInt('9'.repeat(this.maxLength), 10);
    this.minValue = -1 * this.maxValue;
  }

  ngOnInit(): void {
    this.form.statusChanges
      .pipe(takeUntil(this.destroyedSubject$))
      .subscribe((status) => {
        if (status === INVALID_STATUS) {
          this.correctValueLength(this.form.value);
        }
      });
  }

  ngOnDestroy(): void {
    this.destroyedSubject$.next();
    this.destroyedSubject$.complete();
  }

  onBlur() {
    this.valueChange.emit(<ActivityDefinedField>{
      ...this._field,
      values: this.form.value ? [<ActivityDefinedFieldValue>{ decimalValue: this.form.value }] : []
    });
  }

  private correctValueLength(value: number) {
    const sign = Math.sign(value);
    let str = Math.abs(value).toString();
    if (str.includes(E_SIGN)) {
      this.form.setValue(sign * this.correctScientificFormat(str));
    } else if (str.length > this.maxLength) {
      str = str.substr(0, this.maxLength);
      this.form.setValue(sign * Number.parseFloat(str));
    }
  }

  private correctScientificFormat(value: string) {
    const index = value.indexOf(E_SIGN);
    const numberPart = value.slice(0, Math.min(this.maxLength + 1, index));
    const expPart = value.substr(index, 2) + (this.maxLength - 1).toString();
    const correctValue = numberPart + expPart;
    return Number.parseFloat(correctValue);
  }
}
