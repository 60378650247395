import { sortBy } from 'lodash';
import { ActivityDefinedField } from '../../models/activity-defined-field/activity-defined-field.model';
import * as adfActions from '../actions/activity-defined-field.action';
import { AdfSelectionListViewItem } from '../../models';

export interface State {
  hasAny: {
    value: boolean;
    loading: boolean;
    loaded: boolean;
  };

  fields: {
    value: ActivityDefinedField[];
    loading: boolean;
    loaded: boolean;
  };

  selectedFields: ActivityDefinedField[];
  cachedFields: ActivityDefinedField[];
}

export const initialState = (): State => ({
  hasAny: {
    value: false,
    loaded: false,
    loading: false
  },
  fields: {
    value: [],
    loaded: false,
    loading: false
  },
  selectedFields: [],
  cachedFields: []
});

export function reducer(
  state = initialState(),
  action: adfActions.ActivityDefinedFieldAction
): State {
  switch (action.type) {
    case adfActions.CHECK_HAS_ANY: {
      return {
        ...state,
        hasAny: {
          ...state.hasAny,
          loading: true
        }
      };
    }
    case adfActions.CHECK_HAS_ANY_SUCCESS: {
      return {
        ...state,
        hasAny: {
          loading: false,
          loaded: true,
          value: action.payload.hasAny
        }
      };
    }
    case adfActions.CHECK_HAS_ANY_FAIL: {
      return {
        ...state,
        hasAny: {
          ...state.hasAny,
          loading: false
        }
      };
    }

    case adfActions.LOAD_ACTIVITY_DEFINED_FIELDS: {
      return {
        ...state,
        fields: {
          ...state.fields,
          loading: true
        }
      };
    }

    case adfActions.LOAD_ACTIVITY_DEFINED_FIELDS_SUCCESS: {
      return {
        ...state,
        fields: {
          loading: false,
          loaded: true,
          value: action.payload.fields
        }
      };
    }

    case adfActions.LOAD_ACTIVITY_DEFINED_FIELDS_FAIL: {
      return {
        ...state,
        fields: {
          ...state.fields,
          loading: false
        }
      };
    }

    case adfActions.SET_SELECTED_ACTIVITY_DEFINED_FIELDS: {
      return {
        ...state,
        selectedFields: action.payload.fields,
        cachedFields: []
      };
    }

    case adfActions.SELECT_ACTIVITY_DEFINED_FIELD: {
      const cachedValue = state.cachedFields.find(d => d.cdfId === action.payload.fieldId);
      let selected;

      if (cachedValue) {
        selected = cachedValue;
      } else {
        selected = state.fields.value.find(d => d.cdfId === action.payload.fieldId);
      }
      return {
        ...state,
        selectedFields: selected ? [...state.selectedFields, selected] : state.selectedFields,
        cachedFields: cachedValue
          ? state.cachedFields.filter(d => d.cdfId !== action.payload.fieldId)
          : state.cachedFields
      };
    }

    case adfActions.UPDATE_ACTIVITY_DEFINED_FIELD: {
      const index = state.selectedFields.findIndex(d => d.cdfId === action.payload.field.cdfId);

      return {
        ...state,
        selectedFields: [
          ...state.selectedFields.slice(0, index),
          action.payload.field,
          ...state.selectedFields.slice(index + 1)
        ]
      };
    }

    case adfActions.HIDE_ACTIVITY_DEFINED_FIELD: {
      const index = state.selectedFields.findIndex(d => d.cdfId === action.payload.fieldId);
      const values = state.selectedFields[index].values;
      return {
        ...state,
        selectedFields: [
          ...state.selectedFields.slice(0, index),
          ...state.selectedFields.slice(index + 1)
        ],
        cachedFields:
          values
            ? [...state.cachedFields, state.selectedFields[index]]
            : state.cachedFields
      };
    }

    default: {
      return state;
    }
  }
}

export const getHasAnyLoaded = (state: State) => state.hasAny.loaded;
export const getHasAnyFields = (state: State) => state.hasAny.value;

export const getFieldsLoading = (state: State) => state.fields.loading;
export const getFieldsLoaded = (state: State) => state.fields.loaded;
export const getFields = (state: State) => state.fields.value || [];
export const getSelectedFields = (state: State) => sortBy(state.selectedFields || [], 'cdfName');
export const getAdfSelectionViewItems = (
  fields: ActivityDefinedField[],
  selectedFields: ActivityDefinedField[]
) =>
  fields.map(
    field =>
      <AdfSelectionListViewItem>{
        id: field.cdfId,
        name: field.cdfName,
        selected: !!selectedFields.find(d => d.cdfId === field.cdfId)
      }
  );
export const getRemovedFields = (state: State) =>
  [...state.cachedFields, ...state.selectedFields.filter(d => d.values.length === 0)].filter(
    field => !!field.tagId
  );
