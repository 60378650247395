import { Injectable, Inject, PLATFORM_ID, Optional } from '@angular/core';
import {
  CanActivate,
  CanActivateChild,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { BillfoldAuthClient } from './auth-client.service';
import { isPlatformBrowser } from '@angular/common';
import { map, switchMap, catchError } from 'rxjs/operators';
import { SILENT_SIGN_IN_ERROR_HANDLER, SilentSignInErrorHandler } from '../contracts/silent-sign-in-error-handler';
import { SilentSignInError } from './silent-sign-in-iframe-observer.service';

// @dynamic
@Injectable()
export class BillfoldAuthGuard implements CanActivate, CanActivateChild {
  constructor(
    private authService: BillfoldAuthClient,
    @Inject(PLATFORM_ID) private platformId: object,
    @Optional() @Inject(SILENT_SIGN_IN_ERROR_HANDLER) private silentSignInErrorHandler: SilentSignInErrorHandler
  ) {}

  public canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.hasUser(next);
  }

  public canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> {
    return this.hasUser(childRoute);
  }

  private hasUser(routeSnapshot: ActivatedRouteSnapshot): Observable<boolean> {
    if (isPlatformBrowser(this.platformId)) {
      return this.authService.getIsUserSignedIn().pipe(
        switchMap(signedIn => {
          if (!signedIn) {
            return this.authService.silentSingIn()
              .pipe(
                map(user => !!user),
                catchError((error) => {
                  if (this.silentSignInErrorHandler) {
                    if (error instanceof SilentSignInError) {
                      this.silentSignInErrorHandler.handle({
                        failedRouteSnapshot: routeSnapshot,
                        error: error.error,
                        availableToken: error.availableToken,
                        authType: this.authService.authType
                      });
                    } else {
                      this.silentSignInErrorHandler.handle({failedRouteSnapshot: routeSnapshot, error, authType: this.authService.authType });
                    }
                  }
                  return of(false);
                })
              );
          }
          return of(true);
        })
      );
    }
    return of(false);
  }
}
