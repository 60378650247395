var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ADD_ACTIVITY_ATTACHMENTS, LOAD_ACTIVITY_ATTACHMENTS, REMOVE_ACTIVITY_ATTACHMENT, SET_ERRORS } from '../actions/attachment.action';
export var initialState = function () { return ({
    attachments: [],
    errors: []
}); };
var lastId = 0;
export function reducer(state, action) {
    if (state === void 0) { state = initialState(); }
    switch (action.type) {
        case LOAD_ACTIVITY_ATTACHMENTS: {
            lastId = 0;
            return __assign({}, state, { attachments: action.attachments.map(function (a) { return (__assign({}, a, { uid: "" + ++lastId })); }) });
        }
        case ADD_ACTIVITY_ATTACHMENTS: {
            return __assign({}, state, { attachments: state.attachments.concat(action.attachments.map(function (a) { return (__assign({}, a, { uid: "" + ++lastId })); })) });
        }
        case REMOVE_ACTIVITY_ATTACHMENT: {
            return __assign({}, state, { attachments: state.attachments.filter(function (a) { return action.uId !== a.uid; }).slice(), errors: [] });
        }
        case SET_ERRORS: {
            return __assign({}, state, { errors: action.errors });
        }
        default: {
            return state;
        }
    }
}
