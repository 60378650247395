<div class="c-d-flex c-flex-column c-full-height">
    <div class="ea-participants-list-container">
        <div class="ea-participants-list" [class.c-full-page-loader]="loading" data-qa-id="participants-list">
            <div *ngFor="let item of participants | eaPaginate : currentPage : pageSize; trackBy: trackByFn"
                    class="ea-participant-link c-bd-bottom c-p-xs c-d-flex" data-qa-id="attendee-mobile-row">
                <ng-container *ngIf="!item.contact; then companyItem; else contactItem"></ng-container>
                <ng-template #contactItem>
                    <div class="ea-participant-name">
                        <i [ngClass]="item.status | participantStatusIcon" aria-hidden="true" *ngIf="hasStatus(item.status)" [title]="item.status | participantStatusLocalizationKey | translate"></i>
                        <a class="c-text"
                        [href]="linkService.buildProfileLink(item.contact) | async"
                        target="_blank">
                            {{ item.firstName }} {{ item.lastName }}
                        </a>
                        <div class="ea-participant-company" *ngIf="item.company" data-qa-id="attendee-company-name">
                            <a *ngIf="linkService.isProfileAvailable(item.company.id); else noLink"
                                class="c-text-muted c-text-sm"
                                [href]="linkService.buildProfileLink(item.company) | async"
                                target="_blank">
                                {{ item.company.name }}
                            </a>
                            <ng-template #noLink>
                                <span class="c-text-muted c-text-sm">{{ item.company.name }}</span>
                            </ng-template>
                        </div>
                    </div>
                    <ng-container *ngTemplateOutlet="menu"></ng-container>
                </ng-template>

                <ng-template #companyItem>
                    <div class="ea-participant-name" data-qa-id="attendee-contact-name">
                        <a *ngIf="linkService.isProfileAvailable(item.company.id); else noLink"
                            [href]="linkService.buildProfileLink(item.company) | async"
                            class="c-text"
                            target="_blank">
                            {{ item.company.name }}
                        </a>
                        <ng-template #noLink>
                            <span class="c-text">{{ item.company.name }}</span>
                        </ng-template>
                    </div>
                    <ng-container *ngTemplateOutlet="menu"></ng-container>
                </ng-template>


                <ng-template #menu>
                    <span class="c-dropdown">
                        <button [cupcakePopover]="getPopoverOptions(item)" class="c-p-horizontal-sm c-p-vertical-xs ea-menu-btn c-text c-text-primary-hover"
                        (popoverMessage)="onPopoverResponse($event)">
                            <i class="fa fa-ellipsis-v ea-menu-icon"></i>
                        </button>
                    </span>
                </ng-template>
            </div>
        </div>
    </div>
    <div>
        <ea-pager [totalPages]="totalPages" [selectedPage]="currentPage" [visiblePagesCount]="3" (pageSelect)="currentPage = $event"></ea-pager>
    </div>
</div>

