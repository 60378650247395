/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./address-comment.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "@ngx-translate/core";
import * as i4 from "./address-comment.component";
var styles_AddressCommentComponent = [i0.styles];
var RenderType_AddressCommentComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AddressCommentComponent, data: {} });
export { RenderType_AddressCommentComponent as RenderType_AddressCommentComponent };
export function View_AddressCommentComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "input", [["class", "c-input"], ["data-automation-id", "address-comment"], ["maxlength", "255"], ["type", "text"]], [[8, "placeholder", 0], [1, "maxlength", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 1)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i2.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i2.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵdid(2, 540672, null, 0, i2.MaxLengthValidator, [], { maxlength: [0, "maxlength"] }, null), i1.ɵprd(1024, null, i2.NG_VALIDATORS, function (p0_0) { return [p0_0]; }, [i2.MaxLengthValidator]), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.DefaultValueAccessor]), i1.ɵdid(5, 540672, null, 0, i2.FormControlDirective, [[6, i2.NG_VALIDATORS], [8, null], [6, i2.NG_VALUE_ACCESSOR], [2, i2.ɵangular_packages_forms_forms_q]], { form: [0, "form"] }, null), i1.ɵprd(2048, null, i2.NgControl, null, [i2.FormControlDirective]), i1.ɵdid(7, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_9 = "255"; _ck(_v, 2, 0, currVal_9); var currVal_10 = _co.addressComment; _ck(_v, 5, 0, currVal_10); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 0, 0, i1.ɵnov(_v, 8).transform("bd/event-activity/form.apt_suite")); var currVal_1 = (i1.ɵnov(_v, 2).maxlength ? i1.ɵnov(_v, 2).maxlength : null); var currVal_2 = i1.ɵnov(_v, 7).ngClassUntouched; var currVal_3 = i1.ɵnov(_v, 7).ngClassTouched; var currVal_4 = i1.ɵnov(_v, 7).ngClassPristine; var currVal_5 = i1.ɵnov(_v, 7).ngClassDirty; var currVal_6 = i1.ɵnov(_v, 7).ngClassValid; var currVal_7 = i1.ɵnov(_v, 7).ngClassInvalid; var currVal_8 = i1.ɵnov(_v, 7).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }); }
export function View_AddressCommentComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ea-address-comment", [], null, null, null, View_AddressCommentComponent_0, RenderType_AddressCommentComponent)), i1.ɵdid(1, 245760, null, 0, i4.AddressCommentComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AddressCommentComponentNgFactory = i1.ɵccf("ea-address-comment", i4.AddressCommentComponent, View_AddressCommentComponent_Host_0, { value: "value" }, { commentChange: "commentChange" }, []);
export { AddressCommentComponentNgFactory as AddressCommentComponentNgFactory };
