import { Store, select } from '@ngrx/store';
import { shareReplay, map, filter } from 'rxjs/operators';
import { getCountry } from '../../store';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
export var GoogleHostLocation;
(function (GoogleHostLocation) {
    GoogleHostLocation[GoogleHostLocation["Default"] = 0] = "Default";
    GoogleHostLocation[GoogleHostLocation["China"] = 1] = "China";
})(GoogleHostLocation || (GoogleHostLocation = {}));
var GoogleHostSelectorService = /** @class */ (function () {
    function GoogleHostSelectorService(store) {
        var _this = this;
        this.hostLocation$ = store.pipe(select(getCountry), filter(function (c) { return !!c; }), map(function (country) { return _this.chooseGoogleHostLocation(country); }), shareReplay(1));
    }
    GoogleHostSelectorService.prototype.selectHost = function () {
        return this.hostLocation$;
    };
    GoogleHostSelectorService.prototype.chooseGoogleHostLocation = function (country) {
        return country.toUpperCase() === 'CHN' ?
            GoogleHostLocation.China :
            GoogleHostLocation.Default;
    };
    GoogleHostSelectorService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GoogleHostSelectorService_Factory() { return new GoogleHostSelectorService(i0.ɵɵinject(i1.Store)); }, token: GoogleHostSelectorService, providedIn: "root" });
    return GoogleHostSelectorService;
}());
export { GoogleHostSelectorService };
