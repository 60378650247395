var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
var E_SIGN = 'e';
var INVALID_STATUS = 'INVALID';
var NumericAdfComponent = /** @class */ (function () {
    function NumericAdfComponent() {
        this.valueChange = new EventEmitter();
        this.form = new FormControl();
        this.destroyedSubject$ = new Subject();
        this.maxLength = Number.MAX_SAFE_INTEGER.toString().length - 2;
        this.maxValue = Number.parseInt('9'.repeat(this.maxLength), 10);
        this.minValue = -1 * this.maxValue;
    }
    Object.defineProperty(NumericAdfComponent.prototype, "field", {
        get: function () {
            return this._field;
        },
        set: function (value) {
            this._field = value;
            if (this._field && this._field.values[0]) {
                this.form.setValue(this._field.values[0].decimalValue);
            }
        },
        enumerable: true,
        configurable: true
    });
    NumericAdfComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.form.statusChanges
            .pipe(takeUntil(this.destroyedSubject$))
            .subscribe(function (status) {
            if (status === INVALID_STATUS) {
                _this.correctValueLength(_this.form.value);
            }
        });
    };
    NumericAdfComponent.prototype.ngOnDestroy = function () {
        this.destroyedSubject$.next();
        this.destroyedSubject$.complete();
    };
    NumericAdfComponent.prototype.onBlur = function () {
        this.valueChange.emit(__assign({}, this._field, { values: this.form.value ? [{ decimalValue: this.form.value }] : [] }));
    };
    NumericAdfComponent.prototype.correctValueLength = function (value) {
        var sign = Math.sign(value);
        var str = Math.abs(value).toString();
        if (str.includes(E_SIGN)) {
            this.form.setValue(sign * this.correctScientificFormat(str));
        }
        else if (str.length > this.maxLength) {
            str = str.substr(0, this.maxLength);
            this.form.setValue(sign * Number.parseFloat(str));
        }
    };
    NumericAdfComponent.prototype.correctScientificFormat = function (value) {
        var index = value.indexOf(E_SIGN);
        var numberPart = value.slice(0, Math.min(this.maxLength + 1, index));
        var expPart = value.substr(index, 2) + (this.maxLength - 1).toString();
        var correctValue = numberPart + expPart;
        return Number.parseFloat(correctValue);
    };
    return NumericAdfComponent;
}());
export { NumericAdfComponent };
