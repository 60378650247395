import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { State } from '../../store/reducers';
import { getBillfoldHost } from '../../store/selectors';

@Injectable()
export class ParticipantProfileHostService {
  constructor(private store: Store<State>) {}

  getProfilesHost(): Observable<string> {
    return this.store.pipe(select(getBillfoldHost));
  }
}
