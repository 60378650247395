/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
import * as i2 from "./attachment-list-item.component";
import * as i3 from "@progress/kendo-angular-l10n";
import * as i4 from "@progress/kendo-angular-upload";
var styles_AttachmentListItemComponent = [];
var RenderType_AttachmentListItemComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AttachmentListItemComponent, data: {} });
export { RenderType_AttachmentListItemComponent as RenderType_AttachmentListItemComponent };
export function View_AttachmentListItemComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "span", [["class", "k-file-extension-wrapper"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "span", [["class", "k-file-extension"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""])), (_l()(), i0.ɵeld(3, 0, null, null, 0, "span", [["class", "k-file-state"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 5, "span", [["class", "k-file-name-size-wrapper"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "span", [["class", "k-file-name c-cursor-pointer"], ["data-automation-id", "file-name"]], [[8, "title", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.downloadFile() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(6, null, ["", ""])), (_l()(), i0.ɵeld(7, 0, null, null, 2, "span", [["class", "k-file-size"]], null, null, null, null, null)), (_l()(), i0.ɵted(8, null, ["", ""])), i0.ɵpad(9, 1), (_l()(), i0.ɵeld(10, 0, null, null, 2, "button", [["class", "k-upload-action"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onRemove() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 1, "span", [["class", "k-icon k-delete k-i-x"]], [[8, "title", 0]], null, null, null, null)), i0.ɵpid(131072, i1.TranslatePipe, [i1.TranslateService, i0.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.file.extension.substring(1); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.file.name; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.file.name; _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.getTotalFilesSizeMessage(_ck(_v, 9, 0, _co.file)); _ck(_v, 8, 0, currVal_3); var currVal_4 = i0.ɵinlineInterpolate(1, "", i0.ɵunv(_v, 11, 0, i0.ɵnov(_v, 12).transform("bd/event-activity/form.remove")), ""); _ck(_v, 11, 0, currVal_4); }); }
export function View_AttachmentListItemComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ea-attachment-list-item", [], null, null, null, View_AttachmentListItemComponent_0, RenderType_AttachmentListItemComponent)), i0.ɵdid(1, 180224, null, 0, i2.AttachmentListItemComponent, [i3.LocalizationService, i4.UploadService], null, null)], null, null); }
var AttachmentListItemComponentNgFactory = i0.ɵccf("ea-attachment-list-item", i2.AttachmentListItemComponent, View_AttachmentListItemComponent_Host_0, { disabled: "disabled", file: "file" }, { download: "download", remove: "remove" }, []);
export { AttachmentListItemComponentNgFactory as AttachmentListItemComponentNgFactory };
