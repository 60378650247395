/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./duration.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "@ngx-translate/core";
import * as i4 from "../../../../../node_modules/@ipreo/bd-date-time-selector/ipreo-bd-date-time-selector.ngfactory";
import * as i5 from "@ipreo/bd-date-time-selector";
import * as i6 from "@angular/common";
import * as i7 from "./duration.component";
var styles_DurationComponent = [i0.styles];
var RenderType_DurationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DurationComponent, data: {} });
export { RenderType_DurationComponent as RenderType_DurationComponent };
function View_DurationComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "option", [], [[8, "selected", 0]], null, null, null, null)), i1.ɵdid(1, 147456, null, 0, i2.NgSelectOption, [i1.ElementRef, i1.Renderer2, [8, null]], { value: [0, "value"] }, null), i1.ɵdid(2, 147456, null, 0, i2.ɵangular_packages_forms_forms_y, [i1.ElementRef, i1.Renderer2, [8, null]], { value: [0, "value"] }, null), (_l()(), i1.ɵted(3, null, ["", " "])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_1 = _v.context.$implicit.value; _ck(_v, 1, 0, currVal_1); var currVal_2 = _v.context.$implicit.value; _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.selected === _v.context.$implicit.value); _ck(_v, 0, 0, currVal_0); var currVal_3 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform(("bd/event-activity/form." + _v.context.$implicit.translation))); _ck(_v, 3, 0, currVal_3); }); }
function View_DurationComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "c-popover c-popover-top c-popover-danger ea-end-date-tooltip"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "c-popover-arrow"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "c-popover-content"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getEndDateErrorMsg(); _ck(_v, 3, 0, currVal_0); }); }
function View_DurationComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "c-m-left-sm ea-end-date"], ["data-automation-id", "end-date"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "bd-date-time-selector", [], [[2, "has-error", null]], [[null, "dateTimeChange"], [null, "keydown"], [null, "focusin"], ["document", "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("keydown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).keydown($event) !== false);
        ad = (pd_0 && ad);
    } if (("focusin" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).focusin($event) !== false);
        ad = (pd_1 && ad);
    } if (("document:click" === en)) {
        var pd_2 = (i1.ɵnov(_v, 2).documentClick($event) !== false);
        ad = (pd_2 && ad);
    } if (("dateTimeChange" === en)) {
        var pd_3 = (_co.endDateChange.emit($event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, i4.View_DateTimeSelectorComponent_0, i4.RenderType_DateTimeSelectorComponent)), i1.ɵdid(2, 180224, null, 0, i5.DateTimeSelectorComponent, [], { dateTime: [0, "dateTime"], is12HourFormat: [1, "is12HourFormat"], localization: [2, "localization"], positionMode: [3, "positionMode"] }, { dateTimeChange: "dateTimeChange" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DurationComponent_3)), i1.ɵdid(4, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.endDate; var currVal_2 = _co.is12HourFormat; var currVal_3 = _co.localization; var currVal_4 = "fixed"; _ck(_v, 2, 0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_5 = _co.endDateHasError; _ck(_v, 4, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.endDateHasError; _ck(_v, 1, 0, currVal_0); }); }
export function View_DurationComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "c-d-flex ea-duration"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "c-full-width ea-duration-selector"]], [[2, "ea-end-date-duration", null]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, [["duration", 1]], null, 2, "select", [["class", "c-input"], ["data-automation-id", "duration-select"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.onChange(i1.ɵnov(_v, 2).value) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DurationComponent_1)), i1.ɵdid(4, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DurationComponent_2)), i1.ɵdid(6, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.options; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.endDateVisible; _ck(_v, 6, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.endDateVisible; _ck(_v, 1, 0, currVal_0); }); }
export function View_DurationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ea-duration", [], null, null, null, View_DurationComponent_0, RenderType_DurationComponent)), i1.ɵdid(1, 49152, null, 0, i7.DurationComponent, [i3.TranslateService], null, null)], null, null); }
var DurationComponentNgFactory = i1.ɵccf("ea-duration", i7.DurationComponent, View_DurationComponent_Host_0, { selected: "selected", endDate: "endDate", endDateVisible: "endDateVisible", endDateValid: "endDateValid", dateRangeValid: "dateRangeValid", is12HourFormat: "is12HourFormat", localization: "localization" }, { valueChange: "valueChange", endDateChange: "endDateChange" }, []);
export { DurationComponentNgFactory as DurationComponentNgFactory };
