/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./notes.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@ipreo/notes-editor/ipreo-notes-editor.ngfactory";
import * as i3 from "@angular/common";
import * as i4 from "@ipreo/notes-editor";
import * as i5 from "./notes.component";
var styles_NotesComponent = [i0.styles];
var RenderType_NotesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NotesComponent, data: {} });
export { RenderType_NotesComponent as RenderType_NotesComponent };
export function View_NotesComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "bd-notes-editor", [], null, [[null, "notesModelChange"], [null, "focus"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("notesModelChange" === en)) {
        var pd_0 = (_co.onNotesChange($event) !== false);
        ad = (pd_0 && ad);
    } if (("focus" === en)) {
        var pd_1 = (_co.onFocus() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_NotesEditorComponent_0, i2.RenderType_NotesEditorComponent)), i1.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(3, { "ea-notes": 0, "ea-notes-expanded": 1 }), i1.ɵdid(4, 114688, null, 0, i4.NotesEditorComponent, [i4.NotesEditorService], { notesModel: [0, "notesModel"], language: [1, "language"] }, { notesModelChange: "notesModelChange", focus: "focus" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, _co.isHubActivityFormEnabled, _co.isExpanded); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.value; var currVal_2 = _co.language; _ck(_v, 4, 0, currVal_1, currVal_2); }, null); }
export function View_NotesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ea-notes", [], null, null, null, View_NotesComponent_0, RenderType_NotesComponent)), i1.ɵdid(1, 114688, null, 0, i5.NotesComponent, [i1.ChangeDetectorRef, i1.ElementRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NotesComponentNgFactory = i1.ɵccf("ea-notes", i5.NotesComponent, View_NotesComponent_Host_0, { value: "value", language: "language", isHubActivityFormEnabled: "isHubActivityFormEnabled" }, { notesChange: "notesChange" }, []);
export { NotesComponentNgFactory as NotesComponentNgFactory };
