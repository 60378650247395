var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Store, select } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { forkJoin, of } from 'rxjs';
import { switchMap, map, catchError, withLatestFrom, filter } from 'rxjs/operators';
import { EventActivityDataService } from '../../services';
import * as eventActivityDataAction from '../actions/event-activity-data.action';
import * as fromEventActivityData from '../selectors/event-activity-data.selector';
import { ListsService } from '../../services/lists.service';
var EventActivityDataEffects = /** @class */ (function () {
    function EventActivityDataEffects(actions$, store, eventDataService, listsService) {
        var _this = this;
        this.actions$ = actions$;
        this.store = store;
        this.eventDataService = eventDataService;
        this.listsService = listsService;
        this.LoadInternalParticipants$ = this.actions$.pipe(ofType(eventActivityDataAction.LOAD_INTERNAL_PARTICIPANTS), withLatestFrom(this.store.pipe(select(fromEventActivityData.getInternalParticipantsLoaded))), filter(function (_a) {
            var loaded = _a[1];
            return !loaded;
        }), switchMap(function () {
            return _this.eventDataService.getInternalParticipants().pipe(map(function (internalParticipants) {
                return new eventActivityDataAction.LoadInternalParticipantsSuccess(internalParticipants);
            }), catchError(function (error) { return of(new eventActivityDataAction.LoadInternalParticipantsFail(error)); }));
        }));
        this.LoadLists$ = this.actions$.pipe(ofType(eventActivityDataAction.LOAD_LISTS), map(function (action) { return action.payload; }), switchMap(function (searchText) {
            return forkJoin([_this.listsService.getInstitutionLists(searchText),
                _this.listsService.getContactsLists(searchText)]).pipe(map(function (_a) {
                var institutionLists = _a[0], contactLists = _a[1];
                return new eventActivityDataAction.LoadListsSuccess({ institutionLists: institutionLists, contactLists: contactLists });
            }), catchError(function (error) { return of(new eventActivityDataAction.LoadListsFail(error)); }));
        }));
    }
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], EventActivityDataEffects.prototype, "LoadInternalParticipants$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], EventActivityDataEffects.prototype, "LoadLists$", void 0);
    return EventActivityDataEffects;
}());
export { EventActivityDataEffects };
