/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/forms";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "./rixml-field-selector.component";
var styles_RixmlFieldSelectorComponent = [];
var RenderType_RixmlFieldSelectorComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_RixmlFieldSelectorComponent, data: {} });
export { RenderType_RixmlFieldSelectorComponent as RenderType_RixmlFieldSelectorComponent };
function View_RixmlFieldSelectorComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "option", [["hidden", ""], ["value", "undefined"]], null, null, null, null, null)), i0.ɵdid(1, 147456, null, 0, i1.NgSelectOption, [i0.ElementRef, i0.Renderer2, [2, i1.SelectControlValueAccessor]], { value: [0, "value"] }, null), i0.ɵdid(2, 147456, null, 0, i1.ɵangular_packages_forms_forms_y, [i0.ElementRef, i0.Renderer2, [8, null]], { value: [0, "value"] }, null), (_l()(), i0.ɵted(3, null, ["", ""])), i0.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { var currVal_0 = "undefined"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "undefined"; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = i0.ɵunv(_v, 3, 0, i0.ɵnov(_v, 4).transform(_co.placeholder)); _ck(_v, 3, 0, currVal_2); }); }
function View_RixmlFieldSelectorComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "option", [], null, null, null, null, null)), i0.ɵdid(1, 147456, null, 0, i1.NgSelectOption, [i0.ElementRef, i0.Renderer2, [2, i1.SelectControlValueAccessor]], { value: [0, "value"] }, null), i0.ɵdid(2, 147456, null, 0, i1.ɵangular_packages_forms_forms_y, [i0.ElementRef, i0.Renderer2, [8, null]], { value: [0, "value"] }, null), (_l()(), i0.ɵted(3, null, ["", " "])), i0.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i0.ChangeDetectorRef])], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.context.$implicit; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = i0.ɵunv(_v, 3, 0, i0.ɵnov(_v, 4).transform(("bd/event-activity/form." + _co.localizationKeys[(_v.context.$implicit || "N/A")]))); _ck(_v, 3, 0, currVal_2); }); }
export function View_RixmlFieldSelectorComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""])), i0.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(3, 0, null, null, 10, "div", [["class", "c-m-bottom-md"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 9, "select", [["class", "c-input"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "change"], [null, "blur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (i0.ɵnov(_v, 5).onChange($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 5).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("change" === en)) {
        var pd_2 = (_co.onChange() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i0.ɵdid(5, 16384, null, 0, i1.SelectControlValueAccessor, [i0.Renderer2, i0.ElementRef], null, null), i0.ɵprd(1024, null, i1.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i1.SelectControlValueAccessor]), i0.ɵdid(7, 540672, null, 0, i1.FormControlDirective, [[8, null], [8, null], [6, i1.NG_VALUE_ACCESSOR], [2, i1.ɵangular_packages_forms_forms_q]], { form: [0, "form"] }, null), i0.ɵprd(2048, null, i1.NgControl, null, [i1.FormControlDirective]), i0.ɵdid(9, 16384, null, 0, i1.NgControlStatus, [[4, i1.NgControl]], null, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_RixmlFieldSelectorComponent_1)), i0.ɵdid(11, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_RixmlFieldSelectorComponent_2)), i0.ɵdid(13, 278528, null, 0, i3.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_8 = _co.select; _ck(_v, 7, 0, currVal_8); var currVal_9 = (!!_co.placeholder && !_co.selected); _ck(_v, 11, 0, currVal_9); var currVal_10 = _co.options; _ck(_v, 13, 0, currVal_10); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.title)); _ck(_v, 1, 0, currVal_0); var currVal_1 = i0.ɵnov(_v, 9).ngClassUntouched; var currVal_2 = i0.ɵnov(_v, 9).ngClassTouched; var currVal_3 = i0.ɵnov(_v, 9).ngClassPristine; var currVal_4 = i0.ɵnov(_v, 9).ngClassDirty; var currVal_5 = i0.ɵnov(_v, 9).ngClassValid; var currVal_6 = i0.ɵnov(_v, 9).ngClassInvalid; var currVal_7 = i0.ɵnov(_v, 9).ngClassPending; _ck(_v, 4, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
export function View_RixmlFieldSelectorComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ea-rixml-fields-selector", [], null, null, null, View_RixmlFieldSelectorComponent_0, RenderType_RixmlFieldSelectorComponent)), i0.ɵdid(1, 114688, null, 0, i4.RixmlFieldSelectorComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RixmlFieldSelectorComponentNgFactory = i0.ɵccf("ea-rixml-fields-selector", i4.RixmlFieldSelectorComponent, View_RixmlFieldSelectorComponent_Host_0, { title: "title", options: "options", selected: "selected", placeholder: "placeholder" }, { changed: "changed" }, []);
export { RixmlFieldSelectorComponentNgFactory as RixmlFieldSelectorComponentNgFactory };
