var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, ElementRef, OnInit } from '@angular/core';
import { CupcakeDropdownTriggerDirective } from '@ipreo/cupcake-components';
import { PlaceFacade } from '../../../services/place.facade';
import { LocationsSourceService } from '../../../services/locations-source.service';
import { LocationGroupEnum } from '../../../models';
// tslint:disable-next-line:max-line-length
import { ActivitySaveNewLocationComponent } from '../../activity-save-new-location/activity-save-new-location.component';
import { BaseActivityLocationComponent } from '../base-activity-location-input.component';
import { LocationMapperService } from '../../../services';
import { LocationType } from '../../../models/saved-locations/location-type';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
var ExtendedActivityLocationComponent = /** @class */ (function (_super) {
    __extends(ExtendedActivityLocationComponent, _super);
    function ExtendedActivityLocationComponent(placeFacade, cdr, sourceService, mapper, el) {
        var _this = _super.call(this, placeFacade, cdr, sourceService) || this;
        _this.mapper = mapper;
        _this.el = el;
        _this.savedLocationControl = false;
        _this.dropdownOptions = {
            trigger: 'manual',
            expandAreaWidth: 440
        };
        _this.optionsLimit = 100;
        _this.LocationType = LocationType;
        return _this;
    }
    ExtendedActivityLocationComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        this.hasGoogleResults$ = this.sourceService.hasGoogleResults();
        if (this.suggesterInput.value) {
            this.savedLocationControl = true;
            this.cdr.detectChanges();
        }
        this.locationType$.pipe(takeUntil(this.destroySubject$)).subscribe(function (value) {
            var invalidLocation = value === LocationType.InvalidSaved;
            _this.popoverOptions.warningMessage = invalidLocation ?
                'bd/event-activity/form.selected_location_missing_information' :
                _this.popoverOptions.warningMessage;
            _this.popoverOptions.showBrokenLocationPopover = invalidLocation;
        });
        this.address$.pipe(takeUntil(this.destroySubject$)).subscribe(function (value) {
            var input = _this.el.nativeElement.querySelector('.c-suggester-component input');
            if (input) {
                input.title = value;
            }
        });
    };
    ExtendedActivityLocationComponent.prototype.onLocationSelected = function (selectedItems) {
        var first = selectedItems && selectedItems.length > 0 && selectedItems[0];
        if (first && first.groupKey === LocationGroupEnum.SavedLocations) {
            var item = first.item;
            this.locationSelected.emit(this.mapper.toActivityLocation(item));
            this.sourceService.setSavedLocation(this.mapper.toSavedLocation(item));
            this.cdr.detectChanges();
        }
        else {
            _super.prototype.onLocationSelected.call(this, selectedItems);
            this.sourceService.setSavedLocation(null);
        }
        if (selectedItems.length === 0) {
            this.savedLocationControl = false;
            return;
        }
        this.savedLocationControl = true;
    };
    ExtendedActivityLocationComponent.prototype.fetchResult = function (value) {
        this.sourceService.fetchCombinedResults(value);
    };
    ExtendedActivityLocationComponent.prototype.onAddressClear = function (value) {
        _super.prototype.onAddressClear.call(this, value);
        this.sourceService.setLoading(true);
        this.fetchSavedLocations();
    };
    ExtendedActivityLocationComponent.prototype.onFocus = function () {
        _super.prototype.onFocus.call(this);
        if (this.savedLocationFocused) {
            this.collapse();
        }
        this.savedLocationControl = false;
        this.fetchSavedLocations();
    };
    ExtendedActivityLocationComponent.prototype.onBlur = function () {
        if (this.selectedAddress) {
            this.savedLocationControl = true;
        }
    };
    ExtendedActivityLocationComponent.prototype.onNoResult = function (noResults) {
        if (noResults && !this.suggesterInput.value) {
            this.suggester.destroyContainer();
        }
    };
    ExtendedActivityLocationComponent.prototype.toggleDropdown = function (locationType) {
        if (this.dropdown.isExpanded()) {
            this.collapse();
        }
        else {
            this.savedLocationFocused = true;
            this.dropdown.expand();
            this.saveNewLocation.open(locationType);
        }
    };
    ExtendedActivityLocationComponent.prototype.collapse = function () {
        this.savedLocationFocused = false;
        if (!!this.dropdown) {
            this.dropdown.collapse();
        }
    };
    ExtendedActivityLocationComponent.prototype.fetchSavedLocations = function () {
        if (!this.suggesterInput.value) {
            this.sourceService.fetchSavedLocations();
        }
    };
    return ExtendedActivityLocationComponent;
}(BaseActivityLocationComponent));
export { ExtendedActivityLocationComponent };
