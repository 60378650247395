import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { ContactModel, CompositeId, isEqualCompositeId } from '../models';
import { environment } from '@env/environment';
import { uniqWith } from 'lodash';

export interface Account {
  id: CompositeId;
  city: string;
  country: string;
}

@Injectable()
export class AccountQueryService {
  private baseUrl = `${environment.domain}/api/company-catalog/accounts`;
  constructor(private http: HttpClient) {}

  getAccountsFromContactsList(contacts: ContactModel[]): Observable<Account[]> {
    const ids = uniqWith(
      contacts.map(c => <CompositeId>{ prId: c.ipreoCompanyId, crmId: c.companyId }),
      (c1, c2) => isEqualCompositeId(c1, c2));

    return this.createQuery(ids).pipe(switchMap((qId) => this.getQueryResult(qId)));
  }

  private createQuery(ids: CompositeId[]) {
    return this.http.post<string>(`${this.baseUrl}-queries`, {
      accountCompositeKeys: ids
    });
  }

  private getQueryResult(queryId: string): Observable<Account[]> {
    return this.http.get<Account[]>(`${this.baseUrl}?queryId=${queryId}`);
  }
}
