import { Component, ChangeDetectionStrategy } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import {
  getActivityDefinedFieldsLoading,
  getActivityDefinedFieldsLoaded,
  getAdfSelectionViewItems,
  getSelectedFields
} from '../../store/selectors/activity-defined-field.selector';
// tslint:disable-next-line:max-line-length
import * as fromRoot from '../../../store';
import {
  HideActivityDefinedField,
  LoadActivityDefinedFields,
  SelectActivityDefinedField,
  UpdateActivityDefinedField
} from '../../store/actions/activity-defined-field.action';
import { ActivityDefinedField, AdfSelectionListViewItem } from '../../models';
import { getSaveADFsError } from '../../store/selectors';

@Component({
  selector: 'ea-defined-fields-container',
  templateUrl: './event-activity-defined-fields.component.html',
  styleUrls: ['./event-activity-defined-fields.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActivityDefinedFieldsContainerComponent {
  fieldsLoading$: Observable<boolean>;
  fieldsLoaded$: Observable<boolean>;
  fields$: Observable<AdfSelectionListViewItem[]>;
  selectedFields$: Observable<ActivityDefinedField[]>;
  alert$: Observable<boolean>;

  constructor(private store: Store<fromRoot.State>) {
    this.fieldsLoading$ = this.store.pipe(select(getActivityDefinedFieldsLoading));
    this.fieldsLoaded$ = this.store.pipe(select(getActivityDefinedFieldsLoaded));
    this.fields$ = this.store.pipe(select(getAdfSelectionViewItems));
    this.selectedFields$ = this.store.pipe(select(getSelectedFields));
    this.alert$ = this.store.pipe(select(getSaveADFsError));
  }

  onLoadActivityDefinedFields() {
    this.store.dispatch(new LoadActivityDefinedFields());
  }

  onSelect(id: number) {
    this.store.dispatch(new SelectActivityDefinedField({ fieldId: id }));
  }

  onHide(id: number) {
    this.store.dispatch(new HideActivityDefinedField({ fieldId: id }));
  }

  onChange(field: ActivityDefinedField) {
    this.store.dispatch(new UpdateActivityDefinedField({ field }));
  }

  trackByFn(_: number, item: ActivityDefinedField) {
    return item.tagId || item.cdfId;
  }
}
