export abstract class HttpServiceBase {
    private isDate(value: any): boolean {
        return value instanceof Date && !isNaN(+value);
    }

    private getShiftedDate(date: any) {
        const dateOffset = date.getTimezoneOffset() * 60000;
        return new Date(date.getTime() - dateOffset);
    }

    protected correctDates<T>(data: any): T {
        if (!data) {
            return data;
        }

        let val;
        if (data.constructor === Array) {
            for (let i = 0; i < data.length; i++) {
                val = data[i];
                if (this.isDate(val)) {
                    data[i] = this.getShiftedDate(val);
                } else {
                    this.correctDates(val);
                }
            }
        } else if (typeof data === 'object') {
            for (const field in data) {
                if (data.hasOwnProperty(field)) {
                    val = data[field];
                    if (this.isDate(val)) {
                        data[field] = this.getShiftedDate(val);
                    } else {
                        this.correctDates(val);
                    }
                }
            }
        }

        return data;
    }
}
