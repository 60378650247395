import { EventEmitter } from '@angular/core';
import { CupcakeDropdownTriggerDirective } from '@ipreo/cupcake-components';
var ActivityDefinedFieldsSelectorComponent = /** @class */ (function () {
    function ActivityDefinedFieldsSelectorComponent() {
        this.loadFields = new EventEmitter();
        this.select = new EventEmitter();
        this.hide = new EventEmitter();
        this.dropdownOptions = {
            trigger: 'manual',
            expandAreaWidth: '250px'
        };
        this._expanded = false;
    }
    Object.defineProperty(ActivityDefinedFieldsSelectorComponent.prototype, "loaded", {
        get: function () {
            return this._loaded;
        },
        set: function (value) {
            this._loaded = value;
            if (this._expanded && this._loaded) {
                this.dropdown.expand();
            }
        },
        enumerable: true,
        configurable: true
    });
    ActivityDefinedFieldsSelectorComponent.prototype.toggleDropdown = function () {
        if (!this.loaded && !this.loading && !this._expanded) {
            this.loadFields.emit();
        }
        this._expanded = !this._expanded;
        if (this.loaded) {
            this.dropdown.toggle();
        }
    };
    ActivityDefinedFieldsSelectorComponent.prototype.collapseDropdown = function () {
        this._expanded = false;
        this.dropdown.collapse();
    };
    ActivityDefinedFieldsSelectorComponent.prototype.trackByFn = function (_, item) {
        return item && item.id;
    };
    ActivityDefinedFieldsSelectorComponent.prototype.onSelect = function (field) {
        if (field.selected) {
            this.hide.emit(field.id);
        }
        else {
            this.select.emit(field.id);
        }
    };
    return ActivityDefinedFieldsSelectorComponent;
}());
export { ActivityDefinedFieldsSelectorComponent };
