var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { EventEmitter, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
var TextAdfComponent = /** @class */ (function () {
    function TextAdfComponent() {
        this.valueChange = new EventEmitter();
        this.input = new FormControl('');
    }
    TextAdfComponent.prototype.ngOnInit = function () {
        if (this.field && this.field.values[0]) {
            this.input.setValue(this.field.values[0].textValue);
        }
    };
    TextAdfComponent.prototype.onBlur = function () {
        this.valueChange.emit(__assign({}, this.field, { values: this.input.value ? [{ textValue: this.input.value }] : [] }));
    };
    return TextAdfComponent;
}());
export { TextAdfComponent };
