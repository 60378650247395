import { ElementRef, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { ReplaySubject, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, takeUntil, withLatestFrom } from 'rxjs/operators';
import { ViewMode, inputDebounceTime } from '../../models';
import * as fromStore from '../../store';
import { getAddress } from '../../store';
import * as savedLocationsActions from '../../store/actions/saved-locations.action';
import { LocationMapperService, ModalService } from '../../services';
import { LocationType } from '../../models/saved-locations/location-type';
import { ActivityLocationComponent } from '../activity-location-input/activity-location-input.component';
var ActivitySaveNewLocationComponent = /** @class */ (function () {
    function ActivitySaveNewLocationComponent(store, mapper, modalService) {
        this.store = store;
        this.mapper = mapper;
        this.modalService = modalService;
        this.collapse = new EventEmitter();
        this.selectedAddressSubject$ = new ReplaySubject(1);
        this.selectedAddress$ = this.selectedAddressSubject$.asObservable();
        this.name = new FormControl('');
        this.mode = ViewMode;
        this.updateAll = false;
        this.destroySubject$ = new Subject();
    }
    ActivitySaveNewLocationComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.store.pipe(select(fromStore.getEditValue)).pipe(takeUntil(this.destroySubject$)).subscribe(function (value) {
            _this.selectedAddressSubject$.next(value && getAddress(value) || null);
            _this.name.setValue(value && value.locationName || '');
            if (value === null) {
                _this.hide();
            }
        });
        this.viewMode$ = this.store.pipe(select(fromStore.isSavedLocationSelected), map(function (selected) { return selected ? ViewMode.Edit : ViewMode.Create; }));
        this.saveDisabled$ = this.store.pipe(select(fromStore.getSaveDisabled));
        this.defaultDisabled$ = this.store.pipe(select(fromStore.getDefaultDisabled));
        this.warning$ = this.store.pipe(select(fromStore.getWarning));
        this.isDefault$ = this.store.pipe(select(fromStore.isDefaultChecked));
        this.error$ = this.store.pipe(select(fromStore.getError));
        this.name.valueChanges.pipe(debounceTime(inputDebounceTime), distinctUntilChanged(), withLatestFrom(this.store.pipe(select(fromStore.getEditValue))), filter(function (_a) {
            var newValue = _a[0], existingValue = _a[1];
            return existingValue && newValue !== existingValue.locationName;
        }), takeUntil(this.destroySubject$))
            .subscribe(function (_a) {
            var value = _a[0];
            _this.store.dispatch(new savedLocationsActions.UpdateName(value));
        });
    };
    ActivitySaveNewLocationComponent.prototype.ngOnDestroy = function () {
        this.destroySubject$.next();
        this.destroySubject$.complete();
    };
    ActivitySaveNewLocationComponent.prototype.open = function (locationType) {
        this.updateAll = false;
        this.store.dispatch(new savedLocationsActions.EditStart());
        if (locationType === LocationType.InvalidSaved) {
            this.updateAll = true;
            this.locationComponent.suggester.setFocusOnInput();
        }
        else {
            this.locationName.nativeElement.focus();
        }
    };
    ActivitySaveNewLocationComponent.prototype.hide = function () {
        this.updateAll = false;
        this.collapse.emit();
    };
    ActivitySaveNewLocationComponent.prototype.save = function (mode) {
        this.store.dispatch(new savedLocationsActions.UpdateName(this.name.value));
        this.store.dispatch(new savedLocationsActions.SubmitSavedLocation({
            updateAll: this.updateAll
        }));
    };
    ActivitySaveNewLocationComponent.prototype.onLocationsSelected = function ($event) {
        var location = $event ? this.mapper.fromActivityLocation($event, this.name.value) : null;
        this.store.dispatch(new savedLocationsActions.UpdateAddress(location));
    };
    ActivitySaveNewLocationComponent.prototype.onDelete = function () {
        this.openDeleteModal();
    };
    ActivitySaveNewLocationComponent.prototype.delete = function () {
        this.store.dispatch(new savedLocationsActions.DeleteSavedLocation());
    };
    ActivitySaveNewLocationComponent.prototype.toggleUpdateAll = function () {
        this.updateAll = !this.updateAll;
    };
    ActivitySaveNewLocationComponent.prototype.toggleIsDefault = function (checked) {
        this.store.dispatch(new savedLocationsActions.UpdateDefault(checked));
    };
    ActivitySaveNewLocationComponent.prototype.openDeleteModal = function () {
        var _this = this;
        this.modalService.openModal({
            title: 'bd/event-activity/form.delete_location_title',
            text: 'bd/event-activity/form.delete_location_warning',
            titleCancelButton: 'bd/event-activity/form.cancel',
            titleConfirmButton: 'bd/event-activity/form.delete',
            type: 'danger',
            confirmEventEmit: function () { return _this.delete(); }
        });
    };
    return ActivitySaveNewLocationComponent;
}());
export { ActivitySaveNewLocationComponent };
