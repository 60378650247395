var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnDestroy, ChangeDetectorRef, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { FileState } from '@progress/kendo-angular-upload';
import { ActivityAttachmentMapper } from '../../services/activity-attachment-mapper.service';
import { getActivityAttachments, getSaveAttachmentsError, getActivityAttachmentErrors } from '../../store';
import { RemoveActivityAttachmentSubmit, DownloadActivityAttachment, AddActivityAttachments, SetErrors } from '../../store/actions/attachment.action';
import { ALLOWED_FILE_TYPES } from '../../models';
import { ActivityAttachmentValidationService } from '../../services';
var AttachmentsComponent = /** @class */ (function () {
    function AttachmentsComponent(activityAttachmentMapper, attachmentValidation, store, cdr) {
        this.activityAttachmentMapper = activityAttachmentMapper;
        this.attachmentValidation = attachmentValidation;
        this.store = store;
        this.cdr = cdr;
        this.acceptList = ALLOWED_FILE_TYPES;
        this.destroyedSubject$ = new Subject();
    }
    AttachmentsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.attachmentsFileInfo$ = this.store.pipe(select(getActivityAttachments), map(function (attachments) {
            return attachments.map(function (a) {
                return __assign({}, a, { state: FileState.Uploaded });
            });
        }));
        this.store.pipe(select(getActivityAttachments), takeUntil(this.destroyedSubject$)).subscribe(function (a) {
            _this.attachments = a;
        });
        this.partialError$ = this.store.pipe(select(getSaveAttachmentsError));
        this.errorMessage$ = this.store.pipe(select(getActivityAttachmentErrors), map(function (errors) { return errors && errors.join(' '); }));
    };
    AttachmentsComponent.prototype.ngOnDestroy = function () {
        if (this.cdr) {
            this.cdr.detach();
            this.cdr = null;
        }
        this.destroyedSubject$.next();
        this.destroyedSubject$.complete();
    };
    AttachmentsComponent.prototype.onUpload = function (e) {
        var _this = this;
        var errorMessages = [];
        var newAttachments = [];
        e.files.forEach(function (file) {
            var message = _this.attachmentValidation.validateFile(file, _this.attachments.concat(newAttachments));
            if (message) {
                errorMessages.push(message);
            }
            else {
                newAttachments.push(_this.activityAttachmentMapper.fromFileInfo(file));
            }
        });
        this.store.dispatch(new SetErrors(errorMessages));
        if (errorMessages.length === e.files.length) {
            e.preventDefault();
        }
        else {
            this.store.dispatch(new AddActivityAttachments(newAttachments));
        }
        this.detectChanges();
    };
    AttachmentsComponent.prototype.onRemove = function (removeId) {
        this.store.dispatch(new RemoveActivityAttachmentSubmit(removeId));
        this.detectChanges();
    };
    AttachmentsComponent.prototype.onDownload = function (attachment) {
        this.store.dispatch(new DownloadActivityAttachment(attachment));
    };
    AttachmentsComponent.prototype.detectChanges = function () {
        if (this.cdr) {
            this.cdr.detectChanges();
        }
    };
    return AttachmentsComponent;
}());
export { AttachmentsComponent };
