import { Action } from '@ngrx/store';
import {
  ActivityLocation, ActivityPrototypeWarning,
  ChangeItem,
  FailedParticipantsInformation,
  ListEntity
} from '../../models';
import { InternalParticipant } from '../../models/participant/internal-participant.model';
import { Contact } from '@ipreo/bd-external-participants/services/contacts/contact';
import { Institution } from '@ipreo/bd-external-participants/services/institutions/institution';

export const SUBMIT_FORM = '[EVENT ACTIVITY DIALOG] Submit Form';
export const CANCEL_FORM = '[EVENT ACTIVITY DIALOG] Cancel Form';

export const UPDATE_CHANGE_LOG = '[EVENT ACTIVITY DIALOG] Update Change Log';
export const RESET_CHANGE_LOG = '[EVENT ACTIVITY DIALOG] Reset Change Log';

export const SUBMIT_LOCATION = '[EVENT ACTIVITY DIALOG] Submit location';
export const SUBMIT_INTERNAL_PARTICIPANTS = '[EVENT ACTIVITY DIALOG] Submit Internal Participants';
export const SUBMIT_EXTERNAL_PARTICIPANTS = '[EVENT ACTIVITY DIALOG] Submit External Participants';
export const SUBMIT_EXTERNAL_PARTICIPANTS_LIST =
'[EVENT ACTIVITY DIALOG] Submit External Participants List';

export const EXTERNAL_PARTICIPANTS_LOADED = '[EVENT ACTIVITY DIALOG] External Participants Loaded';

export const TOGGLE_LISTS_FILTERED = '[EVENT ACTIVITY DIALOG] Toggle Lists Filtered';

export const MARK_FORM_DIRTY = '[EVENT ACTIVITY DIALOG] Mark Form Dirty';
export const RESET_DIRTY_FLAG = '[EVENT ACTIVITY DIALOG] Reset Dirty Flag';

export const UPDATE_FAILED_PARTICIPANTS = '[EVENT ACTIVITY DIALOG] Update Failed Participants';
export const SUBMIT_TIME_ZONE_UPDATE = '[EVENT ACTIVITY DIALOG] Submit Time Zone Update';
export const UPDATE_TIME_ZONE_SUCCESS = '[EVENT ACTIVITY DIALOG] Update Time Zone Success';
export const UPDATE_TIME_ZONE_FAIL = '[EVENT ACTIVITY DIALOG] Update Time Zone Fail';
export const SET_VIRTUAL_MEETING_VISIBILITY = '[EVENT ACTIVITY DIALOG] Set Virtual Meeting Visibility';
export const SET_ACTIVITY_LOCATION_VISIBILITY = '[EVENT ACTIVITY DIALOG] Set Activity Location Visibility';

export const SET_PRESELECT_WARNINGS = '[EVENT ACTIVITY DIALOG] Set Preselect Warnings';

export class SubmitForm implements Action {
  readonly type = SUBMIT_FORM;
}

export class CancelForm implements Action {
  readonly type = CANCEL_FORM;
}

export class UpdateChangeLog implements Action {
  readonly type = UPDATE_CHANGE_LOG;

  constructor(public payload: ChangeItem[]) {}
}

export class ResetChangeLog implements Action {
  readonly type = RESET_CHANGE_LOG;
}

export class SubmitInternalParticipants implements Action {
  readonly type = SUBMIT_INTERNAL_PARTICIPANTS;

  constructor(public payload: InternalParticipant[]) {}
}

export class SubmitExternalParticipants implements Action {
  readonly type = SUBMIT_EXTERNAL_PARTICIPANTS;

  constructor(public payload: Array<Contact | Institution>) {}
}

export class SubmitExternalParticipantsList implements Action {
  readonly type = SUBMIT_EXTERNAL_PARTICIPANTS_LIST;

  constructor(public payload: ListEntity) {}
}

export class SubmitLocation implements Action {
  readonly type = SUBMIT_LOCATION;

  constructor(public payload: ActivityLocation) {}
}

export class ExternalParticipantsLoaded implements Action {
  readonly type = EXTERNAL_PARTICIPANTS_LOADED;
}

export class ToggleListsFiltered implements Action {
  readonly type = TOGGLE_LISTS_FILTERED;
}

export class MarkFormDirty implements Action {
  readonly type = MARK_FORM_DIRTY;

  constructor () {}
}

export class ResetDirtyFlag implements Action {
  readonly type = RESET_DIRTY_FLAG;

  constructor () {}
}

export class UpdateFailedParticipants implements Action {
  readonly type = UPDATE_FAILED_PARTICIPANTS;

  constructor (public payload: FailedParticipantsInformation) {}
}

export class SubmitTimeZoneUpdate implements Action {
  readonly type = SUBMIT_TIME_ZONE_UPDATE;

  constructor (public payload: string) {}
}

export class UpdateTimeZoneSuccess implements Action {
  readonly type = UPDATE_TIME_ZONE_SUCCESS;
}

export class UpdateTimeZoneFail implements Action {
  readonly type = UPDATE_TIME_ZONE_FAIL;
}

export class SetPreselectWarnings implements Action {
  readonly type = SET_PRESELECT_WARNINGS;

  constructor(public payload: ActivityPrototypeWarning[]) {}
}

export class SetVirtualMeetingVisibility implements Action {
  readonly type = SET_VIRTUAL_MEETING_VISIBILITY;

    constructor(public payload: boolean) {}
}

export class SetActivityLocationVisibility implements Action {
  readonly type = SET_ACTIVITY_LOCATION_VISIBILITY;

    constructor(public payload: boolean) {}
}

export type DialogViewAction =
  | SubmitForm
  | CancelForm
  | UpdateChangeLog
  | ResetChangeLog
  | SubmitInternalParticipants
  | SubmitExternalParticipants
  | SubmitLocation
  | SubmitExternalParticipantsList
  | ExternalParticipantsLoaded
  | ToggleListsFiltered
  | MarkFormDirty
  | ResetDirtyFlag
  | UpdateFailedParticipants
  | SubmitTimeZoneUpdate
  | UpdateTimeZoneSuccess
  | UpdateTimeZoneFail
  | SetPreselectWarnings
  | SetVirtualMeetingVisibility
  | SetActivityLocationVisibility;
