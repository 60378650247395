import { InjectionToken } from '@angular/core';
import { AuthorizationFailInfo } from './authorization-fail-info';

export interface SilentSignInErrorHandler {
  handle(errorInfo: AuthorizationFailInfo): void;
}

export const SILENT_SIGN_IN_ERROR_HANDLER = new InjectionToken<SilentSignInErrorHandler>(
  'SilentSignInErrorHandler'
);
