import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { filter, map } from 'rxjs/operators';
import {
  LoadEventActivityModelSuccess,
  SetVirtualMeetingVisibility,
  SetActivityLocationVisibility,
  LOAD_EVENT_ACTIVITY_MODEL_SUCCESS,
} from '../actions';

@Injectable()
export class ActivityTypeEffect {
  constructor(private actions$: Actions) {}

  @Effect()
  UpdateVirtualMeetingVisibilityOnActivityLoad = this.actions$.pipe(
    ofType<LoadEventActivityModelSuccess>(LOAD_EVENT_ACTIVITY_MODEL_SUCCESS),
    map(action => action.payload.activity),
    filter(activity => !!activity.onlineMeetingLink || !!activity.onlineMeetingPassword),
    map(_ => new SetVirtualMeetingVisibility(true))
  );

  @Effect()
  UpdateActivityLocationVisibilityOnActivityLoad = this.actions$.pipe(
    ofType<LoadEventActivityModelSuccess>(LOAD_EVENT_ACTIVITY_MODEL_SUCCESS),
    map(action => action.payload.activity),
    filter(activity => !!activity.location || !!activity.addressComment),
    map(_ => new SetActivityLocationVisibility(true))
  );
}
