/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./internal-participant-suggester-item.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "./internal-participant-suggester-item.component";
import * as i5 from "../../services/link.service";
var styles_InternalParticipantSuggesterItemComponent = [i0.styles];
var RenderType_InternalParticipantSuggesterItemComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InternalParticipantSuggesterItemComponent, data: {} });
export { RenderType_InternalParticipantSuggesterItemComponent as RenderType_InternalParticipantSuggesterItemComponent };
function View_InternalParticipantSuggesterItemComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.item.value; _ck(_v, 2, 0, currVal_0); }); }
function View_InternalParticipantSuggesterItemComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "c-d-flex c-align-center ea-disabled-item c-p-relative"]], [[8, "title", 0]], null, null, null, null)), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", "fa fa-exclamation-circle c-m-horizontal-xs c-text-danger"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 3, "a", [["class", "c-btn-link ea-edit-btn"], ["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), i1.ɵpid(131072, i3.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵted(7, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 0, 0, i1.ɵnov(_v, 1).transform("bd/event-activity/form.missing_information")); _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.item.value; _ck(_v, 4, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform(_co.editLink)); _ck(_v, 5, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform("bd/event-activity/form.edit_contact")); _ck(_v, 7, 0, currVal_3); }); }
export function View_InternalParticipantSuggesterItemComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_InternalParticipantSuggesterItemComponent_1)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["disabledTemplate", 2]], null, 0, null, View_InternalParticipantSuggesterItemComponent_2))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.item.disabled; var currVal_1 = i1.ɵnov(_v, 2); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_InternalParticipantSuggesterItemComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ea-internal-participant-suggester-item", [], null, null, null, View_InternalParticipantSuggesterItemComponent_0, RenderType_InternalParticipantSuggesterItemComponent)), i1.ɵdid(1, 49152, null, 0, i4.InternalParticipantSuggesterItemComponent, [i5.LinkService], null, null)], null, null); }
var InternalParticipantSuggesterItemComponentNgFactory = i1.ɵccf("ea-internal-participant-suggester-item", i4.InternalParticipantSuggesterItemComponent, View_InternalParticipantSuggesterItemComponent_Host_0, { item: "item" }, {}, []);
export { InternalParticipantSuggesterItemComponentNgFactory as InternalParticipantSuggesterItemComponentNgFactory };
