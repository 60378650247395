<div class="c-p-bottom-sm">
    <div class="c-row c-row-collapsed c-row-align-center c-full-width">
        <span class="c-col-6 c-p-right-md c-text-ellipsis c-text-right" [title]="field?.cdfName">{{field?.cdfName}}</span>
        <span class="c-col-6">
            <cupcake-suggester [formControl]="suggesterInput"
                               [suggesterIdField]="'id'"
                               [suggesterValueField]="'name'"
                               [suggesterDataSource]="filteredItems$"
                               [suggesterClearInputOnBlur]="true"
                               [suggesterPlaceholder]="'bd/event-activity/form.adf_select_value' | translate"
                               [suggesterSelectedItems]="selectedItems$ | async"
                               class="ea-adf-singleselect-suggester"
                               [suggesterMinLength]="0"
                               [suggesterMultiselectMode]="false"
                               [suggesterOptionsLimit]="optionsLimit"
                               (suggesterOnSelectEvent)="onAdfSingleSelectChange($event)">
            </cupcake-suggester>
        </span>
    </div>
</div>
