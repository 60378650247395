var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { HttpClient, HttpParams } from '@angular/common/http';
import { map, switchMap } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import { environment } from '@env/environment';
import { HttpServiceBase } from '../../services/http.service';
import { EventActivityMapper } from './event-activity-mapper.service';
import { PollingService } from './polling.service';
import { SaveType } from '../models/polling/save-type.enum';
var EventActivityService = /** @class */ (function (_super) {
    __extends(EventActivityService, _super);
    function EventActivityService(http, mapper, pollingService) {
        var _this = _super.call(this) || this;
        _this.http = http;
        _this.mapper = mapper;
        _this.pollingService = pollingService;
        _this.baseUrl = environment.baseUrl;
        return _this;
    }
    EventActivityService.prototype.get = function (id) {
        var _this = this;
        return this.http
            .get(this.baseUrl + "/api/activity/" + id)
            .pipe(map(function (dto) { return _this.toEventActivityModel(dto); }));
    };
    EventActivityService.prototype.update = function (activity, changeLog, newAttachments) {
        var _this = this;
        if (changeLog === void 0) { changeLog = []; }
        if (newAttachments === void 0) { newAttachments = []; }
        return this.http
            .post(this.baseUrl + "/api/job/update-activity", this.getRequestContent({
            activity: this.mapper.toServer(this.correctDates(cloneDeep(activity))),
            changeLog: changeLog
        }, newAttachments))
            .pipe(switchMap(function (job) { return _this.pollingService.getSavingJobResult(job.jobId, SaveType.Update); }), map(function (d) { return ({
            activity: _this.mapper.fromServer(d.activity),
            hubLoadActivity: d.hubLoadActivity,
            savingErrors: d.savingErrors
        }); }));
    };
    EventActivityService.prototype.create = function (activity, attachments) {
        var _this = this;
        if (attachments === void 0) { attachments = []; }
        return this.http
            .post(this.baseUrl + "/api/job/create-activity", this.getRequestContent(this.mapper.toServer(this.correctDates(cloneDeep(activity))), attachments))
            .pipe(switchMap(function (job) { return _this.pollingService.getSavingJobResult(job.jobId, SaveType.Create); }), map(function (d) { return ({
            activity: _this.mapper.fromServer(d.activity),
            attachments: d.activity.attachments,
            hubLoadActivity: d.hubLoadActivity,
            savingErrors: d.savingErrors
        }); }));
    };
    EventActivityService.prototype.delete = function (activityId) {
        return this.http.delete(this.baseUrl + "/api/activity/" + activityId);
    };
    EventActivityService.prototype.prototype = function (params) {
        var _this = this;
        if (params === void 0) { params = {}; }
        if (!params.eventId) {
            delete params.eventId;
        }
        if (!params.startDate) {
            delete params.startDate;
        }
        if (!params.actionDataKey) {
            delete params.actionDataKey;
        }
        var httpParams = new HttpParams({
            fromObject: params
        });
        return !!params.eventId ?
            this.http.get(this.baseUrl + "/api/activity/event-prototype", { params: httpParams })
                .pipe(map(function (dto) { return _this.toEventActivityModel(dto); }))
            : this.http.get(this.baseUrl + "/api/activity/v2/prototype", { params: httpParams })
                .pipe(map(function (dto) { return _this.toEventActivityModel(dto); }));
    };
    EventActivityService.prototype.getHasContacts = function (keys) {
        return this.http.post(environment.domain + "/app/bd/contacts/api/institutionsWithContacts", {
            accountCompositeKeys: keys,
            excludeInternals: 'true'
        });
    };
    EventActivityService.prototype.toEventActivityModel = function (dto) {
        return {
            activity: this.mapper.fromServer(dto.activity),
            event: dto.event && this.mapEvent(dto.event),
            activityPrototypeWarnings: dto.activityPrototypeWarnings,
            attachments: !!dto.activity ? dto.activity.attachments : []
        };
    };
    EventActivityService.prototype.mapEvent = function (event) {
        return __assign({}, event, { schedules: event.schedules.map(function (s) { return (__assign({}, s, { startDate: new Date(s.startDate) })); }) });
    };
    EventActivityService.prototype.getRequestContent = function (request, attachments) {
        if (!attachments || attachments.length === 0) {
            return request;
        }
        var formData = new FormData();
        attachments.forEach(function (attachment) {
            formData.append('files', attachment.rawFile, attachment.name);
        });
        formData.append('payload', JSON.stringify(request));
        return formData;
    };
    return EventActivityService;
}(HttpServiceBase));
export { EventActivityService };
