import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ContactSearchResponse } from '../models/participant/contact-search-response';
import { environment } from '@env/environment';
import { CompositeId } from '../models';

@Injectable()
export class CompanyContactsDialogService {
  private baseUrl = `${environment.domain}/app/bd/contacts/api/v2`;
  constructor(private http: HttpClient) {}

  getParticipants(
    id: CompositeId,
    take: number,
    skip: number,
    searchText?: string
  ): Observable<ContactSearchResponse> {
    let params = new HttpParams({
      fromObject: {
        take: take.toString(),
        skip: skip.toString(),
        excludeInternals: 'true'
      }
    });
    if (searchText) {
      params = params.append('searchText', searchText);
    }
    if (id.prId) {
      params = params.append('companyPrId', id.prId.toString());
    }
    if (id.crmId) {
      params = params.append('accountCrmId', id.crmId.toString());
    }
    return this.http.get<ContactSearchResponse>(`${this.baseUrl}/search`, { params });
  }
}
