import {
  Component,
  Input,
  ViewChild,
  Output,
  EventEmitter,
  ChangeDetectionStrategy
} from '@angular/core';
import {
  CupcakeDropdownTriggerDirective,
  CupcakeDropdownTriggerOptions
} from '@ipreo/cupcake-components';
import { AdfSelectionListViewItem } from '../../models';
@Component({
  selector: 'ea-defined-fields-selector',
  templateUrl: './activity-defined-fields-selector.component.html',
  styleUrls: ['./activity-defined-fields-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActivityDefinedFieldsSelectorComponent {
  @Input()
  fields: AdfSelectionListViewItem[];

  @Input()
  public set loaded(value) {
    this._loaded = value;
    if (this._expanded && this._loaded) {
      this.dropdown.expand();
    }
  }
  public get loaded(): boolean {
    return this._loaded;
  }

  @Input()
  loading: boolean;

  @Output()
  loadFields = new EventEmitter();

  @Output()
  select = new EventEmitter();

  @Output()
  hide = new EventEmitter();

  @ViewChild('dropdown', { read: CupcakeDropdownTriggerDirective, static: false })
  dropdown: CupcakeDropdownTriggerDirective;

  public dropdownOptions = <CupcakeDropdownTriggerOptions>{
    trigger: 'manual',
    expandAreaWidth: '250px'
  };

  private _loaded: boolean;
  private _expanded = false;

  public toggleDropdown() {
    if (!this.loaded && !this.loading && !this._expanded) {
      this.loadFields.emit();
    }
    this._expanded = !this._expanded;
    if (this.loaded) {
      this.dropdown.toggle();
    }
  }

  public collapseDropdown() {
    this._expanded = false;
    this.dropdown.collapse();
  }

  public trackByFn(_: number, item: AdfSelectionListViewItem) {
    return item && item.id;
  }

  public onSelect(field: AdfSelectionListViewItem) {
    if (field.selected) {
      this.hide.emit(field.id);
    } else {
      this.select.emit(field.id);
    }
  }
}
