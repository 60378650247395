import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { map, timeout } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { LOCATION_LOAD_TIMEOUT, LocationModel } from '../models';

@Injectable()
export class SavedLocationService {
  private hubBaseUrl = environment.hubBaseUrl;
  constructor(private http: HttpClient) {}

  search(searchText?: string): Observable<LocationModel[]> {
    return this.http
      .post<{docs: LocationModel[]}>(`${this.hubBaseUrl}/api/activity/locations/search`, {
        take: 100,
        skip: 0,
        locationName: searchText || ''
      })
      .pipe(
        timeout(LOCATION_LOAD_TIMEOUT),
        map(response => response.docs)
      );
  }

  create(location: LocationModel): Observable<LocationModel> {
    return this.http
      .post<LocationModel>(`${this.hubBaseUrl}/api/activity/locations`, location);
  }

  delete(locationId: number): Observable<Object> {
    return this.http
      .delete<Object>(`${this.hubBaseUrl}/api/activity/locations/${locationId}`);
  }

  update(location: LocationModel): Observable<LocationModel> {
    return this.http
      .put<LocationModel>(`${this.hubBaseUrl}/api/activity/locations/${location.locationId}`, location);
  }
}
