import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { LinkService } from '../../services';
import { Observable } from 'rxjs';
import { InternalParticipantSuggesterModel } from '../../models/participant/internal-participant-suggester-item';

@Component({
  selector: 'ea-internal-participant-suggester-item',
  templateUrl: './internal-participant-suggester-item.component.html',
  styleUrls: ['./internal-participant-suggester-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InternalParticipantSuggesterItemComponent {
  private _item: InternalParticipantSuggesterModel;

  @Input()
  public set item(value: InternalParticipantSuggesterModel) {
    this._item = value;

    if (this._item.disabled) {
      this.editLink = this.getEditLink();
    }
  }

  public get item() {
    return this._item;
  }

  public editLink: Observable<string>;

  constructor(public linkService: LinkService) { }

  private getEditLink() {
    return this.linkService.getEditContactUrl(
      this._item.participant.contact.id,
      {
        contact: this._item.missingContactFields
      }
    );
  }

}
