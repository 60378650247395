import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class PendoAnalyticsService {
  private baseUrl = `${environment.domain}/pendo`;

  constructor(private http: HttpClient) {}

  getPendoAnalyticsScript(): Observable<string> {
    return this.http
      .get(`${this.baseUrl}/installer.js`, {
        responseType: 'text'}  );
  }
}
