/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./internal-participant-group-popover.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./internal-participant-group-popover.component";
import * as i4 from "../../services/link.service";
var styles_InternalParticipantGroupPopoverComponent = [i0.styles];
var RenderType_InternalParticipantGroupPopoverComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InternalParticipantGroupPopoverComponent, data: {} });
export { RenderType_InternalParticipantGroupPopoverComponent as RenderType_InternalParticipantGroupPopoverComponent };
function View_InternalParticipantGroupPopoverComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "c-row c-row-align-center c-row-collapsed ea-popover-participant"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "c-col-11 c-text-ellipsis"], ["data-qa-id", "participant-pill-row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "a", [["class", "c-link c-p-left-xs"], ["data-qa-id", "popover-participant-name"], ["target", "_blank"]], [[8, "title", 0], [8, "href", 4]], null, null, null, null)), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵted(4, null, [" ", " "])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "c-text-gray c-cursor-pointer"], ["data-qa-id", "popover-delete-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.removeItem(_v.context.$implicit, $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "i", [["class", "fa fa-times"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit.value; var currVal_1 = i1.ɵunv(_v, 2, 1, i1.ɵnov(_v, 3).transform(_co.linkService.buildProfileLink(_v.context.$implicit.model.participant.contact))); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _v.context.$implicit.value; _ck(_v, 4, 0, currVal_2); }); }
export function View_InternalParticipantGroupPopoverComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "ea-popover-content"], ["data-qa-id", "group-popover"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_InternalParticipantGroupPopoverComponent_1)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.context)); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_InternalParticipantGroupPopoverComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ea-internal-participant-group-popover", [], null, null, null, View_InternalParticipantGroupPopoverComponent_0, RenderType_InternalParticipantGroupPopoverComponent)), i1.ɵdid(1, 245760, null, 0, i3.InternalParticipantGroupPopoverComponent, [i4.LinkService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InternalParticipantGroupPopoverComponentNgFactory = i1.ɵccf("ea-internal-participant-group-popover", i3.InternalParticipantGroupPopoverComponent, View_InternalParticipantGroupPopoverComponent_Host_0, {}, {}, []);
export { InternalParticipantGroupPopoverComponentNgFactory as InternalParticipantGroupPopoverComponentNgFactory };
