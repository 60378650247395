var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var LocationMapperService = /** @class */ (function () {
    function LocationMapperService() {
    }
    LocationMapperService.prototype.toActivityLocation = function (viewModel) {
        var location = __assign({}, viewModel);
        delete location.locationName;
        delete location.placeId;
        delete location.description;
        return location;
    };
    LocationMapperService.prototype.toSavedLocation = function (viewModel) {
        var location = __assign({}, viewModel);
        delete location.placeId;
        delete location.description;
        return location;
    };
    LocationMapperService.prototype.fromLocationModel = function (source) {
        var location = __assign({}, source);
        delete location.locationName;
        delete location.venueType;
        return location;
    };
    LocationMapperService.prototype.fromActivityLocation = function (source, name) {
        var location = __assign({}, source, { locationName: name, venueType: 'Onsite' });
        delete location.activityId;
        delete location.activityLocationId;
        return location;
    };
    return LocationMapperService;
}());
export { LocationMapperService };
