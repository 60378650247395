/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./rixml-fields-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../rixml-field-selector/rixml-field-selector.component.ngfactory";
import * as i3 from "../rixml-field-selector/rixml-field-selector.component";
import * as i4 from "@angular/common";
import * as i5 from "@ngx-translate/core";
import * as i6 from "./rixml-fields-modal.component";
import * as i7 from "@ngrx/store";
var styles_RixmlFieldsModalComponent = [i0.styles];
var RenderType_RixmlFieldsModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RixmlFieldsModalComponent, data: {} });
export { RenderType_RixmlFieldsModalComponent as RenderType_RixmlFieldsModalComponent };
export function View_RixmlFieldsModalComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 21, "div", [["class", "ea-rixml-modal"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 12, "div", [["class", "ea-rixml-selectors"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "ea-rixml-fields-selector", [], null, [[null, "changed"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("changed" === en)) {
        var pd_0 = (_co.onSubTypeChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_RixmlFieldSelectorComponent_0, i2.RenderType_RixmlFieldSelectorComponent)), i1.ɵdid(3, 114688, null, 0, i3.RixmlFieldSelectorComponent, [], { title: [0, "title"], options: [1, "options"], selected: [2, "selected"] }, { changed: "changed" }), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 2, "ea-rixml-fields-selector", [], null, [[null, "changed"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("changed" === en)) {
        var pd_0 = (_co.onModeChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_RixmlFieldSelectorComponent_0, i2.RenderType_RixmlFieldSelectorComponent)), i1.ɵdid(6, 114688, null, 0, i3.RixmlFieldSelectorComponent, [], { title: [0, "title"], options: [1, "options"], selected: [2, "selected"] }, { changed: "changed" }), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(8, 0, null, null, 2, "ea-rixml-fields-selector", [], null, null, null, i2.View_RixmlFieldSelectorComponent_0, i2.RenderType_RixmlFieldSelectorComponent)), i1.ɵdid(9, 114688, null, 0, i3.RixmlFieldSelectorComponent, [], { title: [0, "title"], options: [1, "options"], selected: [2, "selected"] }, null), i1.ɵpad(10, 1), (_l()(), i1.ɵeld(11, 0, null, null, 2, "ea-rixml-fields-selector", [], null, [[null, "changed"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("changed" === en)) {
        var pd_0 = (_co.onInitiatorChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_RixmlFieldSelectorComponent_0, i2.RenderType_RixmlFieldSelectorComponent)), i1.ɵdid(12, 114688, null, 0, i3.RixmlFieldSelectorComponent, [], { title: [0, "title"], options: [1, "options"], selected: [2, "selected"], placeholder: [3, "placeholder"] }, { changed: "changed" }), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(14, 0, null, null, 7, "div", [["class", "c-footer c-modal-footer ea-rixml-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 6, "div", [["class", "c-footer-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 2, "button", [["class", "c-btn c-btn-default c-m-left-md"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(17, null, [" ", " "])), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(19, 0, null, null, 2, "button", [["class", "c-btn c-btn-primary c-m-left-md"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSave() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(20, null, [" ", " "])), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = "bd/event-activity/form.type"; var currVal_1 = i1.ɵunv(_v, 3, 1, i1.ɵnov(_v, 4).transform(_co.subTypes$)); var currVal_2 = _co.selectedSubType; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = "bd/event-activity/form.mode"; var currVal_4 = i1.ɵunv(_v, 6, 1, i1.ɵnov(_v, 7).transform(_co.modes$)); var currVal_5 = _co.selectedMode; _ck(_v, 6, 0, currVal_3, currVal_4, currVal_5); var currVal_6 = "bd/event-activity/form.method"; var currVal_7 = _ck(_v, 10, 0, _co.selectedMethod); var currVal_8 = _co.selectedMethod; _ck(_v, 9, 0, currVal_6, currVal_7, currVal_8); var currVal_9 = "bd/event-activity/form.initiator"; var currVal_10 = i1.ɵunv(_v, 12, 1, i1.ɵnov(_v, 13).transform(_co.initiators$)); var currVal_11 = _co.selectedInitiator; var currVal_12 = "bd/event-activity/form.activity_initiator_placeholder"; _ck(_v, 12, 0, currVal_9, currVal_10, currVal_11, currVal_12); }, function (_ck, _v) { var currVal_13 = i1.ɵunv(_v, 17, 0, i1.ɵnov(_v, 18).transform("bd/event-activity/form.cancel")); _ck(_v, 17, 0, currVal_13); var currVal_14 = i1.ɵunv(_v, 20, 0, i1.ɵnov(_v, 21).transform("bd/event-activity/form.save")); _ck(_v, 20, 0, currVal_14); }); }
export function View_RixmlFieldsModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ea-rixml-fields-modal", [], null, null, null, View_RixmlFieldsModalComponent_0, RenderType_RixmlFieldsModalComponent)), i1.ɵdid(1, 245760, null, 0, i6.RixmlFieldsModalComponent, [i7.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RixmlFieldsModalComponentNgFactory = i1.ɵccf("ea-rixml-fields-modal", i6.RixmlFieldsModalComponent, View_RixmlFieldsModalComponent_Host_0, {}, {}, []);
export { RixmlFieldsModalComponentNgFactory as RixmlFieldsModalComponentNgFactory };
