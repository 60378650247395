import { createSelector } from '@ngrx/store';

import { getEventActivityDialogState } from '../reducers';
import * as fromReducer from '../reducers/activity-defined-field.reducer';

export const getActivityDefinedFieldState = createSelector(
  getEventActivityDialogState,
  state => state.activityDataField
);

export const getHasAnyActivityDefinedFieldsLoaded = createSelector(
  getActivityDefinedFieldState,
  fromReducer.getHasAnyLoaded
);

export const getHasAnyActivityDefinedFields = createSelector(
  getActivityDefinedFieldState,
  fromReducer.getHasAnyFields
);

export const getActivityDefinedFieldsLoading = createSelector(
  getActivityDefinedFieldState,
  fromReducer.getFieldsLoading
);

export const getActivityDefinedFieldsLoaded = createSelector(
  getActivityDefinedFieldState,
  fromReducer.getFieldsLoaded
);

export const getActivityDefinedFields = createSelector(
  getActivityDefinedFieldState,
  fromReducer.getFields
);

export const getSelectedFields = createSelector(
  getActivityDefinedFieldState,
  fromReducer.getSelectedFields
);

export const getAdfSelectionViewItems = createSelector(
  getActivityDefinedFields,
  getSelectedFields,
  fromReducer.getAdfSelectionViewItems
);

export const getRemovedFields = createSelector(
  getActivityDefinedFieldState,
  fromReducer.getRemovedFields
);
