import { LinkService } from '../../services';
var InternalParticipantSuggesterItemComponent = /** @class */ (function () {
    function InternalParticipantSuggesterItemComponent(linkService) {
        this.linkService = linkService;
    }
    Object.defineProperty(InternalParticipantSuggesterItemComponent.prototype, "item", {
        get: function () {
            return this._item;
        },
        set: function (value) {
            this._item = value;
            if (this._item.disabled) {
                this.editLink = this.getEditLink();
            }
        },
        enumerable: true,
        configurable: true
    });
    InternalParticipantSuggesterItemComponent.prototype.getEditLink = function () {
        return this.linkService.getEditContactUrl(this._item.participant.contact.id, {
            contact: this._item.missingContactFields
        });
    };
    return InternalParticipantSuggesterItemComponent;
}());
export { InternalParticipantSuggesterItemComponent };
