var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Actions, Effect, ofType } from '@ngrx/effects';
import { switchMap, catchError, map, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { UserService, PendoScriptLoaderService } from '../../services';
import * as userActions from '../actions/user.action';
import * as translateActions from '../actions/translation.action';
var UserEffects = /** @class */ (function () {
    function UserEffects(actions$, userService, pendoLoader) {
        var _this = this;
        this.actions$ = actions$;
        this.userService = userService;
        this.pendoLoader = pendoLoader;
        this.loadUserProfile$ = this.actions$.pipe(ofType(userActions.LOAD_USER_PROFILE), switchMap(function () {
            return _this.userService.load().pipe(switchMap(function (user) { return [
                new userActions.LoadUserProfileSuccess(user),
                new translateActions.LoadTranslation(user.userPreferences.language)
            ]; }), catchError(function (error) { return of(new userActions.LoadUserProfileFail(error)); }));
        }));
        this.loadPendoScript$ = this.actions$.pipe(ofType(userActions.LOAD_USER_PROFILE_SUCCESS), tap(function () {
            _this.pendoLoader.loadPendoAnalyticsScript();
        }));
        this.setDefaultLocation$ = this.actions$.pipe(ofType(userActions.SET_DEFAULT_LOCATION), switchMap(function (action) {
            return _this.userService.setDefaultLocation(action.payload).pipe(map(function () {
                return new userActions.SetDefaultLocationSuccess(action.payload);
            }), catchError(function (error) { return of(new userActions.SetDefaultLocationFail(error)); }));
        }));
    }
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], UserEffects.prototype, "loadUserProfile$", void 0);
    __decorate([
        Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], UserEffects.prototype, "loadPendoScript$", void 0);
    __decorate([
        Effect(),
        __metadata("design:type", Object)
    ], UserEffects.prototype, "setDefaultLocation$", void 0);
    return UserEffects;
}());
export { UserEffects };
