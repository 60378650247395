/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./event-location.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "@ngx-translate/core";
import * as i4 from "@angular/common";
import * as i5 from "./event-location.component";
var styles_EventLocationComponent = [i0.styles];
var RenderType_EventLocationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EventLocationComponent, data: {} });
export { RenderType_EventLocationComponent as RenderType_EventLocationComponent };
function View_EventLocationComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "c-col-6"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getDisplayLocation(_co.schedules[0].location); _ck(_v, 1, 0, currVal_0); }); }
function View_EventLocationComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "option", [["hidden", ""]], null, null, null, null, null)), i1.ɵdid(1, 147456, null, 0, i2.NgSelectOption, [i1.ElementRef, i1.Renderer2, [8, null]], null, null), i1.ɵdid(2, 147456, null, 0, i2.ɵangular_packages_forms_forms_y, [i1.ElementRef, i1.Renderer2, [8, null]], null, null), (_l()(), i1.ɵted(3, null, ["", ""])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("bd/event-activity/form.select_location")); _ck(_v, 3, 0, currVal_0); }); }
function View_EventLocationComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "option", [], [[8, "selected", 0]], null, null, null, null)), i1.ɵdid(1, 147456, null, 0, i2.NgSelectOption, [i1.ElementRef, i1.Renderer2, [8, null]], { value: [0, "value"] }, null), i1.ɵdid(2, 147456, null, 0, i2.ɵangular_packages_forms_forms_y, [i1.ElementRef, i1.Renderer2, [8, null]], { value: [0, "value"] }, null), (_l()(), i1.ɵted(3, null, ["", " "]))], function (_ck, _v) { var currVal_1 = _v.context.$implicit.eventScheduleId; _ck(_v, 1, 0, currVal_1); var currVal_2 = _v.context.$implicit.eventScheduleId; _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.selectedPlaceId === _v.context.$implicit.googlePlaceId); _ck(_v, 0, 0, currVal_0); var currVal_3 = _co.getDisplayLocation(_v.context.$implicit.location); _ck(_v, 3, 0, currVal_3); }); }
function View_EventLocationComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "c-col-6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, [["eventLocation", 1]], null, 4, "select", [["class", "c-input ea-event-location-selector"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.onChange(i1.ɵnov(_v, 1).value) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EventLocationComponent_4)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EventLocationComponent_5)), i1.ɵdid(5, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.selectedPlaceId; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.schedules; _ck(_v, 5, 0, currVal_1); }, null); }
function View_EventLocationComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "c-col-10"], ["data-automation-id", "event-location-value"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "c-row c-row-collapsed"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EventLocationComponent_2)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["multipleLocations", 2]], null, 0, null, View_EventLocationComponent_3))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.schedules.length === 1) && _co.selectedPlaceId); var currVal_1 = i1.ɵnov(_v, 4); _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
function View_EventLocationComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "c-col-10"], ["data-qa-id", "no-event-location"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("bd/event-activity/form.event_location_not_available")); _ck(_v, 1, 0, currVal_0); }); }
export function View_EventLocationComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "c-row c-row-align-center c-m-bottom-sm ea-event-location c-align-center"], ["data-automation-id", "event-location-row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "c-col-2 c-p-right-0"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EventLocationComponent_1)), i1.ɵdid(5, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["noLocation", 2]], null, 0, null, View_EventLocationComponent_6))], function (_ck, _v) { var _co = _v.component; var currVal_1 = ((_co.schedules && _co.schedules.length) && !_co.isVirtualEvent); var currVal_2 = i1.ɵnov(_v, 6); _ck(_v, 5, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("bd/event-activity/form.event_location")); _ck(_v, 2, 0, currVal_0); }); }
export function View_EventLocationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ea-event-location", [], null, null, null, View_EventLocationComponent_0, RenderType_EventLocationComponent)), i1.ɵdid(1, 49152, null, 0, i5.EventLocationComponent, [], null, null)], null, null); }
var EventLocationComponentNgFactory = i1.ɵccf("ea-event-location", i5.EventLocationComponent, View_EventLocationComponent_Host_0, { schedules: "schedules", selectedPlaceId: "selectedPlaceId", isVirtualEvent: "isVirtualEvent" }, { eventLocationChange: "eventLocationChange" }, []);
export { EventLocationComponentNgFactory as EventLocationComponentNgFactory };
