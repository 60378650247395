/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./event-activity-type.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "@ngx-translate/core";
import * as i4 from "@angular/common";
import * as i5 from "../../components/rixml-fields/rixml-fields.component.ngfactory";
import * as i6 from "../../components/rixml-fields/rixml-fields.component";
import * as i7 from "@ipreo/cupcake-components/dist/cjs/modal/cupcake-modal.service";
import * as i8 from "./event-activity-type.component";
import * as i9 from "@ngrx/store";
var styles_EventActivityTypeComponent = [i0.styles];
var RenderType_EventActivityTypeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EventActivityTypeComponent, data: {} });
export { RenderType_EventActivityTypeComponent as RenderType_EventActivityTypeComponent };
function View_EventActivityTypeComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "option", [], [[8, "selected", 0]], null, null, null, null)), i1.ɵdid(1, 147456, null, 0, i2.NgSelectOption, [i1.ElementRef, i1.Renderer2, [8, null]], { value: [0, "value"] }, null), i1.ɵdid(2, 147456, null, 0, i2.ɵangular_packages_forms_forms_y, [i1.ElementRef, i1.Renderer2, [8, null]], { value: [0, "value"] }, null), (_l()(), i1.ɵted(3, null, ["", " "])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_1 = _v.parent.context.$implicit.id; _ck(_v, 1, 0, currVal_1); var currVal_2 = _v.parent.context.$implicit.id; _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = (_v.parent.parent.context.ngIf.id === _v.parent.context.$implicit.id); _ck(_v, 0, 0, currVal_0); var currVal_3 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform(("bd/event-activity/form." + _v.parent.context.$implicit.localizationKey))); _ck(_v, 3, 0, currVal_3); }); }
function View_EventActivityTypeComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EventActivityTypeComponent_3)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = (!_v.context.$implicit.hidden || (_v.parent.context.ngIf.id === _v.context.$implicit.id)); _ck(_v, 2, 0, currVal_0); }, null); }
function View_EventActivityTypeComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "c-col-10"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "c-row c-row-collapsed"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [["class", "c-col-6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, [["template", 1]], null, 5, "select", [["class", "c-input"], ["data-automation-id", "activity-type-select"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.onTemplateChange(i1.ɵnov(_v, 3).value) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_EventActivityTypeComponent_2)), i1.ɵdid(5, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵted(7, null, [" ", " "])), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform(_co.templates$)); _ck(_v, 5, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform(_co.templates$)); _ck(_v, 7, 0, currVal_1); }); }
export function View_EventActivityTypeComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "div", [["class", "ea-type-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "c-row c-row-align-center c-m-bottom-sm"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [["class", "c-col-2 c-p-right-0"], ["data-automation-id", "activity-type-name"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", " "])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(6, 0, null, null, 1, "sup", [["class", "c-text-danger"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["*"])), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_EventActivityTypeComponent_1)), i1.ɵdid(9, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(11, 0, null, null, 6, "ea-rixml-fields", [], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.onFieldsChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_RixmlFieldsComponent_0, i5.RenderType_RixmlFieldsComponent)), i1.ɵdid(12, 180224, null, 0, i6.RixmlFieldsComponent, [i7.CupcakeModalService, i3.TranslateService], { type: [0, "type"], mode: [1, "mode"], method: [2, "method"], initiator: [3, "initiator"], showChangeButton: [4, "showChangeButton"] }, { change: "change" }), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵunv(_v, 9, 0, i1.ɵnov(_v, 10).transform(_co.template$)); _ck(_v, 9, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 12, 0, i1.ɵnov(_v, 13).transform(_co.type$)); var currVal_3 = i1.ɵunv(_v, 12, 1, i1.ɵnov(_v, 14).transform(_co.mode$)); var currVal_4 = i1.ɵunv(_v, 12, 2, i1.ɵnov(_v, 15).transform(_co.method$)); var currVal_5 = i1.ɵunv(_v, 12, 3, i1.ɵnov(_v, 16).transform(_co.initiator$)); var currVal_6 = i1.ɵunv(_v, 12, 4, i1.ɵnov(_v, 17).transform(_co.showChangeButton$)); _ck(_v, 12, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("bd/event-activity/form.activity_type")); _ck(_v, 4, 0, currVal_0); }); }
export function View_EventActivityTypeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ea-activity-type-container", [], null, null, null, View_EventActivityTypeComponent_0, RenderType_EventActivityTypeComponent)), i1.ɵdid(1, 114688, null, 0, i8.EventActivityTypeComponent, [i9.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EventActivityTypeComponentNgFactory = i1.ɵccf("ea-activity-type-container", i8.EventActivityTypeComponent, View_EventActivityTypeComponent_Host_0, {}, {}, []);
export { EventActivityTypeComponentNgFactory as EventActivityTypeComponentNgFactory };
