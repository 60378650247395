/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/forms";
import * as i2 from "./input.component";
import * as i3 from "@ipreo/cupcake-components/dist/cjs/popover/cupcake-popover.service";
var styles_InputComponent = [];
var RenderType_InputComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_InputComponent, data: {} });
export { RenderType_InputComponent as RenderType_InputComponent };
export function View_InputComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "input", [["class", "c-input"]], [[2, "c-input-error", null], [8, "placeholder", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "focus"], [null, "focusout"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 1).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i0.ɵnov(_v, 1)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i0.ɵnov(_v, 1)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("focus" === en)) {
        var pd_4 = (_co.onFocus() !== false);
        ad = (pd_4 && ad);
    } if (("focusout" === en)) {
        var pd_5 = (_co.onBlur() !== false);
        ad = (pd_5 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 16384, null, 0, i1.DefaultValueAccessor, [i0.Renderer2, i0.ElementRef, [2, i1.COMPOSITION_BUFFER_MODE]], null, null), i0.ɵprd(1024, null, i1.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i1.DefaultValueAccessor]), i0.ɵdid(3, 540672, null, 0, i1.FormControlDirective, [[8, null], [8, null], [6, i1.NG_VALUE_ACCESSOR], [2, i1.ɵangular_packages_forms_forms_q]], { form: [0, "form"] }, null), i0.ɵprd(2048, null, i1.NgControl, null, [i1.FormControlDirective]), i0.ɵdid(5, 16384, null, 0, i1.NgControlStatus, [[4, i1.NgControl]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_9 = _co.inputForm; _ck(_v, 3, 0, currVal_9); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isValid; var currVal_1 = _co.placeholder; var currVal_2 = i0.ɵnov(_v, 5).ngClassUntouched; var currVal_3 = i0.ɵnov(_v, 5).ngClassTouched; var currVal_4 = i0.ɵnov(_v, 5).ngClassPristine; var currVal_5 = i0.ɵnov(_v, 5).ngClassDirty; var currVal_6 = i0.ɵnov(_v, 5).ngClassValid; var currVal_7 = i0.ɵnov(_v, 5).ngClassInvalid; var currVal_8 = i0.ɵnov(_v, 5).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }); }
export function View_InputComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ea-input", [], null, null, null, View_InputComponent_0, RenderType_InputComponent)), i0.ɵdid(1, 245760, null, 0, i2.InputComponent, [i3.CupcakePopoverService, i0.ElementRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InputComponentNgFactory = i0.ɵccf("ea-input", i2.InputComponent, View_InputComponent_Host_0, { placeholder: "placeholder", warning: "warning", error: "error", value: "value" }, { valueChange: "valueChange" }, []);
export { InputComponentNgFactory as InputComponentNgFactory };
