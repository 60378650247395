var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as dialogActions from '../actions/dialog-view.action';
import { ActivityPrototypeWarning } from '../../models';
export var initialState = function () { return ({
    listsFiltered: false,
    externalParticipantsLoading: false,
    changeLog: [],
    failedParticipantsInformation: null,
    dirty: false,
    timeZoneLoading: false,
    timeZoneFailed: false,
    displayVirtualMeeting: false,
    displayActivityLocation: false,
    activityPrototypeWarnings: null
}); };
export function reducer(state, action) {
    if (state === void 0) { state = initialState(); }
    switch (action.type) {
        case dialogActions.UPDATE_CHANGE_LOG: {
            return __assign({}, state, { changeLog: state.changeLog.concat(action.payload) });
        }
        case dialogActions.RESET_CHANGE_LOG: {
            return __assign({}, state, { changeLog: [] });
        }
        case dialogActions.SUBMIT_EXTERNAL_PARTICIPANTS_LIST: {
            return __assign({}, state, { externalParticipantsLoading: true });
        }
        case dialogActions.EXTERNAL_PARTICIPANTS_LOADED: {
            return __assign({}, state, { externalParticipantsLoading: false });
        }
        case dialogActions.TOGGLE_LISTS_FILTERED: {
            return __assign({}, state, { listsFiltered: !state.listsFiltered });
        }
        case dialogActions.MARK_FORM_DIRTY: {
            return __assign({}, state, { dirty: true });
        }
        case dialogActions.RESET_DIRTY_FLAG: {
            return __assign({}, state, { dirty: false });
        }
        case dialogActions.UPDATE_FAILED_PARTICIPANTS: {
            return __assign({}, state, { failedParticipantsInformation: action.payload && action.payload.participants.length > 0 ? action.payload : null });
        }
        case dialogActions.SUBMIT_TIME_ZONE_UPDATE: {
            return __assign({}, state, { timeZoneLoading: true, timeZoneFailed: false });
        }
        case dialogActions.UPDATE_TIME_ZONE_SUCCESS: {
            return __assign({}, state, { timeZoneLoading: false });
        }
        case dialogActions.UPDATE_TIME_ZONE_FAIL: {
            return __assign({}, state, { timeZoneLoading: false, timeZoneFailed: true });
        }
        case dialogActions.SET_PRESELECT_WARNINGS: {
            return __assign({}, state, { activityPrototypeWarnings: action.payload });
        }
        case dialogActions.SET_VIRTUAL_MEETING_VISIBILITY: {
            return __assign({}, state, { displayVirtualMeeting: action.payload });
        }
        case dialogActions.SET_ACTIVITY_LOCATION_VISIBILITY: {
            return __assign({}, state, { displayActivityLocation: action.payload });
        }
    }
    return state;
}
export var getChangeLog = function (state) { return state.changeLog; };
export var getListsFiltered = function (state) { return state.listsFiltered; };
export var getExternalParticipantsLoading = function (state) { return state.externalParticipantsLoading; };
export var getFormDirty = function (state) { return state.dirty; };
export var getFailedParticipantsInformation = function (state) {
    return state.failedParticipantsInformation;
};
export var getTimeZoneLoading = function (state) {
    return state.timeZoneLoading;
};
export var getTimeZoneFailed = function (state) {
    return state.timeZoneFailed;
};
export var getDisplayVirtualMeeting = function (state) { return state.displayVirtualMeeting; };
export var getDisplayActivityLocation = function (state) { return state.displayActivityLocation; };
export var getFundsPreselected = function (state) {
    return !!state &&
        state.activityPrototypeWarnings &&
        state.activityPrototypeWarnings.includes(ActivityPrototypeWarning.Fund);
};
