import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { select, Store } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { of, pipe } from 'rxjs';

import { EventActivityService } from '../../services';
import {
  ChangeAction,
  ChangeEntity,
  ChangeItem,
  Error,
  EventActivity,
  EventActivityModel,
  isEqualCompositeId
} from '../../models';
import { CloseDialogAction, ProgressState } from '../../../models';

import * as eventActivityAction from '../actions/event-activity.action';
import * as eventActivityDataAction from '../actions/event-activity-data.action';
import * as attachmentAction from '../actions/attachment.action';
import * as dialogAction from '../actions/dialog-view.action';
import * as adfAction from '../actions/activity-defined-field.action';
import * as securitiesAction from '../actions/securities.action';
import * as fromRoot from '../../../store';
import * as fromSelectors from '../selectors';
import { ActivityAttachmentMapper } from '../../services/activity-attachment-mapper.service';
import { MapsService } from '../../services/maps.service';

@Injectable()
export class EventActivityEffects {
  constructor(
    private actions$: Actions,
    private store: Store<fromRoot.State>,
    private eventService: EventActivityService,
    private attachmentMapper: ActivityAttachmentMapper,
    private mapsService: MapsService
  ) {}

  @Effect()
  Init$ = this.actions$.pipe(
    ofType(eventActivityAction.INIT),
    withLatestFrom(this.store.pipe(select(fromRoot.getActivityId)),
      this.store.pipe(select(fromRoot.getUserProfileProgressState))),
    filter(([, , userProfileProgressState]) => userProfileProgressState !== ProgressState.Failed),
    map(([, id]) =>
      id ? new eventActivityAction.LoadEventActivityModel(id) : new eventActivityAction.InitNew()
    )
  );

  @Effect()
  ResetPartialSaveExternals$ = this.resetError([
    eventActivityAction.UPDATE_EXTERNAL_PARTICIPANT_STATUS,
    eventActivityAction.DELETE_EXTERNAL_PARTICIPANT,
    dialogAction.SUBMIT_EXTERNAL_PARTICIPANTS,
    dialogAction.SUBMIT_EXTERNAL_PARTICIPANTS_LIST],
    ChangeEntity.ExternalParticipant);

  @Effect()
  ResetPartialSaveInternals$ = this.resetError(
    [dialogAction.SUBMIT_INTERNAL_PARTICIPANTS], ChangeEntity.InternalParticipant);

  @Effect()
  ResetPartialSaveLocation$ = this.resetError(
    [eventActivityAction.UPDATE_LOCATION], ChangeEntity.Location);

  @Effect()
  ResetPartialSaveAdf$ = this.resetError([
    adfAction.UPDATE_ACTIVITY_DEFINED_FIELD,
      adfAction.HIDE_ACTIVITY_DEFINED_FIELD], ChangeEntity.Adf);

  @Effect()
  ResetPartialSaveSecurities$ = this.resetError(
    [securitiesAction.SUBMIT_SECURITIES], ChangeEntity.Security);

  @Effect()
  ResetPartialSaveAttachments$ = this.resetError([
    attachmentAction.ADD_ACTIVITY_ATTACHMENTS,
    attachmentAction.REMOVE_ACTIVITY_ATTACHMENT], ChangeEntity.Attachment);

  @Effect()
  LoadEventActivityModel$ = this.actions$.pipe(
    ofType<eventActivityAction.LoadEventActivityModel>(eventActivityAction.LOAD_EVENT_ACTIVITY_MODEL),
    switchMap(action =>
      this.eventService.get(action.payload).pipe(
        map(eventActivity => new eventActivityAction.LoadEventActivityModelSuccess(eventActivity)),
        catchError((error: HttpErrorResponse) =>
          of(new eventActivityAction.LoadEventActivityModelFail(error.status, error))
        )
      )
    )
  );

  @Effect()
  LoadEventActivityModelEntities$ = this.actions$.pipe(
    ofType<eventActivityAction.LoadEventActivityModelSuccess>(
      eventActivityAction.LOAD_EVENT_ACTIVITY_MODEL_SUCCESS
    ),
    map(action => action.payload),
    filter((model: EventActivityModel) => !this.isLoadTimeZoneRequired(model.activity)),
    switchMap((model: EventActivityModel) => [
      new eventActivityAction.LoadEventActivity(model.activity),
      new eventActivityDataAction.LoadEventData(model.event)
    ])
  );

  @Effect()
  LoadDefaultLocationTimeZone$ = this.actions$.pipe(
    ofType<eventActivityAction.LoadEventActivityModelSuccess>(
      eventActivityAction.LOAD_EVENT_ACTIVITY_MODEL_SUCCESS
    ),
    map(action => action.payload),
    filter(model => this.isLoadTimeZoneRequired(model.activity)),
    switchMap((model: EventActivityModel) => {
      return this.mapsService.getTimeZone(model.activity.location.googlePlaceId).pipe(
        map(timeZone => new eventActivityAction.LoadEventActivity({...model.activity, timeZone})),
        catchError((error: HttpErrorResponse) => of(
            new eventActivityAction.LoadEventActivity(model.activity)
          ))
        );
      }
    )
  );

  @Effect()
  InitNew$ = this.actions$.pipe(
    ofType(eventActivityAction.INIT_NEW),
    withLatestFrom(
      this.store.pipe(select(fromRoot.getEventId)),
      this.store.pipe(select(fromRoot.getStartDate)),
      this.store.pipe(select(fromRoot.getActionDataKey))
    ),
    switchMap(([_, eventId, startDate, actionDataKey]) =>
      this.eventService.prototype({eventId, startDate, actionDataKey}).pipe(
        map(model => new eventActivityAction.LoadEventActivityModelSuccess(model)),
        catchError((error: HttpErrorResponse) =>
          of(new eventActivityAction.LoadEventActivityModelFail(error.status, error)))
      )
    )
  );

  @Effect()
  Save$ = this.actions$.pipe(
    ofType(eventActivityAction.SAVE_EVENT_ACTIVITY),
    withLatestFrom(
      this.store.pipe(select(fromSelectors.getEventActivityEntity)),
      this.store.pipe(select(fromSelectors.getChangeLog)),
      this.store.pipe(select(fromSelectors.getSelectedFields)),
      this.store.pipe(select(fromSelectors.getActivityAttachments)),
      this.store.pipe(select(fromSelectors.getSelectedSecurities))
    ),
  switchMap(([, entity, changeLog, selectedFields, attachments, selectedSecurities]) => {
    const adjustedChangeLog = [...changeLog];

      let activity = {
        ...entity,
        activityDefinedFields: selectedFields.filter(field => field.values.length > 0),
        attachments: attachments.filter(a => !!a.id).map(a => this.attachmentMapper.toDto(a)),
        securities: selectedSecurities
      };

    if (entity.addressComment && !entity.addressComment.value && entity.addressComment.tagId) {
      adjustedChangeLog.push(
        {
          id: entity.addressComment.tagId,
          action: ChangeAction.Delete,
          entity: ChangeEntity.AddressComment
        } as ChangeItem
      );

      activity = {
        ...activity,
        addressComment: null
      };
    }

      const newAttachments = attachments.filter(a => !a.id);
      const saveResult = entity.activityId
        ? this.eventService.update(activity, adjustedChangeLog, newAttachments)
        : this.eventService.create(activity, newAttachments);
      return saveResult.pipe(
        map(saved => new eventActivityAction.SaveEventActivitySuccess({
          activity: saved.activity,
          hubLoadActivity: saved.hubLoadActivity,
          savingErrors: saved.savingErrors,
          isParticipantsChanged: this.isParticipantsCreated(activity) || this.isParticipantsUpdated(adjustedChangeLog)
        })),
        catchError(error => of(new eventActivityAction.SaveEventActivityFail(error.status, error)))
      );
    })
  );

  @Effect()
  Delete$ = this.actions$.pipe(
      ofType<eventActivityAction.DeleteActivity>(eventActivityAction.DELETE_ACTIVITY),
      withLatestFrom(this.store.pipe(select(fromSelectors.getEventActivityId))),
      switchMap(([action, activityId]: [eventActivityAction.DeleteActivity, string]) =>
        this.eventService.delete(activityId).pipe(
          map(model => new eventActivityAction.DeleteActivitySuccess()),
          catchError((error: HttpErrorResponse) => of(new eventActivityAction.DeleteActivityFail(error.status, error)))
        )
      )
  );

  @Effect()
  CloseDialog$ = this.actions$.pipe(
    ofType<eventActivityAction.DeleteActivitySuccess>(eventActivityAction.DELETE_ACTIVITY_SUCCESS),
    map((action) => new fromRoot.CloseDialog(CloseDialogAction.Delete))
  );

  @Effect()
  SetHasContactsForLoadedParticipants$ = this.actions$.pipe(
    ofType(eventActivityAction.LOAD_EVENT_ACTIVITY),
    map((action: eventActivityAction.LoadEventActivity) => action.payload),
    this.mapParticipants()
  );

  @Effect()
  SetHasContactsForSavedParticipants$ = this.actions$.pipe(
    ofType(eventActivityAction.SAVE_EVENT_ACTIVITY_SUCCESS),
    map((action: eventActivityAction.SaveEventActivitySuccess) => action.payload.activity),
    this.mapParticipants()
  );

  @Effect()
  UpdateScheduleIdAfterStartDate$ = this.updateEventLocation(eventActivityAction.UPDATE_START_DATE);

  @Effect()
  UpdateScheduleIdAfterEndDate$ = this.updateEventLocation(eventActivityAction.UPDATE_END_DATE);

  private resetError(actionNames: string[], entity: ChangeEntity) {
    return this.actions$.pipe(
      ofType(...actionNames),
      withLatestFrom(this.store.pipe(select(fromSelectors.getEventActivityError))),
      filter(([_, error]: [any, Error]) => !!error &&  error === Error.PartialSaveError),
      switchMap(() =>
        of(new eventActivityAction.ResetActivityPartialErrors(entity)))
    );
  }

  private updateEventLocation(actionName: string) {
    return this.actions$.pipe(
      ofType(actionName),
      withLatestFrom(
        this.store.pipe(select(fromSelectors.getAvailableSchedules)),
        this.store.pipe(select(fromSelectors.getEventSchedule))
      ),
      filter(([, schedules, selectedSchedule]) =>
        schedules.length > 0 &&
        (!selectedSchedule || !schedules.find(d => d.eventScheduleId === selectedSchedule.eventScheduleId))),
      switchMap(([, schedules, selectedSchedule]) => {
        const firstAvailable =
         (selectedSchedule && schedules.find(s => s.googlePlaceId === selectedSchedule.googlePlaceId))
           || schedules[0];

        return [
          new eventActivityAction.UpdateEventLocation({
            scheduleId: firstAvailable ? firstAvailable.eventScheduleId : null,
            timeZone: firstAvailable ? firstAvailable.timeZone : null
          })
        ];
      })
    );
  }

  private isParticipantsCreated(activity: EventActivity) {
    return (!!activity.externalParticipants && activity.externalParticipants.findIndex((p) => !p.id) > -1)
      || (!!activity.internalParticipants && activity.internalParticipants.findIndex((p) => !p.id) > -1);
  }

  private isParticipantsUpdated(changeLog: ChangeItem[]) {
    return !!changeLog && changeLog.findIndex((item) =>
      (item.entity === ChangeEntity.InternalParticipant || item.entity === ChangeEntity.ExternalParticipant)
      && item.action === ChangeAction.Update) > -1;
}

  private mapParticipants() {
    return pipe(
      map((entity: EventActivity) => entity.externalParticipants),
      filter(
        externalParticipants =>
          externalParticipants && externalParticipants.filter(d => !d.contact).length > 0
      ),
      switchMap(externalParticipants => {
        const companyKeys = externalParticipants.filter(d => !d.contact).map(d => d.company.id);
        return this.eventService.getHasContacts(companyKeys).pipe(
          map(d => {
            return externalParticipants.map(participant => {
              return participant.contact
                ? participant
                : {
                    ...participant,
                    hasContacts: !!d.find(a => isEqualCompositeId(participant.company.id, a)
                    )
                  };
            });
          })
        );
      }),
      map(d => new eventActivityAction.UpdateExternalParticipants(d))
    );
  }

  private isLoadTimeZoneRequired(activity: EventActivity) {
    if (!!activity.activityId || !!activity.eventId) {
      return false;
    }
    return  !!activity.location && !!activity.location.googlePlaceId;
  }
}
