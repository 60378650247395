import { Component } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';
import * as fromRoot from '../../../store';
import * as fromStore from '../../store';
import {
  UpdateOnlineMeetingPassword,
  UpdateOnlineMeetingLink,
  getVirtualMeetingVisibility,
  getIsVirtualEvent,
  getOnlineMeetingLink,
  getOnlineMeetingPassword,
  getEventActivityVirtualMeetingLinkIsValid,
  getEventActivityVirtualMeetingPasswordIsValid
} from '../../store';

@Component({
  selector: 'ea-activity-virtual-meeting-container',
  templateUrl: './activity-virtual-meeting.component.html',
  styleUrls: ['./activity-virtual-meeting.component.scss']
})
export class ActivityVirtualMeetingContainerComponent {
  isVirtualEvent$ = this.store.pipe(select(getIsVirtualEvent));
  visible$ = this.store.pipe(select(getVirtualMeetingVisibility),
    withLatestFrom(this.isVirtualEvent$),
    map(([visible, virtualEvent]) => visible || virtualEvent)
  );
  showActivityLocationBtn$ = this.store.pipe(select(fromStore.getActivityLocationVisibility),
    withLatestFrom(this.isVirtualEvent$),
    map(([visible, virtualEvent]) => !visible && virtualEvent)
  );

  linkValue$ = this.store.pipe(select(getOnlineMeetingLink));
  linkError$ = this.store.pipe(
    select(getEventActivityVirtualMeetingLinkIsValid),
    map(valid => valid ? null : this.translate.instant('bd/event-activity/form.link_too_long'))
  );

  passwordValue$ = this.store.pipe(select(getOnlineMeetingPassword));
  passwordError$ = this.store.pipe(
    select(getEventActivityVirtualMeetingPasswordIsValid),
    map(valid => valid ? null : this.translate.instant('bd/event-activity/form.password_too_long')));
  passwordWarning$ = combineLatest([this.linkValue$, this.passwordValue$]).pipe(
    map(([link, password]) => !link && !!password),
    map(warning => warning ? this.translate.instant('bd/event-activity/form.no_link_entered') : null)
  );

  constructor(
    private store: Store<fromRoot.State>,
    private translate: TranslateService
  ) {}

  onLinkChange(link: string) {
    this.store.dispatch(new UpdateOnlineMeetingLink(link));
  }

  onPasswordChange(password: string) {
    this.store.dispatch(new UpdateOnlineMeetingPassword(password));
  }

  onActivityLocation() {
    this.store.dispatch(new fromStore.SetActivityLocationVisibility(true));
  }
}
